import React from 'react'
import { connect } from 'react-redux'

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core'
import { Clear as CancelIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'

import agent from 'agent'
import { GET_CLASS_REPORTS, RELEASE_COMPLETED_REPORTS, UPDATE_REPORT } from 'constants/actionTypes'
import { CLASS_TYPE_REGULAR_CLASSES } from 'constants/generalTypes'
import CreateUpdateReportDialog from 'components/online-class/dialog-create-update-report'
import PreviewReportDialog from 'components/online-class/dialog-preview-report'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  loadingClassReports: state.report.loadingClassReports,
  classReports: state.report.classReports,
  creating: state.report.creating,
  updating: state.report.updating,
  releasingReports: state.report.releasingReports
})

const mapDispatchToProps = dispatch => ({
  getClassReport: (classId, title) =>
    dispatch({ type: GET_CLASS_REPORTS, payload: agent.Report.getByClass(classId, title), classId }),
  releaseCompletedReports: (classId, title) =>
    dispatch({ type: RELEASE_COMPLETED_REPORTS, payload: agent.Report.releaseCompleted(classId, title) }),
  cancelReport: reportId =>
    dispatch({ type: UPDATE_REPORT, payload: agent.Report.update('regular', reportId, { status: 'CANCELED' }) })
})

class ReportTab extends React.Component {
  state = {
    reportOpt: [],
    selectedReport: -1,
    tableData: [],
    selectedStudent: null,
    studentReport: null,
    isCreateUpdateReportDialogOpen: false,
    isPreviewDialogOpen: false
  }

  componentDidMount() {
    this.getReportOpt()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedReport !== this.state.selectedReport && this.state.selectedReport > -1) {
      const { classData } = this.props
      const { reportOpt, selectedReport } = this.state
      this.setState({ tableData: [] })
      this.props.getClassReport(classData._id, reportOpt[selectedReport].title)
    }

    if (prevProps.loadingClassReports && !this.props.loadingClassReports) {
      this.initTableData()
    }

    if (prevProps.creating && !this.props.creating) {
      this.initTableData()
    }

    if (prevProps.updating && !this.props.updating) {
      this.initTableData()
    }

    if (prevProps.releasingReports && !this.props.releasingReports) {
      const { classData } = this.props
      const { reportOpt, selectedReport } = this.state
      this.setState({ tableData: [] })
      this.props.getClassReport(classData._id, reportOpt[selectedReport].title)
    }
  }

  initTableData = () => {
    const { classReports } = this.props
    const { reportOpt, selectedReport } = this.state
    const reportParams = reportOpt[selectedReport]
    let tableData = []

    for (let i = 0; i < reportParams.student.length; i++) {
      const studentData = reportParams.student[i]

      let studentReport = _.find(classReports.data, o => {
        return o.student === studentData._id
      })
      const reportId = studentReport?._id || ''
      const reportStatus = studentReport?.status || 'N/A'

      tableData.push({
        studentId: studentData._id,
        email: studentData.email,
        name: studentData.name,
        reportId,
        reportStatus,
        badge: this.getBadgeStyle(reportStatus)
      })
    }

    this.setState({ tableData })
  }

  getBadgeStyle = status => {
    let backgroundColor = '#e0e0e0',
      color = '#2a2a2a'

    if (status === 'INIT') {
      backgroundColor = '#00bcd4'
      color = '#ffffff'
    } else if (status === 'COMPLETED') {
      backgroundColor = '#4caf50'
      color = '#ffffff'
    } else if (status === 'RELEASED') {
      backgroundColor = '#673ab7'
      color = '#ffffff'
    }

    return {
      backgroundColor,
      color
    }
  }

  getReportOpt = () => {
    const { class_type, lesson, student } = this.props.classData

    let reportOpt = []
    let selectedReportIndex = 0
    if (class_type === CLASS_TYPE_REGULAR_CLASSES) {
      let allTerms = _.uniq(_.map(lesson, 'meta.term'))
      for (let i = 0; i < allTerms.length; i++) {
        let termLessons = _.filter(lesson, o => {
          return Number(o.meta.term) === Number(allTerms[i])
        })
        let sortedTermLessons = _.orderBy(termLessons, ['scheduled_on'])

        if (
          moment(sortedTermLessons[0].scheduled_on) < moment() &&
          moment(sortedTermLessons[sortedTermLessons.length - 1].scheduled_on) > moment()
        ) {
          selectedReportIndex = i
        }

        let studentIDsToReveiceReport = sortedTermLessons[sortedTermLessons.length - 1].student
        let studentList = []

        for (let i = 0; i < studentIDsToReveiceReport.length; i++) {
          let studentData = _.find(student, o => {
            return o._id === studentIDsToReveiceReport[i]
          })
          studentList.push(studentData)
        }

        reportOpt.push({
          title: `Term ${allTerms[i]} Report`,
          term: allTerms[i],
          lessonIds: _.map(sortedTermLessons.slice(0, sortedTermLessons.length - 2), '_id'),
          termStart: moment(sortedTermLessons[0].scheduled_on).format('ll'),
          termEnd: moment(sortedTermLessons[sortedTermLessons.length - 1].scheduled_on).format('ll'),
          student: studentList
        })
      }
    } else {
      let sortedLesson = _.orderBy(lesson, ['scheduled_on'])
      reportOpt.push({
        title: 'Workshop Report',
        term: 'all',
        lessonIds: _.map(sortedLesson, '_id'),
        termStart: sortedLesson[0].scheduled_on,
        termEnd: sortedLesson[sortedLesson.length - 1].scheduled_on,
        student: student
      })
    }

    this.setState({ reportOpt, selectedReport: selectedReportIndex })
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  toggleCreateUpdateReportDialog = studentId => () => {
    const { classData, classReports } = this.props
    let studentData = _.find(classData.student, o => {
      return o._id === studentId
    })
    let reportData = _.find(classReports.data, o => {
      return o.student === studentId
    })
    this.setState({
      isCreateUpdateReportDialogOpen: !this.state.isCreateUpdateReportDialogOpen,
      selectedStudent: studentData,
      studentReport: reportData
    })
  }

  togglePreviewDialog = (reportId, studentId) => () => {
    const { classReports, classData } = this.props
    let reportData = _.find(classReports.data, o => {
      return o._id === reportId
    })

    let studentData = _.find(classData.student, o => {
      return o._id === studentId
    })

    this.setState({
      isPreviewDialogOpen: !this.state.isPreviewDialogOpen,
      studentReport: reportData,
      selectedStudent: studentData
    })
  }

  clickReleaseReports = () => {
    const { classData } = this.props
    const { reportOpt, selectedReport } = this.state
    this.props.releaseCompletedReports(classData._id, reportOpt[selectedReport].title)
  }

  clickCancelReport = reportId => () => {
    this.props.cancelReport(reportId)
  }

  render() {
    const { classData, loadingClassReports, releasingReports, currentUser } = this.props
    const {
      reportOpt,
      selectedReport,
      tableData,
      selectedStudent,
      studentReport,
      isCreateUpdateReportDialogOpen,
      isPreviewDialogOpen
    } = this.state

    return (
      <div>
        <Paper elevation={2} className="p-3 mb-3">
          <div className="d-flex align-items-center justify-content-between">
            <FormControl variant="outlined" style={{ minWidth: '250px' }}>
              <InputLabel>Select a report</InputLabel>
              <Select
                name="selectedReport"
                value={selectedReport > -1 ? selectedReport : ''}
                onChange={this.handleChange}
                label="Select a report">
                {reportOpt.map((group, index) => (
                  <MenuItem key={index} value={index}>
                    {group.title} ({group.termStart} - {group.termEnd})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              variant="outlined"
              color="primary"
              disabled={releasingReports}
              disableFocusRipple
              onClick={this.clickReleaseReports}>
              Release All Completed Reports
              {releasingReports && <CircularProgress color="primary" className="ml-2" size={15} />}
            </Button>
          </div>
        </Paper>

        {loadingClassReports && (
          <div className="text-center my-5">
            <CircularProgress size={40} color="primary" />
          </div>
        )}

        {tableData.length > 0 && (
          <Paper elevation={2} className="p-3">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>
                      <Chip
                        label={row.reportStatus}
                        style={{ backgroundColor: row.badge.backgroundColor, color: row.badge.color }}
                      />
                    </TableCell>
                    <TableCell>
                      {row.reportStatus !== 'CANCELED' && (
                        <div className="d-flex align-items-center">
                          {(['N/A', 'INIT'].includes(row.reportStatus) ||
                            currentUser._id === 'euea4bE5QPK0SEmJ7HpVOw1') && (
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              disableFocusRipple
                              className="mr-2"
                              onClick={this.toggleCreateUpdateReportDialog(row.studentId)}>
                              {row.reportStatus === 'N/A' ? 'Create' : 'Update'}
                            </Button>
                          )}
                          {row.reportStatus !== 'N/A' && (
                            <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              disableFocusRipple
                              className="mr-2"
                              onClick={this.togglePreviewDialog(row.reportId, row.studentId)}>
                              Preview
                            </Button>
                          )}
                          {row.reportStatus !== 'N/A' && (
                            <CancelIcon
                              className="text-danger bg-white clickable ml-2"
                              onClick={this.clickCancelReport(row.reportId)}
                            />
                          )}
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )}

        <Accordion>
          <AccordionSummary className="bg-light-gray mt-3" expandIcon={<ExpandMoreIcon />}>
            About Report Status (expand for detail)
          </AccordionSummary>
          <AccordionDetails>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>INIT</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>Report writing in process but not ready for upload</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>COMPLETE</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>
                    Ready for upload
                    <br />
                    (Should only be marked as completed after last lesson is over to account for
                    attendance/participation rates)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>RELEASED</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>Uploaded for student to view on LMS</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>CANCELED</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>Report recalled due to error; to contact Tech for next steps</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>

        <CreateUpdateReportDialog
          isOpen={isCreateUpdateReportDialogOpen}
          close={this.toggleCreateUpdateReportDialog()}
          reportParams={selectedReport > -1 ? reportOpt[selectedReport] : null}
          studentData={selectedStudent}
          classData={classData}
          reportData={studentReport}
        />

        <PreviewReportDialog
          isOpen={isPreviewDialogOpen}
          close={this.togglePreviewDialog()}
          reportData={studentReport}
          studentData={selectedStudent}
        />
      </div>
    )
  }
}

ReportTab.propTypes = {
  classData: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportTab)
