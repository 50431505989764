import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, CircularProgress, Paper, Tab, Tabs } from '@material-ui/core'
import { ArrowBackIos as BackIcon } from '@material-ui/icons'
import moment from 'moment'
import qs from 'qs'
import agent from 'agent'
import { GET_LESSON_BY_ID } from 'constants/actionTypes'
import { MY_CLASS_LIST, MY_CLASS_DETAILS } from 'routes/routes'
import JoinLessonButton from 'components/online-class/button-join-lesson'
import PlayRecordingDialog from 'components/online-class/dialog-play-recording'
import HomeTab from './tab-home'
import ResourceTab from './tab-resource'
import StudentTab from './tab-student'
import HomeworkTab from './tab-homework'
import ActivityTab from './tab-activity'
import ActivityRecordTab from './tab-activity-record'

const mapStateToProps = state => ({
  lessonData: state.lessons.selectedLesson,
  lessons: state.lessons.lessons
})

const mapDispatchToProps = dispatch => ({
  getLesson: lessonId => dispatch({ type: GET_LESSON_BY_ID, payload: agent.Lessons.getById2(lessonId) })
})

class LessonDetails extends React.Component {
  state = {
    activeTab: 'resources',
    recordingDialogOpen: false,
    resourceTypes: ['notes', 'optional', 'homework']
  }

  componentDidMount() {
    let lessonId = this.props.match.params.lessonId
    this.props.getLesson(lessonId)

    let parsed = qs.parse(window.location.search.slice(1))

    if (parsed.tf) {
      this.setState({ activeTab: parsed.tf })
    }
  }

  toggleRecordingDialog = () => {
    this.setState({ recordingDialogOpen: !this.state.recordingDialogOpen })
  }

  handleChange = (event, value) => {
    this.setState(prevState => {
      if (prevState.activeTab === 0 && value === 0) {
        return { activeTab: value, activeStudent: null }
      } else {
        return { activeTab: value }
      }
    })
  }

  render() {
    const { lessonData, lessons } = this.props
    const { activeTab, recordingDialogOpen } = this.state

    if (!lessonData) {
      return (
        <div className="manage-margin text-center py-5">
          <CircularProgress size={50} />
        </div>
      )
    }

    const current = moment()
    const lessonEndTime = moment(lessonData.ended_on)
    const lessonOver = current.diff(lessonEndTime, 'mins') > 0

    return (
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <Paper elevation={2} className="app-wrapper pb-0 pt-3 sticky-top">
          <div className="row align-items-center">
            <div className="col">
              <div className="row pb-1">
                <div className="col-12 text-muted text-small">
                  <span
                    className="mr-2 clickable"
                    onClick={() => {
                      this.props.history.push(MY_CLASS_LIST)
                    }}>
                    My Classes
                  </span>
                  /
                  <span
                    className="mx-2 clickable"
                    onClick={() => {
                      this.props.history.push(MY_CLASS_DETAILS.replace(':classId', lessonData.class._id))
                    }}>
                    {lessonData.class.name}
                  </span>
                  /<span className="mx-2 font-weight-semibold">{lessonData.name}</span>
                </div>
                <div className="col d-flex flex-row align-items-center mt-2">
                  <BackIcon
                    className="clickable mr-2"
                    onClick={() => {
                      this.props.history.goBack()
                    }}
                  />
                  <h1 className="title--rubik mb-0">{lessonData.name}</h1>
                </div>
              </div>
            </div>

            <div className="col-auto">
              <div className="d-flex flex-row">
                {lessonOver ? (
                  lessonData.playback_url && lessonData.playback_url.length > 0 ? (
                    <Button variant="contained" color="primary" onClick={this.toggleRecordingDialog}>
                      Watch Recording
                    </Button>
                  ) : (
                    <Button variant="contained" color="primary" disabled={true}>
                      Lesson recording ready soon...
                    </Button>
                  )
                ) : (
                  <JoinLessonButton lessonData={lessonData} bypassCountdown={true} />
                )}
              </div>
            </div>
          </div>
          <Tabs value={activeTab} onChange={this.handleChange} scrollButtons="off">
            <Tab className="tab" label="Info" value="home" disableFocusRipple />
            <Tab className="tab" label="Resources" value="resources" disableFocusRipple />
            <Tab className="tab" label="Activities" value="activities" disableFocusRipple />
            <Tab className="tab" label="Activity Records" value="activityrecords" disableFocusRipple />
            <Tab className="tab" label="Students" value="students" disableFocusRipple />
            <Tab className="tab" label="Homework" value="homework" disableFocusRipple />
          </Tabs>
        </Paper>
        <div className="app-wrapper">
          {activeTab === 'home' && <HomeTab lessonData={lessonData} />}
          {activeTab === 'resources' && <ResourceTab lessonId={lessonData._id} />}
          {activeTab === 'activities' && <ActivityTab lessonId={lessonData._id} activities={lessonData.activity} />}
          {activeTab === 'activityrecords' && (
            <ActivityRecordTab lessonId={lessonData._id} activities={lessonData.activity} lessons={lessons} />
          )}
          {activeTab === 'students' && (
            <StudentTab
              lessonId={lessonData._id}
              paidStudent={lessonData.student}
              trialStudent={lessonData.trial_student}
              videoStudent={lessonData.video_student}
            />
          )}
          {activeTab === 'homework' && <HomeworkTab lessonData={lessonData} />}
        </div>

        <PlayRecordingDialog
          isOpen={recordingDialogOpen}
          close={this.toggleRecordingDialog}
          lessonId={lessonData._id}
          originalVideoUrl={lessonData.playback_url}
        />
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LessonDetails))
