import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Paper,
  Slide,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core'
import _ from 'lodash'
import Proptypes from 'prop-types'
import { Pie, PieChart, ResponsiveContainer, Sector } from 'recharts'

import agent from 'agent'
import { GET_REPORT_PDF } from 'constants/actionTypes'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const mapStateToProps = state => ({
  loadingPDF: state.report.loadingPDF,
  classData: state.classes.selectedClass
})

const mapDispatchToProps = dispatch => ({
  getReportPDF: (reportId, fileName) =>
    dispatch({ type: GET_REPORT_PDF, payload: agent.Report.getPDF(reportId), fileName })
})

const renderActiveShape = props => {
  const RADIAN = Math.PI / 180
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, payload, value } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      <text x={cx + 5} y={cy - 5} dy={10} textAnchor="middle" fill="#333" fontSize={22}>
        {value}%
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={payload.color}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={payload.active}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={payload.active} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={payload.active} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
        {payload.label}
      </text>
    </g>
  )
}

class PreviewReportDialog extends React.Component {
  state = {
    textComponent: [],
    attendancePieData: [],
    pollPieData: [],
    homeworkPieData: []
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const { reportData } = this.props

      let textComponent = _.orderBy(reportData.text_component, ['order'])
      let attendancePieData = [
        {
          name: 'Attendance',
          label: 'Attended',
          value: reportData.attendance.percentage,
          color: '#FEBF0F',
          active: '#6200EE'
        },
        {
          name: 'Attendance',
          label: 'Absent',
          value: 100 - reportData.attendance.percentage,
          color: '#EEECE1',
          active: '#6200EE'
        }
      ]

      let pollPieData = [
        {
          name: 'Poll',
          label: 'Attempted',
          value: reportData.poll.percentage,
          color: '#FEBF0F',
          active: '#6200EE'
        },
        {
          name: 'Participation',
          label: 'Not answering',
          value: 100 - reportData.poll.percentage,
          color: '#EEECE1',
          active: '#6200EE'
        }
      ]

      let homeworkPieData = [
        {
          name: 'Homework',
          label: 'Submitted',
          value: reportData.homework.percentage,
          color: '#FEBF0F',
          active: '#6200EE'
        },
        {
          name: 'Homework',
          label: 'Missed',
          value: 100 - reportData.homework.percentage,
          color: '#EEECE1',
          active: '#6200EE'
        }
      ]

      this.setState({
        textComponent,
        attendancePieData,
        pollPieData,
        homeworkPieData
      })
    }

    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState({
        textComponent: [],
        attendancePieData: [],
        pollPieData: [],
        homeworkPieData: []
      })
    }
  }

  clickDownloadPDF = () => {
    const { reportData, studentData } = this.props
    this.props.getReportPDF(reportData._id, `${reportData.title} - ${studentData.name}.pdf`)
  }

  render() {
    const { isOpen, reportData, classData, studentData, loadingPDF } = this.props
    const { textComponent, attendancePieData, pollPieData, homeworkPieData } = this.state
    let classDetails = classData
    if (reportData?.class && _.isObject(reportData.class)) {
      classDetails = reportData.class
    }
    return (
      <Dialog open={isOpen} onClose={this.props.close} maxWidth="lg" fullWidth TransitionComponent={Transition}>
        <DialogTitle className="bg-light-gray">
          <div className="d-flex justify-content-between align-items-center">
            <div>Report Preview</div>
            <Button
              variant="outlined"
              color="primary"
              disabled={loadingPDF}
              disableFocusRipple
              onClick={this.clickDownloadPDF}>
              Download PDF {loadingPDF && <CircularProgress color="primary" className="ml-2" size={15} />}
            </Button>
          </div>
        </DialogTitle>
        <div className="row no-gutters">
          <div className="col-3" style={{ height: '50px', backgroundColor: '#febf0f' }} />
          <div className="col-3" style={{ height: '50px', backgroundColor: '#7ca6d8' }} />
          <div className="col-3" style={{ height: '50px', backgroundColor: '#e53997' }} />
          <div className="col-3" style={{ height: '50px', backgroundColor: '#9a4b9d' }} />
        </div>
        <DialogContent>
          <div className="row align-items-center">
            <div className="col-auto">
              <img
                src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/logo/tenopy-logo-only.png"
                alt="tenopy logo"
                width="100px"
                height="100px"
              />
            </div>
            <div className="col">
              <div style={{ fontSize: '50px', color: '#7e0fa9' }}>{reportData?.title}</div>
            </div>
          </div>

          <div style={{ padding: '20px' }}>
            {classDetails && studentData && (
              <div
                style={{
                  border: '1px solid #8d8d8d',
                  borderRadius: '5px',
                  backgroundColor: '#f9f9f9',
                  padding: '20px',
                  marginBottom: '30px'
                }}>
                <div style={{ fontSize: '24px' }}>
                  <span className="text-muted">Class: </span>
                  {classDetails.name}
                </div>
                <div style={{ fontSize: '24px' }}>
                  <span className="text-muted">Teacher: </span>
                  Teacher {_.map(classDetails.tutor, 'name').join(', Teacher ')}
                </div>
                <div style={{ fontSize: '24px' }}>
                  <span className="text-muted">Student: </span>
                  {studentData.name}
                </div>
              </div>
            )}

            {textComponent.map((component, index) => (
              <div
                key={index}
                style={{ borderBottom: '3px solid #8d8d8d', marginBottom: '30px', paddingBottom: '10px' }}>
                <div style={{ fontSize: '30px', color: '#7e0fa9', marginBottom: '15px' }}>{component.title}</div>
                <div style={{ fontSize: '18px' }} dangerouslySetInnerHTML={{ __html: component.content }} />
              </div>
            ))}

            <div
              className="row"
              style={{ border: '1px solid #8d8d8d', borderRadius: '5px', margin: '0 20px 20px 20px' }}>
              <div className="col-md-5 col-12">
                <ResponsiveContainer width="100%" height={280}>
                  <PieChart>
                    <Pie
                      dataKey="value"
                      activeIndex={0}
                      activeShape={renderActiveShape}
                      data={attendancePieData}
                      innerRadius={60}
                      outerRadius={80}
                      fill="#EEECE1"
                      paddingAngle={2}
                      cx="50%"
                      cy="55%"
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
              <div className="col-md-7 col-12" style={{ paddingTop: '15px' }}>
                <div style={{ fontSize: '24px', color: '#7e0fa9', marginBottom: '0px' }}>Attendance</div>
                <div className="text-muted" style={{ fontSize: '15px', marginBottom: '10px' }}>
                  {reportData?.attendance.desc}
                </div>
                {reportData && (
                  <Paper elevation={2} className="my-3">
                    <Table>
                      <TableBody>
                        {reportData.attendance.detail.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>{row.title}</TableCell>
                            <TableCell>:</TableCell>
                            <TableCell>{row.attended ? 'Attended' : 'Absent'}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                )}
              </div>
            </div>

            <div
              className="row"
              style={{ border: '1px solid #8d8d8d', borderRadius: '5px', margin: '0 20px 20px 20px' }}>
              <div className="col-md-5 col-12 text-center align-self-center">
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={reportData?.class_participation.gauge === 'Low'}
                          className={reportData?.class_participation.gauge === 'Low' ? 'text-purple' : 'text-muted'}
                        />
                      }
                      label="Low"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={reportData?.class_participation.gauge === 'Below Average'}
                          className={
                            reportData?.class_participation.gauge === 'Below Average' ? 'text-purple' : 'text-muted'
                          }
                        />
                      }
                      label="Below Average"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={reportData?.class_participation.gauge === 'Acceptable'}
                          className={
                            reportData?.class_participation.gauge === 'Acceptable' ? 'text-purple' : 'text-muted'
                          }
                        />
                      }
                      label="Acceptable"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={reportData?.class_participation.gauge === 'Very Good'}
                          className={
                            reportData?.class_participation.gauge === 'Very Good' ? 'text-purple' : 'text-muted'
                          }
                        />
                      }
                      label="Very Good"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={reportData?.class_participation.gauge === 'Outstanding'}
                          className={
                            reportData?.class_participation.gauge === 'Outstanding' ? 'text-purple' : 'text-muted'
                          }
                        />
                      }
                      label="Outstanding"
                    />
                  </FormGroup>
                </FormControl>
              </div>
              <div className="col-md-7 col-12" style={{ paddingTop: '15px' }}>
                <div style={{ fontSize: '24px', color: '#7e0fa9', marginBottom: '0px' }}>In-Class Participation</div>
                <div className="text-muted" style={{ fontSize: '15px', marginBottom: '10px' }}>
                  {reportData?.class_participation.desc}
                </div>
                <div
                  style={{ fontSize: '18px' }}
                  dangerouslySetInnerHTML={{ __html: reportData?.class_participation.comment }}
                />
              </div>
            </div>

            {/*
            <div
              className="row"
              style={{ border: '1px solid #8d8d8d', borderRadius: '5px', margin: '0 20px 20px 20px' }}>
              <div className="col-md-5 col-12">
                <ResponsiveContainer width="100%" height={250}>
                  <PieChart>
                    <Pie
                      dataKey="value"
                      activeIndex={0}
                      activeShape={renderActiveShape}
                      data={pollPieData}
                      innerRadius={60}
                      outerRadius={80}
                      fill="#EEECE1"
                      paddingAngle={2}
                      cx="50%"
                      cy="50%"
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
              <div className="col-md-7 col-12" style={{ paddingTop: '15px' }}>
                <div style={{ fontSize: '24px', color: '#7e0fa9', marginBottom: '0px' }}>Poll</div>
                <div style={{ fontSize: '18px', marginBottom: '10px' }}>{reportData?.poll.desc}</div>
              </div>
            </div>
            */}

            <div
              className="row"
              style={{ border: '1px solid #8d8d8d', borderRadius: '5px', margin: '0 20px 20px 20px' }}>
              <div className="col-md-5 col-12">
                <ResponsiveContainer width="100%" height={280}>
                  <PieChart>
                    <Pie
                      dataKey="value"
                      activeIndex={0}
                      activeShape={renderActiveShape}
                      data={homeworkPieData}
                      innerRadius={60}
                      outerRadius={80}
                      fill="#EEECE1"
                      paddingAngle={2}
                      cx="50%"
                      cy="55%"
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
              <div className="col-md-7 col-12" style={{ paddingTop: '15px' }}>
                <div style={{ fontSize: '24px', color: '#7e0fa9', marginBottom: '0px' }}>Homework</div>
                <div className="text-muted" style={{ fontSize: '15px', marginBottom: '10px' }}>
                  {reportData?.homework.desc}
                </div>
                <div style={{ fontSize: '18px' }} dangerouslySetInnerHTML={{ __html: reportData?.homework.comment }} />
                <div className="text-muted" style={{ fontSize: '15px', marginBottom: '10px' }}>
                  *Kindly note that late submissions for homework tasks will result in a delayed return of marked
                  scripts
                </div>
              </div>
            </div>
          </div>
        </DialogContent>

        <div className="row no-gutters">
          <div className="col-3" style={{ height: '50px', backgroundColor: '#febf0f' }} />
          <div className="col-3" style={{ height: '50px', backgroundColor: '#7ca6d8' }} />
          <div className="col-3" style={{ height: '50px', backgroundColor: '#e53997' }} />
          <div className="col-3" style={{ height: '50px', backgroundColor: '#9a4b9d' }} />
        </div>
      </Dialog>
    )
  }
}

PreviewReportDialog.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired,
  reportData: Proptypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewReportDialog)
