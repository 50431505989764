import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { CircularProgress } from '@material-ui/core'
import * as Sentry from '@sentry/browser'
import qs from 'qs'

import agent from 'agent'
import { GET_POST_PAYMENT_STAT } from 'constants/actionTypes'
import { MY_PAID_CLASSES } from 'routes/studentRoutes'
import AlertDialog from 'components/dialog/alert'
import PaymentSuccessDialog from './dialog-payment-success'

const mapStateToProps = state => ({
  gettingPostPaymentStat: state.shoppingCart.gettingPostPaymentStat,
  postPaymentStat: state.shoppingCart.postPaymentStat
})

const mapDispatchToProps = dispatch => ({
  getPaymentStat: reqPayload =>
    dispatch({ type: GET_POST_PAYMENT_STAT, payload: agent.ShoppingCart.getPostPaymentStat(reqPayload) })
})

class ShoppingCart extends React.Component {
  state = {
    delayLoading: true,
    isPaymentSuccessDialogOpen: false,
    paidList: [],
    isAlertDialogOpen: false
  }

  componentDidMount() {
    this.delayLoading = setTimeout(() => {
      this.setState({ delayLoading: false })
    }, 2000)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.delayLoading && !this.state.delayLoading) {
      let parsed = qs.parse(this.props.location.search.slice(1))
      if (parsed.reference && parsed.status === 'completed') {
        this.props.getPaymentStat(parsed.reference)
      } else if (parsed.status === 'failed') {
        this.setState({ isAlertDialogOpen: true })
        Sentry.captureEvent({
          message: 'Hitpay paynow failed',
          extra: {
            ref: parsed.reference
          }
        })
      } else {
        this.props.history.push(MY_PAID_CLASSES)
      }
    }

    if (prevProps.gettingPostPaymentStat && !this.props.gettingPostPaymentStat) {
      const { postPaymentStat } = this.props
      if (postPaymentStat.error) {
        this.setState({
          isAlertDialogOpen: true,
          alertDetail: {
            title: 'Something went wrong',
            content: postPaymentStat.message
          }
        })
      } else {
        this.setState({
          isPaymentSuccessDialogOpen: true,
          paidList: postPaymentStat.order
        })
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.delayLoading)
  }

  closePaymentSuccessDialog = () => {
    this.setState({
      isPaymentSuccessDialogOpen: false,
      paidList: []
    })
    this.props.history.push(MY_PAID_CLASSES)
  }

  closeAlertDialog = () => {
    this.setState({ isAlertDialogOpen: false })
    this.props.history.push(MY_PAID_CLASSES)
  }

  render() {
    const { gettingPostPaymentStat } = this.props
    const { delayLoading, isPaymentSuccessDialogOpen, paidList, isAlertDialogOpen } = this.state

    return (
      <div className={'container-fluid my-4'}>
        <Helmet>
          <title>Shopping Cart | Tenopy</title>
          <meta
            name="description"
            content="At Tenopy, top online teachers lead hands-on classes live with our next-generation classroom technology. Achieve results for English, Math and Science at Primary and Secondary levels with our tuition classes. Enrich learning beyond mere grades with our featured learning programmes."
          />
          <meta
            name="keywords"
            content="Online tuition,Math tuition,English tuition,Secondary school tuition,Primary school tuition,Online tuition singapore,Online learning,Online education,Education platform,Online tutor,Home tutor,home tuition,science tuition,sa1,sa2,ca1,ca2,psle,revision,online revision,online study,online learning,psle revision"
          />
        </Helmet>

        {(gettingPostPaymentStat || delayLoading) && (
          <div className="my-5 text-center">
            <CircularProgress size={40} color="primary" />
          </div>
        )}

        <PaymentSuccessDialog
          isOpen={isPaymentSuccessDialogOpen}
          close={this.closePaymentSuccessDialog}
          paidList={paidList}
        />

        <AlertDialog
          isOpen={isAlertDialogOpen}
          close={this.closeAlertDialog}
          title="Something wrong"
          content="<p>We have encountered a problem while processing items in your cart. Your cart will be cleared, please add back the item you wish to buy.</p><p>Please do not hestitate to contact us for help</p>"
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart)
