import React from 'react'
import { connect } from 'react-redux'
import agent from '../../../agent'
import { Link, withRouter } from 'react-router-dom'
import _ from 'lodash'
import { Card, CardBody } from 'reactstrap'
import { USER_PROFILE } from '../../../routes/routes'
import { validatePassword, validateRetypePassword } from '../../../utils/formValidation'
import { CHANGE_PASSWORD } from '../../../constants/actionTypes'
import { Button, IconButton, FormControl, FormHelperText, Input, InputAdornment, Paper } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

const mapStateToProps = state => ({
  ...state.auth
})

const mapDispatchToProps = dispatch => ({
  changePassword: password => dispatch({ type: CHANGE_PASSWORD, payload: agent.Auth.changePassword({ password }) })
})

class Edit extends React.Component {
  state = {
    password: '',
    confirmPassword: '',
    isPasswordVisible: '',
    formError: {}
  }

  togglePasswordVisibility = () => {
    this.setState({
      isPasswordVisible: !this.state.isPasswordVisible
    })
  }

  validateFormElement = type => {
    let errorMsg

    if (type === 'password') {
      errorMsg = validatePassword(this.state.password)
    } else if (type === 'confirmPassword') {
      errorMsg = validateRetypePassword(this.state[type], this.state.password)
    }

    let tempFormError = this.state.formError
    if (errorMsg) {
      tempFormError[type] = errorMsg
    } else {
      delete tempFormError[type]
    }
    this.setState({ formError: tempFormError })
  }

  onChangeFormElement = e => {
    let type = e.target.name
    let value = e.target.value
    this.setState({ [type]: value })
  }

  onFocusFormElement = e => {
    let type = e.target.name
    let tempFormError = this.state.formError
    delete tempFormError[type]
    this.setState({ formError: tempFormError })
  }

  submitChanges = () => {
    this.validateFormElement('password')
    this.validateFormElement('confirmPassword')

    if (_.isEmpty(this.state.formError)) {
      this.props.changePassword(this.state.password)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.changePasswordInProgress && !this.props.changePasswordInProgress) {
      this.props.history.push(USER_PROFILE)
    }
  }

  render() {
    let { changePasswordInProgress } = this.props
    let { password, confirmPassword, isPasswordVisible, formError } = this.state

    return (
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <Paper elevation={2} className="app-wrapper">
          <h1 className="mb-0">Change Password</h1>
        </Paper>
        <div className="app-wrapper">
          <div className="container">
            <Card className="border-0 shadow">
              <CardBody>
                <table className="table default-table table-sm remove-table-border mb-0">
                  <tbody>
                    <tr>
                      <td valign="top">
                        <h4>New Password</h4>
                      </td>
                      <td>
                        <FormControl className="mb-3" fullWidth>
                          <Input
                            type={isPasswordVisible ? 'text' : 'password'}
                            name="password"
                            value={password}
                            onChange={this.onChangeFormElement}
                            error={!!formError.password}
                            onFocus={this.onFocusFormElement}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Toggle password visibility"
                                  onClick={this.togglePasswordVisibility}>
                                  {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          {formError.password && <FormHelperText error={true}>{formError.password}</FormHelperText>}
                        </FormControl>
                      </td>
                    </tr>
                    <tr valign="middle">
                      <td>
                        <h4 className="mb-0">Retype Password</h4>
                      </td>
                      <td>
                        <FormControl className="mb-3" fullWidth>
                          <Input
                            type={isPasswordVisible ? 'text' : 'password'}
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={this.onChangeFormElement}
                            error={!!formError.confirmPassword}
                            onFocus={this.onFocusFormElement}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Toggle password visibility"
                                  onClick={this.togglePasswordVisibility}>
                                  {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          {formError.confirmPassword && (
                            <FormHelperText error={true}>{formError.confirmPassword}</FormHelperText>
                          )}
                        </FormControl>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="d-flex flex-row-reverse flex-wrap align-items-center">
                  <Button
                    variant="contained"
                    color="primary"
                    className="mb-2 mr-2"
                    onClick={this.submitChanges}
                    disabled={changePasswordInProgress}>
                    Change Password
                  </Button>
                  <Link to={USER_PROFILE} className="mb-2 mr-3">
                    Cancel
                  </Link>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Edit))
