import * as Type from '../constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case Type.GET_ORDER:
      return {
        ...state,
        getOrderInProgress: true,
        currentPage: action.page ? action.page : 1
      }

    case Type.GET_ORDER_SUCCESS:
      return {
        ...state,
        getOrderInProgress: false,
        orders: action.payload.data,
        orderCount: action.payload.count,
        currentPage: action.page ? action.page : 1
      }

    case Type.GET_ORDER_FAILED:
      return {
        ...state,
        getOrderInProgress: false
      }

    default:
      return state
  }
}
