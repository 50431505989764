import React from 'react'
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { Avatar, Button, CircularProgress, Dialog, DialogContent, Slide } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Proptypes from 'prop-types'

import agent from 'agent'
import { GET_TUTOR_INFO } from 'constants/actionTypes'

const mapStateToProps = state => ({
  loadingTutorData: state.info.loadingTutorData,
  tutorList: state.info.tutors
})

const mapDispatchToProps = dispatch => ({
  getTutorInfo: slug => dispatch({ type: GET_TUTOR_INFO, payload: agent.Info.getTutor(slug), slug })
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

class TutorProfileDialog extends React.Component {
  state = {
    tutorData: '',
    profileImageHeight: 300
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isOpen && this.props.isOpen) {
      let tutorData = this.getTutorData()
      if (tutorData) {
        this.setState({ tutorData })
      } else {
        this.props.getTutorInfo(this.props.slug)
      }
    }

    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState({ tutorData: '' })
    }

    if (prevProps.loadingTutorData && !this.props.loadingTutorData) {
      let tutorData = this.getTutorData()
      this.setState({ tutorData })
    }

    if (prevState.tutorData !== this.state.tutorData) {
      let element = document.getElementById('profile-desc')
      if (element) {
        this.setState({ profileImageHeight: element.offsetHeight })
      }
    }
  }

  getTutorData = () => {
    const { slug, tutorList } = this.props
    let tutorData = tutorList[slug] ?? ''
    return tutorData
  }

  getElementHeight = () => {
    let element = document.getElementById('profile-desc')
    return element.offsetHeight
  }

  render() {
    const { isOpen } = this.props
    const { tutorData, profileImageHeight } = this.state

    return (
      <Dialog open={isOpen} onClose={this.props.close} TransitionComponent={Transition} maxWidth="md">
        <DialogContent className="p-0">
          {tutorData ? (
            <div className="row no-gutters align-items-center">
              {isMobile ? (
                <div className="col-12 pt-3">
                  <Avatar
                    src={tutorData.meta.pic.formal.head}
                    alt={tutorData.name}
                    className="size-100 avatar-shadow mx-auto"
                  />
                  <Close
                    className="position-absolute clickable m-2"
                    onClick={this.props.close}
                    style={{ right: 0, top: 0 }}
                  />
                </div>
              ) : (
                <div
                  className="col-4 align-self-end"
                  style={{
                    backgroundImage: `url(${tutorData.meta.pic.informal.hb})`,
                    height: `${profileImageHeight}px`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                />
              )}

              <div className="col align-self-end py-3 px-md-4 px-3" id="profile-desc">
                {!isMobile && <h2 className="title--rubik font-weight-normal my-2">{tutorData.name}</h2>}
                <p className="text-justify" dangerouslySetInnerHTML={{ __html: tutorData.desc }} />
                <div className="text-right">
                  {tutorData.meta?.video?.intro && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="mr-2"
                      onClick={() => {
                        this.props.showVideoIntro({
                          isOpen: true,
                          videoUrl: tutorData.meta?.video?.intro
                        })
                        this.props.close()
                      }}>
                      Watch Video Intro
                    </Button>
                  )}
                  <Button variant="outlined" color="primary" onClick={this.props.close}>
                    Close
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="m-5">
              <CircularProgress color="primary" size={35} />
            </div>
          )}
        </DialogContent>
      </Dialog>
    )
  }
}

TutorProfileDialog.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorProfileDialog)
