import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import MomentUtils from '@date-io/moment'
import { Button, Paper } from '@material-ui/core'
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers'
import _ from 'lodash'
import moment from 'moment'
import agent from 'agent'
import { GET_HOMEWORK } from 'constants/actionTypes'
import { SUBMISSION_LIST } from 'routes/tutorRoutes'
import HomeworkList from 'components/tutor/Homework/List'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  loadingHomeworkList: state.homework.loadingHomeworkList,
  homeworkList: state.homework.homeworkList
})

const mapDispatchToProps = dispatch => ({
  getHomeworkList: filter => dispatch({ type: GET_HOMEWORK, payload: agent.Homework.getList(filter), filter })
})

class HomeworkTab extends React.Component {
  state = {
    fromDate: moment().subtract(30, 'day'),
    toDate: moment().add(7, 'day')
  }

  componentDidMount() {
    this.getHomework()
  }

  getHomework = () => {
    let { homeworkList, noDateFilter, classData } = this.props
    let { fromDate, toDate } = this.state

    let filter = {
      status: 'COMPLETED'
    }

    if (!noDateFilter) {
      filter.released_on = {
        $gte: fromDate.toISOString(),
        $lte: toDate.toISOString()
      }
    }

    if (classData) {
      let lessonIds = _.map(classData.lesson, '_id')
      filter.lesson = {
        $in: lessonIds
      }
    }

    let max = 25
    let offset = homeworkList.data.length

    if (!_.isEqual(filter, homeworkList.filter)) {
      offset = 0
    }

    filter['max'] = max
    filter['offset'] = offset
    this.props.getHomeworkList(filter)
  }

  handleDateChange = name => date => {
    this.setState({ [name]: date })
  }

  viewSubmissionByHomework = homeworkId => () => {
    let classId = this.props.classData._id
    let redirectURL = SUBMISSION_LIST.replace('classId', classId).replace(':homeworkId', homeworkId)

    this.props.history.push(redirectURL)
  }

  render() {
    let { homeworkList } = this.props
    let { fromDate, toDate } = this.state

    return (
      <Fragment>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Paper className="p-3">
            <div className="d-flex align-items-center">
              <KeyboardDateTimePicker
                variant="inline"
                inputVariant="outlined"
                label="From"
                name="fromDate"
                value={fromDate}
                onChange={this.handleDateChange('fromDate')}
                format="DD/MM/YYYY hh:mm a"
                className="mr-3"
              />
              <KeyboardDateTimePicker
                variant="inline"
                inputVariant="outlined"
                label="To"
                name="toDate"
                value={toDate}
                onChange={this.handleDateChange('toDate')}
                format="DD/MM/YYYY hh:mm a"
                className="mr-3"
              />
              <Button variant="contained" color="primary" onClick={this.getHomework} disableFocusRipple>
                Search
              </Button>
            </div>
            <div className="text-small text-muted mt-2">* List homework released within date range</div>
          </Paper>
        </MuiPickersUtilsProvider>

        <HomeworkList />

        {homeworkList.data.length < homeworkList.count && (
          <div className="d-flex justify-content-end p-3">
            <Button variant="outlined" color="primary" onClick={this.getHomework}>
              Load more...
            </Button>
          </div>
        )}
      </Fragment>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeworkTab))
