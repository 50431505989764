import React from 'react'
import { Paper, Table, TableBody, TableRow, TableCell } from '@material-ui/core'
import moment from 'moment'

const HomeTab = props => {
  let { lessonData } = props
  let lessonMeta = lessonData.meta
  let currentTime = moment()
  let lessonScheduledTime = moment(lessonData.scheduled_on)
  let lessonEndTime = moment(lessonData.ended_on)
  let lessonEnded = currentTime > lessonEndTime
  let lessonInSession = currentTime > lessonScheduledTime && currentTime < lessonEndTime
  let lessonInFuture = currentTime < lessonScheduledTime

  return (
    <Paper className="text-large p-3">
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="text-muted">Class type</TableCell>
            <TableCell className="text-muted">:</TableCell>
            <TableCell align="left">{lessonData.class.class_type}</TableCell>
          </TableRow>
          {lessonMeta && (
            <TableRow>
              <TableCell className="text-muted">Lesson</TableCell>
              <TableCell className="text-muted">:</TableCell>
              <TableCell align="left">
                {lessonMeta.term ? `Term ${lessonMeta.term} ` : ''}{' '}
                {lessonMeta.index ? `Lesson ${lessonMeta.index}` : ''}
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell className="text-muted">Scheduled on</TableCell>
            <TableCell className="text-muted">:</TableCell>
            <TableCell align="left">
              <span className="mr-2">{moment(lessonData.scheduled_on).format('lll')}</span>
              {lessonInFuture && (
                <span className="tag info-tag--outline">In {currentTime.to(lessonData.scheduled_on, true)}</span>
              )}
              {lessonInSession && <span className="tag success-tag">Currently in session</span>}
              {lessonEnded && <span className="tag danger-tag--outline">Ended</span>}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="text-muted">Student count</TableCell>
            <TableCell className="text-muted">:</TableCell>
            <TableCell align="left">{lessonData.student.length}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="text-muted">Trial Student count</TableCell>
            <TableCell className="text-muted">:</TableCell>
            <TableCell align="left">{lessonData.trial_student.length}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="text-muted">Host on</TableCell>
            <TableCell className="text-muted">:</TableCell>
            <TableCell align="left">{lessonData.hosting_platform}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  )
}

export default HomeTab
