import * as Type from '../constants/actionTypes'

const initialState = {
  loadingUpcomingLessons: false,
  upcomingLessons: [],
  loadingLesson: false,
  lessonData: null,
  loadingLessonLink: false,
  lessonLink: { id: '', url: '' },
  loadingLessonList: false,
  lessonList: { data: [], count: 0 },
  removingActivity: false,
  updatingLesson: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.GET_UPCOMING_LESSON: // NOTE: to remove
      return {
        ...state,
        getUpcomingInProgress: true,
        upcoming: null
      }

    case Type.GET_UPCOMING_LESSON_SUCCESS:
      return {
        ...state,
        getUpcomingInProgress: false,
        upcoming: action.payload.data
      }

    case Type.GET_UPCOMING_LESSON_FAILED:
      return {
        ...state,
        getUpcomingInProgress: false
      }

    case Type.GET_UPCOMING_LESSONS:
      return {
        ...state,
        loadingUpcomingLessons: true,
        upcomingLessons: []
      }

    case Type.GET_UPCOMING_LESSONS_SUCCESS:
      return {
        ...state,
        loadingUpcomingLessons: false,
        upcomingLessons: action.payload.data
      }

    case Type.GET_UPCOMING_LESSONS_FAILED:
      return {
        ...state,
        loadingUpcomingLessons: false
      }

    case Type.GET_CLASS_LESSONS: // NOTE: review usage, move to lessonList if can
      return {
        ...state,
        inProgress: true,
        lessons: null,
        currentPage: action.page ? action.page : 1
      }

    case Type.GET_CLASS_LESSONS_SUCCESS:
      return {
        ...state,
        inProgress: false,
        lessons: action.payload,
        lessonCount: action.payload.count,
        currentPage: action.page ? action.page : 1
      }

    case Type.GET_CLASS_LESSONS_FAILED:
      return {
        ...state,
        inProgress: false
      }

    case Type.LOAD_CLASS_SUCCESS:
      return {
        ...state,
        upcoming: action.payload[1].data,
        lessons: action.payload[0],
        lessonCount: action.payload[0].count,
        currentPage: 1
      }

    // TODO: merge get lesson by id  & get lesson details
    case Type.GET_LESSON_BY_ID:
      return {
        ...state,
        inProgress: true,
        selectedLesson: null
      }

    case Type.GET_LESSON_BY_ID_SUCCESS:
      return {
        ...state,
        inProgress: false,
        selectedLesson: action.payload.data
      }

    case Type.GET_LESSON_BY_ID_FAILED:
      return {
        ...state,
        inProgress: false,
        selectedLesson: null
      }

    case Type.GET_LESSON_DETAILS:
      return {
        ...state,
        loadingLesson: true,
        lessonData: null
      }

    case Type.GET_LESSON_DETAILS_SUCCESS:
      return {
        ...state,
        loadingLesson: false,
        lessonData: action.payload.data
      }

    case Type.GET_LESSON_DETAILS_FAILED:
      return {
        ...state,
        loadingLesson: false
      }

    case Type.GET_LESSON_LINK:
      return {
        ...state,
        loadingLessonLink: true,
        lessonLink: { id: '', url: '' }
      }

    case Type.GET_LESSON_LINK_SUCCESS:
      return {
        ...state,
        loadingLessonLink: false,
        lessonLink: {
          id: action.lessonId,
          url: action.payload.data.url
        }
      }

    case Type.GET_LESSON_LINK_FAILED:
      return {
        ...state,
        loadingLessonLink: false,
        lessonLink: {
          id: action.lessonId,
          error: action.payload
        }
      }

    case Type.GET_LESSON_LIST:
      return {
        ...state,
        loadingLessonList: true,
        lessonList: { data: [], count: 0 }
      }

    case Type.GET_LESSON_LIST_SUCCESS:
      return {
        ...state,
        loadingLessonList: false,
        lessonList: {
          data: action.payload.data,
          count: action.payload.count
        }
      }

    case Type.GET_LESSON_LIST_FAILED:
      return {
        ...state,
        loadingLessonList: false
      }

    case Type.REMOVE_ACTIVITY_FROM_LESSON:
      return {
        ...state,
        removingActivity: true
      }

    case Type.REMOVE_ACTIVITY_FROM_LESSON_SUCCESS:
    case Type.REMOVE_ACTIVITY_FROM_LESSON_FAILED:
      return {
        ...state,
        removingActivity: false
      }

    case Type.UPDATE_LESSON:
      return {
        ...state,
        updatingLesson: true
      }

    case Type.UPDATE_LESSON_SUCCESS:
    case Type.UPDATE_LESSON_FAILED:
      return {
        ...state,
        updatingLesson: false
      }

    case Type.LOGOUT:
      return initialState

    default:
      return state
  }
}
