import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Register from './Register'
import Login from './Login'
import qs from 'qs'
import { DASHBOARD } from 'routes/studentRoutes'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser
})

class LoginRegister extends React.Component {
  state = {
    hasAccount: false
  }

  componentDidUpdate(prevProps) {
    // TODO: check the usage of this
    if (prevProps.currentUser === null && !!this.props.currentUser) {
      if (this.props.nextLocation === 'none') {
        return null
      } else {
        let promo = qs.parse(this.props.location.search.slice(1)).promo
        let nextLocation = !!this.props.nextLocation ? this.props.nextLocation : DASHBOARD
        this.props.history.push(`${nextLocation}${!!promo ? `?promo=${promo}` : ''}`)
      }
    }
  }

  toggleSignup = () => {
    this.setState({ hasAccount: !this.state.hasAccount })
  }

  render() {
    const { loginButtonText, registerButtonText, header, registerSubHeader } = this.props
    const { hasAccount } = this.state

    return (
      <div>
        {hasAccount ? (
          <div>
            <div className="text__header mb-2">{header}</div>
            <div className="text-primary">
              <span className="clickable" onClick={this.toggleSignup}>
                Don't have an account? <b>Click Here.</b>
              </span>
            </div>
          </div>
        ) : (
          <div>
            <div className="text__header mb-0">New Student</div>
            <div className="mb-2">{registerSubHeader ?? 'Create an account to start attending lessons online'}</div>
            <div className="text-primary">
              <span className="clickable" onClick={this.toggleSignup}>
                Already have an account?
              </span>
            </div>
          </div>
        )}
        <hr />
        {hasAccount ? <Login buttonText={loginButtonText} /> : <Register buttonText={registerButtonText} />}
      </div>
    )
  }
}

LoginRegister.propTypes = {
  header: PropTypes.string,
  nextLocation: PropTypes.string,
  loginButtonText: PropTypes.string,
  registerButtonText: PropTypes.string,
  customAction: PropTypes.string,
  registerSubHeader: PropTypes.string
}

LoginRegister.defaultProps = {
  header: 'Log In & Join Class',
  nextLocation: '/app/class'
}

export default withRouter(connect(mapStateToProps)(LoginRegister))
