import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField
} from '@material-ui/core'
import Proptypes from 'prop-types'

import agent from 'agent'
import { UPDATE_TICKET } from 'constants/actionTypes'

const mapStateToProps = state => ({
  updatingTicket: state.ticket.updating
})

const mapDispatchToProps = dispatch => ({
  updateTicket: reqPayload => dispatch({ type: UPDATE_TICKET, payload: agent.Ticket.update(reqPayload) })
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

class UpdateTicketDialog extends React.Component {
  state = {
    alertMessage: '',
    comment: ''
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const { ticketData } = this.props
      if (ticketData?.tutor_comment) {
        this.setState({ comment: ticketData.tutor_comment })
      }
    }

    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState({ alertMessage: '', comment: '' })
    }

    if (prevProps.updatingTicket && !this.props.updatingTicket) {
      if (this.props.isOpen) {
        this.props.close()
      }
    }
  }

  handleChange = e => {
    this.setState({
      alertMessage: '',
      [e.target.name]: e.target.value
    })
  }

  updateTicket = () => {
    const { studentData, lessonId } = this.props
    const { comment } = this.state

    if (!comment) {
      this.setState({ alertMessage: `Please leave trial lesson comment for student (${studentData.name})` })
      return
    }

    this.props.updateTicket({
      lesson: lessonId,
      student: studentData._id,
      tutor_comment: comment
    })
  }

  render() {
    const { isOpen, close, studentData, updatingTicket } = this.props
    const { alertMessage, comment } = this.state

    return (
      <Dialog open={isOpen} onClose={close} TransitionComponent={Transition} maxWidth="sm" fullWidth>
        <DialogTitle>
          <span className="h2 mb-0">Trial Comment</span>
        </DialogTitle>
        <DialogContent>
          {studentData && (
            <div className="row justify-content-center">
              <div className="col-12 mb-3">
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>User</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>
                        {studentData.name} ({studentData.email ?? '---'})
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>

              <div className="col-12">
                <FormControl className="w-100 mb-2">
                  <TextField
                    label="Comment"
                    name="comment"
                    onChange={this.handleChange}
                    value={comment}
                    variant="outlined"
                    multiline
                    rows={6}
                    error={!!alertMessage}
                    helperText={alertMessage}
                  />
                </FormControl>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary" variant="text">
            Close
          </Button>
          <Button onClick={this.updateTicket} color="primary" variant="contained" disabled={updatingTicket}>
            Update {updatingTicket && <CircularProgress size={15} color="primary" />}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

UpdateTicketDialog.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateTicketDialog)
