import { DRAWER_TYPE, FIXED_DRAWER } from '../constants/settingsTypes'
import { TOGGLE_COLLAPSED_NAV, UPDATE_WINDOW_WIDTH } from '../constants/actionTypes'

const initialSettings = {
  navCollapsed: false,
  drawerType: FIXED_DRAWER,
  width: window.innerWidth,
  isDirectionRTL: false,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  }
}

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        navCollapsed: false
      }
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.isNavCollapsed
      }
    case DRAWER_TYPE:
      return {
        ...state,
        drawerType: action.drawerType
      }
    case UPDATE_WINDOW_WIDTH:
      return {
        ...state,
        width: action.width
      }
    default:
      return state
  }
}

export default settings
