import React, { useState } from 'react'
import Proptypes from 'prop-types'
import { Button, Dialog, Slide } from '@material-ui/core'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const G3 = {
  id: 'oATKhRjURjOPqS1gK1m-ZA',
  img: 'https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/ww-3000-wordlist-g3.jpg'
}
const G8 = {
  id: 'hffgGoeZTsiCqJ-gUxegAw',
  img: 'https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/ww-3000-wordlist-g8.jpg'
}

const VideoPlayerDialog = props => {
  const { classId } = props
  const [isOpen, setIsOpen] = React.useState(false)
  const [img, setImg] = useState('')

  if (classId !== G3.id && classId !== G8.id) {
    return null
  }

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          setIsOpen(true)
          if (classId === G3.id) {
            setImg(G3.img)
          } else if (classId === G8.id) {
            setImg(G8.img)
          }
        }}>
        View Word List
      </Button>

      <Dialog
        fullWidth
        maxWidth="md"
        open={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        TransitionComponent={Transition}>
        <div className="text-center">
          <img src={img} alt="word list" className="img-fluid" />
        </div>
      </Dialog>
    </>
  )
}

VideoPlayerDialog.propTypes = {
  classId: Proptypes.string
}

export default VideoPlayerDialog
