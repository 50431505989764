import React from 'react'
import Countdown from 'react-countdown'

import { CircularProgress, Dialog, DialogContent, Slide } from '@material-ui/core'
import moment from 'moment'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const countdownrRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return (
      <div className="text-center my-5">
        <CircularProgress size={30} color="primary" />
      </div>
    )
  } else {
    return (
      <>
        {days > 0 && (
          <div className="mx-3">
            <div
              className=""
              style={{ fontSize: '3rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif", lineHeight: 1.2 }}>
              {days}
            </div>
            days
          </div>
        )}
        <div className="mx-3">
          <div
            className=""
            style={{ fontSize: '3rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif", lineHeight: 1.2 }}>
            {hours}
          </div>
          hours
        </div>
        <div className="mx-3">
          <div
            className=""
            style={{ fontSize: '3rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif", lineHeight: 1.2 }}>
            {minutes}
          </div>
          minutes
        </div>
        <div className="mx-3">
          <div
            className=""
            style={{ fontSize: '3rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif", lineHeight: 1.2 }}>
            {seconds}
          </div>
          seconds
        </div>
      </>
    )
  }
}

class CountdownDialog extends React.Component {
  joinLessonOnCountdownCompleted = () => {
    this.props.close()
    this.props.proceedToLesson()
  }

  render() {
    const { isOpen, startTime, title } = this.props

    if (!startTime) {
      return null
    }

    const now = moment()
    const then = moment(startTime).subtract(5, 'minutes')
    const duration = moment.duration(then.diff(now)).asMilliseconds()

    return (
      <Dialog open={isOpen} onClose={this.props.close} TransitionComponent={Transition} maxWidth="md">
        <DialogContent>
          <div className="p-4 mb-3">
            <div
              className="mb-3"
              style={{ fontSize: '1.8rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif", lineHeight: 1.2 }}>
              {title}
            </div>
            <div className="d-flex flex-row align-item-center justify-content-center">
              <Countdown
                date={Date.now() + duration}
                renderer={countdownrRenderer}
                onComplete={this.joinLessonOnCountdownCompleted}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

export default CountdownDialog
