import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { Avatar, Dialog, DialogContent, Slide } from '@material-ui/core'
import Proptypes from 'prop-types'
import { APP, FREE_SHARING_VIDEO } from 'routes/routes'
import LoginRegister from 'components/shared/LoginRegister/LoginRegister'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const mapStateToProps = state => ({
  token: state.common.token,
  currentUser: state.common.currentUser
})

const mapDispatchToProps = dispatch => ({})

class RegisterToWatch extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.currentUser === null && !!this.props.currentUser) {
      this.props.history.push(`${APP}${FREE_SHARING_VIDEO.replace(':eventId', this.props.eventData._id)}`)
    }
  }

  render() {
    let { eventData, isOpen } = this.props

    if (!eventData) {
      return null
    }

    let tutorData = eventData.host_by ?? {
      name: 'Tenopy tutor',
      profile_pic: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/tutors/placeholder.jpg'
    }

    let eventName = eventData.name
    if (eventData.meta?.series?.name) {
      eventName = `${eventData.meta?.series?.name} Ep ${eventData.meta?.series?.order}: ${eventData.name}`
    }

    return (
      <Dialog open={isOpen} onClose={this.props.close} TransitionComponent={Transition} maxWidth="md">
        <DialogContent>
          <div className="row align-items-center" style={{ overflow: 'hidden' }}>
            <div className={`col-md-5 col-12 ${isMobile ? 'border-bottom' : 'border-right'}`}>
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="d-flex align-items-center">
                    <Avatar className="size-60 avatar-shadow mr-2" alt={tutorData.name} src={tutorData.profile_pic} />
                    <div>
                      <h3 className="mb-0">{eventName}</h3>
                      <span>by {tutorData.name}</span>
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="label text-small text-muted mb-2">Description</div>
                  <div dangerouslySetInnerHTML={{ __html: eventData.desc }} />
                </div>
              </div>
            </div>
            <div className="col-md-7 col-12 px-4" style={{ position: 'relative' }}>
              <LoginRegister
                header="Join Live Sharing for FREE"
                registerSubHeader="Create an account to watch past live sharing"
                nextLocation="none"
                registerButtonText="Register"
                loginButtonText="Log In"
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

RegisterToWatch.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired,
  eventData: Proptypes.object
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterToWatch))
