import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { isMobile } from 'react-device-detect'

class Pagination extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pager: {}
    }
  }

  componentDidMount() {
    // set page if items array isn't empty
    if (!!this.props.itemCount) {
      const { pager } = this.state
      const pageCount = pager.totalPages
      let initialPage = 1
      if (this.props.initialPage) {
        initialPage = this.props.initialPage
      }
      if (initialPage > pageCount) {
        initialPage = pageCount
      }
      this.setPage(initialPage)
    }
  }

  componentDidUpdate(prevProps) {
    // reset page if items array has changed
    if (this.props.itemCount !== prevProps.itemCount) {
      const { pager } = this.state
      const pageCount = pager.totalPages
      let initialPage = 1
      if (this.props.initialPage) {
        initialPage = this.props.initialPage
      }
      if (initialPage > pageCount) {
        initialPage = pageCount
      }
      this.setPage(initialPage)
    }

    if (prevProps.currentPage !== this.props.currentPage) {
      this.setPage(this.props.currentPage)
    }
  }

  setPage(page) {
    let { itemCount, pageSize } = this.props
    let pager = this.state.pager

    if (page < 1 || page > pager.totalPages) {
      return
    }

    pager = this.getPager(itemCount, page, pageSize)
    this.setState({ pager: pager })
    if (pager.totalPages) {
      this.props.onChangePage(page)
    }
  }

  getPager(totalItems, currentPage, pageSize) {
    // default to first page
    currentPage = currentPage || 1
    // default page size is 5
    pageSize = pageSize || 5
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize)
    let startPage, endPage
    if (isMobile) {
      startPage = 1
      endPage = totalPages
    } else {
      if (totalPages <= 7) {
        startPage = 2
        endPage = totalPages - 1
      } else {
        if (currentPage < 4) {
          startPage = 2
          endPage = 4
        } else if (currentPage > totalPages - 2) {
          startPage = totalPages - 3
          endPage = totalPages - 1
        } else {
          startPage = currentPage - 1
          endPage = currentPage + 1
        }
      }
    }

    // create an array of pages to ng-repeat in the pager control
    let pages = []
    if (endPage >= startPage) {
      pages = [...Array(endPage + 1 - startPage).keys()].map(i => startPage + i)
    }

    // return object with all pager properties required by the view
    return {
      currentPage: currentPage,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      pages: pages
    }
  }

  render() {
    const { pager } = this.state
    if (!pager.pages || pager.totalPages <= 1) {
      // NOTE: don't display pager if there is only 1 page
      return null
    }

    if (isMobile) {
      return (
        <div className="row my-3">
          <div className="col text-right">
            {pager.currentPage > 1 && (
              <Button
                variant="fab"
                color={'primary'}
                className="jr-fab-btn jr-btn-fab-xs mx-0"
                onClick={() => this.setPage(pager.currentPage - 1)}>
                <i className="material-icons">arrow_back_ios</i>
              </Button>
            )}
            <div className="d-inline-block mx-2">
              <span className={'mr-2'}>Page</span>
              <Select value={pager.currentPage} onChange={e => this.setPage(e.target.value)} input={<Input />}>
                {pager.pages.map((page, index) => (
                  <MenuItem key={index} value={page}>
                    {page}
                  </MenuItem>
                ))}
              </Select>
              <span className={'ml-2'}>of {pager.totalPages}</span>
            </div>
            {pager.currentPage < pager.totalPages && (
              <Button
                variant="fab"
                color={'primary'}
                className="jr-fab-btn jr-btn-fab-xs mx-0"
                onClick={() => this.setPage(pager.currentPage + 1)}>
                <i className="material-icons">arrow_forward_ios</i>
              </Button>
            )}
          </div>
        </div>
      )
    } else {
      return (
        <div className="d-flex justify-content-center my-3">
          <div className="custom-pagination__wrapper">
            <ul className="custom-pagination">
              {pager.currentPage !== 1 && (
                <li onClick={() => this.setPage(pager.currentPage - 1)}>
                  <button className="prev" title="previous page">
                    &#10094;
                  </button>
                </li>
              )}
              <li onClick={() => this.setPage(1)}>
                <button className={pager.currentPage === 1 ? 'active' : ''}>1</button>
              </li>
              {pager.totalPages > 7 && pager.currentPage > 3 && (
                <li>
                  <span>...</span>
                </li>
              )}
              {pager.pages.map((page, index) => (
                <li onClick={() => this.setPage(page)} key={index}>
                  <button className={page === pager.currentPage ? 'active' : ''}>{page}</button>
                </li>
              ))}
              {pager.totalPages > 7 && pager.currentPage < pager.totalPages - 2 && (
                <li>
                  <span>...</span>
                </li>
              )}
              <li onClick={() => this.setPage(pager.totalPages)}>
                <button className={pager.currentPage === pager.totalPages ? 'active' : ''}>{pager.totalPages}</button>
              </li>
              {pager.currentPage !== pager.totalPages && (
                <li onClick={() => this.setPage(pager.currentPage + 1)}>
                  <button className="next" title="next page">
                    &#10095;
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
      )
    }
  }
}

Pagination.propTypes = {
  itemCount: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  initialPage: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number
}

export default Pagination
