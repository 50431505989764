import React from 'react'
import { connect } from 'react-redux'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import Proptypes from 'prop-types'
import agent from 'agent'
import { DELETE_HOMEWORK } from 'constants/actionTypes'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const mapStateToProps = state => ({
  deletingHomework: state.homework.deletingHomework,
  deleteStatus: state.homework.deleteStatus
})

const mapDispatchToProps = dispatch => ({
  deleteHomework: homeworkId =>
    dispatch({ type: DELETE_HOMEWORK, payload: agent.Homework.delete(homeworkId), homeworkId })
})

class DeleteHomeworkDialog extends React.Component {
  state = {
    alertMessage: ''
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deletingHomework && !this.props.deletingHomework) {
      let { status } = this.props.deleteStatus

      if (status === 'success') {
        this.props.toggleDialog()()
      } else if (status === 'failed') {
        this.setState({ alertMessage: this.props.deleteStatus.message })
      }
    }

    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({ alertMessage: '' })
    }
  }

  deleteHomework = () => {
    let { homeworkData } = this.props
    this.props.deleteHomework(homeworkData._id)
  }

  render() {
    let { isOpen, homeworkData, deletingHomework } = this.props
    let { alertMessage } = this.state

    if (!homeworkData) return null

    return (
      <Dialog
        open={isOpen}
        onClose={this.props.toggleDialog()}
        maxWidth="xs"
        fullWidth
        TransitionComponent={Transition}>
        <DialogTitle>Confirm Delete Homework?</DialogTitle>
        <DialogContent>
          <p className="text-large">
            You are trying to delete <span className="font-weight-semibold">{homeworkData.name}</span> homework for
            lesson <span className="font-weight-semibold">{homeworkData.lesson.name}</span>
          </p>
          {alertMessage && <Alert severity="error">{alertMessage}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="default" onClick={this.props.toggleDialog()} disabled={deletingHomework}>
            {alertMessage ? 'Close' : 'Cancel'}
          </Button>
          {!alertMessage && (
            <Button
              variant="contained"
              className="bg-danger text-white"
              onClick={this.deleteHomework}
              disabled={deletingHomework}>
              Delete {deletingHomework && <CircularProgress className="text-white ml-2" size={15} />}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }
}

DeleteHomeworkDialog.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  toggleDialog: Proptypes.func.isRequired,
  homeworkData: Proptypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteHomeworkDialog)
