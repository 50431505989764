export const INIT_APP = 'INIT_APP'
export const INIT_APP_SUCCESS = `${INIT_APP}_SUCCESS`
export const INIT_APP_FAILED = `${INIT_APP}_FAILED`

export const REGISTER = 'REGISTER'
export const REGISTER_SUCCESS = `${REGISTER}_SUCCESS`
export const REGISTER_FAILED = `${REGISTER}_FAILED`

export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = `${LOGIN}_SUCCESS`
export const LOGIN_FAILED = `${LOGIN}_FAILED`

export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET'
export const REQUEST_PASSWORD_RESET_SUCCESS = `${REQUEST_PASSWORD_RESET}_SUCCESS`
export const REQUEST_PASSWORD_RESET_FAILED = `${REQUEST_PASSWORD_RESET}_FAILED`

export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = `${RESET_PASSWORD}_SUCCESS`
export const RESET_PASSWORD_FAILED = `${RESET_PASSWORD}_FAILED`

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCESS = `${CHANGE_PASSWORD}_SUCCESS`
export const CHANGE_PASSWORD_FAILED = `${CHANGE_PASSWORD}_FAILED`

export const GET_USER_DATA = 'GET_USER_DATA'
export const GET_USER_DATA_SUCCESS = `${GET_USER_DATA}_SUCCESS`
export const GET_USER_DATA_FAILED = `${GET_USER_DATA}_FAILED`

export const UPDATE_USER = 'UPDATE USER'
export const UPDATE_USER_SUCCESS = `${UPDATE_USER}_SUCCESS`
export const UPDATE_USER_FAILED = `${UPDATE_USER}_FAILED`

export const DETECT_USER = 'DETECT_USER'
export const DETECT_USER_SUCCESS = `${DETECT_USER}_SUCCESS`
export const DETECT_USER_FAILED = `${DETECT_USER}_FAILED`

export const CONTACT_US = 'CONTACT_US'
export const CONTACT_US_SUCCESS = `${CONTACT_US}_SUCCESS`
export const CONTACT_US_FAILED = `${CONTACT_US}_FAILED`

export const GET_CLASS_LIST = 'GET_CLASS_LIST'
export const GET_CLASS_LIST_SUCCESS = `${GET_CLASS_LIST}_SUCCESS`
export const GET_CLASS_LIST_FAILED = `${GET_CLASS_LIST}_FAILED`

export const GET_MY_PAID_CLASSES = 'GET_MY_PAID_CLASSES'
export const GET_MY_PAID_CLASSES_SUCCESS = `${GET_MY_PAID_CLASSES}_SUCCESS`
export const GET_MY_PAID_CLASSES_FAILED = `${GET_MY_PAID_CLASSES}_FAILED`

export const GET_MY_TRIAL_CLASSES = 'GET_MY_TRIAL_CLASSES'
export const GET_MY_TRIAL_CLASSES_SUCCESS = `${GET_MY_TRIAL_CLASSES}_SUCCESS`
export const GET_MY_TRIAL_CLASSES_FAILED = `${GET_MY_TRIAL_CLASSES}_FAILED`

export const GET_MY_ARCHIVED_CLASSES = 'GET_MY_ARCHIVED_CLASSES'
export const GET_MY_ARCHIVED_CLASSES_SUCCESS = `${GET_MY_ARCHIVED_CLASSES}_SUCCESS`
export const GET_MY_ARCHIVED_CLASSES_FAILED = `${GET_MY_ARCHIVED_CLASSES}_FAILED`

export const GET_CLASS_BY_ID = 'GET_CLASS_BY_ID'
export const GET_CLASS_BY_ID_SUCCESS = `${GET_CLASS_BY_ID}_SUCCESS`
export const GET_CLASS_BY_ID_FAILED = `${GET_CLASS_BY_ID}_FAILED`

export const LOAD_CLASS = 'LOAD_CLASS'
export const LOAD_CLASS_SUCCESS = `${LOAD_CLASS}_SUCCESS`
export const LOAD_CLASS_FAILED = `${LOAD_CLASS}_FAILED`

export const GET_CLASS_LESSONS = 'GET_CLASS_LESSONS'
export const GET_CLASS_LESSONS_SUCCESS = `${GET_CLASS_LESSONS}_SUCCESS`
export const GET_CLASS_LESSONS_FAILED = `${GET_CLASS_LESSONS}_FAILED`

export const GET_LESSON_LIST = 'GET_LESSON_LIST'
export const GET_LESSON_LIST_SUCCESS = `${GET_LESSON_LIST}_SUCCESS`
export const GET_LESSON_LIST_FAILED = `${GET_LESSON_LIST}_FAILED`

export const GET_UPCOMING_LESSON = 'GET_UPCOMING_LESSON'
export const GET_UPCOMING_LESSON_SUCCESS = `${GET_UPCOMING_LESSON}_SUCCESS`
export const GET_UPCOMING_LESSON_FAILED = `${GET_UPCOMING_LESSON}_FAILED`

export const GET_UPCOMING_LESSONS = 'GET_UPCOMING_LESSONS'
export const GET_UPCOMING_LESSONS_SUCCESS = `${GET_UPCOMING_LESSONS}_SUCCESS`
export const GET_UPCOMING_LESSONS_FAILED = `${GET_UPCOMING_LESSONS}_FAILED`

export const GET_LESSON_BY_ID = 'GET_LESSON_BY_ID'
export const GET_LESSON_BY_ID_SUCCESS = `${GET_LESSON_BY_ID}_SUCCESS`
export const GET_LESSON_BY_ID_FAILED = `${GET_LESSON_BY_ID}_FAILED`

export const GET_LESSON_DETAILS = 'GET_LESSON_DETAILS'
export const GET_LESSON_DETAILS_SUCCESS = `${GET_LESSON_DETAILS}_SUCCESS`
export const GET_LESSON_DETAILS_FAILED = `${GET_LESSON_DETAILS}_FAILED`

export const GET_LESSON_LINK = 'GET_LESSON_LINK'
export const GET_LESSON_LINK_SUCCESS = `${GET_LESSON_LINK}_SUCCESS`
export const GET_LESSON_LINK_FAILED = `${GET_LESSON_LINK}_FAILED`

export const GET_PLAYBACK_VIDEO = 'GET_PLAYBACK_VIDEO'
export const GET_PLAYBACK_VIDEO_SUCCESS = `${GET_PLAYBACK_VIDEO}_SUCCESS`
export const GET_PLAYBACK_VIDEO_FAILED = `${GET_PLAYBACK_VIDEO}_FAILED`

export const GET_PLAYBACK_VIDEO_2 = 'GET_PLAYBACK_VIDEO_2'
export const GET_PLAYBACK_VIDEO_2_SUCCESS = `${GET_PLAYBACK_VIDEO_2}_SUCCESS`
export const GET_PLAYBACK_VIDEO_2_FAILED = `${GET_PLAYBACK_VIDEO_2}_FAILED`

export const GET_LESSON_MATERIAL = 'GET_LESSON_MATERIAL'
export const GET_LESSON_MATERIAL_SUCCESS = `${GET_LESSON_MATERIAL}_SUCCESS`
export const GET_LESSON_MATERIAL_FAILED = `${GET_LESSON_MATERIAL}_FAILED`

export const UPLOAD_RESOURCE = 'UPLOAD_RESOURCE'
export const UPLOAD_RESOURCE_SUCCESS = `${UPLOAD_RESOURCE}_SUCCESS`
export const UPLOAD_RESOURCE_FAILED = `${UPLOAD_RESOURCE}_FAILED`

export const EDIT_RESOURCE = 'EDIT_RESOURCE'
export const EDIT_RESOURCE_SUCCESS = `${EDIT_RESOURCE}_SUCCESS`
export const EDIT_RESOURCE_FAILED = `${EDIT_RESOURCE}_FAILED`

export const COUNT_ALL_LESSON_RESOURCES = 'COUNT_ALL_LESSON_RESOURCES'
export const COUNT_ALL_LESSON_RESOURCES_SUCCESS = `${COUNT_ALL_LESSON_RESOURCES}_SUCCESS`
export const COUNT_ALL_LESSON_RESOURCES_FAILED = `${COUNT_ALL_LESSON_RESOURCES}_FAILED`

export const GET_LESSON_RESOURCES = 'GET_LESSON_RESOURCES'
export const GET_LESSON_RESOURCES_SUCCESS = `${GET_LESSON_RESOURCES}_SUCCESS`
export const GET_LESSON_RESOURCES_FAILED = `${GET_LESSON_RESOURCES}_FAILED`

// NOTE: this is temporary before totally remove old GET LESSON RESOURCES (remove GET LESSON MATERIAL too later)
export const GET_LESSON_RESOURCES_2 = 'GET_LESSON_RESOURCES_2'
export const GET_LESSON_RESOURCES_2_SUCCESS = `${GET_LESSON_RESOURCES_2}_SUCCESS`
export const GET_LESSON_RESOURCES_2_FAILED = `${GET_LESSON_RESOURCES_2}_FAILED`

export const DELETE_LESSON_RESOURCE = 'DELETE_LESSON_RESOURCE'
export const DELETE_LESSON_RESOURCE_SUCCESS = `${DELETE_LESSON_RESOURCE}_SUCCESS`
export const DELETE_LESSON_RESOURCE_FAILED = `${DELETE_LESSON_RESOURCE}_FAILED`

export const GET_ONLINE_CLASS_LIST = 'GET_ONLINE_CLASS_LIST'
export const GET_ONLINE_CLASS_LIST_SUCCESS = `${GET_ONLINE_CLASS_LIST}_SUCCESS`
export const GET_ONLINE_CLASS_LIST_FAILED = `${GET_ONLINE_CLASS_LIST}_FAILED`

export const GET_RECOMMENDED_CLASSES = 'GET_RECOMMENDED_CLASSES'
export const GET_RECOMMENDED_CLASSES_SUCCESS = `${GET_RECOMMENDED_CLASSES}_SUCCESS`
export const GET_RECOMMENDED_CLASSES_FAILED = `${GET_RECOMMENDED_CLASSES}_FAILED`

export const GET_FEATURED_ONLINE_CLASS_LIST = 'GET_FEATURED_ONLINE_CLASS_LIST' // TODO: deprecate this
export const GET_FEATURED_ONLINE_CLASS_LIST_SUCCESS = `${GET_FEATURED_ONLINE_CLASS_LIST}_SUCCESS`
export const GET_FEATURED_ONLINE_CLASS_LIST_FAILED = `${GET_FEATURED_ONLINE_CLASS_LIST}_FAILED`

export const INIT_PAYMENT = 'INIT_PAYMENT'
export const INIT_PAYMENT_SUCCESS = `${INIT_PAYMENT}_SUCCESS`
export const INIT_PAYMENT_FAILED = `${INIT_PAYMENT}_FAILED`

export const GET_POST_PAYMENT_STAT = 'GET_POST_PAYMENT_STATUS'
export const GET_POST_PAYMENT_STAT_SUCCESS = `${GET_POST_PAYMENT_STAT}_SUCCESS`
export const GET_POST_PAYMENT_STAT_FAILED = `${GET_POST_PAYMENT_STAT}_FAILED`

export const GET_ONLINE_CLASS_BY_ID = 'GET_ONLINE_CLASS_BY_ID'
export const GET_ONLINE_CLASS_BY_ID_SUCCESS = `${GET_ONLINE_CLASS_BY_ID}_SUCCESS`
export const GET_ONLINE_CLASS_BY_ID_FAILED = `${GET_ONLINE_CLASS_BY_ID}_FAILED`

export const GET_TRIAL_AVAILABILITY = 'GET_TRIAL_AVAILABILITY'
export const GET_TRIAL_AVAILABILITY_SUCCESS = `${GET_TRIAL_AVAILABILITY}_SUCCESS`
export const GET_TRIAL_AVAILABILITY_FAILED = `${GET_TRIAL_AVAILABILITY}_FAILED`

export const REQUEST_TRIAL_SERVICE = 'REQUEST_TRIAL_SERVICE'
export const REQUEST_TRIAL_SERVICE_SUCCESS = `${REQUEST_TRIAL_SERVICE}_SUCCESS`
export const REQUEST_TRIAL_SERVICE_FAILED = `${REQUEST_TRIAL_SERVICE}_FAILED`

export const JOIN_TRIAL_SERVICE = 'JOIN_TRIAL_SERVICE'
export const JOIN_TRIAL_SERVICE_SUCCESS = `${JOIN_TRIAL_SERVICE}_SUCCESS`
export const JOIN_TRIAL_SERVICE_FAILED = `${JOIN_TRIAL_SERVICE}_FAILED`

export const CREATE_FEEDBACK = `CREATE_FEEDBACK`
export const CREATE_FEEDBACK_SUCCESS = `${CREATE_FEEDBACK}_SUCCESS`
export const CREATE_FEEDBACK_FAILED = `${CREATE_FEEDBACK}_FAILED`

export const GET_FEEDBACK = `GET_FEEDBACK`
export const GET_FEEDBACK_SUCCESS = `${GET_FEEDBACK}_SUCCESS`
export const GET_FEEDBACK_FAILED = `${GET_FEEDBACK}_FAILED`

export const CHECK_FEEDBACK_EXISTS = `CHECK_FEEDBACK_EXISTS`
export const CHECK_FEEDBACK_EXISTS_SUCCESS = `${CHECK_FEEDBACK_EXISTS}_SUCCESS`
export const CHECK_FEEDBACK_EXISTS_FAILED = `${CHECK_FEEDBACK_EXISTS}_FAILED`

export const GET_ORDER = 'GET_ORDER'
export const GET_ORDER_SUCCESS = `${GET_ORDER}_SUCCESS`
export const GET_ORDER_FAILED = `${GET_ORDER}_FAILED`

export const VALIDATE_REFERRAL_CODE = 'VALIDATE_REFERRAL_CODE'
export const VALIDATE_REFERRAL_CODE_SUCCESS = `${VALIDATE_REFERRAL_CODE}_SUCCESS`
export const VALIDATE_REFERRAL_CODE_FAILED = `${VALIDATE_REFERRAL_CODE}_FAILED`

export const GET_CART = 'GET_CART'
export const GET_CART_SUCCESS = `${GET_CART}_SUCCESS`
export const GET_CART_FAILED = `${GET_CART}_FAILED`

export const POPULATE_CART = `POPULATE_CART`
export const POPULATE_CART_SUCCESS = `${POPULATE_CART}_SUCCESS`
export const POPULATE_CART_FAILED = `${POPULATE_CART}_FAILED`

export const ADD_CART_ITEM = `ADD_CART_ITEM`
export const ADD_CART_ITEM_SUCCESS = `${ADD_CART_ITEM}_SUCCESS`
export const ADD_CART_ITEM_FAILED = `${ADD_CART_ITEM}_FAILED`

export const PATCH_CART_ITEM = `PATCH_CART_ITEM`
export const PATCH_CART_ITEM_SUCCESS = `${PATCH_CART_ITEM}_SUCCESS`
export const PATCH_CART_ITEM_FAILED = `${PATCH_CART_ITEM}_FAILED`

export const REMOVE_CART_ITEM = `REMOVE_CART_ITEM`
export const REMOVE_CART_ITEM_SUCCESS = `${REMOVE_CART_ITEM}_SUCCESS`
export const REMOVE_CART_ITEM_FAILED = `${REMOVE_CART_ITEM}_FAILED`

export const EMPTY_CART = `CLEAR_CART`
export const EMPTY_CART_SUCCESS = `${EMPTY_CART}_SUCCESS`
export const EMPTY_CART_FAILED = `${EMPTY_CART}_FAILED`

export const GET_CART_QUOTE = `GET_CART_QUOTE`
export const GET_CART_QUOTE_SUCCESS = `${GET_CART_QUOTE}_SUCCESS`
export const GET_CART_QUOTE_FAILED = `${GET_CART_QUOTE}_FAILED`

export const CHECKOUT_CART = `CHECKOUT_CART`
export const CHECKOUT_CART_SUCCESS = `${CHECKOUT_CART}_SUCCESS`
export const CHECKOUT_CART_FAILED = `${CHECKOUT_CART}_FAILED`

export const GET_PACKAGE_QUOTE = `GET_PACKAGE_QUOTE`
export const GET_PACKAGE_QUOTE_SUCCESS = `${GET_PACKAGE_QUOTE}_SUCCESS`
export const GET_PACKAGE_QUOTE_FAILED = `${GET_PACKAGE_QUOTE}_FAILED`

export const GET_MATCHING_CLASSES = `GET_MATCHING_CLASSES`
export const GET_MATCHING_CLASSES_SUCCESS = `${GET_MATCHING_CLASSES}_SUCCESS`
export const GET_MATCHING_CLASSES_FAILED = `${GET_MATCHING_CLASSES}_FAILED`

export const SUBSCRIBE_TO_SERVICE = `SUBSCRIBE_TO_SERVICE`
export const SUBSCRIBE_TO_SERVICE_SUCCESS = `${SUBSCRIBE_TO_SERVICE}_SUCCESS`
export const SUBSCRIBE_TO_SERVICE_FAILED = `${SUBSCRIBE_TO_SERVICE}_FAILED`

export const GET_TRIAL_PAGE_VARIANT = `GET_TRIAL_PAGE_VARIANT`
export const GET_TRIAL_PAGE_VARIANT_SUCCESS = `${GET_TRIAL_PAGE_VARIANT}_SUCCESS`
export const GET_TRIAL_PAGE_VARIANT_FAILED = `${GET_TRIAL_PAGE_VARIANT}_FAILED`

export const GET_CUSTOM_OPTION = `GET_CUSTOM_OPTION`
export const GET_CUSTOM_OPTION_SUCCESS = `${GET_CUSTOM_OPTION}_SUCCESS`
export const GET_CUSTOM_OPTION_FAILED = `${GET_CUSTOM_OPTION}_FAILED`

export const GET_CUSTOM_CLASS_CATEGORIES = `GET_CUSTOM_CLASS_CATEGORIES`
export const GET_CUSTOM_CLASS_CATEGORIES_SUCCESS = `${GET_CUSTOM_CLASS_CATEGORIES}_SUCCESS`
export const GET_CUSTOM_CLASS_CATEGORIES_FAILED = `${GET_CUSTOM_CLASS_CATEGORIES}_FAILED`

export const GET_CUSTOM_CONFIG = `GET_CUSTOM_CONFIG`
export const GET_CUSTOM_CONFIG_SUCCESS = `${GET_CUSTOM_CONFIG}_SUCCESS`
export const GET_CUSTOM_CONFIG_FAILED = `${GET_CUSTOM_CONFIG}_FAILED`

export const GET_BLOG_POST = `GET_BLOG_POST`
export const GET_BLOG_POST_SUCCESS = `${GET_BLOG_POST}_SUCCESS`
export const GET_BLOG_POST_FAILED = `${GET_BLOG_POST}_FAILED`

export const GET_EVENT_LIST = `GET_EVENT_LIST`
export const GET_EVENT_LIST_SUCCESS = `${GET_EVENT_LIST}_SUCCESS`
export const GET_EVENT_LIST_FAILED = `${GET_EVENT_LIST}_FAILED`

export const GET_EVENT_BY_ID = 'GET_EVENT_BY_ID'
export const GET_EVENT_BY_ID_SUCCESS = `${GET_EVENT_BY_ID}_SUCCESS`
export const GET_EVENT_BY_ID_FAILED = `${GET_EVENT_BY_ID}_FAILED`

export const GET_HOMEWORK = `GET_HOMEWORK`
export const GET_HOMEWORK_SUCCESS = `${GET_HOMEWORK}_SUCCESS`
export const GET_HOMEWORK_FAILED = `${GET_HOMEWORK}_FAILED`

export const CREATE_HOMEWORK = `CREATE_HOMEWORK`
export const CREATE_HOMEWORK_SUCCESS = `${CREATE_HOMEWORK}_SUCCESS`
export const CREATE_HOMEWORK_FAILED = `${CREATE_HOMEWORK}_FAILED`

export const UPDATE_HOMEWORK = `UPDATE_HOMEWORK`
export const UPDATE_HOMEWORK_SUCCESS = `${UPDATE_HOMEWORK}_SUCCESS`
export const UPDATE_HOMEWORK_FAILED = `${UPDATE_HOMEWORK}_FAILED`

export const DELETE_HOMEWORK = `DELETE_HOMEWORK`
export const DELETE_HOMEWORK_SUCCESS = `${DELETE_HOMEWORK}_SUCCESS`
export const DELETE_HOMEWORK_FAILED = `${DELETE_HOMEWORK}_FAILED`

export const GET_HOMEWORK_LESSON_DETAIL = `GET_HOMEWORK_LESSON_DETAIL`
export const GET_HOMEWORK_LESSON_DETAIL_SUCCESS = `${GET_HOMEWORK_LESSON_DETAIL}_SUCCESS`
export const GET_HOMEWORK_LESSON_DETAIL_FAILED = `${GET_HOMEWORK_LESSON_DETAIL}_FAILED`

export const GET_HOMEWORK_BY_LESSON = `GET_HOMEWORK_BY_LESSON`
export const GET_HOMEWORK_BY_LESSON_SUCCESS = `${GET_HOMEWORK_BY_LESSON}_SUCCESS`
export const GET_HOMEWORK_BY_LESSON_FAILED = `${GET_HOMEWORK_BY_LESSON}_FAILED`

export const GET_HOMEWORK_BY_ID = `GET_HOMEWORK_BY_ID`
export const GET_HOMEWORK_BY_ID_SUCCESS = `${GET_HOMEWORK_BY_ID}_SUCCESS`
export const GET_HOMEWORK_BY_ID_FAILED = `${GET_HOMEWORK_BY_ID}_FAILED`

export const GET_PENDING_SUBMISSION_HOMEWORK = 'GET_PENDING_SUBMISSION_HOMEWORK'
export const GET_PENDING_SUBMISSION_HOMEWORK_SUCCESS = `${GET_PENDING_SUBMISSION_HOMEWORK}_SUCCESS`
export const GET_PENDING_SUBMISSION_HOMEWORK_FAILED = `${GET_PENDING_SUBMISSION_HOMEWORK}_FAILED`

export const GET_MY_SUBMISSION = `GET_MY_SUBMISSION`
export const GET_MY_SUBMISSION_SUCCESS = `${GET_MY_SUBMISSION}_SUCCESS`
export const GET_MY_SUBMISSION_FAILED = `${GET_MY_SUBMISSION}_FAILED`

export const GET_FILTERED_SUBMISSION_LIST = 'GET_FILTERED_SUBMISSION_LIST'
export const GET_FILTERED_SUBMISSION_LIST_SUCCESS = `${GET_FILTERED_SUBMISSION_LIST}_SUCCESS`
export const GET_FILTERED_SUBMISSION_LIST_FAILED = `${GET_FILTERED_SUBMISSION_LIST}_FAILED`

export const CREATE_SUBMISSION = `CREATE_SUBMISSION`
export const CREATE_SUBMISSION_SUCCESS = `${CREATE_SUBMISSION}_SUCCESS`
export const CREATE_SUBMISSION_FAILED = `${CREATE_SUBMISSION}_FAILED`

export const UPDATE_SUBMISSION = `UPDATE_SUBMISSION`
export const UPDATE_SUBMISSION_SUCCESS = `${UPDATE_SUBMISSION}_SUCCESS`
export const UPDATE_SUBMISSION_FAILED = `${UPDATE_SUBMISSION}_FAILED`

export const GET_SUBMISSION_STAT = `GET_SUBMISSION_STAT`
export const GET_SUBMISSION_STAT_SUCCESS = `${GET_SUBMISSION_STAT}_SUCCESS`
export const GET_SUBMISSION_STAT_FAILED = `${GET_SUBMISSION_STAT}_FAILED`

export const GET_SUBMISSION_BY_HOMEWORK = `GET_SUBMISSION_BY_HOMEWORK`
export const GET_SUBMISSION_BY_HOMEWORK_SUCCESS = `${GET_SUBMISSION_BY_HOMEWORK}_SUCCESS`
export const GET_SUBMISSION_BY_HOMEWORK_FAILED = `${GET_SUBMISSION_BY_HOMEWORK}_FAILED`

export const DELETE_SUBMISSION = `DELETE_SUBMISSION`
export const DELETE_SUBMISSION_SUCCESS = `${DELETE_SUBMISSION}_SUCCESS`
export const DELETE_SUBMISSION_FAILED = `${DELETE_SUBMISSION}_FAILED`

export const REQUEST_PHONE_VERIFICATION = `REQUEST_PHONE_VERIFICATION`
export const REQUEST_PHONE_VERIFICATION_SUCCESS = `${REQUEST_PHONE_VERIFICATION}_SUCCESS`
export const REQUEST_PHONE_VERIFICATION_FAILED = `${REQUEST_PHONE_VERIFICATION}_FAILED`

export const VERIFY_PHONE_NUMBER = `VERIFY_PHONE_NUMBER`
export const VERIFY_PHONE_NUMBER_SUCCESS = `${VERIFY_PHONE_NUMBER}_SUCCESS`
export const VERIFY_PHONE_NUMBER_FAILED = `${VERIFY_PHONE_NUMBER}_FAILED`

export const GET_TUTOR_INFO = `GET_TUTOR_INFO`
export const GET_TUTOR_INFO_SUCCESS = `${GET_TUTOR_INFO}_SUCCESS`
export const GET_TUTOR_INFO_FAILED = `${GET_TUTOR_INFO}_FAILED`

export const GET_LESSON_TICKETS = `GET_LESSON_TICKETS`
export const GET_LESSON_TICKETS_SUCCESS = `${GET_LESSON_TICKETS}_SUCCESS`
export const GET_LESSON_TICKETS_FAILED = `${GET_LESSON_TICKETS}_FAILED`

export const UPDATE_TICKET = `UPDATE_TICKET`
export const UPDATE_TICKET_SUCCESS = `${UPDATE_TICKET}_SUCCESS`
export const UPDATE_TICKET_FAILED = `${UPDATE_TICKET}_FAILED`

export const GET_CLASS_REPORTS = 'GET_CLASS_REPORTS'
export const GET_CLASS_REPORTS_SUCCESS = `${GET_CLASS_REPORTS}_SUCCESS`
export const GET_CLASS_REPORTS_FAILED = `${GET_CLASS_REPORTS}_FAILED`

export const GET_REPORT_LIST = 'GET_REPORT_LIST'
export const GET_REPORT_LIST_SUCCESS = `${GET_REPORT_LIST}_SUCCESS`
export const GET_REPORT_LIST_FAILED = `${GET_REPORT_LIST}_FAILED`

export const GET_REPORT_PDF = 'GET_REPORT_PDF'
export const GET_REPORT_PDF_SUCCESS = `${GET_REPORT_PDF}_SUCCESS`
export const GET_REPORT_PDF_FAILED = `${GET_REPORT_PDF}_FAILED`

export const CREATE_REPORT = 'CREATE_REPORT'
export const CREATE_REPORT_SUCCESS = `${CREATE_REPORT}_SUCCESS`
export const CREATE_REPORT_FAILED = `${CREATE_REPORT}_FAILED`

export const UPDATE_REPORT = 'UPDATE_REPORT'
export const UPDATE_REPORT_SUCCESS = `${UPDATE_REPORT}_SUCCESS`
export const UPDATE_REPORT_FAILED = `${UPDATE_REPORT}_FAILED`

export const RELEASE_COMPLETED_REPORTS = 'RELEASE_COMPLETED_REPORTS'
export const RELEASE_COMPLETED_REPORTS_SUCCESS = `${RELEASE_COMPLETED_REPORTS}_SUCCESS`
export const RELEASE_COMPLETED_REPORTS_FAILED = `${RELEASE_COMPLETED_REPORTS}_FAILED`

export const GET_STUDENT_HOMEWORK_REPORT = 'GET_STUDENT_HOMEWORK_REPORT'
export const GET_STUDENT_HOMEWORK_REPORT_SUCCESS = `${GET_STUDENT_HOMEWORK_REPORT}_SUCCESS`
export const GET_STUDENT_HOMEWORK_REPORT_FAILED = `${GET_STUDENT_HOMEWORK_REPORT}_FAILED`

export const RECORD_USER_ACTION = 'RECORD_ACTION'
export const RECORD_USER_ACTION_SUCCESS = `${RECORD_USER_ACTION}_SUCCESS`
export const RECORD_USER_ACTION_FAILED = `${RECORD_USER_ACTION}_FAILED`

export const CREATE_LESSON_ACTIVITY = 'CREATE_LESSON_ACTIVITY'
export const CREATE_LESSON_ACTIVITY_SUCCESS = `${CREATE_LESSON_ACTIVITY}_SUCCESS`
export const CREATE_LESSON_ACTIVITY_FAILED = `${CREATE_LESSON_ACTIVITY}_FAILED`

export const UPDATE_LESSON_ACTIVITY = 'UPDATE_LESSON_ACTIVITY'
export const UPDATE_LESSON_ACTIVITY_SUCCESS = `${UPDATE_LESSON_ACTIVITY}_SUCCESS`
export const UPDATE_LESSON_ACTIVITY_FAILED = `${UPDATE_LESSON_ACTIVITY}_FAILED`

export const GET_LESSON_ACTIVITY = 'GET_LESSON_ACTIVITY'
export const GET_LESSON_ACTIVITY_SUCCESS = `${GET_LESSON_ACTIVITY}_SUCCESS`
export const GET_LESSON_ACTIVITY_FAILED = `${GET_LESSON_ACTIVITY}_FAILED`

export const GET_LESSON_ACTIVITY_ANSWER = 'GET_LESSON_ACTIVITY_ANSWER'
export const GET_LESSON_ACTIVITY_ANSWER_SUCCESS = `${GET_LESSON_ACTIVITY_ANSWER}_SUCCESS`
export const GET_LESSON_ACTIVITY_ANSWER_FAILED = `${GET_LESSON_ACTIVITY_ANSWER}_FAILED`

export const REMOVE_ACTIVITY_FROM_LESSON = 'REMOVE_ACTIVITY_FROM_LESSON'
export const REMOVE_ACTIVITY_FROM_LESSON_SUCCESS = `${REMOVE_ACTIVITY_FROM_LESSON}_SUCCESS`
export const REMOVE_ACTIVITY_FROM_LESSON_FAILED = `${REMOVE_ACTIVITY_FROM_LESSON}_FAILED`

export const UPDATE_LESSON = 'UPDATE_LESSON'
export const UPDATE_LESSON_SUCCESS = `${UPDATE_LESSON}_SUCCESS`
export const UPDATE_LESSON_FAILED = `${UPDATE_LESSON}_FAILED`

export const SEARCH_CLASS = 'SEARCH_CLASS' // NOTE: Get minimal info of list of class. for import homework usage at the moment
export const SEARCH_CLASS_SUCCESS = `${SEARCH_CLASS}_SUCCESS`
export const SEARCH_CLASS_FAILED = `${SEARCH_CLASS}_FAILED`

// NOTE: doesn't require API call
export const GET_CART_NOAUTH = 'GET_CART_NOAUTH'
export const ADD_CART_ITEM_NOAUTH = 'ADD_CART_ITEM_NOAUTH'
export const REMOVE_CART_ITEM_NOAUTH = 'REMOVE_CART_ITEM_NOAUTH'
export const EMPTY_CART_NOAUTH = 'EMPTY_CART_NOAUTH'
export const CLEAR_CART_CLASSES = 'CLEAR_CART_CLASSES'
export const CALC_PACKAGE_QUOTE = 'CALC_PACKAGE_QUOTE'
export const LOAD_PACKAGE = 'LOAD_PACKAGE'
export const CLEAR_PACKAGE_QUOTE = 'CLEAR_PACKAGE_QUOTE'
export const SET_SELECTED_CLASS = 'SET_SELECTED_CLASS'
export const SET_CLASS_PAGE = 'SET_CLASS_PAGE'
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSED_NAV'
export const UPDATE_WINDOW_WIDTH = 'UPDATE_WINDOW_WIDTH'
export const REGISTER_UNLOAD = 'REGISTER_UNLOAD'
export const REDIRECT = 'REDIRECT'
export const LOGOUT = 'LOGOUT'
export const SET_ONLINE_CLASS_FILTER = 'SET_ONLINE_CLASS_FILTER'
export const SET_LESSON_RECORDING_TO_WATCH = 'SET_LESSON_RECORDING_TO_WATCH'
export const STORE_UTM_DATA = 'STORE_UTM_DATA'
export const RESET_PHONE_VERIFICATION = 'RESET_PHONE_VERIFICATION'
export const RESET_SELECTED_VIDEO = 'RESET_SELECTED_VIDEO'
