import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  CircularProgress,
  Divider
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import _ from 'lodash'
import moment from 'moment'

import agent from 'agent'
import {
  CONTACT_US,
  GET_MATCHING_CLASSES,
  CALC_PACKAGE_QUOTE,
  GET_PACKAGE_QUOTE,
  ADD_CART_ITEM,
  ADD_CART_ITEM_NOAUTH
} from 'constants/actionTypes'
import { APP, SHOPPING_CART, ALL_CLASSES } from 'routes/routes'
import { formatCartItem, calculateQuote } from 'utils/cart'
import Testimonials from 'components/misc/testimonial-carousel'
import GetInTouchDialog from 'components/dialog/get-in-touch'
import OralClinicCTADialog from './dialog-oral-cta'
import ClassExperience from 'components/misc/info-class-experience'
import SuperiorMethodology from 'components/misc/info-superior-methodology'
import ProceedToCheckoutDialog from 'components/online-class/dialog-proceed-to-checkout'

const mapStateToProps = state => ({
  token: state.common.token,
  matchingClasses: state.onlineClass.matchingClasses,
  contactSuccess: state.misc.contacted,
  userClassWithDetail: state.common.userClassWithDetail,
  lessonPackages: state.shoppingCart.lessonPackages,
  shoppingCart: state.shoppingCart.shoppingCart
})

const mapDispatchToProps = dispatch => ({
  getEligible: query => dispatch({ type: GET_MATCHING_CLASSES, payload: agent.OnlineClass.getList(query, true) }),
  contactUs: reqPayload => dispatch({ type: CONTACT_US, payload: agent.Misc.contactUs(reqPayload) }),
  calcPackageQuote: (quote, packageItem) => dispatch({ type: CALC_PACKAGE_QUOTE, payload: quote, packageItem }),
  getPackageQuote: (payload, packageItem) =>
    dispatch({ type: GET_PACKAGE_QUOTE, payload: agent.OnlineClass.getQuote(payload), packageItem: packageItem }),
  addToCart: item => dispatch({ type: ADD_CART_ITEM, payload: agent.ShoppingCart.addItem(item), cartItem: item }),
  addToLocalCart: item => dispatch({ type: ADD_CART_ITEM_NOAUTH, payload: item })
})

const ORAL_WORKSHOP_ID = 'K41Dnia0SNeMbPT--qTm_w'
const ORAL_SELF_LEARNING_COURSE_ID = 'OHDAPhZVTTuD5tYiY4j68w'

class OralProgrammes extends React.Component {
  state = {
    selectedProgram: 'self-learning-course',
    oralClass: null,
    selfLearningClass: null,
    selectedClass: null,
    expandFullClassDesc: false,
    isGetInTouchDialogOpen: false,
    isCTADialogOpen: false,
    lessonsIncluded: [],
    isProceedToCheckoutDialogOpen: false,
    addedToCart: [],
    purchased: []
  }

  componentDidMount() {
    this.props.history.push(ALL_CLASSES)

    /*this.props.getEligible({
      _id: {
        $in: [ORAL_WORKSHOP_ID, ORAL_SELF_LEARNING_COURSE_ID]
      }
    })

    if (this.props.userClassWithDetail) {
      let purchased = []
      if (this.props.userClassWithDetail[ORAL_WORKSHOP_ID]?.lesson?.length > 1) {
        purchased.push(ORAL_WORKSHOP_ID)
      }
      if (this.props.userClassWithDetail[ORAL_SELF_LEARNING_COURSE_ID]?.lesson?.length > 1) {
        purchased.push(ORAL_SELF_LEARNING_COURSE_ID)
      }
      this.setState({ purchased })
    }*/
  }

  componentDidUpdate(prevProps) {
    if (prevProps.matchingClasses !== this.props.matchingClasses) {
      this.filterOnlineClass()
    }

    if (prevProps.lessonPackages !== this.props.lessonPackages) {
      const { lessonPackages } = this.props
      const { selectedClass } = this.state

      let lessonPackage = lessonPackages[selectedClass._id]
      if (lessonPackage) {
        this.addToCart(lessonPackage.item[0])
      }
    }

    if (prevProps.shoppingCart !== this.props.shoppingCart) {
      this.updateCartStatus()
    }
  }

  filterOnlineClass = () => {
    let { matchingClasses } = this.props

    if (!matchingClasses) {
      return
    }

    const oralClass = _.find(matchingClasses, o => {
      return o._id === ORAL_WORKSHOP_ID
    })

    const selfLearningClass = _.find(matchingClasses, o => {
      return o._id === ORAL_SELF_LEARNING_COURSE_ID
    })

    this.setState({ oralClass, selfLearningClass })
  }

  selectProgram = selectedProgram => () => {
    this.setState({ selectedProgram })
  }

  scrollToElement = location => () => {
    const elmnt = document.getElementById(location)
    window.scrollTo({ top: elmnt.offsetTop, behavior: 'smooth' })
  }

  toggleExpandFullClassDesc = () => {
    this.setState({ expandFullClassDesc: !this.state.expandFullClassDesc })
  }

  toggleGetInTouchDialog = () => {
    this.setState({ isGetInTouchDialogOpen: !this.state.isGetInTouchDialogOpen })
  }

  toggleCTADialog = () => {
    this.setState({ isCTADialogOpen: !this.state.isCTADialogOpen })
  }

  toggleProceedToCheckoutDialog = () => {
    this.setState({ isProceedToCheckoutDialogOpen: !this.state.isProceedToCheckoutDialogOpen })
  }

  clickPurchase = () => {
    let { token } = this.props
    let { selectedProgram, oralClass, selfLearningClass } = this.state

    let selectedClass = null
    if (selectedProgram === 'online-class') {
      selectedClass = oralClass
    }
    if (selectedProgram === 'self-learning-course') {
      selectedClass = selfLearningClass
    }

    let lessonsIncluded = selectedClass.lesson
    this.setState({ selectedClass, lessonsIncluded })

    if (lessonsIncluded.length > 0) {
      let packageItem = {
        class_id: selectedClass._id,
        count: lessonsIncluded.length,
        options: {
          start_date: lessonsIncluded[0].scheduled_on,
          end_date: lessonsIncluded[lessonsIncluded.length - 1].scheduled_on
        },
        rate: selectedClass.rate,
        title: selectedClass.name
      }

      if (token) {
        this.props.getPackageQuote(
          {
            class_id: selectedClass._id,
            start_date: lessonsIncluded[0].scheduled_on,
            end_date: lessonsIncluded[lessonsIncluded.length - 1].scheduled_on
          },
          packageItem
        )
      } else {
        let quote = calculateQuote(
          lessonsIncluded,
          selectedClass.rate,
          lessonsIncluded[0].scheduled_on,
          lessonsIncluded[lessonsIncluded.length - 1].scheduled_on
        )
        this.props.calcPackageQuote({ data: quote }, packageItem)
      }
    }
  }

  updateCartStatus = () => {
    let addedToCart = []
    for (let i = 0; i < this.props.shoppingCart.item.length; i++) {
      addedToCart.push(this.props.shoppingCart.item[i].item_id)
    }

    this.setState({ addedToCart })
  }

  addToCart = lessonPackage => {
    let { selectedClass } = this.state

    let formattedItem = formatCartItem(selectedClass, 'Class', lessonPackage.packageItem.options)
    if (!!this.props.token) {
      this.props.addToCart(formattedItem)
    } else {
      this.props.addToLocalCart(formattedItem)
    }
    this.toggleProceedToCheckoutDialog()
  }

  goToCart = () => {
    this.toggleProceedToCheckoutDialog()
    let redirectUrl = this.props.token ? `${APP}${SHOPPING_CART}` : SHOPPING_CART
    this.props.history.push(redirectUrl)
  }

  render() {
    const {
      selectedProgram,
      oralClass,
      selfLearningClass,
      selectedClass,
      expandFullClassDesc,
      isGetInTouchDialogOpen,
      isCTADialogOpen,
      isProceedToCheckoutDialogOpen,
      purchased,
      addedToCart,
      lessonsIncluded
    } = this.state

    if (!selfLearningClass) {
      return (
        <div className="manage-margin text-center my-5">
          <CircularProgress size={50} />
        </div>
      )
    }

    const TAB_STYLE = {
      tab: {
        border: '1px solid #dee2e6',
        borderRadius: '10px',
        boxShadow:
          '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
      },
      activeTab: {
        borderBottom: '5px solid #8e51f0',
        borderTop: '1px solid #8e51f0',
        borderRight: '1px solid #8e51f0',
        borderLeft: '1px solid #8e51f0',
        borderRadius: '10px',
        boxShadow:
          '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
      }
    }

    return (
      <div className="landing">
        <Helmet>
          <title>English Oral Programmes | Tenopy</title>
          <meta
            name="description"
            content="At Tenopy, top online teachers lead hands-on classes live with our next-generation classroom technology. Achieve results for English, Math and Science at Primary and Secondary levels with our tuition classes. Enrich learning beyond mere grades with our featured learning programmes."
          />
          <meta
            name="keywords"
            content="Online tuition,Math tuition,English tuition,Secondary school tuition,Primary school tuition,Online tuition singapore,Online learning,Online education,Education platform,Online tutor,Home tutor,home tuition,science tuition,sa1,sa2,ca1,ca2,psle,revision,online revision,online study,online learning,psle revision"
          />
        </Helmet>

        <section className={`trial__banner-bg--main ${isMobile ? 'px-0' : ''}`}>
          {!isMobile && <div className="trial__banner-bg--side" />}
          <div className={`container h-100`}>
            <div className="row align-items-center h-100 mb-3 mb-md-0">
              <div className="col-md-6 col-12">
                <span className="tag amber-tag text-capitalize font-weight-semibold " style={{ color: '#29303b' }}>
                  Recommended
                </span>
                <h1 className="title--rubik">
                  English Oral Programme: Everything you need to Ace the Oral examination
                </h1>
                <p className="text-justify">
                  The Oral Communication component is often overlooked as an integral component of the PSLE, leading
                  students to be unprepared for the actual assessment. Taking up 15% of the total score in PSLE,
                  students will be assessed on their ability to read fluently with appropriate diction and articulate
                  their responses in a conversational discussion on a particular theme.
                </p>
                <p className="text-justify">
                  Here at Tenopy, we learn for exams and also for life, where the addition of oral skills allow our
                  students to express their thoughts and communicate effectively.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section
          id="oral-programmes"
          style={{
            backgroundImage:
              'radial-gradient( circle farthest-corner at 10% 20%,  rgba(215,223,252,1) 0%, rgba(255,255,255,1) 0%, rgba(215,223,252,1) 84% )'
          }}>
          <div className="container">
            <div className="section__header mb-5">
              <div className="title title--rubik mb-2" style={{ fontSize: '2.3rem', lineHeight: 1.2 }}>
                Oral programmes for different needs
              </div>
              <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #8e51f0' }}></div>
            </div>
            <div className="container">
              <div className="row justify-content-center mb-5">
                <div className="col-12 col-md-4 mb-2 d-none">
                  <div
                    className="d-flex flex-row h-100 align-items-center clickable p-2 bg-white"
                    style={selectedProgram === 'online-class' ? TAB_STYLE.activeTab : TAB_STYLE.tab}
                    onClick={this.selectProgram('online-class')}>
                    <img
                      src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/misc/oral-workshop-icon.png"
                      width="70px"
                      height="70px"
                      alt="oral workshop"
                    />
                    <div className="ml-2">
                      <h3 className="title--rubik mb-0">PSLE Oral Workshop</h3>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-2">
                  <div
                    className="d-flex flex-row h-100 align-items-center clickable p-2 bg-white"
                    style={selectedProgram === 'oral-clinic' ? TAB_STYLE.activeTab : TAB_STYLE.tab}
                    onClick={this.selectProgram('oral-clinic')}>
                    <img
                      src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/misc/oral-clinic-icon.png"
                      width="70px"
                      height="70px"
                      alt="oral clinic"
                    />
                    <div className="ml-2">
                      <h3 className="title--rubik mb-0">PSLE English Oral Clinic</h3>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-2">
                  <div
                    className="d-flex flex-row h-100 align-items-center clickable p-2 bg-white"
                    style={selectedProgram === 'self-learning-course' ? TAB_STYLE.activeTab : TAB_STYLE.tab}
                    onClick={this.selectProgram('self-learning-course')}>
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/misc/oral-self-learning-icon.png"
                      width="70px"
                      height="70px"
                      alt="oral clinic"
                    />
                    <div className="ml-2">
                      <h3 className="title--rubik mb-0">Self-Learning Oral Course</h3>
                    </div>
                  </div>
                </div>
              </div>

              {selectedProgram === 'online-class' && false && (
                <div className="row">
                  <div className="col-12 col-md-5">
                    <div
                      className="bg-white py-2 px-4"
                      style={{
                        borderRadius: '10px',
                        borderBottom: '10px solid #8e51f0',
                        borderTop: '1px solid #8e51f0',
                        borderRight: '1px solid #8e51f0',
                        borderLeft: '1px solid #8e51f0'
                      }}>
                      <div className="d-flex flex-row align-items-center">
                        <Avatar
                          className="size-60 avatar-shadow m-2"
                          alt={oralClass?.tutor[0].name}
                          src={oralClass?.tutor[0].profile_pic}
                        />
                        <div className="ml-2">
                          <h2 className="title--rubik mb-1">{oralClass.name}</h2>
                          <div className="d-flex flex-row">
                            <small className="mr-2">by</small>
                            Teacher {oralClass?.tutor[0].name}
                          </div>
                        </div>
                      </div>
                      <div className="text-right">
                        <div className="class__price">
                          <h1 className="text-primary mb-0">$ {oralClass.rate}</h1>
                        </div>
                        <small>per lesson</small>
                      </div>
                      <Divider />
                      <div className="mt-2 d-block d-md-none">
                        <small className="text-muted">About Class</small>
                        <div className="text-justify" onClick={this.toggleExpandFullClassDesc}>
                          {expandFullClassDesc ? (
                            <React.Fragment>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: oralClass.desc
                                }}
                              />
                              <p className="text-right">
                                <span className="link">Read less...</span>
                              </p>
                            </React.Fragment>
                          ) : oralClass.desc.length > 400 ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: `${oralClass.desc.substring(0, 397)} <span class="link"> read more...</span>`
                              }}
                            />
                          ) : (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: oralClass.desc
                              }}
                            />
                          )}
                        </div>
                        <Divider />
                      </div>
                      <div className="mt-2">
                        <small className="text-muted">About {oralClass?.tutor[0].name}</small>
                        <p className="text-justify" dangerouslySetInnerHTML={{ __html: oralClass?.tutor[0].desc }} />
                      </div>
                      <div className="text-center mt-3">
                        {oralClass.is_sold_out || purchased.includes(ORAL_WORKSHOP_ID) ? (
                          <Button
                            fullWidth
                            variant="contained"
                            size="large"
                            className={`${oralClass.is_sold_out ? 'bg-danger text-white' : ''} mb-2`}
                            disabled>
                            {purchased.includes(ORAL_WORKSHOP_ID) ? 'Class Purchased' : 'Class Full'}
                          </Button>
                        ) : (
                          <>
                            {addedToCart.includes(ORAL_WORKSHOP_ID) ? (
                              <Button fullWidth variant="contained" color="primary" onClick={this.goToCart}>
                                Checkout
                              </Button>
                            ) : (
                              <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={this.clickPurchase}>
                                Purchase Workshop
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-7 d-md-block d-none">
                    <h3 className="title--rubik">About Class:</h3>
                    <div className="text-justify" onClick={this.toggleExpandFullClassDesc}>
                      {expandFullClassDesc ? (
                        <React.Fragment>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: oralClass.desc
                            }}
                          />
                          <p className="text-right">
                            <span className="link">Read less...</span>
                          </p>
                        </React.Fragment>
                      ) : oralClass.desc.length > 400 ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: `${oralClass.desc.substring(0, 397)} <span class="link"> read more...</span>`
                          }}
                        />
                      ) : (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: oralClass.desc
                          }}
                        />
                      )}
                    </div>
                    <h3 className="title--rubik">Lessons Schedule:</h3>
                    {oralClass.lesson.map((lesson, index) => (
                      <div
                        key={index}
                        className="mb-2"
                        style={{ backgroundColor: '#f9f9f9', border: '1px solid #dedfe0', padding: '10px 15px' }}>
                        <div className="mb-2">
                          <i className="fa fa-check mr-2" />
                          <span style={{ fontSize: '20px' }}>{lesson.name}</span>
                        </div>
                        <div className="d-flex align-items-center  px-3 mb-1 text-muted">
                          <i className="material-icons mx-2">event</i>
                          {moment(lesson.scheduled_on).format('dddd, MMMM Do YYYY')}
                        </div>
                        <div className="d-flex align-items-center  px-3 text-muted">
                          <i className="material-icons mx-2">access_time</i>
                          {moment(lesson.scheduled_on).format('h:mm a')} to {moment(lesson.ended_on).format('h:mm a')}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {selectedProgram === 'oral-clinic' && (
                <>
                  <div className="row">
                    <div className="col-12 col-md-auto d-none d-md-block">
                      <Avatar
                        className="size-120 avatar-shadow m-2"
                        alt="Esther Ng"
                        src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/teacher/esther-ng.jpg"
                      />
                      <Avatar
                        className="size-120 avatar-shadow m-2"
                        alt="Esther Ng"
                        src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/teacher/celesta-chee.jpg"
                      />
                    </div>
                    <div className="col-12 col-md">
                      <div className="d-flex justify-content-between align-items-center">
                        <h1 className="title--rubik mb-0">PSLE Oral Clinic</h1>
                        <div>
                          <div className="class__price">
                            <h1 className="text-primary mb-0">$ 30</h1>
                          </div>
                          <small>per 1 hour session</small>
                        </div>
                      </div>
                      <Divider />
                      <p className="text-justify mt-3">
                        The English Oral Clinic is an initiative conceived by Tenopy to provide students a platform to
                        practice and receive feedback on their Oral skills in small groups of up to 4 students. Our
                        experienced teachers will take students through a breath of topics and themes, and build on
                        students’ responses to optimise their score in this PSLE component. Students can also use this
                        platform to discuss a given topic with their peers in order to build their depth of knowledge.
                      </p>
                      <small className="text-muted">Prerequisite</small>
                      <p className="text-justify">
                        In order to maximise your learning experience during these clinic sessions. We recommend that
                        students learn the foundational Oral skills from the&nbsp;
                        <span className="link text-underline" onClick={this.selectProgram('online-class')}>
                          PSLE Oral Workshop
                        </span>
                      </p>
                      {/*<Button variant="contained" color="primary" fullWidth onClick={this.toggleCTADialog}>
                        Purchase 4 Oral Clinic Sessions
                      </Button>*/}
                      <Button variant="contained" className="bg-danger text-white" fullWidth disabled>
                        Class Full
                      </Button>
                    </div>

                    <div className="col-12 my-5">
                      <img
                        src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/misc/oral-clinie-june-2021-schedule.jpg"
                        width="100%"
                        className="shadow"
                        alt="oral clinic schedule"
                      />
                    </div>
                  </div>

                  <div
                    className="row"
                    style={{
                      backgroundColor: '#f9f9f9',
                      border: '1px solid #dedfe0',
                      borderRadius: '10px',
                      padding: '15px'
                    }}>
                    <div className="col-12 text-center">
                      <h2 className="tittle--rubik">Session Preview</h2>
                    </div>
                    <div className="col-12 col-md-6 mb-md-0 mb-4">
                      <video
                        autoPlay={false}
                        className="w-100"
                        poster="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/video-assets/cover/default-poster.jpg"
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        controlsList={'nodownload'}>
                        <source
                          src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/video-assets/psle-oral-reading-aloud.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <h3 className="mt-2">Reading Aloud video snippet</h3>
                      <p className="text-justify mb-0">
                        In the reading aloud component of the PSLE Oral Clinic, students will take a few minutes to
                        prepare themselves before taking turns reading aloud a passage. The teachers will observe how
                        students narrate the given passage and provide feedback on areas of improvement. The group
                        setting will allow students to learn from each other’s mistakes and strengths during practice to
                        ensure that they are prepared for their PSLE English Oral examination.
                      </p>
                    </div>
                    <div className="col-12 col-md-6">
                      <video
                        autoPlay={false}
                        className="w-100"
                        poster="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/video-assets/cover/default-poster.jpg"
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        controlsList={'nodownload'}>
                        <source
                          src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/video-assets/psle-oral-sbd.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <h3 className="mt-2">Stimulus-based discussion video snippet</h3>
                      <p className="text-justify mb-0">
                        In the stimulus-based discussion component of the PSLE Oral Clinic, students will take a few
                        minutes to analyse the information in a visual prompt that has been assigned to them. Students
                        will take turns to answer the questions. After which, the teachers will provide feedback on how
                        they can add more depth to their answers through elaboration. The group setting will allow
                        students to learn from each other’s contributions during practice to ensure that they are
                        prepared for their PSLE English Oral examination.
                      </p>
                    </div>
                  </div>
                </>
              )}

              {selectedProgram === 'self-learning-course' && (
                <div className="row">
                  <div className="col-12 col-md-5">
                    <div
                      className="bg-white py-2 px-4"
                      style={{
                        borderRadius: '10px',
                        borderBottom: '10px solid #8e51f0',
                        borderTop: '1px solid #8e51f0',
                        borderRight: '1px solid #8e51f0',
                        borderLeft: '1px solid #8e51f0'
                      }}>
                      <div className="d-flex flex-row align-items-center">
                        <Avatar
                          className="size-60 avatar-shadow m-2"
                          alt={selfLearningClass?.tutor[0].name}
                          src={selfLearningClass?.tutor[0].profile_pic}
                        />
                        <div className="ml-2">
                          <h2 className="title--rubik mb-1">{selfLearningClass.name}</h2>
                          <div className="d-flex flex-row">
                            <small className="mr-2">by</small>
                            Teacher {selfLearningClass?.tutor[0].name}
                          </div>
                        </div>
                      </div>
                      <div className="text-right">
                        <div className="class__price">
                          <h1 className="text-primary mb-0">
                            $ {selfLearningClass.rate * selfLearningClass.lesson.length}
                          </h1>
                        </div>
                        <small>for full course</small>
                      </div>
                      <Divider />
                      <div className="mt-2 d-block d-md-none">
                        <small className="text-muted">About Class</small>
                        <div className="text-justify" onClick={this.toggleExpandFullClassDesc}>
                          {expandFullClassDesc ? (
                            <React.Fragment>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: selfLearningClass.desc
                                }}
                              />
                              <p className="text-right">
                                <span className="link">Read less...</span>
                              </p>
                            </React.Fragment>
                          ) : selfLearningClass.desc.length > 400 ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: `${selfLearningClass.desc.substring(
                                  0,
                                  390
                                )} <span class="link"> read more...</span>`
                              }}
                            />
                          ) : (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: selfLearningClass.desc
                              }}
                            />
                          )}
                        </div>
                        <Divider />
                      </div>
                      <div className="mt-2">
                        <small className="text-muted">About {selfLearningClass?.tutor[0].name}</small>
                        <p
                          className="text-justify"
                          dangerouslySetInnerHTML={{ __html: selfLearningClass?.tutor[0].desc }}
                        />
                      </div>
                      <div className="text-center mt-3">
                        {purchased.includes(ORAL_SELF_LEARNING_COURSE_ID) ? (
                          <Button fullWidth variant="contained" size="large" className="mb-2" disabled>
                            Class Purchased
                          </Button>
                        ) : (
                          <>
                            {addedToCart.includes(ORAL_SELF_LEARNING_COURSE_ID) ? (
                              <Button fullWidth variant="contained" color="primary" onClick={this.goToCart}>
                                Checkout
                              </Button>
                            ) : (
                              <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={this.clickPurchase}>
                                Purchase Self Learning Course
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-7 d-md-block d-none">
                    <h3 className="title--rubik">About Class:</h3>
                    <div className="text-justify" onClick={this.toggleExpandFullClassDesc}>
                      {expandFullClassDesc ? (
                        <React.Fragment>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: selfLearningClass.desc
                            }}
                          />
                          <p className="text-right">
                            <span className="link">Read less...</span>
                          </p>
                        </React.Fragment>
                      ) : selfLearningClass.desc.length > 400 ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: `${selfLearningClass.desc.substring(
                              0,
                              390
                            )} <span class="link"> read more...</span>`
                          }}
                        />
                      ) : (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: selfLearningClass.desc
                          }}
                        />
                      )}
                    </div>
                    <h3 className="title--rubik">Lessons Schedule:</h3>
                    {selfLearningClass.lesson.map((lesson, index) => (
                      <div
                        key={index}
                        className="mb-2"
                        style={{ backgroundColor: '#f9f9f9', border: '1px solid #dedfe0', padding: '10px 15px' }}>
                        <div className="mb-2">
                          <i className="fa fa-check mr-2" />
                          <span style={{ fontSize: '20px' }}>{lesson.name}</span>
                        </div>
                        <div className="d-flex align-items-center  px-3 mb-1 text-muted">
                          <i className="material-icons mx-2">event</i>
                          {moment(lesson.scheduled_on).format('dddd, MMMM Do YYYY')}
                        </div>
                        <div className="d-flex align-items-center  px-3 text-muted">
                          <i className="material-icons mx-2">access_time</i>
                          {moment(lesson.scheduled_on).format('h:mm a')} to {moment(lesson.ended_on).format('h:mm a')}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>

        <ClassExperience />
        <hr />
        <SuperiorMethodology />

        <section className="bg-light-gray">
          <div className="container">
            <div className="section__header mb-5">
              <div
                className="title mb-2"
                style={{ fontSize: '2.3rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif" }}>
                What they said
              </div>
              <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #8089ff' }}></div>
            </div>
            <Testimonials />
          </div>
        </section>

        <section className="bg__dotted">
          <div className="container">
            <div className="section__header mb-5">
              <div className="title--rubik title mb-2" style={{ fontSize: '2.3rem' }}>
                FAQs
              </div>
              <div className="mx-auto" style={{ maxWidth: '60px', borderBottom: '3px solid #8e51f0' }}></div>
            </div>

            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">Why is the Oral clinic in a group setting?</span>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                The Oral clinic is in a group setting to allow students to learn from each other. We believe that
                students can gain valuable knowledge when the teacher shares feedback with students. A group setting
                also provides an exchange of ideas and perspectives that students can incorporate into their
                stimulus-based conversation.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">Why are students required to turn the camera on?</span>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                We require students to turn their cameras on so that the teachers can observe their posture and mouth
                shape, which are both crucial when learning to speak clearly. An accurate posture improves tone and
                articulation.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">How long is one Oral clinic session?</span>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                The duration of one Oral clinic session is 1 hour. However, if there is only one student in a session,
                we will keep the session 30 minutes long.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">Will foundational skills be taught during the Oral clinic?</span>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                The teachers will not be going through foundational skills during the clinic sessions. They would like
                to focus on building up students’ reading and speaking ability. We strongly recommend that students
                learn the Oral foundation skills from the PSLE Oral workshop to maximise their learning experience
                during the Oral clinic sessions.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">Will notes be provided for the Oral clinic?</span>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                There will be no notes provided, in the Oral clinic session. As the Oral clinic session provides
                students with a mock practice of their oral examinations, students will not see the passage/questions
                beforehand. The teacher will share the passage and questions on the screen before giving students a few
                minutes to prepare. Our teachers believe that this is the best way to gauge the students' ability and
                provide accurate feedback.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">Do you provide a model answer for the Oral clinic session?</span>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                We do not provide a fixed model answer as we believe that students should not memorise answers. Instead,
                weekly practices from the Oral Clinic sessions are more effective in helping students develop the skills
                needed to devise a better version of their answers. Students will first attempt to provide their answers
                based on what they best resonate with. The teacher will then advise how they can achieve more depth in
                their answer by building on the points they have brought up. We want our students to form their own
                'model answer' and apply the same technique to different questions.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">Do you provide recordings of the Oral clinic session?</span>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                We do not provide recordings of the oral clinic session. As our students are required to turn their
                cameras on throughout the session, we would like to respect their privacy and not share recordings of
                the Oral clinic sessions.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">How can we make the payment for the Oral clinic?</span>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                Payments are done via Bank Transfer. Please contact us at 88602109/94696793 for more information.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">How can we schedule/reschedule an Oral clinic session?</span>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                The Oral clinic session bookings are done via Calendly. Our sales/customer service team will provide you
                a unique Calendly link to book your preferred time slot after the payment is made. Kindly contact us if
                you would like to reschedule your sessions at 88602109/94696793.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">How can we access the Oral clinic session?</span>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                When booking your preferred time slot on Calendly, you will be required to fill in your email address
                and phone number. After you've booked your preferred time slot, Calendly will send a Zoom meeting link
                to your email. Our customer service team will send you a reminder message on the day of the session as
                well. If you have any questions, please feel free to contact us.
              </AccordionDetails>
            </Accordion>
          </div>
        </section>

        <section className="bg-light-gray">
          <div className="container">
            <div className="row align-items-center">
              <div className="col">
                <h1 className="text-primary font-weight-normal mb-0">Ready to start tutoring with Tenopy?</h1>
                <span style={{ fontSize: '18px' }}>Choose your lesson or get in touch.</span>
              </div>
              <div className="col-auto my-3 my-md-0">
                <Button
                  variant="contained"
                  color="primary"
                  className="mr-2"
                  onClick={this.scrollToElement('oral-programmes')}>
                  Get Started
                </Button>
                <Button variant="outlined" color="primary" onClick={this.toggleGetInTouchDialog}>
                  Get in Touch
                </Button>
              </div>
            </div>
          </div>
        </section>

        <GetInTouchDialog
          isOpen={isGetInTouchDialogOpen}
          close={this.toggleGetInTouchDialog}
          intent="Conversion Page"
        />

        <OralClinicCTADialog isOpen={isCTADialogOpen} close={this.toggleCTADialog} />

        <ProceedToCheckoutDialog
          isOpen={isProceedToCheckoutDialogOpen}
          toggleDialog={this.toggleProceedToCheckoutDialog}
          proceedToCheckout={this.goToCart}
          classTitle={selectedClass?.name}
          lessonsIncluded={lessonsIncluded}
          hideTime={selectedClass?._id === ORAL_SELF_LEARNING_COURSE_ID}
        />
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OralProgrammes))
