import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core'
import { AddCircleOutline as AddIcon, BorderColor as EditIcon, Visibility as ViewIcon } from '@material-ui/icons'
import _ from 'lodash'
import moment from 'moment'
import agent from 'agent'
import { GET_LESSON_TICKETS, UPDATE_TICKET } from 'constants/actionTypes'
import ViewBackgroundDialog from 'components/dialog/alert'
import UpdateTicketDialog from 'components/online-class/dialog-update-ticket'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  updatingTicket: state.ticket.updating,
  lessonTickets: state.ticket.lessonTickets
})

const mapDispatchToProps = dispatch => ({
  getTickets: (lessonId, studentId) =>
    dispatch({ type: GET_LESSON_TICKETS, payload: agent.Ticket.getByLesson(lessonId, studentId), lessonId, studentId }),
  updateTicket: reqPayload => dispatch({ type: UPDATE_TICKET, payload: agent.Ticket.update(reqPayload) })
})

const StudentTable = props => {
  const { studentList, isTrialList, ticketList, clickUpdateComnment, clickViewBackground, currentUserType } = props

  if (studentList.length > 0) {
    return (
      <div className="table-responsive-material bg-white">
        <Table>
          <TableHead style={{ backgroundColor: 'rgba(60, 72, 88, 0.1)' }}>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Birthday</TableCell>
              <TableCell>School</TableCell>
              <TableCell>First Language</TableCell>
              {isTrialList && <TableCell>Tutor Comment</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {studentList.map(studentData => {
              let studentGender = 'N/A'
              if (studentData.gender === 'm') {
                studentGender = 'male'
              } else if (studentData.gender === 'f') {
                studentGender = 'female'
              }

              let ticketData
              if (isTrialList) {
                ticketData = _.find(ticketList, o => {
                  return o.student === studentData._id
                })
              }

              return (
                <TableRow key={studentData._id}>
                  <TableCell>
                    <div className="d-flex align-items-center">
                      <span>
                        {studentData.name} {currentUserType === 'TA' && `(${studentData.email})`}
                      </span>
                      {studentData.meta?.background && (
                        <IconButton color="primary" component="span" onClick={clickViewBackground(studentData)}>
                          <ViewIcon />
                        </IconButton>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>{studentGender}</TableCell>
                  <TableCell>
                    {studentData.date_of_birth ? moment(studentData.date_of_birth).format('ll') : 'N/A'}
                  </TableCell>
                  <TableCell>{studentData.school ?? 'N/A'}</TableCell>
                  <TableCell>{studentData.first_language ?? 'N/A'}</TableCell>
                  {isTrialList && (
                    <TableCell>
                      {ticketData ? (
                        <div className="d-flex align-items-center">
                          {ticketData.tutor_comment ?? ''}
                          <IconButton
                            color="primary"
                            component="span"
                            onClick={clickUpdateComnment(studentData, ticketData)}>
                            {ticketData.tutor_comment ? <EditIcon size="small" /> : <AddIcon />}
                          </IconButton>
                        </div>
                      ) : (
                        <span>No ticket found.</span>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    )
  }

  return null
}

class StudentTab extends React.Component {
  state = {
    selectedStudent: '',
    selectedTicket: '',
    isUpdateTicketDialogOpen: false,
    isViewBackgroundDialogOpen: false
  }

  componentDidMount() {
    const { lessonId } = this.props
    this.props.getTickets(lessonId)
  }

  toggleUpdateTicketDialog = (selectedStudent, selectedTicket) => () => {
    this.setState({
      selectedStudent,
      selectedTicket,
      isUpdateTicketDialogOpen: !this.state.isUpdateTicketDialogOpen
    })
  }

  toggleViewBackgroundDialog = selectedStudent => () => {
    this.setState({
      selectedStudent,
      isViewBackgroundDialogOpen: !this.state.isViewBackgroundDialogOpen
    })
  }

  render() {
    const { paidStudent, trialStudent, lessonTickets, currentUser, lessonId } = this.props
    const { selectedStudent, selectedTicket, isUpdateTicketDialogOpen, isViewBackgroundDialogOpen } = this.state

    return (
      <div className="container animated slideInUpTiny animation-duration-3">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-3">
              <div className="d-flex flex-row align-items-center mb-2">
                <Tooltip id="tooltip-bottom-start" title="Students who paid for this lesson" placement="bottom-start">
                  <i className="fa fa-info-circle mr-1" />
                </Tooltip>
                <h3 className="mb-0">Paid Student ({paidStudent.length})</h3>
              </div>
              <StudentTable
                studentList={paidStudent}
                userType={currentUser._type}
                clickViewBackground={this.toggleViewBackgroundDialog}
              />
            </div>
            <div className="col-12 mb-3">
              <div className="d-flex flex-row align-items-center mb-2">
                <Tooltip
                  id="tooltip-bottom-start"
                  title="Students who attend this lesson as a trial"
                  placement="bottom-start">
                  <i className="fa fa-info-circle mr-1" />
                </Tooltip>
                <h3 className="mb-0">Trial Student ({trialStudent.length})</h3>
              </div>
              <StudentTable
                studentList={trialStudent}
                userType={currentUser._typee}
                isTrialList={true}
                ticketList={lessonTickets.data}
                clickUpdateComnment={this.toggleUpdateTicketDialog}
                clickViewBackground={this.toggleViewBackgroundDialog}
              />
            </div>
          </div>
        </div>

        <UpdateTicketDialog
          isOpen={isUpdateTicketDialogOpen}
          close={this.toggleUpdateTicketDialog()}
          studentData={selectedStudent}
          ticketData={selectedTicket}
          lessonId={lessonId}
        />

        <ViewBackgroundDialog
          isOpen={isViewBackgroundDialogOpen}
          close={this.toggleViewBackgroundDialog()}
          title={`${selectedStudent?.name} background`}
          content={selectedStudent?.meta?.background ?? ''}
        />
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentTab))
