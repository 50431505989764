import React, { Fragment } from 'react'
import Proptypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import moment from 'moment'
import FileWidget from 'components/misc/widget-file'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function SubmissionHistory(props) {
  const { history } = props
  const [isOpen, setIsOpen] = React.useState(false)

  const toggleDialog = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Fragment>
      <span className="link clickable" onClick={toggleDialog}>
        View
      </span>

      <Dialog open={isOpen} onClose={toggleDialog} maxWidth="md" fullWidth TransitionComponent={Transition}>
        <DialogTitle>Submission History</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Submission</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((h, index) => (
                <TableRow key={index}>
                  <TableCell>{h.status}</TableCell>
                  <TableCell>{moment(h.date).format('lll')}</TableCell>
                  <TableCell>
                    {h.attachment.map((file, index) => (
                      <FileWidget fileUrl={file.url} fileName={file.name} key={index} />
                    ))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={toggleDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

SubmissionHistory.propTypes = {
  history: Proptypes.array.isRequired
}

export default SubmissionHistory
