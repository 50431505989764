import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Button, Dialog } from '@material-ui/core'
import { APP, FAQ, SIGNUP } from 'routes/routes'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser
})

class ReferFriendDialog extends React.Component {
  state = {
    isOpen: false,
    showInviteLinkCopied: false
  }

  toggleDialog = () => {
    if (this.state.isOpen) {
      this.setState({ showInviteLinkCopied: false })
    }

    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  toggleInviteLinkCopied = () => {
    this.setState({ showInviteLinkCopied: true })
  }

  render() {
    const { currentUser } = this.props
    const { isOpen, showInviteLinkCopied } = this.state

    if (!currentUser) {
      return null
    }

    const { referral_code } = currentUser

    return (
      <div>
        <Button variant="contained" color="primary" fullWidth disableRipple onClick={this.toggleDialog}>
          Refer a friend
        </Button>
        <Dialog open={isOpen} onClose={this.toggleDialog} maxWidth="md" fullWidth>
          <div className="row py-md-0 py-3 px-md-0 px-2 referral-banner no-gutters" style={{ borderRadius: 0 }}>
            <div className="col-auto d-none d-md-block">
              <img
                src="https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/frontend/illustration-referral.png"
                alt="referral program"
                className="mt-4 mx-4"
                height="120px"
              />
            </div>
            <div className="col align-self-center">
              <h3 className="text-primary mb-1">Invite your friends and both of you will get $20 in credit!</h3>
              <p className="text-large">
                Your friend must <strong>buy at least 1 class</strong> for you to receive your credits.
              </p>
              <Link to={`${APP}${FAQ}#4-1`} target="_blank" className="text-light-blue">
                Terms and conditions apply
              </Link>
            </div>
            <div className="col-md-auto col-12 align-self-center">
              <div className="d-flex flex-row flex-md-column align-items-center mt-md-0 mt-3 mr-md-4 mr-2">
                <p>
                  Your invite code: <strong>{referral_code}</strong>
                </p>
                <CopyToClipboard
                  text={`${window.location.origin}${SIGNUP}?referral=${this.props.currentUser.referral_code}`}
                  onCopy={this.toggleInviteLinkCopied}>
                  <Button color="primary" variant="contained">
                    {showInviteLinkCopied ? 'Copied!' : 'Copy'}
                  </Button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

export default connect(mapStateToProps)(ReferFriendDialog)
