import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import Proptypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'
import agent from 'agent'
import { GET_SUBMISSION_STAT } from 'constants/actionTypes'

const mapStateToProps = state => ({
  loadingSubmissionStat: state.submission.loadingSubmissionStat,
  submissionStat: state.submission.submissionStat
})

const mapDispatchToProps = dispatch => ({
  getSubmissionStat: (filter, homeworkId) =>
    dispatch({ type: GET_SUBMISSION_STAT, payload: agent.Submission.getStat(filter), homeworkId })
})

class HomeworkSubmissionStat extends React.Component {
  componentDidMount() {
    let { homeworkId } = this.props
    this.props.getSubmissionStat({ homework: homeworkId }, homeworkId)
  }

  render() {
    let { homeworkId, totalAssign, loadingSubmissionStat, submissionStat } = this.props

    if (loadingSubmissionStat.includes(homeworkId)) {
      return <CircularProgress size={20} />
    }

    if (!submissionStat[homeworkId]) {
      return <div>Something wrong...</div>
    }

    let stat = submissionStat[homeworkId]
    let totalSubmission = stat.submitted + stat.resubmission + stat.resubmitted + stat.marking + stat.marked

    return (
      <div>
        Assigned to <span className="font-weight-semibold">{totalAssign}</span> students
        <br />
        {totalAssign - totalSubmission > 0 && (
          <Fragment>
            <span className="font-weight-semibold">{totalAssign - totalSubmission}</span> not yet submitted
            <br />
          </Fragment>
        )}
        {stat.submitted > 0 && (
          <Fragment>
            <span className="font-weight-semibold">{stat.submitted}</span> needs marking
            <br />
          </Fragment>
        )}
        {stat.resubmission > 0 && (
          <Fragment>
            <span className="font-weight-semibold">{stat.resubmission}</span> not yet resubmitted
            <br />
          </Fragment>
        )}
        {stat.resubmitted > 0 && (
          <Fragment>
            <span className="font-weight-semibold">{stat.resubmitted}</span> resubmitted, needs marking
            <br />
          </Fragment>
        )}
        {stat.marking > 0 && (
          <Fragment>
            <span className="font-weight-semibold">{stat.marking}</span> marking in progress
            <br />
          </Fragment>
        )}
        {stat.marked > 0 && (
          <Fragment>
            <span className="font-weight-semibold">{stat.marked}</span> marking done
          </Fragment>
        )}
      </div>
    )
  }
}

HomeworkSubmissionStat.propTypes = {
  homeworkId: Proptypes.string.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeworkSubmissionStat)
