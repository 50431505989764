import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Slide
} from '@material-ui/core'
import agent from 'agent'
import { GET_ONLINE_CLASS_LIST } from 'constants/actionTypes'
import { APP, CLASS_DETAILS } from 'routes/routes'
import { Button } from 'reactstrap'

const mapStateToProps = state => ({
  loadingOnlineClassList: state.onlineClass.loadingOnlineClassList,
  onlineClassList: state.onlineClass.onlineClassList
})

const mapDispatchToProps = dispatch => ({
  getClassList: () =>
    dispatch({
      type: GET_ONLINE_CLASS_LIST,
      payload: agent.OnlineClass.getList()
    })
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

class PurchaseLinkedClassDialog extends React.Component {
  state = {
    linkedClass: []
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      if (!this.props.onlineClassList) {
        this.props.getClassList()
      } else {
        this.processData()
      }
    }

    if (prevProps.loadingOnlineClassList && !this.props.loadingOnlineClassList) {
      this.processData()
    }
  }

  processData = () => {
    const { onlineClassList, linkedClassIds } = this.props

    if (!onlineClassList || linkedClassIds?.length === 0) return

    const linkedClass = onlineClassList.filter(o => linkedClassIds.includes(o._id))
    this.setState({ linkedClass })
  }

  viewClass = classId => () => {
    this.props.history.push(`${APP}${CLASS_DETAILS.replace(':classId', classId)}`)
  }

  render() {
    const { isOpen, loadingOnlineClassList } = this.props
    const { linkedClass } = this.state

    const showLoading = loadingOnlineClassList || linkedClass.length === 0

    return (
      <Dialog open={isOpen} onClose={this.props.close} TransitionComponent={Transition} maxWidth="md" fullWidth>
        <DialogTitle>Purchase Class</DialogTitle>
        <Divider />
        <DialogContent>
          {showLoading ? (
            <div className="text-center my-5">
              <CircularProgress size={30} color="primary" />
            </div>
          ) : (
            <>
              {linkedClass.map(classData => (
                <Card className="mb-3" key={classData._id}>
                  <CardContent>
                    <div className="row">
                      <div className="col-12 col-md-3">
                        <img
                          src={classData.tutor[0].profile_pic}
                          alt={classData.tutor[0].name}
                          className="img-fluid rounded shadow"
                        />
                        <div className="mt-2 text-center">
                          <h3 className="mb-0">Teacher {classData.tutor[0].name}</h3>
                        </div>
                      </div>
                      <div className="col-12 col-md-9">
                        <h2 className="intro__title">{classData.name}</h2>
                        <div className="intro__description" dangerouslySetInnerHTML={{ __html: classData.desc }} />
                        <div className="text-right">
                          <Button variant="contained" color="primary" onClick={this.viewClass(classData._id)}>
                            View and Purchase Class
                          </Button>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </>
          )}
        </DialogContent>
      </Dialog>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PurchaseLinkedClassDialog))
