import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { Avatar, Button, CircularProgress, Paper } from '@material-ui/core'
import { ArrowBackIos as BackIcon } from '@material-ui/icons'

import agent from 'agent'
import { GET_EVENT_BY_ID, RECORD_USER_ACTION } from 'constants/actionTypes'
import { APP, FREE_SHARING } from 'routes/routes'
import ShareSessionDialog from 'components/tls/dialog-share-past-session'
import RegisterToWatchDialog from 'components/tls/dialog-register-to-watch'

const mapStateToProps = state => ({
  token: state.common.token,
  loading: state.event.loadingEventData,
  eventData: state.event.eventData
})

const mapDispatchToProps = dispatch => ({
  getTLSData: eventId => dispatch({ type: GET_EVENT_BY_ID, payload: agent.Event.getById(eventId) }),
  getTLSDataPublic: eventId => dispatch({ type: GET_EVENT_BY_ID, payload: agent.Event.getList({ _id: eventId }) }),
  trackAction: reqPayload => dispatch({ type: RECORD_USER_ACTION, payload: agent.Misc.recordAction(reqPayload) })
})

class TLSPlayback extends React.Component {
  state = {
    isShareDialogOpen: false,
    isRegisterToWatchDialogOpen: false,
    isWatchTracked: false
  }

  componentDidMount() {
    document.addEventListener('contextmenu', this.handleContextMenu)
    const eventId = this.props.match.params.eventId
    const { token } = this.props

    if (token) {
      this.props.getTLSData(eventId)
    } else {
      this.setState({ isRegisterToWatchDialogOpen: true })
      this.props.getTLSDataPublic(eventId)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('contextmenu', this.handleContextMenu)
    this.setState({ isWatchTracked: false })
  }

  handleContextMenu = event => {
    event.preventDefault()
  }

  toggleShareDialog = () => {
    this.setState({
      isShareDialogOpen: !this.state.isShareDialogOpen
    })
  }

  toggleRegisterToWatchDialog = () => () => {
    this.props.history.push(FREE_SHARING)
  }

  trackWatchPlayback = () => {
    const { isWatchTracked } = this.state
    if (!isWatchTracked) {
      this.setState({ isWatchTracked: true })
      this.props.trackAction({
        type: 'tls_playback',
        event_id: this.props.match.params.eventId
      })
    }
  }

  render() {
    const { loading, eventData } = this.props
    const { isShareDialogOpen, isRegisterToWatchDialogOpen } = this.state

    if (loading || !eventData) {
      return (
        <div className="text-center my-5">
          <CircularProgress size={40} color="primary" />
        </div>
      )
    }

    let eventName = eventData.name
    if (eventData.meta?.series?.name) {
      eventName = `${eventData.meta?.series?.name} Ep ${eventData.meta?.series?.order}: ${eventData.name}`
    }

    return (
      <>
        <div className="dashboard animated slideInUpTiny animation-duration-3">
          <Paper elevation={2} className="app-wrapper py-3" style={{ zIndex: 1201 }}>
            <div className="row">
              <div className="col">
                <div className="row pb-1">
                  <div className="col d-flex flex-row align-items-center mt-2">
                    <BackIcon
                      className="clickable mr-2"
                      onClick={() => {
                        this.props.history.push(`${APP}${FREE_SHARING}`)
                      }}
                    />
                    <h1 className="title--rubik mb-0">{eventData.name}</h1>
                  </div>
                </div>
              </div>
              <div className="col-auto align-self-center">
                <Button variant="outlined" color="primary" onClick={this.toggleShareDialog}>
                  Share
                </Button>
              </div>
            </div>
          </Paper>
          <div className="app-wrapper container">
            <Paper elevation={3} className="mb-3">
              <video
                className="w-100"
                controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                controlsList="nodownload"
                onPlay={this.trackWatchPlayback}>
                <source src={eventData.playback_url[0]} type="video/mp4" />
              </video>

              <div className="row px-3">
                <div className={`col-md-auto col-12 py-3 ${isMobile ? 'bg-light-gray' : ''}`}>
                  <Avatar src={eventData.cover_photo} className="size-150 avatar-shadow mx-auto" />
                </div>
                <div className="col py-3">
                  <div className="d-flex flex-wrap manage-margin">
                    {eventData.meta.keywords.map((keyword, index) => (
                      <span className="tag info-tag--outline text-capitalize" key={index}>
                        {keyword}
                      </span>
                    ))}
                  </div>
                  <h2 className="title--rubik mb-0">{eventName}</h2>
                  <div className="text-muted text-small mb-3">
                    by&nbsp;
                    {eventData.host_by.salute ?? 'Teacher'}&nbsp;
                    {eventData.host_by.name}
                  </div>
                  <div className="label text-small text-muted mb-2">Description</div>
                  <p className="tls__desc" dangerouslySetInnerHTML={{ __html: eventData.desc }} />
                </div>
              </div>
            </Paper>
          </div>
        </div>

        <ShareSessionDialog isOpen={isShareDialogOpen} close={this.toggleShareDialog} eventData={eventData} />

        <RegisterToWatchDialog
          isOpen={isRegisterToWatchDialogOpen}
          close={this.toggleRegisterToWatchDialog()}
          eventData={eventData}
        />
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TLSPlayback))
