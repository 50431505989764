import * as Sentry from '@sentry/browser'

export function setExternalServiceUserAttributes(userData) {
  Sentry.configureScope(scope => {
    scope.setUser({
      id: userData._id,
      username: userData.name,
      email: userData.email
    })
  })

  if (window._chatlio) {
    let userPhoneNumber = `${userData.phone_country_code} - ${userData.phone}`
    window._chatlio.identify(userData.email, {
      name: userData.name,
      email: userData.email,
      phone: userPhoneNumber
    })
  }
}

export function arrContains(target, pattern) {
  var value = 0
  pattern.forEach(function (word) {
    value = value + target.includes(word)
  })
  return value >= 1
}
