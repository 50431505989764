import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Avatar, Button, Card, CardHeader, CardMedia, CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import _ from 'lodash'
import moment from 'moment'
import { Player, ControlBar, PlaybackRateMenuButton, ReplayControl, ForwardControl } from 'video-react'

import agent from 'agent'
import { GET_PLAYBACK_VIDEO_2, RESET_SELECTED_VIDEO, RECORD_USER_ACTION } from 'constants/actionTypes'
import { getInitials } from 'utils/misc'
import HLSSource from 'components/misc/hls-source'

const mapStateToProps = state => ({
  selectedClass: state.classes.selectedClass,
  currentUser: state.common.currentUser,
  gettingVideoAccessURL: state.videos.gettingVideoAccessURL,
  selectedVideoLessonId: state.videos.selectedVideoLessonId,
  selectedVideoData: state.videos.selectedVideoURL,
  lessonRecordingId: state.classes.lessonRecordingId
})

const mapDispatchToProps = dispatch => ({
  getVideo: lessonId =>
    dispatch({ type: GET_PLAYBACK_VIDEO_2, payload: agent.Lessons.getVideo(lessonId), lessonId: lessonId }),
  resetSelectedVideo: () => dispatch({ type: RESET_SELECTED_VIDEO }),
  trackAction: reqPayload => dispatch({ type: RECORD_USER_ACTION, payload: agent.Misc.recordAction(reqPayload) })
})

const styles = () => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9 ratio
    cursor: 'pointer'
  },
  card: {
    position: 'relative'
  },
  overlay: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    opacity: '0.6',
    '&:hover': {
      cursor: 'not-allowed'
    }
  },
  loading: {
    position: 'absolute',
    top: '35%',
    left: 'calc(50% - 25px);',
    color: 'white'
  }
})

class TabRecording extends React.Component {
  state = {
    activeMonth: this.props.activeMonth,
    activeMonthLessons: [],
    activeTerm: this.props.activeTerm,
    activeLessonVideo: '',
    activeVideoURL: ''
  }

  componentDidMount() {
    document.addEventListener('contextmenu', this.handleContextMenu)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activeMonth !== this.props.activeMonth) {
      this.setState({ activeMonth: this.props.activeMonth })
    }

    if (prevProps.activeTerm !== this.props.activeTerm) {
      this.setState({ activeTerm: this.props.activeTerm })
    }

    if (prevState.activeMonth !== this.state.activeMonth || prevState.activeTerm !== this.state.activeTerm) {
      this.processLessonData()
    }

    if (prevProps.lessonRecordingId !== this.props.lessonRecordingId) {
      this.getPlayback(this.props.lessonRecordingId)
      let recordingDOM = document.getElementById(this.props.lessonRecordingId)
      if (recordingDOM) {
        window.scrollTo(0, recordingDOM.offsetTop)
      }
    }

    if (prevProps.selectedVideoData !== this.props.selectedVideoData) {
      if (
        this.props.selectedVideoLessonId === this.state.activeLessonVideo &&
        this.props.selectedVideoData.playback &&
        this.props.selectedVideoData.playback.length > 0
      ) {
        this.setState({ activeVideoURL: this.props.selectedVideoData.playback[0] })

        const { selectedClass } = this.props
        const { activeLessonVideo } = this.state
        this.props.trackAction({
          type: 'lesson_playback',
          class_id: selectedClass._id,
          lesson_id: activeLessonVideo
        })
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('contextmenu', this.handleContextMenu)
  }

  handleContextMenu = event => {
    event.preventDefault()
  }

  processLessonData = () => {
    let { selectedClass, showByTerm } = this.props
    let { activeMonth, activeTerm } = this.state
    let activeMonthLessons = []

    if (showByTerm) {
      activeMonthLessons = _.filter(selectedClass.lesson, o => {
        return parseInt(o.meta.term) === activeTerm
      })
    } else {
      if (activeMonth === 'All') {
        activeMonthLessons = selectedClass.lesson
      } else {
        activeMonthLessons = _.filter(selectedClass.lesson, function (o) {
          return moment(o.scheduled_on).isSame(moment(activeMonth), 'month')
        })
      }
    }

    let sortedActiveMonthLessons = _.sortBy(activeMonthLessons, ['scheduled_on'])

    this.setState({
      activeMonthLessons: sortedActiveMonthLessons
    })
  }

  getPlayback = lessonId => {
    // NOTE: hls file type cant go through stream service yet because need to deccrypt every ts file to send to client, so set m3u8 as public accessible now
    this.props.getVideo(lessonId)
    this.setState({ activeLessonVideo: lessonId })
  }

  toggleVideoPart = videoURL => {
    this.setState({ activeVideoURL: videoURL })
    this.player.load()
  }

  closeVideo = () => {
    if (this.player) {
      this.player.pause()
    }
    this.props.resetSelectedVideo()
  }

  render() {
    let { currentUser, classes, gettingVideoAccessURL, selectedVideoLessonId, selectedVideoData } = this.props
    let { activeMonthLessons, activeLessonVideo, activeVideoURL } = this.state

    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <div className="row">
          {activeMonthLessons.map(lesson => {
            if (!lesson.is_active) {
              return null
            }

            let placeHolderImage
            let disable = false
            let videoType = 'MP4'
            let haveAccess =
              lesson.student.includes(currentUser._id) ||
              lesson.trial_student.includes(currentUser._id) ||
              lesson.video_student.includes(currentUser._id)

            if (moment(lesson.ended_on).add(30, 'minutes') > moment()) {
              disable = true
              placeHolderImage =
                'https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/frontend/recording-coming-soon.jpg'
            } else {
              let haveAccess =
                lesson.student.includes(currentUser._id) ||
                lesson.trial_student.includes(currentUser._id) ||
                lesson.video_student.includes(currentUser._id)

              if (haveAccess) {
                if (lesson.playback_url && lesson.playback_url.length > 0) {
                  disable = false
                  placeHolderImage =
                    'https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/frontend/recording-available.jpg'
                  let fileNameArr = lesson.playback_url[0].split('.')
                  videoType = fileNameArr[fileNameArr.length - 1]
                } else {
                  disable = true
                  if (moment().diff(moment(lesson.ended_on), 'days') > 1) {
                    placeHolderImage =
                      'https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/frontend/recording-not-available.jpg'
                  } else {
                    placeHolderImage =
                      'https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/frontend/recording-coming-soon.jpg'
                  }
                }
              } else {
                disable = true
                placeHolderImage =
                  'https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/frontend/recording-no-purchased.jpg'
              }
            }
            if (!haveAccess) {
              disable = true
            }

            let loadingVideo = false
            if (!disable) {
              loadingVideo = activeLessonVideo === lesson._id && gettingVideoAccessURL === lesson._id
            }

            return (
              <div
                className={selectedVideoLessonId === lesson._id ? 'col-12 mb-3' : 'col-12 col-md-6 mb-3'}
                id={lesson._id}
                key={lesson._id}
                style={{ transition: 'all 1s' }}>
                <Card className={classes.card} raised={true}>
                  {selectedVideoLessonId === lesson._id && activeVideoURL ? (
                    <>
                      <Player
                        autoPlay
                        playsInline
                        ref={player => {
                          this.player = player
                        }}>
                        {videoType === 'm3u8' ? (
                          <HLSSource isVideoChild src={lesson.playback_url[0]} />
                        ) : (
                          <source src={activeVideoURL} type="video/mp4" />
                        )}
                        <ControlBar>
                          <ReplayControl seconds={10} order={2.2} />
                          <ForwardControl seconds={10} order={3.2} />
                          <PlaybackRateMenuButton rates={[2, 1.5, 1.25, 1, 0.5]} />
                        </ControlBar>
                      </Player>

                      <div className="text-right">
                        {selectedVideoData.playback.length > 1 &&
                          selectedVideoData.playback.map((playback, index) => {
                            return (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  this.toggleVideoPart(playback)
                                }}
                                className="mr-2"
                                key={index}
                                disabled={activeVideoURL === playback}>
                                Part {index + 1}
                              </Button>
                            )
                          })}
                      </div>
                    </>
                  ) : (
                    <CardMedia
                      className={classes.media}
                      title="lesson recording"
                      onClick={() => {
                        if (!disable) {
                          this.getPlayback(lesson._id)
                        }
                      }}
                      image={placeHolderImage}
                    />
                  )}
                  <div className="row align-items-center">
                    <div className="col">
                      <CardHeader
                        avatar={
                          <Avatar aria-label="Tutor">
                            {lesson.tutor && lesson.tutor.length > 0 && lesson.tutor[0].profile_pic ? (
                              <Avatar
                                className="size-50 avatar-shadow mx-auto mb-2"
                                alt={lesson.tutor[0].name}
                                src={lesson.tutor[0].profile_pic}
                              />
                            ) : (
                              <Avatar className="size-50 mx-auto mb-2 bg-blue">
                                <h3 className="title--rubik text-white m-0">{getInitials(lesson.tutor[0].name)} asd</h3>
                              </Avatar>
                            )}
                          </Avatar>
                        }
                        title={`${
                          lesson.meta && lesson.name !== `Lesson ${lesson.meta.index}`
                            ? `Lesson ${lesson.meta.index}: `
                            : ''
                        }${lesson.name}`}
                        subheader={
                          lesson.class.is_video_product ? '' : moment(lesson.scheduled_on).format('ddd D MMMM, h:mma')
                        }
                      />
                    </div>
                    {selectedVideoLessonId === lesson._id && activeVideoURL && (
                      <div className="col-auto">
                        <CancelOutlinedIcon className="mx-3 clickable" onClick={this.closeVideo} />
                      </div>
                    )}
                  </div>

                  {disable && <div className={classes.overlay} />}
                  {loadingVideo && (
                    <div className={classes.overlay}>
                      <CircularProgress size={50} className={classes.loading} />
                    </div>
                  )}
                </Card>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(TabRecording)))
