import React from 'react'
import classNames from 'classnames'
import timelineIcon from '../../../assets/bullseye.png'

const TimeLineItem = ({ className, timeLine, children }) => {
  const { time, image, title } = timeLine

  return (
    <div className={classNames('timeline-item', className)}>
      <div className="timeline-badge timeline-img">
        <img src={timelineIcon} alt="Pentagon" title="Pentagon" />
      </div>

      <div className="timeline-panel ">
        <div className="timeline-panel-header">
          {!!image && (
            <div className="timeline-header-img timeline-left">
              <img className="size-60 rounded-circle" src={image} alt="Pentagon" title="Pentagon" />
            </div>
          )}
          <div className="timeline-heading">
            <h5 className={'mb-2'}>{time}</h5>
            <h3 className="timeline-title">{title}</h3>
          </div>
        </div>
        {!!children && <div className="timeline-body">{children}</div>}
      </div>
    </div>
  )
}

export default TimeLineItem
