import * as Type from 'constants/actionTypes'
import { addToCart, getCart, removeFromCart, emptyCart } from 'utils/cart'
import _ from 'lodash'
import moment from 'moment'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'

const defaultState = {
  shoppingCart: {
    item: []
  },
  updatingCartItem: false,
  gettingCartQuote: false,
  cartQuote: null,
  cartClasses: null,
  cartQuoteError: null,
  checkoutInProgress: false,
  invoice: null, // NOTE: use after checkout success, check whether can simplify
  orderList: null, // NOTE: use after checkout success, check whether can simplify
  checkoutError: null,
  initializingPayment: false,
  paymentIntent: null,
  initPaymentError: null,
  gettingPostPaymentStat: false,
  postPaymentStat: null,
  lessonPackages: {}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case Type.GET_USER_DATA_SUCCESS: {
      let updatedCart = state.shoppingCart
      //NOTE: to process cart upon INIT app
      if (action.payload.length > 2) {
        updatedCart = action.payload[2].data
      }

      return {
        ...state,
        shoppingCart: updatedCart
      }
    }

    case Type.GET_CART:
      return {
        ...state,
        loadingCart: true
      }

    case Type.GET_CART_SUCCESS:
      return {
        ...state,
        loadingCart: false,
        shoppingCart: action.payload.data
      }

    case Type.GET_CART_FAILED:
      return {
        ...state,
        loadingCart: false,
        shoppingCart: null
      }

    case Type.PATCH_CART_ITEM:
      return {
        ...state,
        updatingCartItem: true
      }

    case Type.PATCH_CART_ITEM_SUCCESS:
    case Type.PATCH_CART_ITEM_FAILED:
      return {
        ...state,
        updatingCartItem: false
      }

    case Type.POPULATE_CART:
      return {
        ...state,
        populatingCart: true
      }

    case Type.POPULATE_CART_SUCCESS:
      return {
        ...state,
        populatingCart: false,
        cartClasses: action.payload.data
      }

    case Type.POPULATE_CART_FAILED:
      return {
        ...state,
        populatingCart: false,
        cartClasses: null
      }

    case Type.CLEAR_CART_CLASSES:
      return {
        ...state,
        cartClasses: null
      }

    case Type.GET_CART_NOAUTH: {
      let cart = getCart()
      return {
        ...state,
        shoppingCart: cart
      }
    }

    case Type.REMOVE_CART_ITEM_NOAUTH: {
      let newCart = removeFromCart(action.payload)
      return {
        ...state,
        shoppingCart: newCart
      }
    }

    case Type.EMPTY_CART_NOAUTH: {
      let newCart = emptyCart(action.payload)
      return {
        ...state,
        shoppingCart: newCart
      }
    }

    case Type.ADD_CART_ITEM_NOAUTH: {
      let newCart = addToCart(action.payload)
      ReactPixel.track('AddToCart', action.payload)
      ReactGA.event({
        category: 'Purchase',
        action: 'Add to Cart',
        label: 'Not logged in'
      })

      return {
        ...state,
        shoppingCart: newCart
      }
    }

    case Type.ADD_CART_ITEM:
      return {
        ...state,
        addingCartItem: true,
        error: false
      }

    case Type.ADD_CART_ITEM_SUCCESS: {
      ReactPixel.track('AddToCart', action.cartItem)
      ReactGA.event({
        category: 'Purchase',
        action: 'Add to Cart',
        label: 'Logged in'
      })
      return {
        ...state,
        addingCartItem: false,
        shoppingCart: action.payload.data
      }
    }

    case Type.ADD_CART_ITEM_FAILED:
      return {
        ...state,
        addingCartItem: false,
        error: true
      }

    case Type.REMOVE_CART_ITEM:
    case Type.EMPTY_CART:
      return {
        ...state,
        removingCartItem: true,
        error: false
      }

    case Type.REMOVE_CART_ITEM_SUCCESS:
    case Type.EMPTY_CART_SUCCESS:
      return {
        ...state,
        removingCartItem: false,
        shoppingCart: action.payload.data
      }

    case Type.REMOVE_CART_ITEM_FAILED:
    case Type.EMPTY_CART_FAILED:
      return {
        ...state,
        removingCartItem: true,
        error: true
      }

    case Type.GET_CART_QUOTE:
      return {
        ...state,
        gettingCartQuote: true,
        cartQuoteError: null
      }

    case Type.GET_CART_QUOTE_SUCCESS:
      return {
        ...state,
        gettingCartQuote: false,
        cartQuote: action.payload.data
      }

    case Type.GET_CART_QUOTE_FAILED:
      return {
        ...state,
        gettingCartQuote: false,
        cartQuoteError: action.payload
      }

    case Type.CHECKOUT_CART:
      return {
        ...state,
        checkoutInProgress: true,
        checkoutError: null,
        orderList: null
      }

    case Type.CHECKOUT_CART_SUCCESS: {
      let hasNew = !!action.newClasses ? action.newClasses.length > 0 : false
      let hasExisting = !!action.existingClasses ? action.existingClasses.length > 0 : false
      let contentArray = []
      if (hasNew) contentArray.push('new_purchase')
      if (hasExisting) contentArray.push('renewal')
      if (contentArray.length === 0) contentArray.push('Online Class')

      ReactPixel.track('Purchase', {
        content_type: contentArray.join('|'),
        contents: action.payload.data.order_list.map(o => {
          return {
            class_id: o.merchandise_id,
            original_price: o.original_price,
            actual_price: o.actual_price,
            start: o.subscription_started_on,
            end: o.subscription_ended_on
          }
        }),
        value: action.payload.data.invoice.quote,
        currency: !!action.payload.data.invoice.currency ? action.payload.data.invoice.currency : 'SGD'
      })

      for (let i = 0; i < action.payload.data.class_list.length; i++) {
        let targetClass = action.payload.data.class_list[i]
        ReactPixel.trackCustom(`Purchase ${targetClass.subject}`, { class_id: targetClass._id, name: targetClass._id })
      }

      ReactGA.event({
        category: 'Purchase',
        action: 'Shopping Cart',
        value: Math.trunc(action.payload.data.invoice.quote)
      })

      return {
        ...state,
        shoppingCart: {
          item: []
        },
        checkoutInProgress: false,
        invoice: action.payload.data.invoice,
        orderList: action.payload.data.order_list
      }
    }

    case Type.CHECKOUT_CART_FAILED: {
      // NOTE: use this stupid logic 1st, if error message receive from server doesn't contain space, it
      //       means is an error code, so handle it, else display it as error message to user.
      let checkoutError =
        "Your payment was unsuccessful. Please contact us at +65 9469 6793 and we'll help you immediately."
      if (action?.payload?.message) {
        let errorMessage = action.payload.message
        if (errorMessage.indexOf(' ') >= 0) {
          checkoutError = errorMessage
        }
      }

      return {
        ...state,
        checkoutInProgress: false,
        invoice: null,
        orderList: null,
        checkoutError
      }
    }

    case Type.INIT_PAYMENT:
      return {
        ...state,
        initializingPayment: true,
        paymentIntent: null,
        initPaymentError: null
      }

    case Type.INIT_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentIntent: action.payload.data
      }

    case Type.INIT_PAYMENT_FAILED: {
      // NOTE: use this stupid logic 1st, if error message receive from server doesn't contain space, it
      //       means is an error code, so handle it, else display it as error message to user.
      let initPaymentError =
        "Your payment request was unsuccessful. Please contact us at +65 9469 6793 and we'll help you immediately."
      if (action?.payload?.message) {
        let errorMessage = action.payload.message
        if (errorMessage.indexOf(' ') >= 0) {
          initPaymentError = errorMessage
        }
      }

      return {
        ...state,
        initializingPayment: false,
        paymentIntent: null,
        initPaymentError
      }
    }

    case Type.GET_POST_PAYMENT_STAT:
      return {
        ...state,
        gettingPostPaymentStat: true,
        postPaymentStat: null
      }

    case Type.GET_POST_PAYMENT_STAT_SUCCESS:
      return {
        ...state,
        gettingPostPaymentStat: false,
        postPaymentStat: action.payload.data
      }

    case Type.GET_POST_PAYMENT_STAT_FAILED:
      return {
        ...state,
        gettingPostPaymentStat: false,
        postPaymentStat: {
          error: true,
          message: action.payload.message
        }
      }

    case Type.GET_PACKAGE_QUOTE:
      return {
        ...state,
        quoting: true
      }

    case Type.GET_PACKAGE_QUOTE_SUCCESS:
    case Type.CALC_PACKAGE_QUOTE: {
      let lessonPackages = {
        ...state.lessonPackages
      }

      if (action.payload.data === 'no_more_lesson_to_purchase') {
        lessonPackages[action.packageItem.class_id] = 'no_more_lesson_to_purchase'
      } else {
        const quotationData = action.payload.data
        let updatedPackageItem = action.packageItem
        let billableLessonCount = quotationData.billable_lesson.length
        updatedPackageItem.count = billableLessonCount
        updatedPackageItem.options = {
          start_date: quotationData.subscription_started_on,
          end_date: quotationData.subscription_ended_on
        }
        updatedPackageItem.dateRange = `${moment(quotationData.subscription_started_on).format('MMM')} -
          ${moment(quotationData.subscription_ended_on).format('MMM YYYY')}`

        if (billableLessonCount !== action.packageItem.count) {
          updatedPackageItem.title = `${billableLessonCount} lessons`
        }

        let newItem = { quote: quotationData, packageItem: updatedPackageItem }

        if (
          lessonPackages[action.packageItem.class_id] &&
          lessonPackages[action.packageItem.class_id].hasOwnProperty('item')
        ) {
          lessonPackages[action.packageItem.class_id].item.push(newItem)
        } else {
          lessonPackages[action.packageItem.class_id] = { item: [newItem] }
        }
        lessonPackages[action.packageItem.class_id].item = _.sortBy(
          lessonPackages[action.packageItem.class_id].item,
          i => i.quote.quote
        )
      }

      return {
        ...state,
        quoting: false,
        lessonPackages: lessonPackages
      }
    }

    case Type.GET_PACKAGE_QUOTE_FAILED:
      return {
        ...state,
        quoting: false
      }

    case Type.CLEAR_PACKAGE_QUOTE: {
      let lessonPackages = {
        ...state.lessonPackages
      }

      if (lessonPackages.hasOwnProperty(action.class_id)) {
        delete lessonPackages[action.class_id]
      }

      return {
        ...state,
        lessonPackages: lessonPackages
      }
    }

    default:
      return state
  }
}
