import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Button, Collapse, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { USER_PROFILE_EDIT } from 'routes/routes'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser
})

class UpdateProfileCTA extends React.Component {
  state = {
    isOpen: false
  }

  componentDidMount() {
    let hitMaxCountShowingToUser = false
    let basicInfoUpdated = false

    // NOTE: record user attempt to close update profile CTA, if more than 3 times, stop showing 1st
    // format: timestamp||attempt count
    const userAttempt = window.localStorage.getItem('u-p-cta')
    if (userAttempt) {
      let parsed = userAttempt.split('||')
      if (parsed[1] > 3) {
        hitMaxCountShowingToUser = true
      }
    }

    const { currentUser } = this.props
    const { level, school, parent } = currentUser

    if (level && school && parent?.name) {
      basicInfoUpdated = true
    }

    if (hitMaxCountShowingToUser || basicInfoUpdated) {
      this.setState({ isOpen: false })
    } else {
      this.setState({ isOpen: true })
    }
  }

  closeCTA = () => {
    const userAttempt = window.localStorage.getItem('u-p-cta')
    let updatedAttempt
    if (userAttempt) {
      let parsed = userAttempt.split('||')
      let attemptCount = Number(parsed[1])
      updatedAttempt = `${Date.now()}||${attemptCount + 1}`
    } else {
      updatedAttempt = `${Date.now()}||1`
    }
    window.localStorage.setItem('u-p-cta', updatedAttempt)
    this.setState({ isOpen: false })
  }

  render() {
    const { isOpen } = this.state

    return (
      <Collapse in={isOpen}>
        <Alert
          className="mb-3"
          severity="warning"
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={this.closeCTA}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          <AlertTitle>Action Required</AlertTitle>
          <div>
            <p className="mb-1">
              Kindly fill in your personal information so that we can assist you and your child in their Tenopy learning
              journey.
            </p>
            <Button
              variant="outlined"
              className="bg-white border-warning text-warning mt-2 px-3"
              onClick={() => {
                this.props.history.push(USER_PROFILE_EDIT)
              }}>
              Update My Details
            </Button>
          </div>
        </Alert>
      </Collapse>
    )
  }
}

export default withRouter(connect(mapStateToProps)(UpdateProfileCTA))
