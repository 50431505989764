import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Accordion, AccordionSummary, AccordionDetails, Divider } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import _ from 'lodash'
import moment from 'moment'
import qs from 'qs'
import ResourceSection from './section-resource'
import FullTermResources from 'components/misc/term-1-resources'

const mapStateToProps = state => ({
  selectedClass: state.classes.selectedClass,
  currentUser: state.common.currentUser
})

class TabResource extends React.Component {
  state = {
    activeMonth: this.props.activeMonth,
    activeMonthLessons: [],
    activeTerm: this.props.activeTerm,
    expandedLesson: []
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activeMonth !== this.props.activeMonth) {
      this.setState({ activeMonth: this.props.activeMonth })
    }

    if (prevProps.activeTerm !== this.props.activeTerm) {
      this.setState({ activeTerm: this.props.activeTerm })
    }

    if (prevState.activeMonth !== this.state.activeMonth || prevState.activeTerm !== this.state.activeTerm) {
      this.processLessonData()
    }
  }

  //NOTE: Tab LiveLesson, Tab Resource is having same process lesson data, try to optimize it
  processLessonData = () => {
    let { selectedClass, firstLoad, showByTerm } = this.props
    let { activeMonth, activeTerm } = this.state
    let activeMonthLessons = []
    let sortedActiveMonthLessons = []
    let expandedLesson

    if (showByTerm) {
      activeMonthLessons = _.filter(selectedClass.lesson, o => {
        return parseInt(o.meta.term) === activeTerm
      })

      sortedActiveMonthLessons = _.sortBy(activeMonthLessons, ['scheduled_on'])
      let parsed = qs.parse(this.props.location.search.slice(1))

      if (firstLoad && parsed.tab && parsed.tab === 'resource' && parsed.lid) {
        expandedLesson = [parsed.lid]
      } else {
        let activeLesson = _.find(sortedActiveMonthLessons, function (o) {
          return moment(o.ended_on) > moment()
        })
        expandedLesson = activeLesson ? [activeLesson._id] : []
      }
    } else {
      if (activeMonth === 'All') {
        activeMonthLessons = selectedClass.lesson
      } else {
        activeMonthLessons = _.filter(selectedClass.lesson, function (o) {
          return moment(o.scheduled_on).isSame(moment(activeMonth), 'month')
        })
      }

      sortedActiveMonthLessons = _.sortBy(activeMonthLessons, ['scheduled_on'])
      let parsed = qs.parse(this.props.location.search.slice(1))

      if (firstLoad && parsed.tab && parsed.tab === 'resource' && parsed.lid) {
        expandedLesson = [parsed.lid]
      } else {
        let activeLesson = _.find(sortedActiveMonthLessons, function (o) {
          if (activeMonth === 'All') {
            return moment(o.ended_on) > moment()
          } else {
            return moment().isSame(moment(activeMonth), 'month') && moment(o.ended_on) > moment()
          }
        })
        expandedLesson = activeLesson ? [activeLesson._id] : []
      }
    }

    this.setState({
      activeMonthLessons: sortedActiveMonthLessons,
      expandedLesson
    })
  }

  handleLessonExpand = lessonId => (event, expanded) => {
    let { expandedLesson } = this.state
    if (expanded) {
      expandedLesson.push(lessonId)
    } else {
      expandedLesson = _.remove(expandedLesson, function (o) {
        return o !== lessonId
      })
    }
    this.setState({ expandedLesson })
  }

  render() {
    const { currentUser, selectedClass } = this.props
    const { activeTerm, activeMonthLessons, expandedLesson } = this.state

    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <FullTermResources activeTerm={activeTerm} classId={selectedClass._id} />
        {activeMonthLessons.map(lesson => {
          if (!lesson.is_active) {
            return null
          }

          let isUserLesson = false
          if (
            lesson.student.includes(currentUser._id) ||
            lesson.trial_student.includes(currentUser._id) ||
            lesson.video_student.includes(currentUser._id)
          ) {
            isUserLesson = true
          }

          return (
            <Accordion
              expanded={expandedLesson.includes(lesson._id) && isUserLesson}
              key={lesson._id}
              disabled={!isUserLesson}
              onChange={this.handleLessonExpand(lesson._id)}
              style={{ zIndex: '0' }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon style={{ color: `${expandedLesson.includes(lesson._id) ? '#00b96c' : '#333333'}` }} />
                }
                style={{ color: `${expandedLesson.includes(lesson._id) ? '#00b96c' : '#333333'}` }}>
                <div className="row align-items-center w-100">
                  <div className="col">
                    {lesson.meta && <div className="text-small text-muted">Lesson {lesson.meta.index}</div>}
                    <h3
                      className="title--rubik mb-0"
                      style={{ color: `${expandedLesson.includes(lesson._id) ? '#00b96c' : '#333333'}` }}>
                      {lesson.name}
                    </h3>
                  </div>
                  {!isUserLesson && <div className="col-auto text-right">Not purchased yet</div>}
                </div>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                {expandedLesson.includes(lesson._id) && (
                  <ResourceSection lessonId={lesson._id} lessonDate={lesson.scheduled_on} />
                )}
              </AccordionDetails>
            </Accordion>
          )
        })}
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, {})(TabResource))
