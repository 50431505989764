import React from 'react'
import { connect } from 'react-redux'
import { Button, Checkbox, CircularProgress, FormGroup, FormControlLabel } from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'

import agent from 'agent'
import { GET_FEEDBACK } from 'constants/actionTypes'
import Pagination from 'utils/Pagination'

import { Card, CardBody, ButtonDropdown, DropdownToggle, DropdownMenu } from 'reactstrap'

const mapStateToProps = state => ({
  ...state.feedback
})

const mapDispatchToProps = dispatch => ({
  getFeedback: (filter, perPage, page) =>
    dispatch({ type: GET_FEEDBACK, payload: agent.UserInput.getFeedback(filter, perPage, page) })
})

const FeedbackDisplay = props => {
  const { created_by, rating, content, created, feedback_target_object } = props.feedback

  return (
    <Card className={'mb-3'}>
      <CardBody>
        <div className="row">
          <div className="col-3">
            <h4 className="font-weight-semibold">{created_by.name}</h4>
            {feedback_target_object && <h5>{feedback_target_object.name}</h5>}
            <h5 className={'mb-0'}>{moment(created).format('D MMM YYYY, ddd')}</h5>
          </div>
          <div className="col">
            <div className={'mb-3'} style={{ fontSize: 25, lineHeight: 1 }}>
              <i className={rating >= 1 ? 'zmdi zmdi-star text-warning pr-1' : 'zmdi zmdi-star-outline pr-1'} />
              <i className={rating >= 2 ? 'zmdi zmdi-star text-warning pr-1' : 'zmdi zmdi-star-outline pr-1'} />
              <i className={rating >= 3 ? 'zmdi zmdi-star text-warning pr-1' : 'zmdi zmdi-star-outline pr-1'} />
              <i className={rating >= 4 ? 'zmdi zmdi-star text-warning pr-1' : 'zmdi zmdi-star-outline pr-1'} />
              <i className={rating >= 5 ? 'zmdi zmdi-star text-warning pr-1' : 'zmdi zmdi-star-outline pr-1'} />
            </div>
            <div className={!!content ? '' : 'text-muted'}>{!!content ? content : 'No comments'}</div>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

class FeedbackTab extends React.Component {
  state = {
    ratingDropdownOpen: false,
    selectedLessonId: 'all',
    selectedRating: ['1', '2', '3', '4', '5'],
    perPage: 20,
    page: 1
  }

  componentDidMount() {
    this.getFeedback()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      this.getFeedback()
    }
  }

  getFeedback = () => {
    const { selectedLessonId, selectedRating, perPage, page } = this.state
    const { classData } = this.props

    let lessonList = !!classData.lesson ? classData.lesson : []

    let filter = {}

    if (lessonList.length === 0) {
      filter.feedback_target_id = 'none'
    } else if (selectedLessonId === 'all') {
      filter.feedback_target_id = {
        $in: lessonList.map(item => item._id)
      }
    } else {
      filter.feedback_target_id = selectedLessonId
    }

    if (selectedRating.length !== 0) {
      filter.rating = { $in: selectedRating }
    }

    this.props.getFeedback(filter, perPage, page)
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleCheckbox = e => {
    const { selectedRating } = this.state

    let toChange = [...selectedRating]
    let value = e.target.value
    let checked = e.target.checked

    if (checked) {
      toChange.push(value)
    } else {
      _.remove(toChange, v => {
        return v === value
      })
    }

    toChange = _.sortBy(toChange)

    this.setState({ selectedRating: toChange })
  }

  toggleRatingDropdown = () => {
    this.setState({ ratingDropdownOpen: !this.state.ratingDropdownOpen })
  }

  onChangePage = page => {
    this.setState({ page })
  }

  render() {
    const { classData, feedbackList, feedbackCount } = this.props
    const { ratingDropdownOpen, selectedRating, perPage, page } = this.state

    return (
      <div>
        <h2 className="mb-3">Feedback</h2>
        <div className="row mb-3">
          <div className="col-8">
            <div className="row">
              <div className="col-auto d-flex align-items-center">
                <span>Filter By</span>
              </div>
              <div className="col">
                <select name={'selectedLessonId'} onChange={this.handleChange} className={'form-control'}>
                  <option value="all">All Lessons</option>
                  {!!classData &&
                    !!classData.lesson &&
                    classData.lesson.map(lesson => {
                      return (
                        <option key={lesson._id} value={lesson._id}>
                          {lesson.name}
                        </option>
                      )
                    })}
                </select>
              </div>
              <div className="col">
                <ButtonDropdown isOpen={ratingDropdownOpen} toggle={this.toggleRatingDropdown} className={'d-block'}>
                  <DropdownToggle
                    className={'border bg-white text-black form-control text-left d-flex align-items-center'}
                    style={{ height: 'calc(2.25rem + 2px)' }}
                    caret>
                    <span className="d-inlien-block mr-auto">
                      {selectedRating.length === 5 ? 'All ratings' : `${selectedRating.join(', ')} stars`}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <FormGroup>
                      <FormControlLabel
                        className={'ml-0'}
                        checked={selectedRating.includes('1')}
                        control={<Checkbox color="primary" onChange={this.handleCheckbox} value={'1'} />}
                        label={'1 star'}
                      />
                      <FormControlLabel
                        className={'ml-0'}
                        checked={selectedRating.includes('2')}
                        control={<Checkbox color="primary" onChange={this.handleCheckbox} value={'2'} />}
                        label={'2 star'}
                      />
                      <FormControlLabel
                        className={'ml-0'}
                        checked={selectedRating.includes('3')}
                        control={<Checkbox color="primary" onChange={this.handleCheckbox} value={'3'} />}
                        label={'3 star'}
                      />
                      <FormControlLabel
                        className={'ml-0'}
                        checked={selectedRating.includes('4')}
                        control={<Checkbox color="primary" onChange={this.handleCheckbox} value={'4'} />}
                        label={'4 star'}
                      />
                      <FormControlLabel
                        className={'ml-0'}
                        checked={selectedRating.includes('5')}
                        control={<Checkbox color="primary" onChange={this.handleCheckbox} value={'5'} />}
                        label={'5 star'}
                      />
                    </FormGroup>
                  </DropdownMenu>
                </ButtonDropdown>
              </div>
              <div className="col-auto">
                <Button variant={'contained'} color={'primary'} onClick={this.getFeedback}>
                  Filter
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="feedback-list">
          {!!feedbackList ? (
            feedbackList.length === 0 ? (
              <h3>No feedback was found</h3>
            ) : (
              feedbackList.map(f => {
                return <FeedbackDisplay key={f._id} feedback={f} />
              })
            )
          ) : (
            <div className="my-5 text-center">
              <CircularProgress color={'primary'} size={40} />
            </div>
          )}
        </div>
        {!!feedbackCount && (
          <Pagination
            itemCount={feedbackCount}
            onChangePage={this.onChangePage}
            pageSize={perPage}
            currentPage={page}
          />
        )}
      </div>
    )
  }
}

FeedbackTab.propTypes = {
  classData: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackTab)
