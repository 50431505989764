import React from 'react'
import _ from 'lodash'

const PollResult = props => {
  const { questionData, answerData, lessonAccess } = props

  let pollResult = ''
  if (lessonAccess) {
    if (questionData.length > 0) {
      let correctlyAnsweredQuestion = 0
      if (answerData) {
        if (answerData === 'N/A') {
          pollResult = 'N/A'
        } else {
          correctlyAnsweredQuestion = _.filter(answerData, o => {
            return o.result.result === 'Correct'
          }).length
          pollResult = `${correctlyAnsweredQuestion}/${questionData.length}`
        }
      } else {
        pollResult = `${correctlyAnsweredQuestion}/${questionData.length}`
      }
    }
  }
  return (
    <span
      className={pollResult && pollResult !== 'N/A' ? 'pointer' : ''}
      onClick={() => {
        if (pollResult && pollResult !== 'N/A') {
          props.viewPollDetail()
        }
      }}>
      {pollResult}
    </span>
  )
}

export default PollResult
