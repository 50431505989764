import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core'
import _ from 'lodash'
import Proptypes from 'prop-types'
import agent from 'agent'
import { EDIT_RESOURCE, GET_LESSON_RESOURCES_2 } from 'constants/actionTypes'
import { validateTextEntry } from 'utils/formValidation'

const mapStateToProps = state => ({
  editing: state.resources.editing,
  edited: state.resources.edited
})

const mapDispatchToProps = dispatch => ({
  editResource: (resourceId, name, type) =>
    dispatch({ type: EDIT_RESOURCE, payload: agent.Lessons.editLessonResource(resourceId, name, type) }),
  getLessonResource: lessonId =>
    dispatch({ type: GET_LESSON_RESOURCES_2, payload: agent.Lessons.getMaterial(lessonId), lessonId })
})

class EditResourceDialog extends React.Component {
  state = {
    name: '',
    type: '',
    formError: {}
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const { resourceData } = this.props
      this.setState({
        name: resourceData.name,
        type: resourceData.type,
        formError: {}
      })
    }

    if (prevProps.editing && !this.props.editing) {
      if (this.props.edited) {
        this.props.getLessonResource(this.props.resourceData.lesson)
      }
      this.props.close()
    }
  }

  handleChange = event => {
    const name = event.target.name
    const value = event.target.value
    this.setState({ [name]: value })
  }

  validateName = () => {
    const errorMsg = validateTextEntry(this.state.name, 'file name')
    let tempFormError = this.state.formError
    if (errorMsg) {
      tempFormError.name = errorMsg
    } else {
      delete tempFormError.name
    }
    this.setState({ formError: tempFormError })
  }

  clickSave = () => {
    this.validateName()
    const { resourceData } = this.props
    const { name, type, formError } = this.state

    if (_.isEmpty(formError)) {
      this.props.editResource(resourceData._id, name.trim(), type)
    }
  }

  render() {
    const { isOpen, editing } = this.props
    const { name, type, formError } = this.state

    return (
      <Dialog open={isOpen} onClose={this.props.close}>
        <DialogTitle>
          <h2 className="mb-0">Edit Resource</h2>
        </DialogTitle>
        <DialogContent>
          <div className="form-group">
            <TextField
              type="text"
              label="File Name / Description"
              name="name"
              onChange={this.handleChange}
              error={!!formError.name}
              helperText={formError.name}
              fullWidth
              value={name}
              margin="normal"
              className="mt-0 mb-2"
            />
          </div>
          <FormControl component="fieldset" required>
            <FormLabel component="legend">Category</FormLabel>
            <RadioGroup aria-label="category" name="type" value={type} onChange={this.handleChange}>
              <FormControlLabel value="homework" control={<Radio color="primary" />} label="Homework" />
              <FormControlLabel value="notes" control={<Radio color="primary" />} label="Notes" />
              <FormControlLabel value="optional" control={<Radio color="primary" />} label="Optional" />
              <FormControlLabel value="teaching" control={<Radio color="primary" />} label="Teaching Materials" />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.close} variant="outlined" color="default" disabled={editing}>
            Cancel
          </Button>
          <Button onClick={this.clickSave} variant="contained" color="primary" disabled={editing}>
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

EditResourceDialog.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired,
  resourceData: Proptypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(EditResourceDialog)
