import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { TERM_OF_SERVICE } from 'routes/routes'

export default function PrivacyPolicy() {
  return (
    <div className="landing">
      <Helmet>
        <title>Privacy Policy | Tenopy</title>
        <meta
          name="description"
          content="Sign up for online tutoring classes with Tenopy to prepare for Mathematics, Science and English exams"
        />
        <meta
          name="keywords"
          content="Online tuition,Math tuition,English tuition,Secondary school tuition,Primary school tuition,Online tuition singapore,Online learning,Online education,Education platform,Online tutor,Home tutor,home tuition,science tuition,sa1,sa2,ca1,ca2,psle,revision,online revision,online study,online learning,psle revision"
        />
      </Helmet>

      <section className="bg-white">
        <div className="section__header">
          <div className="title">Privacy Policy</div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 section__text text-justify">
              <p>Tenopy is committed to protecting and respecting your privacy.</p>
              <p>
                This policy, together with our <Link to={TERM_OF_SERVICE}>Terms of Service</Link> and any other
                documents referred to in it, sets out the basis on which any personal data we collect from you, or that
                you provide to us, will be processed by us. Please read the following carefully to understand our views
                and practices regarding your personal data and how we will treat it.
              </p>
              <p>If you are under 18, please do not send any personal data about yourself to us.</p>

              <p className="text-underline mb-0">
                <strong>Information collection</strong>
              </p>
              <p>
                In order to provide a better service to you, we may collect and process the following data about you:
              </p>
              <ul>
                <li>
                  information that you provide by filling in forms on our site www.tenopy.com ("our site"). This
                  includes information provided at the time of registering to use our site, subscribing to our service,
                  posting material or requesting further services. We may also ask you for information at other times,
                  for example in connection with a promotion or when you report a problem with our site
                </li>
                <li>if you contact us, we may keep a record of that correspondence</li>
                <li>
                  we may also ask you to complete surveys that we use for research purposes, although you do not have to
                  respond to them
                </li>
                <li>details of transactions you carry out through our site and of the fulfilment of your orders</li>
                <li>details of your visits to our site and the resources that you access</li>
              </ul>
              <p>
                We only retain personal data for so long as it is necessary. Data may be archived as long as the purpose
                for which the data was used still exists.
              </p>

              <p className="text-underline mb-0">
                <strong>Uses made of the information</strong>
              </p>
              <p>The purposes for which information may be used by us in and outside Singapore include:</p>
              <ul>
                <li>
                  ensuring that content from our site is presented in the most effective manner for you and for your
                  computer
                </li>
                <li>
                  providing you with alerts, newsletter, education materials or information that you requested or signed
                  up to
                </li>
                <li>carrying out our obligations arising from any contracts entered into between you and us</li>
                <li>allowing you to participate in interactive features of our service, when you choose to do so</li>
                <li>
                  designing and conducting surveys/questionnaires for client profiling/segmentation, statistical
                  analysis, improving and furthering the provision our products and services
                </li>
                <li>
                  complying with laws and regulations applicable to us or any of our affiliates in or outside Singapore
                </li>
                <li>legal proceedings, including collecting overdue amounts and seeking professional advices</li>
                <li>researching, designing and launching services or products including seminars/events/forums</li>
                <li>promoting and marketing services and products subject to your exercise of the opt-out right or</li>
                <li>purposes directly related or incidental to the above</li>
              </ul>
              <p>
                We intend to use your data in direct marketing and we require your consent (which includes an indication
                of no objection) for that purpose. In this connection, please note that:
              </p>
              <ul>
                <li>
                  your name, contact details (including address, contact number, email address), products and services
                  information, transaction pattern and behaviour, background and demographic data held by us from time
                  to time may be used by us in direct marketing;
                </li>
                <li>
                  the following classes of services, products and subjects may be marketed in direct marketing:
                  <ol>
                    <li>
                      services and products related to our site and/or our affiliates (including marketing affiliates
                      programs we are a part of)
                    </li>
                    <li>reward, loyalty or privileges programmes, promotional offers and related services and</li>
                    <li>
                      invitations to events such as seminars/webinars/tele-seminars, conferences, live programs or
                      events
                    </li>
                  </ol>
                </li>
                <li>
                  We may conduct direct marketing via fax, email, direct mail, telephone and other means of
                  communication or send e-newsletters to you. You may choose not to receive promotional materials, by
                  simply telling us (see below for contact details), and we will cease to do so, without charge
                </li>
              </ul>

              <p className="text-underline mb-0">
                <strong>Disclosure of your information</strong>
              </p>
              <p>We will keep the personal data we hold confidential but may provide information to:</p>
              <ul>
                <li>
                  personnel, agents, advisers, auditors, contractors, financial institutions, and service providers in
                  connection with our operations or services
                </li>
                <li>our overseas offices, affiliates, business partners and counterparts (if any)</li>
                <li>persons under a duty of confidentiality to us</li>
                <li>
                  persons to whom we are required to make disclosure under applicable laws and regulations in or outside
                  Singapore or
                </li>
                <li>actual or proposed transferees or participants of our services in or outside Singapore</li>
              </ul>

              <p className="text-underline mb-0">
                <strong>Cookies</strong>
              </p>
              <p>
                Our website uses cookies to distinguish you from other users of our website. This helps us to provide
                you with a good experience when you browse our website and also allows us to improve our site. By
                continuing to browse the site, you are agreeing to our use of cookies.
              </p>
              <p>
                A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your
                computer if you agree. Cookies contain information that is transferred to your computer's hard drive.
              </p>
              <p>We use the following cookies:</p>
              <ul>
                <li>
                  <strong>Strictly necessary cookies.</strong> These are cookies that are required for the operation of
                  our website. They include, for example, cookies that enable you to log into secure areas of our
                  website, use a shopping cart or make use of ebilling services.
                </li>
                <li>
                  <strong>Analytical/performance cookies.</strong> They allow us to recognise and count the number of
                  visitors and to see how visitors move around our website when they are using it. This helps us to
                  improve the way our website works, for example, by ensuring that users are finding what they are
                  looking for easily.
                </li>
                <li>
                  <strong>Functionality cookies.</strong> These are used to recognise you when you return to our
                  website. This enables us to personalise our content for you, greet you by name and remember your
                  preferences (for example, your choice of language or region).
                </li>
                <li>
                  <strong>Targeting cookies.</strong> These cookies record your visit to our website, the pages you have
                  visited and the links you have followed. We will use this information to make our website and the
                  advertising displayed on it more relevant to your interests. We may also share this information with
                  third parties for this purpose.
                </li>
              </ul>
              <p>
                Please note that third parties (including, for example, advertising networks and providers of external
                services like web traffic analysis services) may also use cookies, over which we have no control. These
                cookies are likely to be analytical/performance cookies or targeting cookies.
              </p>
              <p>
                Our website uses Google Analytics, a web traffic analysis service provided by Google Inc. ("Google").
                Please refer to http://www.google.com/policies/privacy/partners to find out more about how Google uses
                data when you use our website and how to control the information sent to Google.
              </p>
              <p>
                You block cookies by activating the setting on your browser that allows you to refuse the setting of all
                or some cookies. However, if you use your browser settings to block all cookies (including essential
                cookies) you may not be able to access all or parts of our site.
              </p>
              <p>
                Furthermore, you can prevent Google's collection and processing of data by using the Google Ads Settings
                page or downloading and installing their browser plug-in (https://tools.google.com/dlpage/gaoptout).
              </p>

              <p className="text-underline mb-0">
                <strong>Security</strong>
              </p>
              <p>
                All information you provide to us is stored on our secure servers. Where we have given you (or where you
                have chosen) a password which enables you to access certain parts of our site, you are responsible for
                keeping this password confidential. We ask you not to share a password with anyone.
              </p>
              <p>
                Unfortunately, the transmission of information via the internet is not completely secure. Although we
                will do our best to protect your personal data, we cannot guarantee the security of your data
                transmitted to our site; any transmission is at your own risk and you agree not to hold us responsible
                for any breach of security while accessing the internet that is out of our control. Once we have
                received your information, we will use strict procedures and security features to try to prevent
                unauthorised access.
              </p>

              <p className="text-underline mb-0">
                <strong>Changes to our privacy policy</strong>
              </p>
              <p>
                Any changes we may make to our Privacy Policy in the future will be posted on this page. Your continued
                use of our services constitutes your agreement to this Privacy Policy and any updates.
              </p>

              <p className="text-underline mb-0">
                <strong>Data transfers</strong>
              </p>
              <p>
                We may hold your data on servers in Singapore and any other territories as we see fit from time to time.
                We may also transfer your data to our overseas offices or to any people listed at&nbsp;
                <strong>Disclosure of your information</strong> above, who may be located in or outside of Singapore.
              </p>

              <p className="text-underline mb-0">
                <strong>Your consent and rights</strong>
              </p>
              <p>
                By using our service, making an application or visiting our website, you consent to the collection and
                use of your information and other activities as outlined in this policy.
              </p>
              <p>Under the Personal Data Protection Act (the "PDPA"), individuals have the right:</p>
              <ul>
                <li>to check whether we hold personal data about you and to access such data</li>
                <li>
                  to require us to correct as soon as reasonably practicable any data relating to you that is inaccurate
                </li>
                <li>
                  to ascertain our policies and practices in relation to personal data and the kind of personal data
                  held by us and
                </li>
                <li>
                  to object to the use of your personal data for marketing purposes and we shall not use your personal
                  data for marketing purposes after you communicate your objection to us
                </li>
              </ul>
              <p>
                You may exercise your opt-out right by notifying us if you wish to object to the use of your ersonal
                data for direct marketing purposes. Please send requests for such objections, access to data, correction
                of data, information regarding policies and practices and kinds of data held, questions or complaints
                to: ask@tenopy.com
              </p>
              <p>
                In accordance with the terms of the PDPA, we have the right to and may charge a minimum fee for
                processing any data access request.
              </p>

              <p className="text-underline mb-0">
                <strong>Governing law and jurisdiction</strong>
              </p>
              <p>
                Nothing in this Privacy Policy shall limit the rights of the data subject under the PDPA. This Privacy
                Policy shall be governed by the laws of Singapore. You agree to submit to the exclusive jurisdiction of
                the Singapore courts.
              </p>

              <p>
                Last updated: <strong>16 June 2017</strong>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
