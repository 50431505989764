import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { ALL_CLASSES } from 'routes/routes'

const SuperiorMethodology = props => {
  return (
    <section className="bg-white">
      <div className="container">
        <div className="row align-items-center mb-5">
          <div className="col-12 col-md-5">
            <img
              src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/home-great-methodology.jpg"
              alt="superior methodology"
              width="85%"
              className="mb-3 shadow"
            />
          </div>
          <div className="col-12 col-md-7">
            <div
              className="mb-3"
              style={{
                fontSize: '2.3rem',
                fontFamily: "'Rubik', sans-serif",
                lineHeight: 1.2,
                color: '#000000'
              }}>
              Superior Methodology
            </div>
            <div className="text-large mb-3">
              Our teaching approach and curriculum are directed by top educational experts with proven results so
              parents can safely entrust their children's education to us.
            </div>
            <Button
              variant="contained"
              size="large"
              className="btn--purple"
              onClick={() => {
                props.history.push(ALL_CLASSES)
              }}>
              Learn more about our programmes
            </Button>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-4 text-center">
            <img
              src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/home-english-icon.png"
              alt="English"
              className="feature-icon mb-2"
            />
            <div className="h1" style={{ color: '#000000' }}>
              English Programme
            </div>
            <div className="text-large text-justify">
              Aimed to reinforce grammar rules, build vocabulary, and develop inference skills. High Ability classes
              stretch students' linguistic talent with advanced class materials.
            </div>
          </div>
          <div className="col-12 col-md-4 text-center">
            <img
              src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/home-science-icon.png"
              alt="English"
              className="feature-icon mb-2"
            />
            <div className="h1" style={{ color: '#000000' }}>
              Science Programme
            </div>
            <div className="text-large text-justify">
              Based on the LiSC and T.A.P approach developed by our programme director Cedric Chai and widely adopted in
              schools.
            </div>
          </div>
          <div className="col-12 col-md-4 text-center">
            <img
              src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/home-math-icon.png"
              alt="English"
              className="feature-icon mb-2"
            />
            <div className="h1" style={{ color: '#000000' }}>
              Math Programme
            </div>
            <div className="text-large text-justify">
              Topical and Heuristics classes help weaker students with foundation building and challenge stronger
              students with complex word problems.
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default withRouter(SuperiorMethodology)
