import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button } from '@material-ui/core'
import _ from 'lodash'
import agent from 'agent'
import { GET_HOMEWORK } from 'constants/actionTypes'
import HomeworkList from 'components/online-class/list-homework'
import HomeworkCreateUpdateDialog from 'components/online-class/dialog-create-update-homework'

const mapDispatchToProps = dispatch => ({
  getHomeworkList: filter => dispatch({ type: GET_HOMEWORK, payload: agent.Homework.getList(filter), filter })
})

class Homework extends React.Component {
  state = {
    isCreateUpdateHomeworkDialogOpen: false
  }

  componentDidMount() {
    this.props.getHomeworkList({ lesson: this.props.lessonData._id })
  }

  toggleCreateUpdateHomeworkDialog = action => () => {
    this.setState({ isCreateUpdateHomeworkDialogOpen: !_.isEmpty(action) })
  }

  render() {
    let { lessonData } = this.props
    let { isCreateUpdateHomeworkDialogOpen } = this.state

    return (
      <div className="container animated slideInUpTiny animation-duration-3">
        <div className="container">
          <div className="row">
            <div className="col-12 text-right">
              <Button
                variant="contained"
                color="primary"
                onClick={this.toggleCreateUpdateHomeworkDialog('create')}
                disableFocusRipple>
                Add New Homework
              </Button>
              <HomeworkCreateUpdateDialog
                isOpen={isCreateUpdateHomeworkDialogOpen}
                toggleDialog={this.toggleCreateUpdateHomeworkDialog}
                lessonData={lessonData}
              />
            </div>
            <div className="col-12">
              <HomeworkList />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(null, mapDispatchToProps)(Homework))
