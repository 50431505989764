import * as Type from '../constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case Type.GET_PLAYBACK_VIDEO:
      return {
        ...state,
        fetchingVideo: true
      }
    case Type.GET_PLAYBACK_VIDEO_SUCCESS:
      return {
        ...state,
        fetchingVideo: false,
        [action.lessonId]: action.payload.data
      }
    case Type.GET_PLAYBACK_VIDEO_FAILED:
      return {
        ...state,
        fetchingVideo: false
      }

    case Type.GET_PLAYBACK_VIDEO_2:
      return {
        ...state,
        gettingVideoAccessURL: action.lessonId,
        selectedVideoLessonId: ''
      }
    case Type.GET_PLAYBACK_VIDEO_2_SUCCESS:
      return {
        ...state,
        gettingVideoAccessURL: false,
        selectedVideoURL: action.payload.data,
        selectedVideoLessonId: action.lessonId
      }

    case Type.GET_PLAYBACK_VIDEO_2_FAILED:
      return {
        ...state,
        gettingVideoAccessURL: false,
        selectedVideoURL: '',
        selectedVideoLessonId: ''
      }

    case Type.RESET_SELECTED_VIDEO: {
      return {
        ...state,
        selectedVideoURL: '',
        selectedVideoLessonId: ''
      }
    }

    default:
      return state
  }
}
