import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import agent from '../../../agent'
import { GET_CLASS_LIST, SET_CLASS_PAGE, SET_SELECTED_CLASS } from '../../../constants/actionTypes'
import ClassItem from '../../shared/Classes/ClassItem'
import { MY_CLASS_DETAILS, ONLINE_CLASS_LIST } from '../../../routes/routes'
import Pagination from '../../../utils/Pagination'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Recommendation from './Recommendation'
import qs from 'qs'
import UpdateProfileCTA from 'components/misc/cta-update-profile'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  classes: state.classes.classes,
  classCount: state.classes.classCount,
  loadingClassList: state.classes.loadingClassList,
  classPage: state.classes.classPage,
  newTrialClassId: state.classes.newTrialClassId,
  refreshClassList: state.classes.refreshClassList,
  newFreeClassId: state.classes.newFreeClassId
})

const mapDispatchToProps = dispatch => ({
  getClassList: (classType, perPage, page, query) =>
    dispatch({
      type: GET_CLASS_LIST,
      payload: agent.Classes.getClassList(classType, perPage, page, { sortBy: 'end_date', order: 'desc', ...query })
    }),
  setClass: classObject => dispatch({ type: SET_SELECTED_CLASS, selectedClass: classObject }),
  setPage: page => dispatch({ type: SET_CLASS_PAGE, payload: page })
})

class CourseListingStudent extends React.Component {
  constructor(props) {
    super()

    this.viewClass = selectedClass => {
      this.props.setClass(selectedClass)
      let classDetailUrl = MY_CLASS_DETAILS.replace(':classId', selectedClass._id)
      this.props.history.push(classDetailUrl)
    }

    this.onChangePage = page => {
      this.props.setPage(page)
      this.setState({ currentPage: page })
    }

    this.state = {
      activeTab: null,
      perPage: 18,
      currentPage: props.classPage ? props.classPage : 1
    }
  }

  componentDidMount() {
    this.props.getClassList('class', this.state.perPage, this.state.currentPage)
    window.scrollTo(0, 0)

    let parsed = qs.parse(this.props.location.search.slice(1))
    this.setState({
      activeTab: parsed.lt ? parsed.lt : 'main'
    })
  }

  componentDidUpdate(prevProps, prevState) {
    let query = {}

    if (this.state.activeTab !== 'main') {
      query.subject = this.state.activeTab
    }

    if (prevState.currentPage !== this.state.currentPage && prevState.activeTab === this.state.activeTab) {
      this.props.getClassList('class', this.state.perPage, this.state.currentPage, query)
      window.scrollTo(0, 0)
    }

    if (!prevProps.refreshClassList && this.props.refreshClassList) {
      this.props.getClassList('class', this.state.perPage, this.state.currentPage, query)
    }

    if (prevState.activeTab !== this.state.activeTab) {
      this.props.getClassList('class', this.state.perPage, this.state.currentPage, query)
    }
  }

  viewClassCatalogue = () => {
    const { activeTab } = this.state
    let catalogueLink = ONLINE_CLASS_LIST

    if (activeTab !== 'main') {
      catalogueLink += `?subject=${activeTab}`
    }

    this.props.history.push(catalogueLink)
  }

  handleTabChange = selectedTab => {
    this.setState({
      activeTab: selectedTab,
      currentPage: 1
    })
  }

  render() {
    const { classes, classCount, loadingClassList, newTrialClassId, newFreeClassId } = this.props
    const { perPage, currentPage, activeTab } = this.state

    return (
      <div className="app-wrapper">
        <div className="dashboard animated slideInUpTiny animation-duration-3">
          <UpdateProfileCTA />

          <h1 className={'font-weight-semibold mb-3'}>My Classes</h1>

          {/*<ReferralBanner />*/}

          <div className="tab__component">
            <input
              id="main"
              type="radio"
              name="tabs"
              className={'tab__input'}
              checked={activeTab === 'main'}
              disabled={loadingClassList}
              onChange={() => {
                this.handleTabChange('main')
              }}
            />
            <label htmlFor={'main'}>
              <i className="fa fa-book" />
              <span className="text">All Classes</span>
            </label>

            {process.env.REACT_APP_REGION === 'SG' && (
              <React.Fragment>
                <input
                  id="English"
                  type="radio"
                  name="tabs"
                  className={'tab__input'}
                  checked={activeTab === 'English'}
                  disabled={loadingClassList}
                  onChange={() => {
                    this.handleTabChange('English')
                  }}
                />
                <label htmlFor={'English'}>
                  <i className="fa fa-sort-alpha-asc" />
                  <span className="text">English</span>
                </label>

                <input
                  id="Mathematics"
                  type="radio"
                  name="tabs"
                  className={'tab__input'}
                  checked={activeTab === 'Mathematics'}
                  disabled={loadingClassList}
                  onChange={() => {
                    this.handleTabChange('Mathematics')
                  }}
                />
                <label htmlFor={'Mathematics'}>
                  <i className="fa fa-superscript" />
                  <span className="text">Maths</span>
                </label>

                <input
                  id="Science"
                  type="radio"
                  name="tabs"
                  className={'tab__input'}
                  checked={activeTab === 'Science'}
                  disabled={loadingClassList}
                  onChange={() => {
                    this.handleTabChange('Science')
                  }}
                />
                <label htmlFor={'Science'}>
                  <i className="fa fa-flask" />
                  <span className="text">Science</span>
                </label>
              </React.Fragment>
            )}

            <section className={'tab__content tab--active'}>
              {loadingClassList ? (
                <div className="my-5 text-center">
                  <CircularProgress size={40} color={'primary'} />
                </div>
              ) : classes && classes.length > 0 ? (
                <div className="grid__renderer">
                  {classes.map((classData, index) => {
                    return (
                      <ClassItem
                        key={index}
                        className={'grid__item'}
                        classData={classData}
                        viewClass={() => {
                          this.viewClass(classData)
                        }}
                        //isNewClass={newClassId ? newClassId.includes(classData._id) : false}
                        isNewTrialClass={newTrialClassId ? newTrialClassId.includes(classData._id) : false}
                        isNewFreeClass={newFreeClassId ? newFreeClassId.includes(classData._id) : false}
                      />
                    )
                  })}
                </div>
              ) : (
                <div className="my-5 text-center">
                  <img
                    src="https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/frontend/illustration-class-empty.png"
                    alt="get your first class"
                    width="250px"
                    className="mb-4"
                  />
                  <h2 className="font-weight-semibold">
                    {activeTab === 'main'
                      ? 'Join your first Tenopy class today!'
                      : `Join your first Tenopy ${activeTab} class today!`}
                  </h2>
                  <Button color="primary" variant="contained" className="my-2" onClick={this.viewClassCatalogue}>
                    View Class Catalogue
                  </Button>
                </div>
              )}

              {!!classCount && (
                <Pagination
                  itemCount={classCount}
                  pageSize={perPage}
                  initialPage={currentPage}
                  onChangePage={this.onChangePage}
                  className={'mb-3 shadow'}
                />
              )}
            </section>
          </div>

          <Recommendation />
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CourseListingStudent))
