import moment from 'moment'
import _ from 'lodash'
import { CLASS_TYPE_WORKSHOP, CLASS_TYPE_REGULAR_CLASSES, CLASS_TYPE_RECORDING } from '../constants/generalTypes'

export const getPackageForOnlineClass = (userClassWithDetail, onlineClass) => {
  let lessonPackages = []
  let currentDate = moment()
  let activeLesson = _.filter(onlineClass.lesson, function (lesson) {
    return lesson.is_active && lesson.is_released
  })
  let sortedClassLessons = _.sortBy(activeLesson, function (lesson) {
    return new Date(lesson.scheduled_on)
  })

  if (onlineClass.class_type === CLASS_TYPE_REGULAR_CLASSES) {
    let userLastLesson
    if (userClassWithDetail && userClassWithDetail[onlineClass._id]) {
      let userLessons = userClassWithDetail[onlineClass._id].lesson
      let userLessonsDetail = _.filter(onlineClass.lesson, function (l) {
        return userLessons.includes(l._id)
      })
      let sortedUserLessons = _.sortBy(userLessonsDetail, function (lesson) {
        return new Date(lesson.scheduled_on)
      })
      userLastLesson = sortedUserLessons[sortedUserLessons.length - 1]
    }

    let firstLessonDate = moment(sortedClassLessons[0].scheduled_on)

    let _10LessonsPackage = [],
      _20LessonsPackage = [],
      _FullLessonsPackage = []

    let nextLessonIndex = _.findIndex(sortedClassLessons, lesson => {
      return moment(lesson.scheduled_on) > currentDate
    })

    if (userLastLesson) {
      if (moment(userLastLesson.scheduled_on) >= moment(sortedClassLessons[nextLessonIndex].scheduled_on)) {
        nextLessonIndex = _.findIndex(sortedClassLessons, lesson => {
          return lesson._id === userLastLesson._id
        })
        nextLessonIndex++
      }
    } else {
      // NOTE: class not yet start
      if (currentDate < firstLessonDate) {
        nextLessonIndex = 0
      }
    }

    if (
      nextLessonIndex === -1 ||
      nextLessonIndex > sortedClassLessons.length ||
      sortedClassLessons.length - nextLessonIndex === 0
    ) {
      return 'no_more_lesson_to_purchase'
    } else {
      let remainingLessonCount = sortedClassLessons.length - nextLessonIndex
      if (remainingLessonCount > 0) {
        _FullLessonsPackage = _.slice(sortedClassLessons, nextLessonIndex)
        if (remainingLessonCount > 10) {
          _10LessonsPackage = _.slice(sortedClassLessons, nextLessonIndex, nextLessonIndex + 10)
        }
        if (remainingLessonCount > 20) {
          _20LessonsPackage = _.slice(sortedClassLessons, nextLessonIndex, nextLessonIndex + 20)
        }
      }
    }

    _10LessonsPackage = _.compact(_10LessonsPackage)
    _20LessonsPackage = _.compact(_20LessonsPackage)
    _FullLessonsPackage = _.compact(_FullLessonsPackage)

    if (_10LessonsPackage.length > 0) {
      lessonPackages.push(populateRegularClassPackageProps(onlineClass, _10LessonsPackage))
    }
    if (_20LessonsPackage.length > 0 && _20LessonsPackage.length !== _10LessonsPackage.length) {
      lessonPackages.push(populateRegularClassPackageProps(onlineClass, _20LessonsPackage))
    }
    if (_FullLessonsPackage.length > 0 && _FullLessonsPackage.length !== _20LessonsPackage.length) {
      lessonPackages.push(populateRegularClassPackageProps(onlineClass, _FullLessonsPackage))
    }
  } else if (onlineClass.class_type === CLASS_TYPE_WORKSHOP || onlineClass.class_type === CLASS_TYPE_RECORDING) {
    let workshopPurchased = false
    if (userClassWithDetail && userClassWithDetail[onlineClass._id]) {
      let userLessons = userClassWithDetail[onlineClass._id].lesson
      let userLessonsDetail = _.filter(onlineClass.lesson, function (l) {
        return userLessons.includes(l._id)
      })
      let sortedUserLessons = _.sortBy(userLessonsDetail, function (lesson) {
        return new Date(lesson.scheduled_on)
      })
      let userLastLesson = sortedUserLessons[sortedUserLessons.length - 1]
      let classLastLesson = sortedClassLessons[sortedClassLessons.length - 1]
      if (userLastLesson._id === classLastLesson._id) {
        workshopPurchased = true
      }
    }

    if (workshopPurchased) {
      return 'no_more_lesson_to_purchase'
    } else {
      if (onlineClass.force_full_purchase) {
        let packageItem = {
          class_id: onlineClass._id,
          title: `${sortedClassLessons.length} lessons workshop`,
          rate: onlineClass.rate,
          count: sortedClassLessons.length,
          options: {
            start_date: sortedClassLessons[0].scheduled_on,
            end_date: sortedClassLessons[sortedClassLessons.length - 1].scheduled_on
          }
        }

        lessonPackages.push({
          start_date: sortedClassLessons[0].scheduled_on,
          end_date: sortedClassLessons[sortedClassLessons.length - 1].scheduled_on,
          packageItem,
          lessons: sortedClassLessons
        })
      } else {
        let futureLessons = _.filter(sortedClassLessons, o => {
          return moment(o.scheduled_on) > moment()
        })
        let sortedFutureLessons = _.sortBy(futureLessons, o => {
          return new Date(o.scheduled_on)
        })

        let title = ''
        if (sortedClassLessons.length === sortedFutureLessons.length) {
          title = `${sortedFutureLessons.length} lessons workshop`
        } else {
          title = `Remaining ${sortedFutureLessons.length} lessons`
        }

        if (futureLessons.length > 0) {
          let packageItem = {
            class_id: onlineClass._id,
            title,
            rate: onlineClass.rate,
            count: sortedFutureLessons.length,
            options: {
              start_date: sortedFutureLessons[0].scheduled_on,
              end_date: sortedFutureLessons[sortedFutureLessons.length - 1].scheduled_on
            }
          }

          lessonPackages.push({
            start_date: sortedFutureLessons[0].scheduled_on,
            end_date: sortedFutureLessons[sortedFutureLessons.length - 1].scheduled_on,
            packageItem,
            lessons: sortedFutureLessons
          })
        } else {
          return 'no_more_lesson_to_purchase'
        }
      }
    }
  }

  return lessonPackages
}

const populateRegularClassPackageProps = (onlineClass, lessonPackage, title) => {
  let firstLessonDate = lessonPackage[0].scheduled_on
  let lastLessonDate = lessonPackage[lessonPackage.length - 1].scheduled_on
  let firstMonth = moment(firstLessonDate).format('MMM')
  let lastMonth = moment(lastLessonDate).format('MMM YYYY')
  let dateRange = ''

  if (firstMonth === lastMonth) {
    dateRange = lastMonth
  } else {
    dateRange = `${firstMonth} - ${lastMonth}`
  }

  let packageItem = {
    class_id: onlineClass._id,
    title: title || `${lessonPackage.length} ${lessonPackage.length > 1 ? 'Lessons' : 'Lesson'}`,
    rate: onlineClass.rate,
    count: lessonPackage.length,
    dateRange,
    options: {
      start_date: firstLessonDate,
      end_date: lastLessonDate
    }
  }

  return {
    start_date: lessonPackage[0].scheduled_on,
    end_date: lessonPackage[lessonPackage.length - 1].scheduled_on,
    packageItem,
    lessons: lessonPackage
  }
}

export const roundPrice = price => {
  let rounded = price

  if ((Math.round(price * 10) / 10) % 1 === 0) {
    rounded = price.toFixed(0)
  } else {
    rounded = (Math.round(price * 10) / 10).toFixed(2)
  }

  return rounded
}
