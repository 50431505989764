import React from 'react'
import { connect } from 'react-redux'
import { Button, CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import agent from 'agent'
import { INIT_PAYMENT } from 'constants/actionTypes'
import { TERM_OF_SERVICE } from 'routes/routes'

const mapStateToProps = state => ({
  shoppingCart: state.shoppingCart.shoppingCart,
  initializingPayment: state.shoppingCart.initializingPayment,
  paymentIntent: state.shoppingCart.paymentIntent,
  initPaymentError: state.shoppingCart.initPaymentError,
  currentUser: state.common.currentUser
})

const mapDispatchToProps = dispatch => ({
  initPayment: reqPayload => dispatch({ type: INIT_PAYMENT, payload: agent.ShoppingCart.initPayment(reqPayload) })
})

class HitPayButton extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.initializingPayment && !this.propsinitializingPayment) {
      const { paymentIntent } = this.props
      const checkoutUrl = paymentIntent?.payment_gateway?.meta?.url
      if (checkoutUrl) {
        window.localStorage.removeItem('tnp_cart')
        window.open(checkoutUrl, '_self')
      }
    }
  }

  initPayment = () => {
    const { shoppingCart, creditToUse, discountCode } = this.props
    this.props.initPayment({
      payment_gateway: 'hitpay',
      shopping_cart: shoppingCart._id,
      credit: creditToUse,
      discount_code: discountCode
    })
  }

  goToTnCPage = () => {
    window.open(`${process.env.REACT_APP_HOSTNAME}${TERM_OF_SERVICE}`)
  }

  render() {
    const { price, initializingPayment, initPaymentError } = this.props

    return (
      <div className="my-3">
        {initPaymentError && (
          <Alert severity="error" className="mb-3">
            {initPaymentError}
          </Alert>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={this.initPayment}
          disabled={initializingPayment || !price}
          fullWidth>
          Pay {price > 0 && `${Number(price).toFixed(2)}`}
          {initializingPayment && <CircularProgress size={15} className="ml-2" />}
        </Button>
        <div className="text-muted text-small text-center mt-2">
          By clicking on "Pay" button, I agree to the purchase&nbsp;
          <span className="link" onClick={this.goToTnCPage}>
            terms & conditions
          </span>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HitPayButton)
