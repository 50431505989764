import * as Type from '../constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case Type.CREATE_FEEDBACK:
      return {
        ...state,
        feedbackCreated: false,
        inProgress: true
      }
    case Type.CREATE_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedbackCreated: true,
        inProgress: false
      }
    case Type.CREATE_FEEDBACK_FAILED:
      return {
        ...state,
        feedbackCreated: false,
        inProgress: false
      }
    case Type.GET_FEEDBACK:
      return {
        ...state,
        inProgress: true,
        feedbackList: null
      }
    case Type.GET_FEEDBACK_SUCCESS:
      return {
        ...state,
        inProgress: false,
        feedbackList: action.payload.data,
        feedbackCount: action.payload.count
      }
    case Type.GET_FEEDBACK_FAILED:
      return {
        ...state,
        inProgress: false,
        feedbackList: [],
        feedbackCount: 0
      }
    case Type.CHECK_FEEDBACK_EXISTS:
      return {
        ...state,
        checkingFeedback: true,
        feedbackExists: false
      }
    case Type.CHECK_FEEDBACK_EXISTS_SUCCESS: {
      let exists = action.payload.count > 0

      return {
        ...state,
        checkingFeedback: false,
        feedbackExists: exists,
        error: false
      }
    }
    case Type.CHECK_FEEDBACK_EXISTS_FAILED:
      return {
        ...state,
        checkingFeedback: false,
        feedbackExists: false,
        error: true
      }
    default:
      return state
  }
}
