import React from 'react'
import { Link } from 'react-router-dom'
import { MailOutline as EmailIcon, Phone as PhoneIcon, Business as AddressIcon } from '@material-ui/icons'
import moment from 'moment'
import { ALL_CLASSES, PRIVACY_POLICY, TERM_OF_SERVICE, TRIAL, FREE_SHARING, BOOK_CLUB, WW_3000 } from 'routes/routes'

function MainFooter() {
  return (
    <div className="footer" id="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-12">
            <img
              src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/logo/tenopy-white.png"
              alt="footer logo"
              height="40px"
              className="mb-2"
            />
            {/* <p>
              At Tenopy, top online teachers lead hands-on classes live with our next-generation classroom technology.
              Achieve results for English, Math and Science at Primary and Secondary levels with our tuition classes.
              Enrich learning beyond mere grades with our featured learning programmes.
            </p> */}
            <div className="d-lg-none d-block my-lg-none my-4">
              <hr style={{ backgroundColor: ' #b1b5b999' }} />
            </div>
          </div>

          <div className="col-lg-1 d-lg-block d-none">&nbsp;</div>

          <div className="col-lg-3 col-12 justify-content-end">
            <div className="row">
              <div className="col-lg-12 col-6 mb-4">
                <div className="menu__header">Explore</div>
                <div className="mb-1">
                  <Link to={ALL_CLASSES}>Class Catalogue</Link>
                </div>
                <div className="mb-1">
                  <Link to={TRIAL}>Trial Class</Link>
                </div>
                <div className="mb-1">
                  <Link to={FREE_SHARING}>
                    <div className="d-flex flex-nowrap align-items-center">
                      Sharing By Experts <span className="link-tag ml-1">Free</span>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-12 col-6 mb-4">
                <div className="menu__header">Featuring</div>
                {/* <div className="mb-1">
                  <Link to={BOOK_CLUB}>Book Club</Link>
                </div> */}
                {/* {moment() < moment('2022-05-26T00:00:00Z') && ( */}
                <div className="mb-1">
                  <Link to={WW_3000}>Wordly Wise i3000</Link>
                </div>
                {/* )} */}
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-12">
            <div className="row">
              <div className="col-lg-12 col-6 mb-4">
                <div className="menu__header">Contact</div>
                {/* <div className="d-flex flex-row align-items-center mb-3"> */}
                  {/* <PhoneIcon className="text-white mr-2" /> */}
                  {/* <a href="tel:+6594696793">+65 9469 6793</a> */}
                {/* </div> */}
                <div className="d-flex flex-row mb-3">
                  <EmailIcon className="text-white mr-2" />
                  <div>
                    <a href="mailto:ask@tenopy.com">ask@tenopy.com</a>
                    <br />
                    <a href="mailto:careers@tenopy.com">careers@tenopy.com</a>
                  </div>
                </div>
                <div className="d-flex flex-row mb-2">
                  <AddressIcon className="text-white mr-2" />
                  <span className="text-white mt-1">79 Ayer Rajah Crescent, #02-08, Singapore 139955</span>
                </div>
              </div>
              <div className="col-lg-12 col-6 mb-4">
                <div className="menu__header">Connect</div>
                <div className="d-flex flex-wrap manage-margin">
                  <a href="https://web.facebook.com/tenopy.sg/" target="_blank" rel="noopener noreferrer">
                    <img
                      src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/icon/social-facebook-white.png"
                      width="40"
                      height="40"
                      alt="facebook"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UC6wwqAnOrB6rm44b7vC06Eg"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/icon/social-youtube-white.png"
                      width="40"
                      height="40"
                      alt="whatsapp"
                    />
                  </a>
                  <a href="https://www.instagram.com/tenopy.sg/" target="_blank" rel="noopener noreferrer">
                    <img
                      src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/icon/social-insta-white.png"
                      width="40"
                      height="40"
                      alt="whatsapp"
                    />
                  </a>
                  <a href="http://bit.ly/TenopyWA" target="_blank" rel="noopener noreferrer">
                    <img
                      src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/icon/social-whatsapp-white.png"
                      width="40"
                      height="40"
                      alt="whatsapp"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-4">
        <hr style={{ backgroundColor: '#b1b5b999' }} />
      </div>

      <div className="row mt-2">
        <div className="col-lg-6 col-12 order-lg-1 order-2 footer__copyright text-lg-left text-center">
          © Copyright {new Date().getFullYear()} TenOfTen Pte Ltd. All rights reserved.
        </div>
        <div className="col-lg-6 col-12 order-lg-2 order-1 text-lg-right text-center mb-4 mb-lg-0">
          <span className="mr-3">
            <Link to={PRIVACY_POLICY} className="footer__link">
              Privacy Policy
            </Link>
          </span>
          <span>
            <Link to={TERM_OF_SERVICE} className="footer__link">
              Terms of Service
            </Link>
          </span>
        </div>
      </div>
    </div>
  )
}

export default MainFooter
