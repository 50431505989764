import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Clipboard from 'react-clipboard.js'
import { Button, Card, CardContent, CircularProgress, Paper, Switch } from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'

import agent from 'agent'
import educationLevels from 'assets/education_level.json'
import { SUBSCRIBE_TO_SERVICE, UPDATE_USER } from 'constants/actionTypes'
import { USER_PROFILE_EDIT, USER_PASSWORD_EDIT, APP, FAQ, SIGNUP } from 'routes/routes'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  updateUserInProgress: state.common.updateUserInProgress
})

const mapDispatchToProps = dispatch => ({
  updateUser: (user, updatedUserObj) =>
    dispatch({ type: UPDATE_USER, payload: agent.Auth.updateUser(user), user: updatedUserObj }),
  subscribeService: (service, subscribe) =>
    dispatch({ type: SUBSCRIBE_TO_SERVICE, payload: agent.Misc.subscribe(service, subscribe) })
})

class Profile extends React.Component {
  state = {
    showInviteLinkCopied: false,
    parent_report: true,
    news_letter: true
  }

  componentDidMount() {
    const { currentUser } = this.props
    let parent_report = currentUser.service_subscription?.parent_report === 'unsub' ? false : true
    let news_letter = currentUser.service_subscription?.news_letter === 'unsub' ? false : true
    this.setState({
      parent_report,
      news_letter
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.parent_report !== prevState.parent_report) {
      this.props.subscribeService('parent_report', this.state.parent_report)
    }
    if (this.state.news_letter !== prevState.news_letter) {
      this.props.subscribeService('news_letter', this.state.news_letter)
    }
  }

  getInviteLink = () => {
    return `${window.location.origin}${SIGNUP}?referral=${this.props.currentUser.referral_code}`
  }

  toggleInviteLinkCopied = () => {
    this.setState({ showInviteLinkCopied: true })
  }

  handleSwitchChange = event => {
    this.setState({ [event.target.name]: event.target.checked })
  }

  render() {
    const { currentUser, updateUserInProgress } = this.props
    const {
      email,
      name,
      gender,
      date_of_birth,
      phone_country_code,
      phone,
      level,
      school,
      first_language,
      parent,
      referral_code,
      credit
    } = currentUser
    let { showInviteLinkCopied, parent_report, news_letter } = this.state

    let displayLevel = '---'
    if (level) {
      let levelObj = _.find(educationLevels, function (o) {
        return o.code === level
      })
      if (levelObj) {
        displayLevel = levelObj.name
      } else {
        displayLevel = level
      }
    }

    let creditBalance = 0
    let userCredit = credit?.credit
    if (userCredit) {
      let userCountry = currentUser.country
      if (userCountry === 'SG') {
        if (userCredit.SGD && userCredit.SGD.balance > 0) {
          creditBalance = userCredit.SGD.balance
        }
      } else if (userCountry === 'US') {
        if (userCredit.USD && userCredit.USD.balance > 0) {
          creditBalance = userCredit.USD.balance
        }
      }
    }

    let genderText = '---'
    if (gender === 'f') {
      genderText = 'Female'
    } else if (gender === 'm') {
      genderText = 'Male'
    }

    return (
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <Paper elevation={2} className="app-wrapper">
          <h2 className="mb-0">Profile</h2>
        </Paper>
        <div className="app-wrapper">
          <div className="container">
            <Card className="mb-3">
              <CardContent>
                <h2>Student</h2>
                <div className="row align-items-center">
                  <div className="col-md-3 col-5 my-2 font-weight-medium">Email</div>
                  <div className="col-md-9 col-7 my-2">{email}</div>

                  <div className="col-md-3 col-5 my-2 font-weight-medium">Name</div>
                  <div className="col-md-9 col-7 my-2">{name}</div>

                  <div className="col-md-3 col-5 my-2 font-weight-medium">Date of birth</div>
                  <div className="col-md-9 col-7 my-2">
                    {date_of_birth ? moment(date_of_birth).format('ll') : '---'}
                  </div>

                  <div className="col-md-3 col-5 my-2 font-weight-medium">Gender</div>
                  <div className="col-md-9 col-7 my-2">{genderText}</div>

                  <div className="col-md-3 col-5 my-2 font-weight-medium">Phone number</div>
                  <div className="col-md-9 col-7 my-2">
                    {phone ? (
                      <span>
                        (+{phone_country_code}) {phone}
                      </span>
                    ) : (
                      '---'
                    )}
                  </div>

                  <div className="col-md-3 col-5 my-2 font-weight-medium">Level / Grade</div>
                  <div className="col-md-9 col-7 my-2">{displayLevel}</div>

                  <div className="col-md-3 col-5 my-2 font-weight-medium">School</div>
                  <div className="col-md-9 col-7 my-2">{school ?? '---'}</div>

                  <div className="col-md-3 col-5 my-2 font-weight-medium">First Language</div>
                  <div className="col-md-9 col-7 my-2">{first_language ?? '---'}</div>
                </div>

                <h2 className="mt-4">Parent</h2>
                <div className="row align-items-center">
                  <div className="col-md-3 col-5 my-2 font-weight-medium">Name</div>
                  <div className="col-md-9 col-7 my-2">{parent?.name ? `${parent?.salute} ${parent.name}` : '---'}</div>

                  <div className="col-md-3 col-5 my-2 font-weight-medium">Email</div>
                  <div className="col-md-9 col-7 my-2">{parent?.email ?? '---'}</div>

                  <div className="col-md-3 col-5 my-2 font-weight-medium">Phone number</div>
                  <div className="col-md-9 col-7 my-2">
                    {parent?.phone ? (
                      <span>
                        (+{parent.phone_country_code}) {parent.phone}
                      </span>
                    ) : (
                      '---'
                    )}
                  </div>

                  <div className="col-md-3 col-5 my-2 font-weight-medium">Contact Preference</div>
                  <div className="col-md-9 col-7 my-2">
                    {parent?.contact_preference?.whatsapp && <span>Whatsapp</span>}
                    {parent?.contact_preference?.wechat && <span>Wechat</span>}
                    {parent?.contact_preference?.other && <span>{parent.contact_preference.other}</span>}
                  </div>
                </div>

                <div className="d-flex flex-wrap mt-3">
                  <Button
                    variant="contained"
                    color="primary"
                    className="text-white mr-2 mb-2"
                    component={Link}
                    to={USER_PROFILE_EDIT}>
                    Update my details
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className="text-white mr-2 mb-2"
                    component={Link}
                    to={USER_PASSWORD_EDIT}>
                    Change my password
                  </Button>
                </div>
              </CardContent>
            </Card>

            <Card className="mb-3">
              <CardContent>
                <h2>Credits</h2>
                <p>
                  You have <strong>${creditBalance}</strong> in credit.
                </p>

                <div className="row py-md-0 py-3 px-md-0 px-2 mx-2 referral-banner">
                  <div className="col-auto d-none d-md-block">
                    <img
                      src="https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/frontend/illustration-referral.png"
                      alt="referral program"
                      className="mt-4 mx-4"
                      height="120px"
                    />
                  </div>
                  <div className="col align-self-center">
                    <h3 className="text-primary mb-1">Invite your friends and both of you will get $20 in credit!</h3>
                    <p className="text-large">
                      Your friend must <strong>buy at least 1 class</strong> for you to receive your credits.
                    </p>
                    <Link to={`${APP}${FAQ}#4-1`} target="_blank" className="text-light-blue">
                      Terms and conditions apply
                    </Link>
                  </div>
                  <div className="col-md-auto col-12 align-self-center">
                    <div className="d-flex flex-row flex-md-column align-items-center mt-md-0 mt-3 mr-md-4 mr-2">
                      <p>
                        Your invite code: <strong>{referral_code}</strong>
                      </p>
                      <Clipboard component="a" option-text={this.getInviteLink} onSuccess={this.toggleInviteLinkCopied}>
                        <Button color="primary" variant="contained">
                          Copy Invite Link
                        </Button>
                      </Clipboard>
                      {showInviteLinkCopied && <div className="text-primary text-md-center text-left">Link copied</div>}
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>

            <Card className="mb-3">
              <CardContent>
                <h2>Subscriptions</h2>
                <div className="row align-items-center mb-2">
                  <div className="col-md-6">
                    <h3 className="mb-0">Weekly Parent Report</h3>
                    <span className="text-muted">Send a weekly report to parent's email</span>
                  </div>
                  <div className="col">
                    <Switch
                      checked={parent_report}
                      name="parent_report"
                      onChange={this.handleSwitchChange}
                      color="primary"
                    />
                    {updateUserInProgress && <CircularProgress size={20} />}
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <h3 className="mb-0">Newsletter</h3>
                    <span className="text-muted">Stay up to date with the latest happenings at Tenopy</span>
                  </div>
                  <div className="col">
                    <Switch
                      checked={news_letter}
                      name="news_letter"
                      onChange={this.handleSwitchChange}
                      color="primary"
                    />
                    {updateUserInProgress && <CircularProgress size={20} />}
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
