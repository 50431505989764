import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Button,
  CircularProgress,
  Divider
} from '@material-ui/core'
import { ExpandMore as ExpandMoreIcon, Videocam as VideoIcon } from '@material-ui/icons'
import ReactGA from 'react-ga'
import { isMobile } from 'react-device-detect'
import ReactPixel from 'react-facebook-pixel'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import moment from 'moment'

import agent from 'agent'
import {
  ADD_CART_ITEM,
  ADD_CART_ITEM_NOAUTH,
  CALC_PACKAGE_QUOTE,
  GET_PACKAGE_QUOTE,
  CLEAR_PACKAGE_QUOTE,
  CONTACT_US,
  GET_ONLINE_CLASS_LIST
} from 'constants/actionTypes'
import { CLASS_TYPE_REGULAR_CLASSES, CLASS_TYPE_WORKSHOP } from 'constants/generalTypes'
import { getClassData } from 'utils/misc'
import { formatCartItem, calculateQuote } from 'utils/cart'
import { roundPrice } from 'utils/merchandise'
import { APP, ALL_CLASSES, CLASS_DETAILS, TRIAL, SHOPPING_CART } from 'routes/routes'
import JoinTrialDialog from 'pages/common/online-class/trial/dialog-join-trial'
import DialogProceedToCheckout from 'components/online-class/dialog-proceed-to-checkout'
import Testimonials from 'components/misc/testimonial-carousel'
import GetInTouchDialog from 'components/dialog/get-in-touch'
import ClassExperience from 'components/misc/info-class-experience'
import SuperiorMethodology from 'components/misc/info-superior-methodology'
import VideoPlayerDialog from 'components/dialog/video-player'

const mapStateToProps = state => ({
  token: state.common.token,
  onlineClassList: state.onlineClass.onlineClassList,
  contactSuccess: state.misc.contacted,
  lessonPackages: state.shoppingCart.lessonPackages,
  shoppingCart: state.shoppingCart.shoppingCart,
  userClassWithDetail: state.common.userClassWithDetail,
  loadingConfig: state.misc.loadingConfig
})

const mapDispatchToProps = dispatch => ({
  getClassList: () => dispatch({ type: GET_ONLINE_CLASS_LIST, payload: agent.OnlineClass.getList() }),
  contactUs: reqPayload => dispatch({ type: CONTACT_US, payload: agent.Misc.contactUs(reqPayload) }),
  addToCart: item => dispatch({ type: ADD_CART_ITEM, payload: agent.ShoppingCart.addItem(item), cartItem: item }),
  addToLocalCart: item => dispatch({ type: ADD_CART_ITEM_NOAUTH, payload: item }),
  calcPackageQuote: (quote, packageItem) => dispatch({ type: CALC_PACKAGE_QUOTE, payload: quote, packageItem }),
  getPackageQuote: (payload, packageItem) =>
    dispatch({ type: GET_PACKAGE_QUOTE, payload: agent.OnlineClass.getQuote(payload), packageItem: packageItem }),
  clearPackageQuote: (class_id, component_id) =>
    dispatch({ type: CLEAR_PACKAGE_QUOTE, class_id: class_id, component: component_id })
})

const PSLE_CRASH_COURSE_IDS = ['i1KoqGJkSg2fIZbwUaI10Q', '-WOHaJDXRYuAkw6bppH1-Q', 'AIIHS8itTjS2nZzwZhGrNg']
const PREVIEW_VIDEO = {
  i1KoqGJkSg2fIZbwUaI10Q:
    'https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/video-assets/psle-revision-2021-compre-cloze.mp4',
  '-WOHaJDXRYuAkw6bppH1-Q':
    'https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/video-assets/psle-revision-2021-compre-open-ended.mp4',
  AIIHS8itTjS2nZzwZhGrNg:
    'https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/video-assets/psle-revision-2021-synthesis-transformation.mp4'
}

class PSLECrashCourse extends React.Component {
  state = {
    promoClassList: null,
    promoClassId: '',
    showBackIcon: false,
    activeScheduleIndex: 0,
    lessonsIncluded: [],
    isVideoDialogOpen: false,
    isJoinTrialDialogOpen: false,
    selectedClass: null,
    isProceedToCheckoutDialogOpen: false,
    addedToCart: [],
    activeTerm: '1',
    expandFullClassDesc: false,
    isGetInTouchDialogOpen: false,
    classPreviewVideo: ''
  }

  componentDidMount() {
    if (!this.props.onlineClassList || this.props.onlineClassList.length === 0) {
      this.props.getClassList()
    } else {
      this.filterOnlineClass()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loadingConfig && !this.props.loadingConfig) {
      ReactPixel.trackCustom('ViewTrial')
    }

    if (prevProps.onlineClassList !== this.props.onlineClassList) {
      this.filterOnlineClass()
    }

    if (prevState.promoClassList !== this.state.promoClassList && this.state.promoClassList.length > 0) {
      this.selectClass(0)()
      this.updateCartStatus()
    }

    if (prevState.activeScheduleIndex !== this.state.activeScheduleIndex && isMobile) {
      this.scrollTo(`class-${this.state.activeScheduleIndex}`)()
    }

    if (
      prevProps.lessonPackages !== this.props.lessonPackages &&
      this.state.selectedClass &&
      this.props.lessonPackages[this.state.selectedClass._id]
    ) {
      let lessonPackage = this.props.lessonPackages[this.state.selectedClass._id].item[0]
      this.addToCart(lessonPackage)
    }

    if (prevProps.shoppingCart !== this.props.shoppingCart) {
      this.updateCartStatus()
    }
  }

  filterOnlineClass = () => {
    let { onlineClassList } = this.props

    if (!onlineClassList) {
      return
    }

    const promoClassList = _.filter(onlineClassList, function (o) {
      return PSLE_CRASH_COURSE_IDS.includes(o._id)
    })

    if (promoClassList.length > 0) {
      this.setState({ promoClassList })
    } else {
      this.props.history.push(ALL_CLASSES)
    }
  }

  goToClass = classId => () => {
    ReactGA.event({
      category: 'Conversion',
      action: 'View Class Detail',
      label: 'C'
    })
    this.props.history.push(CLASS_DETAILS.replace(':classId', classId))
  }

  scrollToClassSelection = location => () => {
    ReactGA.event({
      category: 'Conversion',
      action: `Get started - ${location}`,
      label: 'C'
    })

    let elmnt = document.getElementById('what-we-cover')
    window.scrollTo({ top: elmnt.offsetTop, behavior: 'smooth' })
  }

  scrollToReadMore = () => {
    ReactGA.event({
      category: 'Conversion',
      action: `Read more`,
      label: 'C'
    })

    let elmnt = document.getElementById('readmore')
    window.scrollTo({ top: elmnt.offsetTop, behavior: 'smooth' })
  }

  scrollTo = id => () => {
    let element = document.getElementById(id)
    window.scrollTo({ top: element.offsetTop - 70, behavior: 'smooth' })
  }

  clickOnTutor = tutorName => () => {
    ReactGA.event({
      category: 'Conversion',
      action: `Click tutor - ${tutorName}`,
      label: 'C'
    })
  }

  changeSchedule = indexChange => () => {
    let { promoClassList } = this.state
    let newScheduleIndex = this.state.activeScheduleIndex + indexChange
    if (newScheduleIndex >= promoClassList.length) {
      newScheduleIndex = 0
    }
    if (newScheduleIndex < 0) {
      newScheduleIndex = promoClassList.length - 1
    }

    this.setState({
      activeScheduleIndex: newScheduleIndex
    })

    ReactGA.event({
      category: 'Conversion',
      action: 'Navigate class schedule',
      label: 'C'
    })
  }

  selectClass = index => () => {
    let { promoClassList, lessonsIncluded } = this.state
    if (lessonsIncluded.length > 0) {
      ReactGA.event({
        category: 'Conversion',
        action: `View ${promoClassList[index].name}`,
        label: 'C'
      })
    }

    let updatedLessonsIncluded = []
    let activeTerm = '1'
    if (promoClassList[index].class_type === CLASS_TYPE_WORKSHOP) {
      updatedLessonsIncluded = promoClassList[index].lesson
    } else {
      let now = moment()
      let nextLesson = _.find(promoClassList[index].lesson, o => {
        return moment(o.scheduled_on) > now
      })
      activeTerm = nextLesson.meta.term.toString()
      updatedLessonsIncluded = _.filter(promoClassList[index].lesson, function (o) {
        return moment(o.scheduled_on) > now && o.meta.term.toString() === activeTerm.toString()
      })
    }

    this.setState({
      activeScheduleIndex: index,
      lessonsIncluded: updatedLessonsIncluded,
      activeTerm,
      expandFullClassDesc: false
    })
  }

  toggleVideoDialog = classId => () => {
    this.setState({
      isVideoDialogOpen: !this.state.isVideoDialogOpen,
      classPreviewURL: classId ? PREVIEW_VIDEO[classId] : ''
    })
  }

  joinTrial = index => () => {
    let { promoClassList } = this.state
    if (!this.state.isJoinTrialDialogOpen) {
      ReactGA.event({
        category: 'Conversion',
        action: `Init join trial ${promoClassList[index].name}`,
        label: 'C'
      })
    }

    this.setState({
      isJoinTrialDialogOpen: true,
      selectedClass: promoClassList[index]
    })
  }

  toggleJoinTrialDialog = () => {
    if (!this.state.isJoinTrialDialogOpen) {
      ReactGA.event({
        category: 'Conversion',
        action: this.props.token ? 'JoinTrial' : 'InitRegistration',
        label: 'C'
      })
    }
    this.setState({
      isJoinTrialDialogOpen: !this.state.isJoinTrialDialogOpen
    })
  }

  buyOnlineLessons = (index, lessonCount) => () => {
    let { token, userClassWithDetail } = this.props
    let { promoClassList, activeTerm } = this.state

    if (!promoClassList) {
      return
    }

    this.setState({
      selectedClass: promoClassList[index]
    })

    let now = moment()
    let classData = promoClassList[index]
    let userLessonIds = []
    if (token && userClassWithDetail && userClassWithDetail[classData._id]) {
      userLessonIds = userClassWithDetail[classData._id].lesson
    }
    let lessonsIncluded = []
    if (classData.class_type === CLASS_TYPE_WORKSHOP) {
      lessonsIncluded = classData.lesson
    } else {
      lessonsIncluded = _.filter(classData.lesson, function (o) {
        return moment(o.scheduled_on) > now && !userLessonIds.includes(o._id) && o.meta.term.toString() === activeTerm
      })
    }

    if (lessonCount && lessonsIncluded.length > lessonCount) {
      lessonsIncluded = _.slice(lessonsIncluded, 0, lessonCount)
      this.setState({ lessonsIncluded })
    }

    if (lessonsIncluded.length > 0) {
      let packageItem = {
        class_id: classData._id,
        count: lessonsIncluded.length,
        options: {
          start_date: lessonsIncluded[0].scheduled_on,
          end_date: lessonsIncluded[lessonsIncluded.length - 1].scheduled_on
        },
        rate: classData.rate,
        title: classData.name
      }

      if (token) {
        this.props.getPackageQuote(
          {
            class_id: classData._id,
            start_date: lessonsIncluded[0].scheduled_on,
            end_date: lessonsIncluded[lessonsIncluded.length - 1].scheduled_on
          },
          packageItem
        )
      } else {
        let quote = calculateQuote(
          lessonsIncluded,
          classData.rate,
          lessonsIncluded[0].scheduled_on,
          lessonsIncluded[lessonsIncluded.length - 1].scheduled_on
        )
        this.props.calcPackageQuote({ data: quote }, packageItem)
      }
    }
  }

  updateCartStatus = () => {
    if (!this.state.promoClassList) return

    let addedToCart = []
    for (let i = 0; i < this.props.shoppingCart.item.length; i++) {
      addedToCart.push(this.props.shoppingCart.item[i].item_id)
    }

    this.setState({ addedToCart })
  }

  addToCart = lessonPackage => {
    let { selectedClass } = this.state

    let formattedItem = formatCartItem(selectedClass, 'Class', lessonPackage.packageItem.options)
    if (!!this.props.token) {
      this.props.addToCart(formattedItem)
    } else {
      this.props.addToLocalCart(formattedItem)
    }
    this.toggleProceedToCheckoutDialog()
  }

  toggleProceedToCheckoutDialog = () => {
    this.setState({ isProceedToCheckoutDialogOpen: !this.state.isProceedToCheckoutDialogOpen })
  }

  goToCart = () => {
    this.toggleProceedToCheckoutDialog()
    let redirectUrl = this.props.token ? `${APP}${SHOPPING_CART}` : SHOPPING_CART
    this.props.history.push(redirectUrl)
  }

  toggleExpandFullClassDesc = () => {
    this.setState({ expandFullClassDesc: !this.state.expandFullClassDesc })
  }

  toggleGetInTouchDialog = () => {
    this.setState({ isGetInTouchDialogOpen: !this.state.isGetInTouchDialogOpen })
  }

  render() {
    const {
      promoClassList,
      activeScheduleIndex,
      lessonsIncluded,
      isVideoDialogOpen,
      isJoinTrialDialogOpen,
      selectedClass,
      isProceedToCheckoutDialogOpen,
      addedToCart,
      expandFullClassDesc,
      isGetInTouchDialogOpen,
      classPreviewURL
    } = this.state

    if (!promoClassList) {
      return (
        <div className="manage-margin text-center my-5">
          <CircularProgress size={50} />
        </div>
      )
    }
    let processedData2 = getClassData(
      promoClassList[activeScheduleIndex].class_type,
      promoClassList[activeScheduleIndex].lesson,
      promoClassList[activeScheduleIndex].rate,
      promoClassList[activeScheduleIndex].quote
    )

    return (
      <div className="landing">
        <Helmet>
          <title>PSLE Crash Courses | Tenopy</title>
          <meta
            name="description"
            content="At Tenopy, top online teachers lead hands-on classes live with our next-generation classroom technology. Achieve results for English, Math and Science at Primary and Secondary levels with our tuition classes. Enrich learning beyond mere grades with our featured learning programmes."
          />
          <meta
            name="keywords"
            content="Online tuition,Math tuition,English tuition,Secondary school tuition,Primary school tuition,Online tuition singapore,Online learning,Online education,Education platform,Online tutor,Home tutor,home tuition,science tuition,sa1,sa2,ca1,ca2,psle,revision,online revision,online study,online learning,psle revision"
          />
        </Helmet>

        <section className={`trial__banner-bg--main ${isMobile ? 'px-0' : ''}`}>
          {!isMobile && <div className="trial__banner-bg--side" />}
          <div className={`container h-100`}>
            <div className="row align-items-center h-100 mb-3 mb-md-0">
              <div className="col-md-6 col-12">
                <span className="tag amber-tag text-capitalize font-weight-semibold " style={{ color: '#29303b' }}>
                  Recommended
                </span>
                <h1>Ace the English Paper 2 with the PSLE Revision Crash Course</h1>
                <div className="text-justify">
                  <p>
                    Ask any student which component worries them the most about the upcoming PSLE English exam. Chances
                    are they will name at least one of these major components: Comprehension OE, Comprehension Cloze,
                    and Synthesis and Transformation.
                  </p>
                  <p>
                    The questions in these components, which make up 45 marks of the PSLE English exam, are often tricky
                    and test students' understanding of various English rules. The PSLE Revision Crash Course at Tenopy
                    teaches students how to accurately decode the questions and apply intermediate skills such as
                    critical reading and text analysis to formulate the most accurate answer for the various question
                    types in each component.
                  </p>
                </div>
                <Button variant="text" color="primary" onClick={this.scrollToReadMore}>
                  Read more...
                </Button>
              </div>
            </div>
          </div>
        </section>

        {isMobile && (
          <section id="what-we-cover">
            <div className="container px-0">
              <div className="section__header mb-5">
                <div className="title mb-2">Our Online Classes</div>
                <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #8e51f0' }}></div>
              </div>

              <div className="row no-gutters">
                {promoClassList.map((classData, index) => {
                  let processedData = getClassData(
                    classData.class_type,
                    classData.lesson,
                    classData.rate,
                    classData.quote
                  )
                  let now = moment()
                  let remainingLessons = classData.lesson
                  if (classData.class_type === CLASS_TYPE_REGULAR_CLASSES) {
                    let nextLesson = _.find(classData.lesson, o => {
                      return moment(o.scheduled_on) > moment()
                    })
                    let activeTerm = nextLesson.meta.term.toString()
                    remainingLessons = _.filter(classData.lesson, function (o) {
                      return moment(o.scheduled_on) > now && o.meta.term.toString() === activeTerm
                    })
                  }

                  return (
                    <div
                      className="col-12 clickable border mb-3 p-2"
                      key={index}
                      id={`class-${index}`}
                      style={{
                        boxShadow:
                          '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
                      }}>
                      <div
                        className="d-flex flex-row align-items-center"
                        onClick={() => {
                          this.selectClass(index)()
                        }}>
                        <Avatar
                          className="size-60 avatar-shadow m-2"
                          alt={classData.tutor[0].name}
                          src={classData.tutor[0].profile_pic}
                        />
                        <div className="ml-2">
                          <h3 className="font-weight-semibold mb-1">{classData.name}</h3>
                          <div className="d-flex flex-row">
                            <small className="mr-2">by</small>
                            {classData.tutor[0].name}
                          </div>
                        </div>
                      </div>
                      <div
                        className="d-flex flex-row justify-content-between align-items-center"
                        onClick={() => {
                          this.selectClass(index)()
                        }}>
                        <small className="text-primary">{activeScheduleIndex !== index ? 'Tap to expand' : ''}</small>
                        <div className="text-right">
                          <div className="class__price">
                            <h1 className="text-primary mb-0">
                              $&nbsp;
                              {classData.class_type === CLASS_TYPE_REGULAR_CLASSES
                                ? roundPrice(processedData.price)
                                : classData.rate}
                            </h1>
                          </div>
                          <small>per lesson</small>
                        </div>
                      </div>

                      {activeScheduleIndex === index && (
                        <React.Fragment>
                          <div className="text-center mt-3">
                            {addedToCart.includes(classData._id) ? (
                              <Button fullWidth variant="outlined" color="primary" onClick={this.goToCart}>
                                Checkout
                              </Button>
                            ) : (
                              <React.Fragment>
                                {classData.is_sold_out ? (
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    className="mb-2 bg-danger text-white"
                                    disabled>
                                    Class Full
                                  </Button>
                                ) : (
                                  <React.Fragment>
                                    <Button
                                      fullWidth
                                      variant="contained"
                                      color="primary"
                                      size="large"
                                      onClick={this.buyOnlineLessons(index)}>
                                      {classData.class_type === CLASS_TYPE_WORKSHOP ? (
                                        <span>Buy Workshop ({classData.lesson.length} lessons)</span>
                                      ) : (
                                        <span>
                                          Buy All Term {remainingLessons.length > 0 && remainingLessons[0].meta.term}{' '}
                                          Lessons
                                        </span>
                                      )}
                                    </Button>
                                    {classData.is_trial && (
                                      <Button variant="text" color="primary" onClick={this.joinTrial(index)}>
                                        Join a Trial Lesson
                                      </Button>
                                    )}
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            )}
                          </div>

                          <hr style={{ borderTop: '1px solid rgba(0, 0, 0, 0.4)' }} />
                          <div className="my-3">
                            <small className="text-muted">About Class</small>
                            <p className="text-justify" dangerouslySetInnerHTML={{ __html: classData.desc }} />
                          </div>
                          <div className="text-right">
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={this.toggleVideoDialog(promoClassList[activeScheduleIndex]._id)}>
                              <VideoIcon color="primary" className="mr-2" /> Class Preview
                            </Button>
                          </div>
                          <hr style={{ borderTop: '1px solid rgba(0, 0, 0, 0.4)' }} />
                          <div className="my-3">
                            <div className="mb-1">
                              <small className="text-muted">Lessons Schedule</small>
                            </div>
                            {lessonsIncluded &&
                              lessonsIncluded.map((lesson, index) => (
                                <div
                                  key={index}
                                  className="mb-2"
                                  style={{
                                    backgroundColor: '#f9f9f9',
                                    border: '1px solid #dedfe0',
                                    padding: '10px 15px'
                                  }}>
                                  <div className="mb-2">
                                    <i className="fa fa-check mr-2" />
                                    {lesson.name}
                                  </div>
                                  <div
                                    className="d-flex align-items-center  mb-1 text-muted"
                                    style={{ fontSize: '15px' }}>
                                    <i className="material-icons mr-2">event</i>
                                    {moment(lesson.scheduled_on).format('ddd, MMMM Do YYYY')}
                                  </div>
                                  <div className="d-flex align-items-center  text-muted" style={{ fontSize: '15px' }}>
                                    <i className="material-icons mr-2">access_time</i>
                                    {moment(lesson.scheduled_on).format('h:mm a')} to{' '}
                                    {moment(lesson.ended_on).format('h:mm a')}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
        )}

        {!isMobile && (
          <React.Fragment>
            <section id="what-we-cover">
              <div className="container">
                <div className="section__header mb-5">
                  <div className="title mb-2">Our Online Classes</div>
                  <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #8e51f0' }}></div>
                </div>
                <div className="container">
                  <div className="row mb-5">
                    {promoClassList.map((classData, index) => {
                      let style = {
                        tab: {
                          border: '1px solid #dee2e6',
                          borderRadius: '10px',
                          boxShadow:
                            '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
                        },
                        activeTab: {
                          borderBottom: '5px solid #8e51f0',
                          borderTop: '1px solid #8e51f0',
                          borderRight: '1px solid #8e51f0',
                          borderLeft: '1px solid #8e51f0',
                          borderRadius: '10px',
                          boxShadow:
                            '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
                        }
                      }

                      return (
                        <div className="col-3 mb-2" key={index}>
                          <div
                            className="d-flex flex-row h-100 align-items-center clickable p-2"
                            style={activeScheduleIndex === index ? style.activeTab : style.tab}
                            onClick={this.selectClass(index)}>
                            <Avatar
                              className="size-60 avatar-shadow mx-auto my-2"
                              alt={classData.tutor[0].name}
                              src={classData.tutor[0].profile_pic}
                            />
                            <div className="ml-2">
                              <h3 className="mb-0">{classData.name}</h3>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>

                  <div className="row">
                    <div className="col-5">
                      <div
                        className="py-2 px-4"
                        style={{
                          borderRadius: '10px',
                          borderBottom: '10px solid #8e51f0',
                          borderTop: '1px solid #8e51f0',
                          borderRight: '1px solid #8e51f0',
                          borderLeft: '1px solid #8e51f0'
                        }}>
                        <div className="d-flex flex-row align-items-center">
                          <Avatar
                            className="size-60 avatar-shadow m-2"
                            alt={promoClassList[activeScheduleIndex].tutor[0].name}
                            src={promoClassList[activeScheduleIndex].tutor[0].profile_pic}
                          />
                          <div className="ml-2">
                            <h3 className="font-weight-semibold mb-1">{promoClassList[activeScheduleIndex].name}</h3>
                            <div className="d-flex flex-row">
                              <small className="mr-2">by</small>
                              {promoClassList[activeScheduleIndex].tutor[0].name}
                            </div>
                          </div>
                        </div>
                        <div className="text-right">
                          <div className="class__price">
                            <h1 className="text-primary mb-0">
                              $&nbsp;
                              {promoClassList[activeScheduleIndex].class_type === CLASS_TYPE_REGULAR_CLASSES
                                ? roundPrice(processedData2.price)
                                : promoClassList[activeScheduleIndex].rate}
                            </h1>
                          </div>
                          <small>per lesson</small>
                        </div>
                        <Divider />
                        <div className="mt-2">
                          <small className="text-muted">
                            About {promoClassList[activeScheduleIndex].tutor[0].name}
                          </small>
                          <p
                            className="text-justify"
                            dangerouslySetInnerHTML={{ __html: promoClassList[activeScheduleIndex].tutor[0].desc }}
                          />
                        </div>
                        <div className="text-center mt-3">
                          {addedToCart.includes(promoClassList[activeScheduleIndex]._id) ? (
                            <Button fullWidth variant="outlined" color="primary" onClick={this.goToCart}>
                              Checkout
                            </Button>
                          ) : (
                            <React.Fragment>
                              {promoClassList[activeScheduleIndex].is_sold_out ? (
                                <Button
                                  fullWidth
                                  variant="contained"
                                  size="large"
                                  className="mb-2 bg-danger text-white"
                                  disabled>
                                  Class Full
                                </Button>
                              ) : (
                                <React.Fragment>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={this.buyOnlineLessons(activeScheduleIndex)}>
                                    {promoClassList[activeScheduleIndex].class_type === CLASS_TYPE_WORKSHOP ? (
                                      <span>
                                        Buy Workshop ({promoClassList[activeScheduleIndex].lesson.length} lessons)
                                      </span>
                                    ) : (
                                      <span>
                                        Buy All Term {lessonsIncluded.length > 1 && lessonsIncluded[0].meta.term}{' '}
                                        Lessons
                                      </span>
                                    )}
                                  </Button>
                                  {promoClassList[activeScheduleIndex].is_trial && (
                                    <Button
                                      variant="text"
                                      color="primary"
                                      onClick={this.joinTrial(activeScheduleIndex)}>
                                      Join a Trial Lesson
                                    </Button>
                                  )}
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-7">
                      <h3>About Class:</h3>
                      <div className="text-justify" onClick={this.toggleExpandFullClassDesc}>
                        {expandFullClassDesc ? (
                          <React.Fragment>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: promoClassList[activeScheduleIndex].desc
                              }}
                            />
                            <p className="text-right">
                              <span className="link">Read less...</span>
                            </p>
                          </React.Fragment>
                        ) : promoClassList[activeScheduleIndex].desc.length > 400 ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: `${promoClassList[activeScheduleIndex].desc.substring(
                                0,
                                400
                              )} <span class="link"> read more...</span>`
                            }}
                          />
                        ) : (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: promoClassList[activeScheduleIndex].desc
                            }}
                          />
                        )}
                      </div>
                      <div className="text-right">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={this.toggleVideoDialog(promoClassList[activeScheduleIndex]._id)}>
                          <VideoIcon color="primary" className="mr-2" /> Class Preview
                        </Button>
                      </div>
                      <h3>Lessons Schedule:</h3>
                      {lessonsIncluded &&
                        lessonsIncluded.map((lesson, index) => (
                          <div
                            key={index}
                            className="mb-2"
                            style={{ backgroundColor: '#f9f9f9', border: '1px solid #dedfe0', padding: '10px 15px' }}>
                            <div className="mb-2">
                              <i className="fa fa-check mr-2" />
                              <span style={{ fontSize: '20px' }}>{lesson.name}</span>
                            </div>
                            <div className="d-flex align-items-center  px-3 mb-1 text-muted">
                              <i className="material-icons mx-2">event</i>
                              {moment(lesson.scheduled_on).format('dddd, MMMM Do YYYY')}
                            </div>
                            <div className="d-flex align-items-center  px-3 text-muted">
                              <i className="material-icons mx-2">access_time</i>
                              {moment(lesson.scheduled_on).format('h:mm a')} to{' '}
                              {moment(lesson.ended_on).format('h:mm a')}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {promoClassList.length < 5 && (
              <section className="py-4" style={{ backgroundColor: '#e3e7ee' }}>
                <div className="container">
                  <div className="text-center">
                    <h3>Choose other class</h3>
                  </div>

                  <div className="card-deck">
                    {promoClassList.map((classData, index) => {
                      if (activeScheduleIndex === index) {
                        return null
                      }

                      return (
                        <div
                          className="card border clickable"
                          style={{ borderRadius: '10px' }}
                          key={index}
                          onClick={() => {
                            this.selectClass(index)()
                            this.scrollTo('what-we-cover')()
                          }}>
                          <div className="d-flex flex-row align-items-center p-2">
                            <Avatar
                              className="size-60 avatar-shadow m-2"
                              alt={classData.tutor[0].name}
                              src={classData.tutor[0].profile_pic}
                            />
                            <div className="ml-2">
                              <h3 className="font-weight-semibold mb-1">{classData.name}</h3>
                              <div className="d-flex flex-row">
                                <small className="mr-2">by</small>
                                {classData.tutor[0].name}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </section>
            )}
          </React.Fragment>
        )}

        <ClassExperience />
        <hr />
        <SuperiorMethodology />

        <section>
          <div className="container">
            <div className="section__header mb-5">
              <div
                className="title mb-2"
                style={{ fontSize: '2.3rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif" }}>
                What they said
              </div>
              <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #8089ff' }}></div>
            </div>
            <Testimonials />
          </div>
        </section>

        <section className="bg__dotted">
          <div className="container">
            <div className="section__header mb-5">
              <div className="title mb-2" style={{ fontSize: '2.3rem', fontWeight: '500' }}>
                FAQs
              </div>
              <div className="mx-auto" style={{ maxWidth: '60px', borderBottom: '3px solid #8e51f0' }}></div>
            </div>

            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">What is Tenopy?</span>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                Tenopy is a live online tutoring platform for students to learn from top tutors, through live
                interactive classes, from the comforts of home. Students can also watch the video playback of their
                lessons. It’s flexible, hassle-free and convenient, as students can learn from the comfort of their
                homes, anytime.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">How do you interact with the tutor during lesson?</span>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                During a live online class, students can see the tutor teaching, participate in quizzes and ask
                questions in real time via chat box. During a live online class, students can see the tutor teaching,
                participate in quizzes and ask questions in real time via chat box. The tutor will also enable the
                students’ microphone to allow them to speak during class when the student clicks “raise hand”.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">Do you have your own curriculum?</span>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                Yes, our curriculum is developed based on Singapore’s syllabus. We are also partnering with several
                established education providers to bring their content and expertise to our platform. Find out about our
                class schedules and details <Link to={ALL_CLASSES}>here</Link>.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">What classes do you currently offer?</span>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                Tenopy currently offers English, Mathematics and Science subjects for Primary 3 to Primary 6. Keep a
                lookout for our future announcement for more classes.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">Do you give lesson notes and homework?</span>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                Yes, lesson notes and homework will be uploaded by our tutors at least 2 days prior to the scheduled
                lesson. Students can also submit Tenopy homework to get tutors’ feedback on our platform.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">How do I keep track of my child’s learning progress?</span>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                <div>
                  <p>
                    We will be giving a weekly Parent Report. This report is meant to provide you with information
                    regarding your child in several ways:
                  </p>
                  <ul>
                    <li>
                      Attendance: The report reflects whether your child has been attending lessons as expected for the
                      full duration of the lesson. You may ignore this if your child is watching the replay of lessons
                      due to his/her schedule.
                    </li>
                    <li>
                      Attention: The report reflects whether your child has been active and responsive during lessons by
                      answering the quiz questions.
                    </li>
                    <li>
                      Understanding: The report reflects areas of understanding that your child may need more guidance
                      or revision in. Please note, however, that some quiz questions are deliberately designed to be
                      especially tricky for students.
                    </li>
                  </ul>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">What is a free trial?</span>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                Join a free trial lesson to see what online tutoring is like. This is a great way for you to check out a
                class or a tutor you’re keen on. You may join up to one free trial lesson per class, unless otherwise
                specified. Sign up for a free trial <Link to={TRIAL}>here</Link>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span className="font-weight-semibold">What do I need to attend Tenopy lesson?</span>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                <div>
                  <p>All you need to join Tenopy lessons is your personal computer and earphones with a microphone.</p>
                  <p>
                    You can also join lessons with your mobile phone, but you will be prompted to download and run a
                    companion app. Please follow the on-screen instructions to install this app. However, we suggest
                    having lessons on computer as students may absorb knowledge better with a bigger screen.
                  </p>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </section>

        <section className="bg-light-gray">
          <div className="container">
            <div className="row align-items-center">
              <div className="col">
                <h1 className="text-primary font-weight-normal mb-0">Ready to start tutoring with Tenopy?</h1>
                <span style={{ fontSize: '18px' }}>Choose your lesson or get in touch.</span>
              </div>
              <div className="col-auto my-3 my-md-0">
                <Button
                  variant="contained"
                  color="primary"
                  className="mr-2"
                  onClick={this.scrollToClassSelection('footer')}>
                  Sign up
                </Button>
                <Button variant="outlined" color="primary" onClick={this.toggleGetInTouchDialog}>
                  Get in Touch
                </Button>
              </div>
            </div>
          </div>
        </section>

        <GetInTouchDialog
          isOpen={isGetInTouchDialogOpen}
          close={this.toggleGetInTouchDialog}
          intent="Conversion Page"
        />

        <VideoPlayerDialog isOpen={isVideoDialogOpen} close={this.toggleVideoDialog()} videoURL={classPreviewURL} />

        <JoinTrialDialog
          isOpen={isJoinTrialDialogOpen}
          toggleDialog={this.toggleJoinTrialDialog}
          classData={selectedClass}
        />

        <DialogProceedToCheckout
          isOpen={isProceedToCheckoutDialogOpen}
          toggleDialog={this.toggleProceedToCheckoutDialog}
          proceedToCheckout={this.goToCart}
          classTitle={promoClassList[activeScheduleIndex] ? PREVIEW_VIDEO[promoClassList[activeScheduleIndex]._id] : ''}
          lessonsIncluded={lessonsIncluded}
        />
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PSLECrashCourse))
