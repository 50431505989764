import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { Button, CircularProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'

import { SUBMISSION_LIST } from 'routes/tutorRoutes'
import HomeworkCreateUpdateDialog from 'components/tutor/Homework/DialogCreateUpdateHomework'
import HomeworkDeleteDialog from 'components/tutor/Homework/DialogDeleteHomework'
import HomeworkSubmissionStat from 'components/tutor/Homework/HomeworkSubmissionStat'

const mapStateToProps = state => ({
  loadingHomeworkList: state.homework.loadingHomeworkList,
  homeworkList: state.homework.homeworkList,
  creatingHomework: state.homework.creatingHomework,
  deletingHomework: state.homework.deletingHomework
})

class Homework extends React.Component {
  state = {
    isCreateUpdateHomeworkDialogOpen: false,
    isDeleteHomeworkDialogOpen: false,
    selectedHomework: null
  }

  goToSubmissionPage = (classId, homeworkId) => () => {
    let redirectURL = SUBMISSION_LIST.replace('classId', classId).replace(':homeworkId', homeworkId)
    this.props.history.push(redirectURL)
  }

  toggleCreateUpdateHomeworkDialog = selectedHomework => () => {
    this.setState({
      isCreateUpdateHomeworkDialogOpen: !_.isEmpty(selectedHomework),
      selectedHomework
    })
  }

  toggleDeleteHomeworkDialog = selectedHomework => () => {
    this.setState({
      isDeleteHomeworkDialogOpen: !_.isEmpty(selectedHomework),
      selectedHomework
    })
  }

  render() {
    let { loadingHomeworkList, homeworkList } = this.props
    let { isCreateUpdateHomeworkDialogOpen, isDeleteHomeworkDialogOpen, selectedHomework } = this.state

    if (loadingHomeworkList) {
      return (
        <div className="manage-margin text-center my-4">
          <CircularProgress size={40} />
        </div>
      )
    }

    return (
      <Paper className="my-3">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Submission</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {homeworkList.data.map((homework, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <span className="text-small text-muted">
                    {homework.lesson.meta.term ? `Term ${homework.lesson.meta.term}` : ''}{' '}
                    {homework.lesson.meta.index ? `Lesson ${homework.lesson.meta.index}` : ''}
                  </span>
                  <br />
                  <span className="text-large font-weight-semibold">{homework.name}</span>
                  <br />
                  <span className="text-muted">Lesson:</span> {homework.lesson.name}
                  <br />
                  <span className="text-muted">File:</span>{' '}
                  <a href={homework.attachment[0].url} download>
                    Download
                  </a>
                </TableCell>
                <TableCell>{moment(homework.due_on).format('lll')}</TableCell>
                <TableCell>
                  <HomeworkSubmissionStat homeworkId={homework._id} totalAssign={homework.student.length} />
                </TableCell>
                <TableCell>
                  <div className="text-center">
                    <Button
                      variant="text"
                      size="small"
                      color="primary"
                      onClick={this.goToSubmissionPage(homework.lesson.class._id, homework._id)}
                      disableFocusRipple>
                      View
                    </Button>
                    <Button
                      variant="text"
                      size="small"
                      color="primary"
                      onClick={this.toggleCreateUpdateHomeworkDialog(homework)}
                      disableFocusRipple>
                      Edit
                    </Button>
                    <Button
                      variant="text"
                      size="small"
                      className="text-danger"
                      onClick={this.toggleDeleteHomeworkDialog(homework)}
                      disableFocusRipple>
                      Delete
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <HomeworkCreateUpdateDialog
          isOpen={isCreateUpdateHomeworkDialogOpen}
          toggleDialog={this.toggleCreateUpdateHomeworkDialog}
          homeworkData={selectedHomework}
        />

        <HomeworkDeleteDialog
          isOpen={isDeleteHomeworkDialogOpen}
          toggleDialog={this.toggleDeleteHomeworkDialog}
          homeworkData={selectedHomework}
        />
      </Paper>
    )
  }
}

export default withRouter(connect(mapStateToProps, {})(Homework))
