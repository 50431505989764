import React from 'react'
import { isMobileOnly, isTablet } from 'react-device-detect'
import { Carousel } from 'react-responsive-carousel'
import { Avatar, Button } from '@material-ui/core'
import _ from 'lodash'
import VideoPlayerDialog from 'components/dialog/video-player'

const reviews = [
  {
    fullText:
      '<p>Tenopy has helped me in various ways from composition writing to cloze passages. The classes are very interactive even though the lessons are conducted online. The teachers are fun and interesting and introduce the information in such a way that is easy to understand. <span class="text-primary clickable">read less</span></p>',
    shortText:
      '<p>Tenopy has helped me in various ways from composition writing to cloze passages. The <span class="text-primary clickable">read more</span></p>',
    video: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/testi/wen-tao.mp4',
    profile: {
      image: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/testi/wen-tao.jpg',
      name: 'Wen Tao',
      position: 'Primary 5 Student'
    }
  },
  {
    fullText:
      '<p>Tenopy classes are intuitive and fun. Often making the usually boring subjects enjoyable. Tenopy is unique in the sense that it caters to many of our needs despite being online. Tenopy has been doing the seemingly unfeasible for years, assisting me as I learn numerous things. <span class="text-primary clickable">read less</span></p>',
    shortText:
      '<p>Tenopy classes are intuitive and fun. Often making the usually boring subjects <span class="text-primary clickable">read more</span></p>',
    video: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/testi/wen-xuan.mov',
    profile: {
      image: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/testi/wen-xuan.jpg',
      name: 'Wen Xuan',
      position: 'Primary 6 Student'
    }
  },
  {
    fullText:
      '<p>Charlotte started attending Tenopy’s online classes on English Paper 1 since January 2020 and she enjoys it till today.</p><p>There are always giggles and laughter during lessons! The notes and marked homework are loaded on time weekly, and the recorded lessons are useful for students to revise at any time. The notes are very well planned and Teacher is very engaging.</p><p>The administration support from Tenopy is also efficient and my enquiries are often addressed within a short time, I highly recommend Tenopy to parents looking for online tuition! <span class="text-primary clickable">read less</span></p>',
    shortText:
      '<p>Charlotte started attending Tenopy’s online classes on English Paper 1 since January <span class="text-primary clickable">read more</span></p>',
    video: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/testi/charlotte.mp4',
    profile: {
      image: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/testi/charlotte.jpg',
      name: 'Jannie',
      position: 'Mother of Charlotte, Primary 6'
    }
  },
  {
    fullText:
      '<p>Richelle really likes Teacher Esther\'s lessons as she explains clearly during lessons, and she is also humorous. Richelle has been asking me to sign her up the P6 English ever her last P5 lesson in Nov 2019 with Teacher Esther.<span class="text-primary clickable">read less</span></p>',
    shortText:
      '<p>Richelle really likes Teacher Esther\'s lessons as she explains clearly during lessons, <span class="text-primary clickable">read more</span></p>',
    video: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/testi-richelle.mp4',
    profile: {
      image: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/testi-richelle.jpg',
      name: 'Shirley',
      position: 'Mother of Richelle, Primary 5'
    }
  },
  {
    fullText:
      '<p>I love Tenopy online classes because it has helped me to improve my English. The strategies taught are useful for answering comprehension questions and writing compositions.</p><p>I get to participate in the lessons by using the microphone and the teacher will give immediate feedback on our answers.</p><p>I enjoy Tenopy online classes at the comfort of my home! <span class="text-primary clickable">read less</span></p>',
    shortText:
      '<p>I love Tenopy Online classes because it has helped me to improve my English. <span class="text-primary clickable">read more</span></p>',
    video: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/testi/melissa-sim.mp4',
    profile: {
      image: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/testi/melissa-sim.jpg',
      name: 'Melissa Sim',
      position: 'Primary 4 Student'
    }
  },
  {
    fullText:
      '<p>In Tenopy, I took English and Mathematics classes. I enjoy learning because the lessons are fun, interesting, and easy to learn. Tenopy has helped my studies by making good improvements. You should sign up for Tenopy now! <span class="text-primary clickable">read less</span></p>',
    shortText:
      '<p>In Tenopy, I took English and Mathematics classes. I enjoy learning because <span class="text-primary clickable">read more</span></p>',
    video: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/testi/yu-yin.mp4',
    profile: {
      image: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/testi/yu-yin.jpg',
      name: 'Pang Yu Yin',
      position: 'Primary 3 Student'
    }
  },
  {
    fullText:
      '<p>Teacher Tatum’s  lessons are fun and helpful. Teacher Tatum’s jokes and videos are what make lessons engaging. I strongly encourage you to join her class!</p>',
    profile: {
      image: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/testi/chen-yu-dian.jpg',
      name: 'Chen Yudian',
      position: 'Primary 6 Student'
    }
  },
  {
    fullText:
      '<p>The lessons prepared by the teachers were very fun and creative. I am always very excited to attend lessons every week because I learn so much.</p>',
    profile: {
      image: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/testi/lim-yi-jun.jpg',
      name: 'Lim Yi Jun',
      position: 'Primary 6 Student'
    }
  },
  {
    fullText:
      '<p>The lessons conducted by the tutor is excellent. It was easy to understand things I thought were difficult. Teacher Esther was also very engaging and fun!</p>',
    profile: {
      image: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/testi/zhang-lun-hao.jpg',
      name: 'Zhang Lunhao',
      position: 'Primary 6 Student'
    }
  },
  {
    fullText:
      '<p>I improved a lot as the techniques that the teachers taught us are very easy to understand and apply in exams. The teachers are also very engaging and fun.</p>',
    profile: {
      image: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/testi/chen-bo-yan.jpg',
      name: 'Chen Boyan',
      position: 'Primary 6 Student'
    }
  },
  {
    fullText:
      '<p>I would recommend students to join Tenopy classes as the lessons are very enriching and educational. The tutors at Tenopy are nice and try to find the best ways to teach us so that it is easier for us to understand. <span class="text-primary clickable">read less</span></p>',
    shortText:
      '<p>I would recommend students to join Tenopy classes as the lessons are very enriching and educational. The tutors at Tenopy are nice and try to find the best ways <span class="text-primary clickable">read more</span></p>',
    profile: {
      image: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/testi/win-win-kyaw.jpg',
      name: 'Win Win Kyaw',
      position: 'Primary 6 Student'
    }
  },
  {
    fullText:
      '<p>We are currently residing overseas due to work commitments. Our kids are studying in an international school under the IB system. By chance, we found Tenopy on search engine and our initial intention is to follow the Singapore syllabus closely. It surprised us that our kids feedback that the analysis skills taught by Teacher Esther and Teacher Tatum are very useful in the IB curriculum too. Both teachers\' lessons are well planned and engaging. The videos shown during lessons are brain teasers that enable them to think further into the topic. We recommended Tenopy to their friends in the international school as the Tenopy curriculum is not constrained by the Singapore syllabus, it focuses on a holistic approach in learning. The international school has no examinations in primary school. Though we can\'t see their improvements through marks, it has been a fruitful year with Tenopy and we are sure there are more to come as they continue the journey with Tenopy. We hope Tenopy will continue growing and will soon include secondary and JC lessons. <span class="text-primary clickable">read less</span></p>',
    shortText:
      '<p>We are currently residing overseas due to work commitments. Our kids are studying in an international school under the IB system. <span class="text-primary clickable">read more</span></p>',
    profile: {
      image: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/testi-camellia.jpg',
      name: 'Camellia',
      position: 'Mother of Zhong Wen Xuan / Zhong Wen Tao, Primary 4'
    }
  },
  {
    fullText:
      '<p>Teacher Tatum is fun and detailed. The class is also very engaging. It\'s convenient for me as well, as I can watch the lesson videos to make sure I don\'t miss out on anything. I learn a lot more through the lessons than in school.<span class="text-primary clickable">read less</span></p>',
    shortText:
      '<p>Teacher Tatum is fun and detailed. The class is also very engaging. It\'s convenient for me as well, as I can watch <span class="text-primary clickable">read more</span></p>',
    video: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/testi-jann.mp4',
    profile: {
      image: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/testi-jann.jpg',
      name: 'Jann',
      position: 'Primary 6 Student'
    }
  },
  {
    fullText:
      '<p>Through Tenopy, my son has gained confidence and skills that he is able to apply to Mathematics problems. I can see vast improvements in his abilities and scores. <span class="text-primary clickable">read less</span></p>',
    shortText:
      '<p>Through Tenopy, my son has gained confidence and skills that he is able to apply to Mathematics problems. <span class="text-primary clickable">read more</span></p>',
    video: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/testi-kevin.mp4',
    profile: {
      image: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/testi-kevin.jpg',
      name: 'Kelvin',
      position: 'Father of Max, Primary 4'
    }
  },
  {
    fullText:
      '<p>Tenopy has exceeded my expectation. My daughter, Victoria, started her 1st P3 English class with Tenopy in Feb 2019. She enjoyed the class and requested to continue for this whole year and even for next year! Teacher Tatum is a fun and engaging teacher who is never stingy in giving encouragements and praises to her students.</p><p>I am glad to have found Tenopy. Not only do they have high quality teachers, my daughter is able to have the classes conducted in the comfort of her home. I would definitely recommend Tenopy to my friends and everyone. <span class="text-primary clickable">read less</span></p>',
    shortText:
      '<p>Tenopy has exceeded my expectation. My daughter, Victoria, started her 1st P3 English class with Tenopy in Feb 2019. She enjoyed the class and requested <span class="text-primary clickable">read more</span></p>',
    proof: '',
    profile: {
      image: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/testi-placeholder-mom.jpg',
      name: 'Angela',
      position: 'Mother of Victoria, Primary 3'
    }
  },
  {
    fullText:
      '<p>My child found the lessons useful as the tutors gave very clear explanations and relevant examples. The tutors focused on Comprehension Cloze and Comprehension which my child was weak in at first. Attending Tenopy lessons helped my child to improve in these areas and achieve an excellent grade in English. <span class="text-primary clickable">read less</span></p>',
    shortText:
      '<p>My child found the lessons useful as the tutors gave very clear explanations and relevant examples. The tutors focused on Comprehension Cloze and <span class="text-primary clickable">read more</span></p>',
    profile: {
      image: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/testi/charlene-tan.png',
      name: 'Charlene Tan',
      position: 'Primary 6 Student'
    }
  }
]

class Testimonials extends React.Component {
  state = {
    expandFullTextIndex: [],
    selectedVideoURL: '',
    isVideoDialogOpen: false
  }

  scrollNext = () => {
    let elmnt = document.getElementById('testimonial-list-slider')
    if (elmnt) {
      elmnt.scrollBy(300, 0)
    }
  }

  scrollBack = () => {
    let elmnt = document.getElementById('testimonial-list-slider')
    if (elmnt) {
      elmnt.scrollBy(-300, 0)
    }
  }

  expandFullText = index => () => {
    let { expandFullTextIndex } = this.state
    if (expandFullTextIndex.includes(index)) {
      _.remove(expandFullTextIndex, function (n) {
        return n === index
      })
    } else {
      expandFullTextIndex.push(index)
    }

    this.setState({ expandFullTextIndex })
  }

  toggleVideoDialog = video => () => {
    this.setState({
      isVideoDialogOpen: !this.state.isVideoDialogOpen,
      selectedVideoURL: video
    })
  }

  render() {
    let { expandFullTextIndex, selectedVideoURL, isVideoDialogOpen } = this.state

    if (isMobileOnly) {
      return (
        <React.Fragment>
          <Carousel
            showThumbs={false}
            showIndicators={false}
            showArrows={false}
            showStatus={false}
            centerMode={true}
            centerSlidePercentage={90}>
            {reviews.map((review, index) => (
              <div className={`bg-white ${index === 0 ? 'pl-3' : 'pl-2'}`} style={{ position: 'relative' }} key={index}>
                <div style={{ position: 'absolute', left: 'calc(50% - 60px)', top: '-60px' }}>
                  <Avatar src={review.profile.image} className="size-120 avatar-shadow" style={{ zIndex: 1 }} />
                </div>
                <div
                  className="mr-3"
                  style={{ border: '2px solid #dee2e6', borderRadius: '15px', marginTop: '65px', paddingTop: '65px' }}>
                  {review.shortText && !expandFullTextIndex.includes(index) ? (
                    <div
                      onClick={this.expandFullText(index)}
                      className="text-center"
                      style={{
                        fontFamily: "'Nunito Sans', sans-serif",
                        fontSize: '18px',
                        color: '#333',
                        padding: '0 10px'
                      }}
                      dangerouslySetInnerHTML={{ __html: review.shortText }}
                    />
                  ) : (
                    <div
                      onClick={this.expandFullText(review.shortText ? index : '')}
                      className="text-center"
                      style={{
                        fontFamily: "'Nunito Sans', sans-serif",
                        fontSize: '18px',
                        color: '#333',
                        padding: '0 10px'
                      }}
                      dangerouslySetInnerHTML={{ __html: review.fullText }}
                    />
                  )}
                  {review.video && (
                    <div className="text-center mb-3">
                      <Button
                        variant="outlined"
                        color="primary"
                        className="btn-white-bg"
                        style={{ paddingLeft: '7px', paddingRight: '16px' }}
                        onClick={this.toggleVideoDialog(review.video)}>
                        <i className="material-icons text-primary mr-2">play_arrow</i>
                        Watch
                      </Button>
                    </div>
                  )}
                  <div
                    style={{
                      padding: '10px 15px',
                      borderBottomLeftRadius: '15px',
                      borderBottomRightRadius: '15px',
                      backgroundColor: '#54657b'
                    }}>
                    <p className="h3 mb-1 text-white" style={{ fontFamily: "'Rubik', sans-serif" }}>
                      {review.profile.name}
                    </p>
                    <span className="text-white" style={{ fontFamily: "'Nunito Sans', sans-serif" }}>
                      {review.profile.position}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>

          <VideoPlayerDialog isOpen={isVideoDialogOpen} close={this.toggleVideoDialog()} videoURL={selectedVideoURL} />
        </React.Fragment>
      )
    }

    return (
      <div className="position-relative">
        <div className="carousel-container">
          <div className="carousel-slides" id="testimonial-list-slider">
            {reviews.map((review, index) => (
              <div className="col-lg-4 col-6" style={{ position: 'relative' }} key={index}>
                <div style={{ position: 'absolute', left: 'calc(50% - 60px)' }}>
                  <Avatar src={review.profile.image} className="size-120 avatar-shadow" style={{ zIndex: 1 }} />
                </div>
                <div
                  className="mx-3 bg-white"
                  style={{ border: '2px solid #dee2e6', borderRadius: '15px', marginTop: '65px', paddingTop: '65px' }}>
                  {review.shortText && !expandFullTextIndex.includes(index) ? (
                    <div
                      onClick={this.expandFullText(index)}
                      className="text-center"
                      style={{
                        fontFamily: "'Nunito Sans', sans-serif",
                        fontSize: '18px',
                        color: '#333',
                        padding: '0 10px'
                      }}
                      dangerouslySetInnerHTML={{ __html: review.shortText }}
                    />
                  ) : (
                    <div
                      onClick={this.expandFullText(review.shortText ? index : '')}
                      className="text-center"
                      style={{
                        fontFamily: "'Nunito Sans', sans-serif",
                        fontSize: '18px',
                        color: '#333',
                        padding: '0 10px'
                      }}
                      dangerouslySetInnerHTML={{ __html: review.fullText }}
                    />
                  )}
                  {review.video && (
                    <div className="text-center mb-3">
                      <Button
                        variant="outlined"
                        color="primary"
                        className="btn-white-bg"
                        style={{ paddingLeft: '7px', paddingRight: '16px' }}
                        onClick={this.toggleVideoDialog(review.video)}>
                        <i className="material-icons text-primary mr-2">play_arrow</i>
                        Watch
                      </Button>
                    </div>
                  )}
                  <div
                    style={{
                      padding: '10px 15px',
                      borderBottomLeftRadius: '15px',
                      borderBottomRightRadius: '15px',
                      backgroundColor: '#54657b'
                    }}>
                    <p className="h3 mb-1 text-white" style={{ fontFamily: "'Rubik', sans-serif" }}>
                      {review.profile.name}
                    </p>
                    <span className="text-white" style={{ fontFamily: "'Nunito Sans', sans-serif" }}>
                      {review.profile.position}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={`carousel-nav ${isTablet ? 'next--tablet' : 'next'}`}>
          <img
            className="carousel-nav-icon"
            src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/carousel-next.png"
            alt="next"
            onClick={this.scrollNext}
          />
        </div>
        <div className={`carousel-nav ${isTablet ? 'prev--tablet' : 'prev'}`}>
          <img
            className="carousel-nav-icon"
            src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/carousel-back.png"
            alt="prev"
            onClick={this.scrollBack}
          />
        </div>

        <VideoPlayerDialog isOpen={isVideoDialogOpen} close={this.toggleVideoDialog()} videoURL={selectedVideoURL} />
      </div>
    )
  }
}

export default Testimonials
