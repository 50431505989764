import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { IconButton, Menu, MenuItem, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core'
import { MoreVert as MoreVertIcon, Videocam as VideoCamIcon, Person as PersonIcon } from '@material-ui/icons'
import moment from 'moment'
import Pluralize from 'react-pluralize'
import Proptypes from 'prop-types'
import { LESSON_PAGE } from 'routes/tutorRoutes'
import JoinLessonButton from 'components/online-class/button-join-lesson'

const mapStateToProps = state => ({
  upcoming: state.lessons.upcoming
})

class LessonCard extends React.Component {
  state = {
    menuOpen: false,
    menuAnchor: undefined
  }

  goToPage = location => () => {
    this.props.history.push(location)
  }

  render() {
    const { lesson, upcoming } = this.props
    const { menuOpen, menuAnchor } = this.state
    const current = moment()
    const lessonEndTime = moment(lesson.ended_on)
    const lessonOver = current.diff(lessonEndTime, 'mins') > 0
    const recordingAvailable = lesson.playback_url && lesson.playback_url.length > 0 ? true : false
    const isNextLesson = upcoming && upcoming.length > 0 && lesson._id === upcoming[0]._id ? true : false

    return (
      <ListItem
        className="py-3"
        divider={true}
        button={true}
        onClick={this.goToPage(
          LESSON_PAGE.replace(':classId', lesson.class._id).replace(':lessonId', lesson._id) + '?tf=resources'
        )}>
        <ListItemText disableTypography={true}>
          <div className="mb-1">
            <span className="d-flex align-items-center">
              <div>
                <h3 className="font-weight-medium mb-0">
                  {lesson.meta && <span>Lesson {lesson.meta.index}: </span>}
                  {lesson.name}
                </h3>
              </div>
              {lessonOver && <span className="tag danger-tag--outline align-middle ml-2">Ended</span>}
              {isNextLesson && <span className="tag success-tag align-middle ml-2">Next</span>}
            </span>
          </div>
          <div className="mb-0 text-faded">
            {moment(lesson.scheduled_on).format('ddd D MMMM, h:mma')} - {moment(lesson.ended_on).format('h:mma')}
          </div>
          {lessonOver && (
            <div className={`d-flex flex-row align-items-center mt-2`}>
              <VideoCamIcon
                className={`${recordingAvailable ? 'text-blue' : 'text-faded'} mr-1`}
                style={{ marginBottom: '1px' }}
              />
              {recordingAvailable ? 'Recording available' : 'Recording coming soon'}
            </div>
          )}
          <div className="d-flex align-items-center mt-2">
            <PersonIcon className="text-success mr-1" />
            <span className="mr-3">
              <Pluralize singular="paid student" count={lesson.student.length} />
            </span>
            <PersonIcon className="text-cyan mr-1" />
            <span>
              <Pluralize singular="trial student" count={lesson.trial_student.length} />
            </span>
          </div>
        </ListItemText>
        <ListItemSecondaryAction className="mr-md-3 mr-0">
          <div className="d-flex align-items-center">
            {isNextLesson && <JoinLessonButton lessonData={lesson} bypassCountdown={true} />}
            <div>
              <IconButton aria-label="Menu" onClick={this.toggleMenu}>
                <MoreVertIcon color="primary" />
              </IconButton>
              <Menu open={menuOpen} anchorEl={menuAnchor} onClose={this.toggleMenu}>
                <MenuItem
                  onClick={this.goToPage(
                    LESSON_PAGE.replace(':classId', lesson.class._id).replace(':lessonId', lesson._id) + '?tf=resources'
                  )}>
                  Resources
                </MenuItem>
                <MenuItem
                  onClick={this.goToPage(
                    LESSON_PAGE.replace(':classId', lesson.class._id).replace(':lessonId', lesson._id) + '?tf=homework'
                  )}>
                  Homework
                </MenuItem>
              </Menu>
            </div>
          </div>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }
}

LessonCard.propTypes = {
  lesson: Proptypes.object.isRequired
}

export default withRouter(connect(mapStateToProps, {})(LessonCard))
