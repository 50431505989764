import React from 'react'
import { isMobile } from 'react-device-detect'
import { Avatar, Button, CircularProgress, Dialog, DialogContent, Slide } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Proptypes from 'prop-types'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const STATIC_PROFILE_DATA = {
  cedric: {
    name: 'Cedric Chai',
    profile_img: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/about/tnp-science-cedric.jpg',
    desc: 'Cedric Chai holds a Postgraduate in Education (Merit–NTU/NIE) and a Bachelor of Engineering (Civil and Structural - Nanyang Technological University). As a Head of Department for Science, Cedric was nominated for the President’s Award for his outstanding performance and contributions to students’ learning. He has numerous years of experience as the Head of Department for Science, A. Supervisor of PSLE Marking, PSLE Chief Invigilator and Presiding Examiner. Cedric’s vast achievements and experience have earned him exclusive feature in parenting and education-related magazines, as well as invitations to speak at teaching and learning conferences.'
  },
  alda: {
    name: 'Alda Lim',
    profile_img: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/about/tnp-science-alda.jpg',
    desc: 'Alda Lim holds a Master of Arts (Educational Management, NTU/NIE), a Postgraduate in Education (NTU/NIE), a Diploma in Departmental Management (NIE) and a Bachelor of Business (Honours - Nanyang Technological University). In 2009, she was awarded the Pingat Berkebolehan (PB) in recognition of her work in the education system. She has numerous years of experience as the Head of Department for Science, Former Vice-Principal Designate, A. Supervisor PSLE Marking, and PSLE Chief Presiding Examiner. Alda’s vast achievement and experience have earned her invitations to attend education and learning related local/overseas conferences.'
  }
}

class StaticProfileDialog extends React.Component {
  state = {
    tutorData: '',
    profileImageHeight: 300
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({
        tutorData: STATIC_PROFILE_DATA[this.props.slug]
      })
    }

    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState({ tutorData: '' })
    }

    if (prevState.tutorData !== this.state.tutorData) {
      let element = document.getElementById('profile-desc')
      if (element) {
        this.setState({ profileImageHeight: element.offsetHeight })
      }
    }
  }

  getElementHeight = () => {
    let element = document.getElementById('profile-desc')
    return element.offsetHeight
  }

  render() {
    const { isOpen } = this.props
    const { tutorData, profileImageHeight } = this.state

    return (
      <Dialog open={isOpen} onClose={this.props.close} TransitionComponent={Transition} maxWidth="md">
        <DialogContent className="p-0">
          {tutorData ? (
            <div className="row no-gutters align-items-center">
              {isMobile ? (
                <div className="col-12 pt-3">
                  <Avatar src={tutorData.profile_img} alt={tutorData.name} className="size-100 avatar-shadow mx-auto" />
                  <Close
                    className="position-absolute clickable m-2"
                    onClick={this.props.close}
                    style={{ right: 0, top: 0 }}
                  />
                </div>
              ) : (
                <div
                  className="col-4 align-self-end"
                  style={{
                    backgroundImage: `url(${tutorData.profile_img})`,
                    height: `${profileImageHeight}px`,
                    backgroundSize: 'cover'
                  }}
                />
              )}

              <div className="col align-self-end py-3 px-md-4 px-3" id="profile-desc">
                {!isMobile && <h2 className="title--rubik font-weight-normal my-2">{tutorData.name}</h2>}
                <p className="text-justify" dangerouslySetInnerHTML={{ __html: tutorData.desc }} />

                <div className="text-right">
                  <Button variant="outlined" color="primary" onClick={this.props.close}>
                    Close
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="m-5">
              <CircularProgress color="primary" size={35} />
            </div>
          )}
        </DialogContent>
      </Dialog>
    )
  }
}

StaticProfileDialog.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired
}

export default StaticProfileDialog
