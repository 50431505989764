import React from 'react'
import { connect } from 'react-redux'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import Proptypes from 'prop-types'
import agent from 'agent'
import { DELETE_SUBMISSION } from 'constants/actionTypes'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const mapStateToProps = state => ({
  deletingSubmission: state.submission.deletingSubmission,
  deleteStatus: state.submission.deleteStatus
})

const mapDispatchToProps = dispatch => ({
  deleteSubmission: submissionId =>
    dispatch({ type: DELETE_SUBMISSION, payload: agent.Submission.delete(submissionId), submissionId })
})

class DeleteSubmissionDialog extends React.Component {
  state = {
    alertMessage: ''
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deletingSubmission && !this.props.deletingSubmission) {
      let { status } = this.props.deleteStatus

      if (status === 'success') {
        this.props.toggleDialog()()
      } else if (status === 'failed') {
        this.setState({ alertMessage: this.props.deleteStatus.message })
      }
    }

    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({ alertMessage: '' })
    }
  }

  deleteSubmission = () => {
    let { submissionData } = this.props
    this.props.deleteSubmission(submissionData._id)
  }

  render() {
    let { isOpen, submissionData, deletingSubmission } = this.props
    let { alertMessage } = this.state

    if (!submissionData) return null

    return (
      <Dialog
        open={isOpen}
        onClose={this.props.toggleDialog()}
        maxWidth="xs"
        fullWidth
        TransitionComponent={Transition}>
        <DialogTitle>Confirm Delete Submission?</DialogTitle>
        <DialogContent>
          <p className="text-large">
            You are trying to delete submission from{' '}
            <span className="font-weight-semibold">{submissionData.created_by.name}</span> for{' '}
            <span className="font-weight-semibold">{submissionData.homework.name}</span>homework{' '}
          </p>
          {alertMessage && <Alert severity="error">{alertMessage}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="default" onClick={this.props.toggleDialog()} disabled={deletingSubmission}>
            {alertMessage ? 'Close' : 'Cancel'}
          </Button>
          {!alertMessage && (
            <Button
              variant="contained"
              className="bg-danger text-white"
              onClick={this.deleteSubmission}
              disabled={deletingSubmission}>
              Delete {deletingSubmission && <CircularProgress className="text-white ml-2" size={15} />}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }
}

DeleteSubmissionDialog.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  toggleDialog: Proptypes.func.isRequired,
  submissionData: Proptypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSubmissionDialog)
