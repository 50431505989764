import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import MomentUtils from '@date-io/moment'
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Slide,
  TextField
} from '@material-ui/core'
import { Folder as FolderIcon } from '@material-ui/icons'
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers'
import _ from 'lodash'
import moment from 'moment'
import Proptypes from 'prop-types'
import agent from 'agent'
import { CREATE_HOMEWORK, UPDATE_HOMEWORK, GET_HOMEWORK_LESSON_DETAIL } from 'constants/actionTypes'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const initialState = {
  name: '',
  releaseDate: moment(),
  dueDate: moment().add(4, 'days'),
  closeDate: moment().add(11, 'days'),
  returnDate: moment().add(7, 'days'),
  audioOnly: false,
  files: null,
  tagName: '',
  tagData: [],
  allStudents: [],
  selectedStudents: [],
  lessonData: null,
  formError: {}
}

const mapStateToProps = state => ({
  creatingHomework: state.homework.creatingHomework,
  createSuccess: state.homework.createSuccess,
  updatingHomework: state.homework.updatingHomework,
  updateStatus: state.homework.updateStatus,
  loadingLessonDetail: state.homework.loadingLessonDetail,
  homeworkList: state.homework.homeworkList
})

const mapDispatchToProps = dispatch => ({
  createHomework: (homeworkType, data, lessonId) =>
    dispatch({ type: CREATE_HOMEWORK, payload: agent.Homework.create(homeworkType, data), lessonId }),
  updateHomework: (homeworkType, homeworkId, data) =>
    dispatch({ type: UPDATE_HOMEWORK, payload: agent.Homework.update(homeworkType, homeworkId, data), homeworkId }),
  getHomeworkLessonDetail: (lessonId, homeworkId) =>
    dispatch({ type: GET_HOMEWORK_LESSON_DETAIL, payload: agent.Lessons.getById(lessonId), homeworkId, lessonId })
})

class CreateUpdateHomeworkDialog extends React.Component {
  state = initialState

  componentDidUpdate(prevProps) {
    if (prevProps.creatingHomework && !this.props.creatingHomework && this.props.createSuccess) {
      this.props.toggleDialog()()
    }

    if (prevProps.updatingHomework && !this.props.updatingHomework && this.props.updateStatus === 'success') {
      this.props.toggleDialog()()
    }

    if (!prevProps.isOpen && this.props.isOpen) {
      let { homeworkData, lessonData } = this.props

      if (homeworkData) {
        this.setState({
          name: homeworkData.name,
          releaseDate: moment(homeworkData.released_on),
          dueDate: moment(homeworkData.due_on),
          closeDate: homeworkData.close_on
            ? moment(homeworkData.close_on)
            : moment(homeworkData.lesson.ended_on).add(11, 'days').endOf('day'),
          returnDate: homeworkData.return_on
            ? moment(homeworkData.return_on)
            : moment(homeworkData.lesson.ended_on).add(7, 'days').endOf('day'),
          audioOnly: homeworkData.audio_only,
          tagData: homeworkData.tag || [],
          selectedStudents: homeworkData.student
        })
        this.props.getHomeworkLessonDetail(homeworkData.lesson._id, homeworkData._id)
      } else {
        let selectedStudents = _.map(lessonData.student, '_id')
        this.setState({
          lessonData,
          releaseDate: moment(lessonData.ended_on),
          dueDate: moment(lessonData.ended_on).add(4, 'days').endOf('day'),
          closeDate: moment(lessonData.ended_on).add(11, 'days').endOf('day'),
          returnDate: moment(lessonData.ended_on).add(7, 'days').endOf('day'),
          selectedStudents
        })
      }
    }

    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState(initialState)
    }

    if (prevProps.loadingLessonDetail && !this.props.loadingLessonDetail && this.props.homeworkData) {
      let { homeworkList, homeworkData } = this.props
      let updatedHomework = _.find(homeworkList.data, o => {
        return o._id === homeworkData._id
      })

      this.setState({ lessonData: updatedHomework.lesson })
    }
  }

  addFile = e => {
    let file = e.target.files[0]
    let tempFormError = this.state.formError
    delete tempFormError['files']
    this.setState({
      files: file,
      formError: tempFormError
    })
  }

  handleChange = e => {
    let name = e.target.name
    let value = e.target.value
    this.setState({ [name]: value })
  }

  handleCheckboxChange = (e, checked) => {
    let name = e.target.name
    this.setState({ [name]: checked })
  }

  handleDateChange = name => date => {
    this.setState({ [name]: date })
  }

  handleKeyPress = event => {
    let { tagData } = this.state

    if (event.key === 'Enter') {
      this.setState({
        tagName: '',
        tagData: tagData.concat(event.target.value.trim())
      })
    }
  }

  handleRequestDelete = item => () => {
    let { tagData } = this.state
    _.remove(tagData, o => {
      return o === item
    })

    this.setState({ tagData })
  }

  handleStudentSelection = (studentId, action) => event => {
    let { selectedStudents, lessonData } = this.state

    if (action === 'CLEAR') {
      selectedStudents = []
    } else if (action === 'SELECT_ALL') {
      selectedStudents = []
      _.each(lessonData.student, s => {
        selectedStudents.push(s._id)
      })
      _.each(lessonData.trial_student, s => {
        selectedStudents.push(s._id)
      })
    } else {
      if (selectedStudents.includes(studentId)) {
        _.remove(selectedStudents, o => {
          return o === studentId
        })
      } else {
        selectedStudents.push(studentId)
      }
    }

    this.setState({ selectedStudents })
  }

  validateFormElement = type => {
    let errorMsg
    if (type === 'files') {
      let { files } = this.state
      if (!files || files.length === 0) {
        errorMsg = 'Please select a homework file'
      }
    }

    let tempFormError = this.state.formError
    if (errorMsg) {
      tempFormError[type] = errorMsg
    } else {
      delete tempFormError[type]
    }
    this.setState({ formError: tempFormError })
  }

  handleSave = () => {
    let { homeworkData } = this.props

    if (homeworkData) {
      this.updateHomework()
    } else {
      this.validateFormElement('files')
      this.createHomework()
    }
  }

  createHomework = () => {
    let {
      lessonData,
      name,
      releaseDate,
      dueDate,
      closeDate,
      returnDate,
      files,
      tagData,
      audioOnly,
      selectedStudents,
      formError
    } = this.state

    if (!_.isEmpty(formError)) return

    let tutorList = _.map(lessonData.class.tutor, '_id')
    let fileType = files.name.split('.').pop()
    let fileName = `${name}.${fileType}`
    let newHomework = new FormData()
    newHomework.append('name', name)
    newHomework.append('released_on', moment(releaseDate).toISOString())
    newHomework.append('due_on', moment(dueDate).toISOString())
    newHomework.append('close_on', moment(closeDate).toISOString())
    newHomework.append('return_on', moment(returnDate).toISOString())
    newHomework.append('tag_list', tagData.join('||'))
    newHomework.append('audio_only', audioOnly)
    newHomework.append('attachment', files, fileName)
    newHomework.append('student_list', selectedStudents.join('||'))
    newHomework.append('tutor_list', tutorList.join('||'))
    newHomework.append('lesson', lessonData._id)
    newHomework.append('class', lessonData.class._id)
    //TODO: handle status later
    newHomework.append('status', 'COMPLETED')
    this.props.createHomework('classic', newHomework, lessonData._id)
  }

  updateHomework = () => {
    let { homeworkData } = this.props
    let { name, releaseDate, dueDate, closeDate, returnDate, tagData, audioOnly, selectedStudents } = this.state

    let data = {
      name,
      released_on: moment(releaseDate).toISOString(),
      due_on: moment(dueDate).toISOString(),
      close_on: moment(closeDate).toISOString(),
      return_on: moment(returnDate).toISOString(),
      audio_only: audioOnly,
      tag_list: tagData.join('||'),
      student_list: selectedStudents.join('||')
    }
    this.props.updateHomework('classic', homeworkData._id, data)
  }

  render() {
    let { isOpen, creatingHomework, homeworkData } = this.props
    let {
      lessonData,
      name,
      releaseDate,
      dueDate,
      closeDate,
      returnDate,
      files,
      tagName,
      tagData,
      audioOnly,
      selectedStudents,
      formError
    } = this.state
    let allowAudioOnlyToggle = lessonData && lessonData.class.subject === 'English'

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Dialog
          open={isOpen}
          onClose={this.props.toggleDialog()}
          maxWidth="md"
          fullWidth
          TransitionComponent={Transition}>
          <DialogTitle>{homeworkData ? 'Update' : 'Create'} Homework</DialogTitle>
          <DialogContent>
            <TextField
              type="text"
              label="Name / Title"
              name="name"
              variant="outlined"
              onChange={this.handleChange}
              fullWidth
              value={name}
              className="mb-3"
            />

            <div className="d-none">
              <h4 className="text-muted mb-2">Custom Tag</h4>
              <div className="manage-margin d-flex flex-wrap mb-4">
                {tagData.map(tag => {
                  return <Chip label={tag} key={tag} onDelete={this.handleRequestDelete(tag)} />
                })}
                <TextField
                  type="text"
                  placeholder="Add new tag"
                  name="tagName"
                  value={tagName}
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeyPress}
                  className="ml-1"
                />
              </div>
            </div>

            {!homeworkData && (
              <Fragment>
                <div className="row align-items-center mb-4">
                  <div className="col-8">
                    <TextField
                      type="text"
                      label="Homework file"
                      placeholder="Choose your file"
                      name="fileName"
                      variant="outlined"
                      error={!!formError.files}
                      helperText={formError.files}
                      value={files ? files.name : ''}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <input
                              accept=".pdf"
                              id="input-file"
                              type="file"
                              style={{ display: 'none' }}
                              onChange={this.addFile}
                            />
                            <label htmlFor="input-file" className="clickable">
                              <FolderIcon />
                            </label>
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </div>
              </Fragment>
            )}

            <div className="row mb-2">
              <div className="col-4">
                <KeyboardDateTimePicker
                  variant="inline"
                  inputVariant="outlined"
                  label="Release on"
                  name="releaseDate"
                  value={releaseDate}
                  onChange={this.handleDateChange('releaseDate')}
                  fullWidth
                  format="DD/MM/YYYY hh:mm a"
                />
              </div>
              <div className="col-4">
                <KeyboardDateTimePicker
                  variant="inline"
                  inputVariant="outlined"
                  label="Due on"
                  name="dueDate"
                  value={dueDate}
                  onChange={this.handleDateChange('dueDate')}
                  fullWidth
                  format="DD/MM/YYYY hh:mm a"
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-4">
                <KeyboardDateTimePicker
                  variant="inline"
                  inputVariant="outlined"
                  label="Close on"
                  name="closeDate"
                  value={closeDate}
                  onChange={this.handleDateChange('closeDate')}
                  fullWidth
                  format="DD/MM/YYYY hh:mm a"
                />
              </div>
              <div className="col-4">
                <KeyboardDateTimePicker
                  variant="inline"
                  inputVariant="outlined"
                  label="Return on"
                  name="returnDate"
                  value={returnDate}
                  onChange={this.handleDateChange('returnDate')}
                  fullWidth
                  format="DD/MM/YYYY hh:mm a"
                />
              </div>
            </div>

            {allowAudioOnlyToggle && (
              <div className="row mb-3">
                <div className="col-12">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={audioOnly}
                        onChange={this.handleCheckboxChange}
                        name="audioOnly"
                        color="primary"
                      />
                    }
                    label="Accept audio submission only"
                  />
                </div>
              </div>
            )}

            <div className="d-flex align-items-center">
              <h3 className="mb-0">Students</h3>
              <span className="text-muted ml-2">(* for trial student)</span>
              <span
                className="text-info text-small clickable ml-2"
                onClick={this.handleStudentSelection('', 'SELECT_ALL')}>
                Select All
              </span>
              <span className="text-info text-small clickable ml-2" onClick={this.handleStudentSelection('', 'CLEAR')}>
                Clear All
              </span>
            </div>
            <FormGroup row>
              {lessonData ? (
                <Fragment>
                  {lessonData.student.map((student, index) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedStudents.includes(student._id)}
                          onChange={this.handleStudentSelection(student._id)}
                          value={student._id}
                          color="primary"
                        />
                      }
                      label={student.name}
                      key={index}
                    />
                  ))}

                  {lessonData.trial_student.map((student, index) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedStudents.includes(student._id)}
                          onChange={this.handleStudentSelection(student._id)}
                          value={student._id}
                          color="primary"
                        />
                      }
                      label={`*${student.name}`}
                      key={index}
                    />
                  ))}
                </Fragment>
              ) : (
                <CircularProgress size={25} color="primary" />
              )}
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button variant="text" color="default" onClick={this.props.toggleDialog()} disabled={creatingHomework}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={this.handleSave} disabled={creatingHomework}>
              Save & Upload {creatingHomework && <CircularProgress className="ml-2" size={15} color="primary" />}
            </Button>
          </DialogActions>
        </Dialog>
      </MuiPickersUtilsProvider>
    )
  }
}

CreateUpdateHomeworkDialog.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  toggleDialog: Proptypes.func.isRequired,
  homeworkData: Proptypes.object,
  lessonData: Proptypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUpdateHomeworkDialog)
