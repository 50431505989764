import React from 'react'
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import { Avatar, Button, CircularProgress, Paper } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import _ from 'lodash'
import moment from 'moment'
import qs from 'qs'

import agent from 'agent'
import { GET_MATCHING_CLASSES } from 'constants/actionTypes'
import TestimonialCarousel from 'components/misc/testimonial-carousel'
import ClassExperience from 'components/misc/info-class-experience'
import SuperiorMethodology from 'components/misc/info-superior-methodology'
import VideoPlayerDialog from 'components/dialog/video-player'
import GetInTouchDialog from 'components/dialog/get-in-touch'
import TutorProfileDialog from 'components/dialog/regular-tutor-profile'
import ClassDetailDialog from 'components/dialog/class-detail'
import JoinTrialDialog from './dialog-join-trial'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  trials: state.auth.trials,
  requestingTrialService: state.auth.requestingTrialService,
  joiningTrialService: state.auth.joiningTrialService,
  joinTrialStatus: state.auth.joinTrialStatus,
  checkingTrialAvailability: state.auth.checkingTrialAvailability,
  trialAvailable: state.auth.trialAvailable,
  loadingClasses: state.onlineClass.loadingClasses,
  matchingClasses: state.onlineClass.matchingClasses
})

const mapDispatchToProps = dispatch => ({
  getEligible: query => dispatch({ type: GET_MATCHING_CLASSES, payload: agent.OnlineClass.getList(query, true) })
})

const AVAILABLE_LEVEL = ['secondary-2', 'secondary-1', 'primary-6', 'primary-5', 'primary-4', 'primary-3']

const LessonInfo = props => {
  let { nextLesson, price } = props

  return (
    <table>
      <tbody>
        <tr>
          <td className="d-flex flex-row align-items-center font-weight-medium">
            <i className="material-icons mr-1" style={{ fontSize: '19px', fontWeight: '500' }}>
              desktop_windows
            </i>
            Lesson
          </td>
          <td className="px-2">:</td>
          <td>{nextLesson.name}</td>
        </tr>
        <tr>
          <td className="d-flex flex-row align-items-center font-weight-medium">
            <i className="material-icons mr-1" style={{ fontSize: '20px', fontWeight: '500' }}>
              event
            </i>
            Date
          </td>
          <td className="px-2">:</td>
          <td>{moment(nextLesson.scheduled_on).format('ll (dddd)')}</td>
        </tr>
        <tr>
          <td className="d-flex flex-row align-items-center font-weight-medium">
            <i className="material-icons mr-1" style={{ fontSize: '20px', fontWeight: '500' }}>
              access_time
            </i>
            Time
          </td>
          <td className="px-2">:</td>
          <td>
            {moment(nextLesson.scheduled_on).format('h:mm a')} - {moment(nextLesson.ended_on).format('h:mm a')}
          </td>
        </tr>
        <tr>
          <td className="d-flex flex-row align-items-center font-weight-medium">
            <i className="material-icons mr-1" style={{ fontSize: '20px', fontWeight: '500' }}>
              attach_money
            </i>
            Price
          </td>
          <td className="px-2">:</td>
          <td>
            <div className="d-flex align-items-center">
              <span>$0</span>
              <del className="text-danger text-small ml-2">${price.toFixed(2)}</del>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

class Trial extends React.Component {
  state = {
    availableSubjectFilter: ['English'],
    filterSubject: 'All',
    filterLevel: '',
    selectedClass: null,
    filteredClassList: [],
    noTrialClass: false,
    isJoinTrialDialogOpen: false,
    isVideoDialogOpen: false,
    isGetInTouchDialogOpen: false,
    isTutorProfileDialogOpen: false,
    isClassDetailDialogOpen: false,
    selectedTutorData: null
  }

  componentDidMount() {
    const searchParams = qs.parse(this.props.location.search.slice(1))
    this.setState({
      filterLevel: searchParams.level || 'primary-6',
      filterSubject: searchParams.subject || 'All'
    })
    ReactPixel.trackCustom('ViewTrial')
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filterLevel !== this.state.filterLevel) {
      this.getEligibleClasses()
    }

    if (prevProps.loadingClasses && !this.props.loadingClasses) {
      this.getAvailableSubject()
      this.filterClass()
    }

    if (prevState.filterSubject !== this.state.filterSubject) {
      this.filterClass()
    }
  }

  getAvailableSubject = () => {
    const { matchingClasses } = this.props
    const availableSubjectFilter = _.uniq(_.map(matchingClasses, 'subject'))
    this.setState({ availableSubjectFilter })
  }

  filterClass = () => {
    let { matchingClasses } = this.props
    let { filterSubject } = this.state

    let filteredClassListBySubject = matchingClasses
    if (filterSubject !== 'All') {
      filteredClassListBySubject = _.filter(matchingClasses, o => {
        return o.subject.toUpperCase() === filterSubject.toUpperCase()
      })
    }

    let filteredClassList = []
    for (let i = 0; i < filteredClassListBySubject.length; i++) {
      let classData = filteredClassListBySubject[i]
      let nextLesson = _.find(classData.lesson, function (o) {
        return moment(o.scheduled_on) > moment()
      })
      if (nextLesson) {
        let trialClass = filteredClassListBySubject[i]
        trialClass['next_lesson'] = nextLesson
        filteredClassList.push(trialClass)
      }
    }
    //filteredClassList = _.sortBy(filteredClassList, ['subject', 'next_lesson.scheduled_on'])
    filteredClassList = _.sortBy(filteredClassList, ['next_lesson.scheduled_on'])
    this.setState({
      filteredClassList,
      noTrialClass: filteredClassList.length === 0
    })
  }

  getEligibleClasses = () => {
    this.props.getEligible({
      is_trial: true,
      is_active: true,
      is_listed: true,
      level: this.state.filterLevel,
      start_date: {
        $gte: moment('2021-11-01T00:00:00Z').toISOString()
      }
    })
  }

  scrollToTrialClassSection = () => {
    ReactGA.event({
      category: 'Trial',
      action: 'ViewTrialClass'
    })
    let elmnt = document.getElementById('trial-classes')
    elmnt.scrollIntoView({ behavior: 'smooth' })
  }

  toggleFilterOption = (type, value) => {
    this.setState({ [type]: value })
  }

  toggleJoinTrialDialog = selectedClass => () => {
    if (!this.state.isJoinTrialDialogOpen) {
      ReactGA.event({
        category: 'Trial',
        action: this.props.token ? 'JoinTrial' : 'InitRegistration'
      })
    }
    this.setState({
      selectedClass,
      isJoinTrialDialogOpen: !this.state.isJoinTrialDialogOpen
    })
  }

  toggleVideoDialog = () => {
    this.setState({ isVideoDialogOpen: !this.state.isVideoDialogOpen })
  }

  toggleGetInTouchDialog = () => {
    ReactGA.event({
      category: 'Trial',
      action: 'ToggleGetInTouch'
    })
    this.setState({ isGetInTouchDialogOpen: !this.state.isGetInTouchDialogOpen })
  }

  toggleTutorProfileDialog = tutorData => () => {
    ReactGA.event({
      category: 'Trial',
      action: 'ViewTutorProfile'
    })

    this.setState({
      selectedTutorData: tutorData,
      isTutorProfileDialogOpen: !this.state.isTutorProfileDialogOpen
    })
  }

  toggleClassDetailDialog = selectedClass => () => {
    this.setState({
      selectedClass,
      isClassDetailDialogOpen: !this.state.isClassDetailDialogOpen
    })
  }

  render() {
    const { currentUser } = this.props
    const {
      filterLevel,
      filterSubject,
      availableSubjectFilter,
      selectedClass,
      filteredClassList,
      noTrialClass,
      isJoinTrialDialogOpen,
      isVideoDialogOpen,
      isGetInTouchDialogOpen,
      isTutorProfileDialogOpen,
      isClassDetailDialogOpen,
      selectedTutorData
    } = this.state
    return (
      <div className="landing bg-white">
        <Helmet>
          <title>Trial Class | Tenopy</title>
          <meta
            name="description"
            content="Experience Tenopy live online tuition with a free trial upon creating an account with us, enjoy homework marking, lesson materials and video replay. We teach English, Mathematics and Science for primary and secondary students of different learning abilities. Join us now!"
          />
          <meta
            name="keywords"
            content="Free trial, free online tuition trial, free trial class, free online class, online english tution, online math tuition, online mathematics tuition, online science tuition, primary student, secondary student, online tuition, online tuition Singapore, Singapore online tuition, free trial online tutor, video replay, video playback"
          />
        </Helmet>
        {/*<section className="trial__banner-bg--main pt-4">
          <div className="trial__banner-bg--side d-md-block d-none" />
          <div className="container h-100">
            <div className="row align-items-center h-100 mb-3 mb-md-0">
              <div className="col-md-6 col-12">
                <div className="trial__banner-title text-center text-md-left">
                  Tenopy Trial Class for 2023 Programmes!
                </div>
                <div className="text-justify text-md-left">
                  <p>
                    Moving into 2023, Tenopy teachers are now empowered to teach live online in revolutionary ways with
                    the launch of our proprietary virtual classroom technology. Three main ways online teaching is now
                    taken to a new level:
                  </p>
                  <ul>
                    <li>Hands-on learning with a new range of on-screen activities, no more one-way lecture</li>
                    <li>
                      Effective real-time teacher guidance for group class, aided by live dashboard on students’
                      responses
                    </li>
                    <li>Better individual assessment of students’ progress on learning objective</li>
                  </ul>
                  <div
                    className="d-flex align-items-center justify-content-center d-md-none d-block"
                    style={{
                      backgroundImage:
                        "url('https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/student/main_background.jpg')",
                      backgroundSize: 'cover',
                      backgroundPosition: 'center center',
                      minHeight: '200px',
                      marginBottom: '1rem'
                    }}>
                    <div className="sonar-wrapper w-100" onClick={this.toggleVideoDialog}>
                      <i className="material-icons sonar-play">play_circle_filled</i>
                      <div className="sonar-emitter">
                        <div className="sonar-wave"></div>
                      </div>
                    </div>
                  </div>
                  <p>
                    Combining passionate teachers with our proprietary technology and curriculum is the Tenopy approach
                    to help your child discover his or her full potential. Book your free trial now to experience
                    Tenopy’s new online learning experience!
                  </p>
                </div>

                <div className="d-flex flex-row flex-wrap">
                  <Button
                    size={isMobile ? 'medium' : 'large'}
                    variant="contained"
                    color="primary"
                    className="mr-2 mt-3"
                    onClick={this.scrollToTrialClassSection}>
                    <i className="material-icons d-md-block d-none mr-2">view_list</i>View Trial Class
                  </Button>
                  <Button
                    size={isMobile ? 'medium' : 'large'}
                    variant="outlined"
                    color="primary"
                    className="btn-white-bg mt-3"
                    onClick={this.toggleGetInTouchDialog}>
                    <i className="material-icons d-md-block d-none mr-2">forum</i>Get In Touch
                  </Button>
                </div>
              </div>
              <div className="col-md-6 text-center d-md-block d-none" style={{ zIndex: 2 }}>
                <div className="sonar-wrapper" onClick={this.toggleVideoDialog}>
                  <i className="material-icons sonar-play">play_circle_filled</i>
                  <div className="sonar-emitter">
                    <div className="sonar-wave"></div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
        </section>*/}

        <section
          id="trial-classes"
          style={{
            backgroundImage:
              'radial-gradient( circle farthest-corner at 10% 20%,  rgba(215,223,252,1) 0%, rgba(255,255,255,1) 0%, rgba(215,223,252,1) 84% )'
          }}>
          <div className="container">
            <div className="section__header mb-0">
              <div
                className="title mb-2"
                style={{ fontSize: '2.3rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif", lineHeight: 1.2 }}>
                Choose Your Trial Class
              </div>
              <div className="mx-auto mb-5" style={{ maxWidth: '120px', borderBottom: '3px solid #3f84f2' }}></div>
            </div>

            <div className="mt-3">
              <Paper elevation={4} className="row bg-white no-gutters mb-4 p-3">
                <div className="col-12 d-flex flex-wrap flex-row">
                  <div className="filter__label">Level&nbsp;&nbsp;&nbsp;</div>
                  {AVAILABLE_LEVEL.map((level, index) => {
                    let levelName = level.replace('secondary-', 'Sec ').replace('primary-', 'Pri ')
                    if (level === 'secondary-4') {
                      levelName = 'O Level'
                    }

                    return (
                      <div
                        key={index}
                        className={`filter__option filter__option--${
                          filterLevel === level ? 'active' : 'inactive'
                        } mb-2`}
                        onClick={() => this.toggleFilterOption('filterLevel', level)}>
                        {levelName}
                      </div>
                    )
                  })}
                </div>

                <div className="col-12 d-flex flex-wrap flex-row">
                  <div className="filter__label">Subject</div>
                  <div
                    className={`filter__option filter__option--${filterSubject === 'All' ? 'active' : 'inactive'}`}
                    onClick={() => this.toggleFilterOption('filterSubject', 'All')}>
                    All
                  </div>
                  {availableSubjectFilter.map((subject, index) => (
                    <div
                      key={index}
                      className={`filter__option filter__option--${filterSubject === subject ? 'active' : 'inactive'}`}
                      onClick={() => this.toggleFilterOption('filterSubject', subject)}>
                      {subject}
                    </div>
                  ))}
                </div>
              </Paper>
            </div>

            {noTrialClass ? (
              <Alert severity="warning">No trial class found</Alert>
            ) : filteredClassList.length > 0 ? (
              filteredClassList.map((classData, index) => {
                return (
                  <React.Fragment key={index}>
                    {isMobile ? (
                      <div className="shadow bg-white mb-3 p-3">
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <Avatar
                              className="size-60 avatar-shadow pointer"
                              alt={classData.tutor[0].name}
                              src={classData.tutor[0].profile_pic}
                              onClick={this.toggleTutorProfileDialog(classData.tutor[0])}
                            />
                          </div>
                          <div className="col">
                            <h3 className="mb-0">{classData.name}</h3>
                            <span>
                              by&nbsp;
                              <span
                                className="link text-underline"
                                onClick={this.toggleTutorProfileDialog(classData.tutor[0])}>
                                {classData.tutor[0].name}
                              </span>
                            </span>
                          </div>
                          <div className="col-12 my-3">
                            <LessonInfo nextLesson={classData.next_lesson} price={classData.rate} />
                          </div>
                          <div className="col-12">
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              onClick={this.toggleJoinTrialDialog(classData)}>
                              Join Trial
                            </Button>
                            <Button
                              variant="text"
                              color="primary"
                              className="mt-2"
                              fullWidth
                              onClick={this.toggleClassDetailDialog(classData)}>
                              View Class Info
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="row no-gutters shadow mb-3 align-items-center bg-white"
                        key={index}
                        style={{ position: 'relative' }}>
                        <div
                          className="col-auto text-center pointer"
                          onClick={this.toggleTutorProfileDialog(classData.tutor[0])}>
                          <img
                            src={classData.tutor[0].profile_pic}
                            alt={classData.tutor[0].name}
                            height="auto"
                            width="190px"
                          />
                        </div>
                        <div className="col px-3">
                          <div className="d-flex flex-row align-items-end mb-2">
                            <h2 className="mb-0">{classData.name}</h2>
                            <div className="ml-2">
                              by&nbsp;
                              <span
                                className="link text-underline"
                                onClick={this.toggleTutorProfileDialog(classData.tutor[0])}>
                                {classData.tutor[0].name}
                              </span>
                            </div>
                          </div>
                          <LessonInfo nextLesson={classData.next_lesson} price={classData.rate} />
                        </div>
                        <div style={{ position: 'absolute', bottom: '0px', right: '0px' }}>
                          <Button
                            variant="text"
                            color="primary"
                            className="mb-3 mr-2"
                            onClick={this.toggleClassDetailDialog(classData)}>
                            View Class Info
                          </Button>

                          <Button
                            variant="contained"
                            color="primary"
                            className="mb-3 mr-3"
                            onClick={this.toggleJoinTrialDialog(classData)}>
                            Join Trial
                          </Button>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )
              })
            ) : (
              <div className="d-flex justify-content-center">
                <CircularProgress size={40} className="my-5" />
              </div>
            )}
          </div>
        </section>

        {/* <ClassExperience /> */}
        <hr />
        <SuperiorMethodology />

        <section style={{ backgroundColor: '#f6f6fc' }}>
          <div className="container">
            <div className="section__header mb-5">
              <div
                className="title mb-2"
                style={{ fontSize: '2.3rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif" }}>
                What they said
              </div>
              <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #0cc652' }}></div>
            </div>
            <TestimonialCarousel />
          </div>
        </section>

        {!currentUser && (
          <section className="bg-light-gray">
            <div className="container">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="title--rubik text-primary font-weight-normal mb-0">
                    Ready to experience Live Tuition with Tenopy?
                  </h1>
                  <span style={{ fontSize: '18px' }}>Create a free account or get in touch.</span>
                </div>
                <div className="col-auto my-3">
                  <Button variant="contained" color="primary" className="mr-2" onClick={this.scrollToTrialClassSection}>
                    View Trial Class
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    className="btn-white-bg"
                    onClick={this.toggleGetInTouchDialog}>
                    Get In Touch
                  </Button>
                </div>
              </div>
            </div>
          </section>
        )}

        <GetInTouchDialog isOpen={isGetInTouchDialogOpen} close={this.toggleGetInTouchDialog} intent="Trial Page" />

        <JoinTrialDialog
          isOpen={isJoinTrialDialogOpen}
          toggleDialog={this.toggleJoinTrialDialog(selectedClass)}
          classData={selectedClass}
        />

        <VideoPlayerDialog
          videoURL="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/video-assets/tenopy-testi-trial-captioned.mp4"
          isOpen={isVideoDialogOpen}
          close={this.toggleVideoDialog}
        />

        <TutorProfileDialog
          isOpen={isTutorProfileDialogOpen}
          close={this.toggleTutorProfileDialog()}
          tutorData={selectedTutorData}
        />

        <ClassDetailDialog
          isOpen={isClassDetailDialogOpen}
          close={this.toggleClassDetailDialog()}
          classData={selectedClass}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Trial)
