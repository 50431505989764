import React from 'react'
import { Button } from '@material-ui/core'
import { ALL_CLASSES, TRIAL } from 'routes/routes'

const CTADivider = props => {
  function openPage(destination) {
    window.open(destination, '_blank')
  }

  let bgStyle = 'bg__divider--4',
    titleStyle = 'text-white',
    subTitleStyle = 'text-white',
    getTrialBtnStyle = 'btn-banner-cta--white'
  if (props.styleOpt === 'plain') {
    bgStyle = 'bg-light-gray'
    titleStyle = 'text-primary'
    subTitleStyle = 'text-dark'
    getTrialBtnStyle = 'bg-white'
  }

  return (
    <section className={`${bgStyle} d-flex align-items-center`} style={{ height: '100%', maxHeight: '100%' }}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col">
            <div className={`${titleStyle} h1 mb-0`} style={{ fontFamily: "'Rubik', sans-serif", fontWeight: 400 }}>
              {/* Experience your first LIVE online lesson with Tenopy! */}
              Get started with Tenopy!
            </div>
            {/* <span className={subTitleStyle} style={{ fontSize: '18px' }}>
              Check out classes we offer.
            </span> */}
          </div>
          <div className="col-auto">
            {/* <Button
              variant="outlined"
              size="large"
              color="primary"
              className={`mr-2 mt-4 mt-md-0 ${getTrialBtnStyle}`}
              onClick={() => {
                openPage(TRIAL)
              }}>
              Get A Free Trial
            </Button> */}
            <Button
              variant="outlined"
              size="large"
              color="primary"
              className={`mr-2 mt-4 mt-md-0 ${getTrialBtnStyle}`}
              onClick={() => {
                openPage(
                  'https://formfacade.com/public/116456509124236683597/all/form/1FAIpQLSeW8WyPGrbwajelPS-ylx_KPY6DTZPFOw-uBIPdgI2Ad_lKHA'
                )
              }}>
              Talk to us
            </Button>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className="mr-2 mt-4 mt-md-0"
              onClick={() => {
                openPage(ALL_CLASSES)
              }}>
              View Classes
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CTADivider
