import React from 'react'
import Proptypes from 'prop-types'
import { Avatar, Paper } from '@material-ui/core'
import {
  CLASS_TYPE_REGULAR_CLASSES,
  CLASS_TYPE_WORKSHOP,
  BOOK_CLUB_IDS,
  CLASS_TYPE_RECORDING
} from 'constants/generalTypes'
import { OnlineClassSummary, getClassData } from 'utils/misc'
import { roundPrice } from 'utils/merchandise'

function CatalogueItemCard(props) {
  const { classData, viewOnlineClass } = props
  const { name, class_type, tutor, lesson, rate, quote, is_sold_out, schedule, _id } = classData

  const processedData = getClassData(class_type, lesson, rate, quote, _id)
  const activeTutor = tutor[0]
  const isBookClub = BOOK_CLUB_IDS.includes(classData._id)
  let workshopUnit = 'lesson'
  let profilePic = activeTutor.profile_pic
  if (isBookClub) {
    workshopUnit = 'session'
    profilePic =
      'https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/book-club/placeholder-primary.jpg'
  }

  return (
    <div className="col-md-4 col-12 mb-3 pointer" onClick={viewOnlineClass}>
      <Paper elevation={2} className="d-flex flex-column align-items-end position-relative h-100">
        <div className="w-100 px-3 pt-3">
          <div className="d-flex align-items-start">
            <Avatar src={profilePic} alt={activeTutor.name} className="size-60 avatar-shadow mr-2" />
            <div className="title--rubik mb-1" style={{ fontSize: '21px' }}>
              {name}
            </div>
          </div>

          <div className="my-2">
            <OnlineClassSummary
              classType={class_type}
              startDate={lesson[0].scheduled_on}
              endDate={lesson[lesson.length - 1].scheduled_on}
              lessonStartTime={schedule.start_time}
              lessonEndTime={schedule.end_time}
              tutor={activeTutor}
              hideTeacherPrefix={BOOK_CLUB_IDS.includes(classData._id)}
            />
          </div>
        </div>

        <div
          className="d-flex align-items-center justify-content-between px-3 py-2 w-100 mt-auto"
          style={{
            borderTop: '1px solid #D3DCE6',
            backgroundColor: '#f7f7f7'
          }}>
          <div>
            {is_sold_out ? (
              <span className="tag danger-tag text-normal">Class Full</span>
            ) : (
              <>
                {class_type === CLASS_TYPE_REGULAR_CLASSES && (
                  <span className="tag grey-tag--outline">REGULAR CLASS</span>
                )}
                {class_type === CLASS_TYPE_WORKSHOP && (
                  <>
                    {BOOK_CLUB_IDS.includes(classData._id) ? (
                      <span className="tag purple-tag--outline">BOOK CLUB</span>
                    ) : (
                      <span className="tag info-tag--outline">WORKSHOP</span>
                    )}
                  </>
                )}
                {class_type === CLASS_TYPE_RECORDING && <span className="tag info-tag--outline">RECORDING</span>}
              </>
            )}
          </div>

          {classData.force_full_purchase ? (
            <div className="text-right">
              <div className="text-primary title--rubik mb-0" style={{ fontSize: '22px' }}>
                ${roundPrice(processedData.price)}
                {processedData.discount > 0 && (
                  <del className="text-danger ml-1">
                    <small className="text-muted">${processedData.original_price}</small>
                  </del>
                )}
              </div>
              <small>
                {class_type === CLASS_TYPE_WORKSHOP ? (
                  <>
                    for {processedData.lessonCount} {workshopUnit}s
                  </>
                ) : (
                  <>per {workshopUnit}</>
                )}
              </small>
            </div>
          ) : (
            <div className="text-right">
              <div className="text-primary title--rubik mb-0" style={{ fontSize: '22px' }}>
                ${classData.rate.toFixed(2)}
              </div>
              <small>per {workshopUnit}</small>
            </div>
          )}
        </div>
      </Paper>
    </div>
  )
}

CatalogueItemCard.propTypes = {
  classData: Proptypes.object.isRequired,
  viewOnlineClass: Proptypes.func.isRequired
}

export default CatalogueItemCard
