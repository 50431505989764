import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Proptypes from 'prop-types'
import { Button, Dialog, DialogContent, Slide } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import moment from 'moment'

import { MY_PAID_CLASSES } from 'routes/studentRoutes'
import { APP } from 'routes/routes'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PaymentSuccessDialog = props => {
  const { isOpen, paidList } = props

  return (
    <Dialog open={isOpen} onClose={props.close} TransitionComponent={Transition} fullWidth maxWidth="md">
      <DialogContent>
        <div className="row">
          <div className="col-12">
            <Close
              className="position-absolute clickable m-2"
              onClick={props.close}
              style={{ right: 0, top: '-15px' }}
            />
            <div className="text-center">
              <img
                src="https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/frontend/illustration-payment-success.png"
                alt="checkout success"
                width="250px"
              />
              <h2 className="font-weight-semibold my-3">Payment is completed</h2>
              <h3 className="my-3">You will receive a payment confirmation email for reference.</h3>
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th align="left">Item</th>
                  <th>Transaction No.</th>
                </tr>
              </thead>
              <tbody>
                {paidList.map(o => {
                  return (
                    <tr key={o.ref_no}>
                      <td align="left">
                        <div className="text-large">{o.detail.class_obj.name}</div>
                        <small className="text-muted">
                          {moment(o.subscription_started_on).format('DD MMM YYYY')}&nbsp;-&nbsp;
                          {moment(o.subscription_ended_on).format('DD MMM YYYY')} ({o.detail.billable_lesson.length}{' '}
                          lessons)
                        </small>
                      </td>
                      <td style={{ whiteSpace: 'nowrap' }}>{o.ref_no}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>

            <div className="mt-2 text-center">
              <Button
                variant="contained"
                color="primary"
                to={`${APP}${MY_PAID_CLASSES}`}
                component={Link}
                className="text-white">
                View My Classes
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

PaymentSuccessDialog.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired,
  paidList: Proptypes.array.isRequired
}

export default withRouter(PaymentSuccessDialog)
