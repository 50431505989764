import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import ReactPixel from 'react-facebook-pixel'
import _ from 'lodash'
import CircularProgress from '@material-ui/core/CircularProgress'

import agent from 'agent'
import {
  GET_ONLINE_CLASS_LIST,
  GET_PACKAGE_QUOTE,
  CALC_PACKAGE_QUOTE,
  CLEAR_PACKAGE_QUOTE
} from 'constants/actionTypes'
import { ROOT, ALL_CLASSES, ONLINE_CLASS_LIST } from 'routes/routes'
import { BOOK_CLUB_IDS } from 'constants/generalTypes'
import { getPackageForOnlineClass } from 'utils/merchandise'
import { calculateQuote } from 'utils/cart'
import VideoPlayerDialog from 'components/dialog/video-player'
import Intro from './section-intro'
import TutorProfile from './section-tutor-profile'
import LessonSchedule from './section-lesson-schedule'
import BookClubDetail from './section-book-club'

import { Breadcrumb, BreadcrumbItem } from 'reactstrap'

const mapStateToProps = state => ({
  ...state.onlineClass,
  currentUser: state.common.currentUser,
  token: state.common.token,
  userClassWithDetail: state.common.userClassWithDetail
})

const mapDispatchToProps = dispatch => ({
  getClassList: () => dispatch({ type: GET_ONLINE_CLASS_LIST, payload: agent.OnlineClass.getList() }),
  getPackageQuote: (payload, packageItem) =>
    dispatch({ type: GET_PACKAGE_QUOTE, payload: agent.OnlineClass.getQuote(payload), packageItem: packageItem }),
  calcPackageQuote: (quote, packageItem) => dispatch({ type: CALC_PACKAGE_QUOTE, payload: quote, packageItem }),
  clearPackageQuote: (class_id, component_id) =>
    dispatch({ type: CLEAR_PACKAGE_QUOTE, class_id: class_id, component: component_id })
})

class ClassInfo extends React.Component {
  state = {
    onlineClass: null,
    isVideoDialogOpen: false,
    selectedVideoUrl: ''
  }

  componentDidMount() {
    ReactPixel.track('ViewContent', { class_id: this.props.match.params.classId })
    if (this.props.onlineClassList) {
      this.initOnlineClass()
    } else {
      this.props.getClassList()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.onlineClassList !== this.props.onlineClassList && !this.state.onlineClass) {
      this.initOnlineClass()
    }

    if (prevState.onlineClass !== this.state.onlineClass) {
      this.processClassData()
    }
  }

  componentWillUnmount() {
    if (this.state.onlineClass && this.state.onlineClass._id) {
      this.props.clearPackageQuote(this.state.onlineClass._id)
    }
    this.setState({
      onlineClass: null
    })
  }

  initOnlineClass = () => {
    let onlineClass = _.find(this.props.onlineClassList, { _id: this.props.match.params.classId })
    if (onlineClass) {
      this.setState({ onlineClass })
    } else {
      this.props.history.push(this.props.token ? ONLINE_CLASS_LIST : ALL_CLASSES)
    }
  }

  processClassData = () => {
    let { userClassWithDetail, token } = this.props
    let { onlineClass } = this.state
    if (onlineClass) {
      let lessonPackages = getPackageForOnlineClass(userClassWithDetail, onlineClass)

      if (lessonPackages.length > 0) {
        if (lessonPackages === 'no_more_lesson_to_purchase') {
          this.props.calcPackageQuote({ data: lessonPackages }, { class_id: onlineClass._id })
        } else {
          for (let i = 0; i < lessonPackages.length; i++) {
            if (token) {
              this.props.getPackageQuote(
                {
                  class_id: onlineClass._id,
                  start_date: lessonPackages[i].start_date,
                  end_date: lessonPackages[i].end_date
                },
                lessonPackages[i].packageItem
              )
            } else {
              let quote = calculateQuote(
                lessonPackages[i].lessons,
                lessonPackages[i].packageItem.rate,
                lessonPackages[i].start_date,
                lessonPackages[i].end_date,
                onlineClass._id
              )
              this.props.calcPackageQuote({ data: quote }, lessonPackages[i].packageItem)
            }
          }
        }
      }
    }
  }

  scrollToTutorProfileSection = () => {
    let tutorProfileDom = document.getElementById('tutor-profile')
    window.scrollTo({
      top: tutorProfileDom.offsetTop,
      left: 0,
      behavior: 'smooth'
    })
  }

  toggleVideoDialog = videoUrl => () => {
    this.setState({
      isVideoDialogOpen: !this.state.isVideoDialogOpen,
      selectedVideoUrl: videoUrl
    })
  }

  render() {
    const { token } = this.props
    const { onlineClass, isVideoDialogOpen, selectedVideoUrl } = this.state

    if (onlineClass) {
      return (
        <div className={token ? 'app-wrapper' : 'container my-4'}>
          <Helmet>
            <title>{onlineClass.name} | Tenopy</title>
            <meta name="description" content={`Sign up for ${onlineClass.name} class with Tenopy`} />
            <meta
              name="keywords"
              content="Online tuition, Online tuition Singapore, affordable tuition, affordable online tuition Singapore, English tuition, Math tuition, Mathematics tuition, science tuition, primary student, secondary student, high learning ability, workshops, Online english tuition, online math tuition, online science tuition, primary school, secondary school, online primary school tuition, online secondary school tuition, online english tutor, online math tutor, online science tutor, home tuition, online tutoring, online learning, online tutoring, tuition assignment, tuition assignments singapore, online tuition portal singapore"
            />
          </Helmet>

          <div className="animated slideInUpTiny animation-duration-3">
            <Breadcrumb className="bg-white" tag="nav">
              {!token && (
                <BreadcrumbItem
                  tag="a"
                  className="pointer"
                  onClick={() => {
                    this.props.history.push(ROOT)
                  }}>
                  Home
                </BreadcrumbItem>
              )}
              <BreadcrumbItem
                tag="a"
                className="pointer"
                onClick={() => {
                  this.props.history.push(token ? ONLINE_CLASS_LIST : ALL_CLASSES)
                }}>
                All Classes
              </BreadcrumbItem>
              <BreadcrumbItem active tag="span">
                {onlineClass.name}
              </BreadcrumbItem>
            </Breadcrumb>

            {BOOK_CLUB_IDS.includes(onlineClass._id) ? (
              <div className="card p-4">
                <BookClubDetail onlineClassItem={onlineClass} />
              </div>
            ) : (
              <div className="card p-4">
                <Intro
                  onlineClassItem={onlineClass}
                  scrollToTutorProfileSection={this.scrollToTutorProfileSection}
                  showIntroVideo={this.toggleVideoDialog}
                />
                <hr />
                <br />
                <LessonSchedule classType={onlineClass.class_type} lessons={onlineClass.lesson} />
                <hr />
                <TutorProfile tutor={onlineClass.tutor} showIntroVideo={this.toggleVideoDialog} />
              </div>
            )}
          </div>

          <VideoPlayerDialog
            videoURL={selectedVideoUrl}
            isOpen={isVideoDialogOpen}
            close={this.toggleVideoDialog('')}
          />
        </div>
      )
    } else {
      return (
        <div className="manage-margin text-center py-5">
          <CircularProgress size={50} />
        </div>
      )
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassInfo)
