import React from 'react'
import { connect } from 'react-redux'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Slide,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField
} from '@material-ui/core'
import { ExpandMore as ExpandMoreIcon, RemoveCircle as RemoveIcon } from '@material-ui/icons'
import CKEditor from 'ckeditor4-react'
import _ from 'lodash'
import Proptypes from 'prop-types'

import agent from 'agent'
import { GET_STUDENT_HOMEWORK_REPORT, CREATE_REPORT, UPDATE_REPORT } from 'constants/actionTypes'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const CLASS_PARTICIPATION_GAUGE = [
  {
    gauge: 'Low',
    desc: 'Student is rarely active and usually needs to be prompted to participate throughout the session'
  },
  {
    gauge: 'Below Average',
    desc: 'Student is occasionally participative but mostly silent throughout the session'
  },
  {
    gauge: 'Acceptable',
    desc: 'Student makes intermittent attempts to participate throughout the session, but can afford to engage further'
  },
  {
    gauge: 'Very Good',
    desc: 'Student frequently participates and is eager to engage throughout the session'
  },
  {
    gauge: 'Outstanding',
    desc: 'Student is extremely participative and engages consistently throughout the session'
  }
]

const initialState = {
  systemData: {
    attendance: '',
    poll: '',
    homework: ''
  },
  completed: false,
  textComponent: [
    {
      _id: Math.random().toString(36),
      title: 'Key Learning Objectives',
      content: '',
      order: 1
    }
  ],
  attendancePercentage: 0,
  attendanceDesc: '',
  pollPercentage: 0,
  pollDesc: '',
  pollComment: '',
  classParticipationGauge: CLASS_PARTICIPATION_GAUGE[2].gauge,
  classParticipationDesc: CLASS_PARTICIPATION_GAUGE[2].desc,
  classParticipationComment: '',
  homeworkPercentage: 0,
  homeworkDesc: '',
  homeworkComment: ''
}

const mapStateToProps = state => ({
  loadingStudentHomeworkReport: state.report.loadingStudentHomeworkReport,
  homeworkReport: state.report.classReports.homework,
  creating: state.report.creating,
  updating: state.report.updating
})

const mapDispatchToProps = dispatch => ({
  getStudentHomeworkReport: (studentId, lessonIds) =>
    dispatch({
      type: GET_STUDENT_HOMEWORK_REPORT,
      payload: agent.Homework.getStudentReportByLessons(studentId, lessonIds)
    }),
  createReport: reqPayload => dispatch({ type: CREATE_REPORT, payload: agent.Report.create(reqPayload) }),
  updateReport: (reportType, reportId, reqPayload) =>
    dispatch({ type: UPDATE_REPORT, payload: agent.Report.update(reportType, reportId, reqPayload) })
})

class CreateReportDialog extends React.Component {
  state = initialState

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isOpen && this.props.isOpen) {
      let { reportData, reportParams, homeworkReport, studentData, classData } = this.props

      if (!homeworkReport[studentData._id]) {
        this.props.getStudentHomeworkReport(studentData._id, reportParams.lessonIds.join('||'))
      } else {
        this.processData()
      }

      if (reportData) {
        let textComponent = reportData.text_component
        for (let i = 0; i < textComponent.length; i++) {
          textComponent[i]['_id'] = Math.random().toString(36)
        }

        this.setState({
          textComponent,
          attendancePercentage: reportData.attendance.percentage,
          attendanceDesc: reportData.attendance.desc,
          pollPercentage: reportData.poll.percentage,
          pollDesc: reportData.poll.desc,
          classParticipationGauge: reportData.class_participation.gauge,
          classParticipationDesc: reportData.class_participation.desc,
          classParticipationComment: reportData.class_participation.comment,
          homeworkPercentage: reportData.homework.percentage,
          homeworkDesc: reportData.homework.desc,
          homeworkComment: reportData.homework.comment
        })
      } else {
        let textComponent = this.state.textComponent
        textComponent[0].title =
          classData?.subject === 'Science' ? "Summary of Child's learning" : 'Key Learning Objectives'
        this.setState({ textComponent })
      }
    }

    if (prevProps.isOpen && !this.props.isOpen) {
      const textComponent = [
        {
          _id: Math.random().toString(36),
          title: 'Key Learning Objectives',
          content: '',
          order: 1
        }
      ]
      this.setState({
        ...initialState,
        textComponent
      })
    }

    if (prevProps.loadingStudentHomeworkReport && !this.props.loadingStudentHomeworkReport) {
      this.processData()
    }

    if (prevProps.creating && !this.props.creating) {
      this.props.close()
    }

    if (prevProps.updating && !this.props.updating) {
      if (this.props.isOpen) {
        this.props.close()
      }
    }

    if (prevState.classParticipationGauge !== this.state.classParticipationGauge) {
      this.setState({
        classParticipationDesc: _.find(CLASS_PARTICIPATION_GAUGE, o => {
          return o.gauge === this.state.classParticipationGauge
        }).desc
      })
    }
  }

  processData = () => {
    const { reportParams, classData, studentData, homeworkReport, reportData } = this.props
    const userLessons = _.filter(classData.lesson, o => {
      const purchased = o.student.includes(studentData._id)
      const included = reportParams.lessonIds.includes(o._id)
      return purchased && included
    })
    const sortedUserLessons = _.orderBy(userLessons, ['scheduled_on'])

    let attendanceData = {
      attendedCount: 0,
      totalLessons: sortedUserLessons.length,
      detail: []
    }
    let pollData = {
      attemptedCount: 0,
      correctCount: 0,
      totalQuestions: 0,
      detail: []
    }
    for (let i = 0; i < sortedUserLessons.length; i++) {
      const lessonData = sortedUserLessons[i]
      const lessonTitle = `Lesson ${lessonData.meta.index}: ${lessonData.name}`

      if (lessonData.attendance?.length > 0) {
        let attendanceRecord = _.filter(lessonData.attendance, o => {
          return o.user_email === studentData.email
        })
        if (attendanceRecord.length > 0) {
          attendanceData.attendedCount++
          attendanceData.detail.push({
            title: lessonTitle,
            attended: true,
            log: attendanceRecord
          })

          // NOTE: only check for poll data if user attended the lesson
          let questionsLaunched = _.filter(lessonData.poll_stat, value => {
            return value.participant_count > 0
          })
          pollData.totalQuestions = pollData.totalQuestions + questionsLaunched.length

          if (lessonData.poll?.length > 0) {
            let pollRecord = _.find(lessonData.poll[0].questions, o => {
              return o.email === studentData.email
            })
            if (pollRecord?.question_details) {
              pollData.attemptedCount = pollData.attemptedCount + pollRecord.question_details.length
              let correctQuestion = _.filter(pollRecord.question_details, o => {
                return o.result.result === 'Correct'
              })
              pollData.correctCount = pollData.correctCount + correctQuestion.length
              pollData.detail.push({
                title: lessonTitle,
                result: `${correctQuestion.length} / ${questionsLaunched.length}`
              })
            } else {
              pollData.detail.push({
                title: lessonTitle,
                result: `0 / ${questionsLaunched.length}`
              })
            }
          }
        } else {
          attendanceData.detail.push({
            title: lessonTitle,
            attended: false
          })
        }
      }
    }

    const studentHomework = homeworkReport[studentData._id] || []
    const submitedHomework = _.filter(studentHomework, o => {
      return o.submission
    })
    const markedHomework = _.filter(studentHomework, o => {
      return o.submission?.status === 'MARKED'
    })
    let homeworkData = {
      submitted: submitedHomework.length,
      marked: markedHomework.length,
      totalAssigned: studentHomework.length,
      detail: _.orderBy(studentHomework, ['homework.lesson.scheduled_on'])
    }

    this.setState({
      systemData: {
        attendance: attendanceData,
        poll: pollData,
        homework: homeworkData
      }
    })

    if (!reportData) {
      let attendancePercentage = (attendanceData.attendedCount / attendanceData.totalLessons) * 100
      let pollPercentage = (pollData.attemptedCount / pollData.totalQuestions) * 100
      let homeworkPercentage = (homeworkData.submitted / homeworkData.totalAssigned) * 100

      this.setState({
        attendancePercentage: Number.isNaN(attendancePercentage) ? 0 : attendancePercentage.toFixed(2),
        attendanceDesc: `Rated out of ${attendanceData.totalLessons} sessions`,
        pollPercentage: Number.isNaN(pollPercentage) ? 0 : pollPercentage.toFixed(2),
        pollDesc:
          "The total score attained from attempting in-class poll questions. Do note that if you'd like to have a better understanding of the questions asked, you may review the poll report that is e-mailed to you weekly",
        homeworkPercentage: Number.isNaN(homeworkPercentage) ? 0 : homeworkPercentage.toFixed(2),
        homeworkDesc: `Rated out of ${homeworkData.totalAssigned} homework assignments`
      })
    }
  }

  handleChange = e => {
    let name = e.target.name
    let value = e.target.value

    if (name === 'completed') {
      value = e.target.checked
    }

    this.setState({ [name]: value })
  }

  handleEditorChange = targetName => e => {
    this.setState({
      [targetName]: e.editor.getData()
    })
  }

  handleTextComponentChange = index => event => {
    let updatedComponent = this.state.textComponent
    let targetName = event?.target?.name

    if (targetName) {
      updatedComponent[index][targetName] = event.target.value
    } else {
      updatedComponent[index]['content'] = event.editor.getData()
    }

    this.setState({
      textComponent: updatedComponent
    })
  }

  addNewTextComponent = () => {
    let { textComponent } = this.state
    textComponent.push({
      _id: Math.random().toString(36),
      title: '',
      content: '',
      order: textComponent.length + 1
    })
    this.setState({ textComponent })
  }

  removeTextComponent = index => () => {
    let { textComponent } = this.state
    if (textComponent.length === 1) return

    let updatedComponent = []
    for (let i = 0; i < textComponent.length; i++) {
      if (index !== i) updatedComponent.push(textComponent[i])
    }

    this.setState({ textComponent: updatedComponent })
  }

  clickSave = () => {
    const { reportParams, studentData, classData, reportData } = this.props
    const {
      systemData,
      completed,
      textComponent,
      attendancePercentage,
      attendanceDesc,
      pollPercentage,
      pollDesc,
      classParticipationGauge,
      classParticipationDesc,
      classParticipationComment,
      homeworkPercentage,
      homeworkDesc,
      homeworkComment
    } = this.state

    for (let i = 0; i < textComponent.length; i++) {
      delete textComponent[i]['_id']
    }

    let formattedReq = {
      title: reportParams.title,
      student: studentData._id,
      status: completed ? 'COMPLETED' : 'INIT',
      class: classData._id,
      text_component: textComponent,
      attendance: {
        percentage: _.isNaN(attendancePercentage) ? 0 : attendancePercentage,
        desc: attendanceDesc,
        detail: systemData.attendance.detail
      },
      poll: {
        percentage: _.isNaN(pollPercentage) ? 0 : pollPercentage,
        desc: pollDesc
      },
      homework: {
        percentage: _.isNaN(homeworkPercentage) ? 0 : homeworkPercentage,
        desc: homeworkDesc,
        comment: homeworkComment
      },
      class_participation: {
        gauge: classParticipationGauge,
        desc: classParticipationDesc,
        comment: classParticipationComment
      }
    }

    if (reportData) {
      delete formattedReq['title']
      delete formattedReq['class']

      // NOTE: only update status to complete when server data is INIT and mark complete by tutor
      if (!completed && reportData.status !== 'INIT') {
        delete formattedReq['status']
      }

      this.props.updateReport('regular', reportData._id, formattedReq)
    } else {
      this.props.createReport(formattedReq)
    }
  }

  render() {
    const { isOpen, reportParams, studentData, reportData, loadingStudentHomeworkReport } = this.props
    const {
      systemData,
      completed,
      textComponent,
      attendancePercentage,
      attendanceDesc,
      pollPercentage,
      pollDesc,
      classParticipationGauge,
      classParticipationDesc,
      classParticipationComment,
      homeworkPercentage,
      homeworkDesc,
      homeworkComment
    } = this.state

    return (
      <Dialog open={isOpen} onClose={this.props.close} maxWidth="lg" fullWidth TransitionComponent={Transition}>
        <DialogTitle className="bg-light-gray">
          <div className="row">
            <div className="col">
              {reportData ? 'Update' : 'Create'} {reportParams?.title} ({studentData?.name})
            </div>
            <div className="col text-right">
              {reportData?.status !== 'COMPLETED' && reportData?.status !== 'RELEASED' && (
                <FormControlLabel
                  control={
                    <Checkbox checked={completed} onChange={this.handleChange} name="completed" color="primary" />
                  }
                  label="Mark Complete"
                />
              )}
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <h3 className="mt-2">Report Component</h3>
          {textComponent.map((component, index) => (
            <div className="row mb-2" key={index}>
              <div className="col">
                <div className="d-flex manage-margin mb-2">
                  <TextField
                    type="text"
                    label="Order"
                    name="order"
                    variant="outlined"
                    onChange={this.handleTextComponentChange(index)}
                    value={component.order}
                  />
                  <TextField
                    type="text"
                    label="Title"
                    name="title"
                    variant="outlined"
                    fullWidth
                    onChange={this.handleTextComponentChange(index)}
                    value={component.title}
                  />
                </div>

                <CKEditor
                  name="content"
                  data={component.content}
                  onChange={this.handleTextComponentChange(index)}
                  config={{
                    toolbarGroups: [
                      { name: 'basicstyles', groups: ['basicstyles'] },
                      { name: 'paragraph', groups: ['list', 'align', 'bidi'] }
                    ]
                  }}
                />
              </div>
              <div className="col-auto">
                <RemoveIcon className="text-danger clickable mt-2" onClick={this.removeTextComponent(index)} />
              </div>
            </div>
          ))}
          <Button variant="outlined" color="primary" className="mb-4" onClick={this.addNewTextComponent}>
            Add New Component
          </Button>

          <div className="d-flex align-items-center mb-3">
            <h3 className="mb-0 mr-2">Attendance</h3>
            {loadingStudentHomeworkReport && <CircularProgress color="primary" size={15} />}
          </div>
          <Accordion className="mb-3">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="bg-light-gray">
              Attendance: Attended {systemData.attendance.attendedCount} out of {systemData.attendance.totalLessons}{' '}
              lessons
            </AccordionSummary>
            <AccordionDetails>
              {systemData?.attendance && (
                <Table size="small">
                  <TableBody>
                    {systemData.attendance.detail.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.title}</TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>{row.attended ? 'Yes' : 'No'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </AccordionDetails>
          </Accordion>

          <div className="row mb-4">
            <div className="col-auto">
              <TextField
                type="text"
                label="Percentage"
                name="attendancePercentage"
                variant="outlined"
                onChange={this.handleChange}
                value={attendancePercentage}
              />
            </div>
            <div className="col">
              <TextField
                type="text"
                label="Descriptor"
                name="attendanceDesc"
                variant="outlined"
                onChange={this.handleChange}
                fullWidth
                value={attendanceDesc}
              />
            </div>
          </div>

          {/*
          <div className="d-flex align-items-center mb-3">
            <h3 className="mb-0 mr-2">Poll</h3>
            {loadingStudentHomeworkReport && <CircularProgress color="primary" size={15} />}
          </div>

          <Accordion className="mb-3">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="bg-light-gray">
              Poll: Attempted {systemData.poll.attemptedCount} out of {systemData.poll.totalQuestions} questions (
              {systemData.poll.correctCount} correct)
            </AccordionSummary>
            <AccordionDetails>
              {systemData?.poll && (
                <Table size="small">
                  <TableBody>
                    {systemData.poll.detail.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.title}</TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>{row.result}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </AccordionDetails>
          </Accordion>

          <div className="row mb-4">
            <div className="col-auto">
              <TextField
                type="text"
                label="Percentage"
                name="pollPercentage"
                variant="outlined"
                onChange={this.handleChange}
                value={pollPercentage}
              />
            </div>
            <div className="col">
              <TextField
                type="text"
                label="Descriptor"
                name="pollDesc"
                variant="outlined"
                onChange={this.handleChange}
                fullWidth
                value={pollDesc}
              />
            </div>
          </div>
          */}

          <div className="d-flex align-items-center mb-3">
            <h3 className="mb-0 mr-2">In Class Participation</h3>
            {loadingStudentHomeworkReport && <CircularProgress color="primary" size={15} />}
          </div>

          <div className="row mb-4">
            <div className="col-auto">
              <FormControl variant="outlined" style={{ minWidth: '250px' }}>
                <InputLabel>Select one</InputLabel>
                <Select
                  name="classParticipationGauge"
                  value={classParticipationGauge}
                  onChange={this.handleChange}
                  label="Select one">
                  {CLASS_PARTICIPATION_GAUGE.map((item, index) => (
                    <MenuItem key={index} value={item.gauge}>
                      {item.gauge}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col">
              <TextField
                type="text"
                label="Descriptor"
                name="classParticipationDesc"
                variant="outlined"
                disabled
                fullWidth
                value={classParticipationDesc}
              />
            </div>
            <div className="col-12 mt-2">
              <CKEditor
                data={classParticipationComment}
                onChange={this.handleEditorChange('classParticipationComment')}
                config={{
                  toolbarGroups: [
                    { name: 'basicstyles', groups: ['basicstyles'] },
                    { name: 'paragraph', groups: ['list', 'align', 'bidi'] }
                  ]
                }}
              />
            </div>
          </div>

          <div className="d-flex align-items-center mb-3">
            <h3 className="mb-0 mr-2">Homework</h3>
            {loadingStudentHomeworkReport && <CircularProgress color="primary" size={15} />}
          </div>
          <Accordion className="mb-3">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="bg-light-gray">
              Homework: Submitted {systemData.homework.submitted} out of {systemData.homework.totalAssigned} homework (
              {systemData.homework.marked} marked)
            </AccordionSummary>
            <AccordionDetails>
              {systemData?.homework && (
                <Table size="small">
                  <TableBody>
                    {systemData.homework.detail.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          Lesson {row.homework.lesson.meta.index}: {row.homework.lesson.name}
                        </TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>{row.submission?.status || 'N/A'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </AccordionDetails>
          </Accordion>

          <div className="row mb-3">
            <div className="col-auto">
              <TextField
                type="text"
                label="Percentage"
                name="homeworkPercentage"
                variant="outlined"
                onChange={this.handleChange}
                value={homeworkPercentage}
              />
            </div>
            <div className="col">
              <TextField
                type="text"
                label="Descriptor"
                name="homeworkDesc"
                variant="outlined"
                onChange={this.handleChange}
                fullWidth
                value={homeworkDesc}
              />
            </div>
            <div className="col-12 mt-2">
              <CKEditor
                data={homeworkComment}
                onChange={this.handleEditorChange('homeworkComment')}
                config={{
                  toolbarGroups: [
                    { name: 'basicstyles', groups: ['basicstyles'] },
                    { name: 'paragraph', groups: ['list', 'align', 'bidi'] }
                  ]
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="default" onClick={this.props.close} disabled={false}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={this.clickSave} disabled={false}>
            Save {false && <CircularProgress className="ml-2" size={15} color="primary" />}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

CreateReportDialog.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired,
  reportParams: Proptypes.object,
  studentData: Proptypes.object,
  classData: Proptypes.object,
  reportData: Proptypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateReportDialog)
