import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Carousel } from 'react-responsive-carousel'
import VisibilitySensor from 'react-visibility-sensor'
import { AccountBox, Videocam } from '@material-ui/icons'
import PropTypes from 'prop-types'
import { Button, Collapse } from '@material-ui/core'
import _ from 'lodash'
import { CLASS_TYPE_RECORDING, CLASS_TYPE_REGULAR_CLASSES, CLASS_TYPE_WORKSHOP } from 'constants/generalTypes'
import ClassPackageSelector from 'components/online-class/select-class-package'
import JoinTrialDialog from 'pages/common/online-class/trial/dialog-join-trial'
import WW3000WordList from 'components/misc/info-ww3000-word-list'
import OxkidsCollaborated from 'components/online-class/misc-partner-oxkids'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const mapStateToProps = state => ({
  shoppingCart: state.shoppingCart.shoppingCart,
  userClassWithDetail: state.common.userClassWithDetail,
  token: state.common.token
})

class ClassIntro extends React.Component {
  state = {
    addToCartVisible: true,
    isJoinTrialDialogOpen: false
  }

  ctaVisibilityChange = isVisible => {
    this.setState({ addToCartVisible: isVisible })
  }

  toggleJoinTrialDialog = () => () => {
    this.setState({
      isJoinTrialDialogOpen: !this.state.isJoinTrialDialogOpen
    })
  }

  render() {
    const { onlineClassItem, shoppingCart, userClassWithDetail } = this.props
    const { addToCartVisible, isJoinTrialDialogOpen } = this.state

    let showTrialButton = true
    let inCart = _.find(shoppingCart.item, ci => {
      return ci.item_id === onlineClassItem._id
    })
    let withClassHistory = userClassWithDetail && userClassWithDetail[onlineClassItem._id]
    if (inCart || withClassHistory || !onlineClassItem.is_trial) {
      showTrialButton = false
    }

    return (
      <>
        <VisibilitySensor onChange={this.ctaVisibilityChange} partialVisibility={true} minTopValue={200}>
          <div className="intro mb-3">
            <div className="row">
              <div className="col-md-3">
                <div className="intro__image tutor-intro">
                  <Carousel
                    showThumbs={false}
                    showIndicators={onlineClassItem.tutor.length > 1 ? true : false}
                    showArrows={true}
                    showStatus={false}>
                    {onlineClassItem.tutor.map((tutorObj, index) => {
                      return (
                        <div key={index} className="row">
                          <div className="col-6 col-md-12">
                            <img src={tutorObj.profile_pic} alt={tutorObj.name} className="img-fluid rounded shadow" />
                          </div>
                          <div
                            className={`col-6 col-md-12 d-flex align-items-center ${
                              onlineClassItem.tutor.length > 1 ? 'pb-4' : ''
                            }`}>
                            <div className="mt-2 mx-auto text-center">
                              <h3 className="mb-0">Teacher {tutorObj.name}</h3>
                              <h3 className="text-muted">{onlineClassItem.subject}</h3>
                              <Button
                                variant="outlined"
                                color="primary"
                                className="mb-2"
                                fullWidth
                                onClick={this.props.scrollToTutorProfileSection}>
                                <AccountBox className="mr-2" />
                                Teacher Profile
                              </Button>
                              {tutorObj.meta?.video?.intro && (
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  fullWidth
                                  onClick={this.props.showIntroVideo(tutorObj.meta?.video?.intro)}>
                                  <Videocam className="mr-2" />
                                  Teacher Intro
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </Carousel>
                </div>
              </div>
              <div className="col-md-9">
                <div className="intro__text">
                  <div className="mb-2">
                    {onlineClassItem.class_type === CLASS_TYPE_REGULAR_CLASSES && (
                      <span className="tag grey-tag--outline">REGULAR CLASS</span>
                    )}
                    {onlineClassItem.class_type === CLASS_TYPE_WORKSHOP && (
                      <span className="tag info-tag--outline">WORKSHOP</span>
                    )}
                    {onlineClassItem.class_type === CLASS_TYPE_RECORDING && (
                      <span className="tag info-tag--outline">RECORDING</span>
                    )}
                  </div>
                  <h2 className="intro__title">{onlineClassItem.name}</h2>

                  <div style={{ position: 'relative' }}>
                    <div className="intro__description" dangerouslySetInnerHTML={{ __html: onlineClassItem.desc }} />
                    <OxkidsCollaborated classId={onlineClassItem._id} />
                    <div className="mb-4">
                      <WW3000WordList classId={onlineClassItem._id} />
                    </div>
                  </div>

                  {onlineClassItem.is_sold_out ? (
                    <Button disabled variant="outlined" className="bg-danger text-white" fullWidth>
                      Class is full
                    </Button>
                  ) : (
                    <div className="d-flex justify-content-end">
                      <ClassPackageSelector onlineClassItem={onlineClassItem} />
                      {showTrialButton && (
                        <Button
                          variant="outlined"
                          color="primary"
                          className="ml-2"
                          onClick={this.toggleJoinTrialDialog()}>
                          Join Trial Lesson
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Collapse in={!addToCartVisible} className="d-md-none fixed-bottom">
              {onlineClassItem.is_sold_out ? (
                <Button disabled variant="outlined" className="bg-danger text-white" fullWidth>
                  Class is full
                </Button>
              ) : (
                <div className="text-center p-1 bg-white border-top">
                  <ClassPackageSelector onlineClassItem={onlineClassItem} variant="button" />
                </div>
              )}
            </Collapse>
          </div>
        </VisibilitySensor>

        <JoinTrialDialog
          isOpen={isJoinTrialDialogOpen}
          toggleDialog={this.toggleJoinTrialDialog()}
          classData={onlineClassItem}
        />
      </>
    )
  }
}

ClassIntro.propTypes = {
  onlineClassItem: PropTypes.object.isRequired
}

export default withRouter(connect(mapStateToProps)(ClassIntro))
