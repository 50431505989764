import React from 'react'
import { connect } from 'react-redux'
import { Paper } from '@material-ui/core'

import agent from 'agent'
import { GET_MY_PAID_CLASSES } from 'constants/actionTypes'
import Pagination from 'utils/Pagination'
import UpdateProfileCTA from 'components/misc/cta-update-profile'
import ClassListing from '../my-class-list'

const mapStateToProps = state => ({
  loadingPaidClass: state.classes.loadingPaidClass,
  paidClasses: state.classes.paidClass
})

const mapDispatchToProps = dispatch => ({
  getOngoingClasses: filter =>
    dispatch({ type: GET_MY_PAID_CLASSES, payload: agent.Classes.getMyClassList('ongoing', filter) })
})

const PER_PAGE = 12

class OngoingClasses extends React.Component {
  componentDidMount() {
    this.getOngoingClasses(0)
  }

  getOngoingClasses = page => {
    this.props.getOngoingClasses({
      max: PER_PAGE,
      offset: page * PER_PAGE
    })
  }

  onChangePage = nextPage => {
    this.getOngoingClasses(nextPage - 1)
  }

  render() {
    const { loadingPaidClass, paidClasses } = this.props

    return (
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <Paper elevation={2} className="app-wrapper">
          <div className="row align-items-center">
            <div className="col">
              <h2 className="mb-0">My Classes</h2>
            </div>
          </div>
        </Paper>

        <div className="app-wrapper">
          <UpdateProfileCTA />

          <ClassListing loading={loadingPaidClass} classList={paidClasses.data} classCount={paidClasses.count} />

          <Pagination itemCount={paidClasses.count} pageSize={PER_PAGE} onChangePage={this.onChangePage} />
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OngoingClasses)
