import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import { Accordion, AccordionSummary, AccordionDetails, Button, CircularProgress, Divider } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import _ from 'lodash'
import moment from 'moment'
import agent from 'agent'
import {
  CONTACT_US,
  GET_MATCHING_CLASSES,
  CALC_PACKAGE_QUOTE,
  GET_PACKAGE_QUOTE,
  ADD_CART_ITEM,
  ADD_CART_ITEM_NOAUTH
} from 'constants/actionTypes'
import { APP, SHOPPING_CART } from 'routes/routes'
import { formatCartItem, calculateQuote } from 'utils/cart'
import GetInTouchDialog from 'components/dialog/get-in-touch'
import OralClinicCTADialog from './dialog-oral-cta'
import ProceedToCheckoutDialog from 'components/online-class/dialog-proceed-to-checkout'
import VideoPlayerDialog from 'components/dialog/video-player'

const mapStateToProps = state => ({
  token: state.common.token,
  matchingClasses: state.onlineClass.matchingClasses,
  contactSuccess: state.misc.contacted,
  userClassWithDetail: state.common.userClassWithDetail,
  lessonPackages: state.shoppingCart.lessonPackages,
  shoppingCart: state.shoppingCart.shoppingCart
})

const mapDispatchToProps = dispatch => ({
  getEligible: query => dispatch({ type: GET_MATCHING_CLASSES, payload: agent.OnlineClass.getList(query, true) }),
  contactUs: reqPayload => dispatch({ type: CONTACT_US, payload: agent.Misc.contactUs(reqPayload) }),
  calcPackageQuote: (quote, packageItem) => dispatch({ type: CALC_PACKAGE_QUOTE, payload: quote, packageItem }),
  getPackageQuote: (payload, packageItem) =>
    dispatch({ type: GET_PACKAGE_QUOTE, payload: agent.OnlineClass.getQuote(payload), packageItem: packageItem }),
  addToCart: item => dispatch({ type: ADD_CART_ITEM, payload: agent.ShoppingCart.addItem(item), cartItem: item }),
  addToLocalCart: item => dispatch({ type: ADD_CART_ITEM_NOAUTH, payload: item })
})

const PRIMARY_ID = 'TeQyzd6aTuuhbRqdnmNCHA' // change to '8oF3bfChToCrtd34hVfHcg' for testing
const KINDERGARDEN_ID = 'suspend-for-oct-onward-sessions'
const PAST_SESSION_ID = 'Ml7l3z5NQpCiftZJ21aPjw' // change to '1zF3tJhISu6Qi-v3IzCddw' for testing
const LEVEL_REGEX = /-/g
const TESTIMONIALS = [
  {
    content:
      'I am very happy to take part in the Ox-Ten book club. Through this book club, I know what are some other recommended books to read and have already borrowed one book (The Giver) and started reading it! I like teacher Celesta’s session because she explained the abridged version of The Giver very clearly.',
    name: 'Tan Shao Jie',
    level: 'Primary Level'
  },
  {
    content:
      'Isabel enjoyed the sessions very much and I think it served its purpose to instil an appreciation for reading. I saw in her NLB app that she recently borrowed one of the book club titles, The Giver. I’m encouraged by the fact that she looked up the title and borrowed the book.',
    name: 'Isabel Hannah Randhawa',
    level: 'Primary Level'
  },
  {
    content: 'I loved how detailed the lesson was! It made me enjoy reading a lot more and it was interesting!',
    name: 'Yu Youning',
    level: 'Primary Level'
  }
]

class BookClubProgrammes extends React.Component {
  state = {
    selectedProgram: 'primary',
    primaryClass: null,
    kindergardenClass: null,
    selectedClass: null,
    pastClass: null,
    nextLessonId: {
      kindergarden: '',
      primary: ''
    },
    expandFullClassDesc: false,
    isGetInTouchDialogOpen: false,
    isCTADialogOpen: false,
    lessonsIncluded: [],
    isProceedToCheckoutDialogOpen: false,
    addedToCart: [],
    purchased: [],
    isVideoDialogOpen: false
  }

  componentDidMount() {
    this.props.getEligible({
      _id: {
        $in: [PRIMARY_ID, KINDERGARDEN_ID, PAST_SESSION_ID]
      }
    })

    if (this.props.userClassWithDetail) {
      let purchased = []
      if (this.props.userClassWithDetail[PRIMARY_ID]?.lesson?.length > 1) {
        purchased.push(PRIMARY_ID)
      }
      if (this.props.userClassWithDetail[KINDERGARDEN_ID]?.lesson?.length > 1) {
        purchased.push(KINDERGARDEN_ID)
      }
      this.setState({ purchased })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.matchingClasses !== this.props.matchingClasses) {
      this.filterOnlineClass()
    }

    if (prevProps.lessonPackages !== this.props.lessonPackages) {
      const { lessonPackages } = this.props
      const { selectedClass } = this.state

      if (selectedClass) {
        let lessonPackage = lessonPackages[selectedClass._id]
        if (lessonPackage) {
          this.addToCart(lessonPackage.item[0])
        }
      }
    }

    if (prevProps.shoppingCart !== this.props.shoppingCart) {
      this.updateCartStatus()
    }
  }

  filterOnlineClass = () => {
    let { matchingClasses } = this.props
    let { selectedProgram } = this.state

    if (!matchingClasses) {
      return
    }

    const primaryClass = _.find(matchingClasses, o => {
      return o._id === PRIMARY_ID
    })

    const pastClass = _.find(matchingClasses, o => {
      return o._id === PAST_SESSION_ID
    })

    //const kindergardenClass = _.find(matchingClasses, o => {
    //  return o._id === KINDERGARDEN_ID
    //})

    let selectedClass = null
    if (selectedProgram === 'primary') {
      selectedClass = primaryClass
    } /*else if (selectedProgram === 'kindergarden') {
      selectedClass = kindergardenClass
    }*/

    //const sortedKindergardenClassLesson = _.orderBy(kindergardenClass.lesson, ['scheduled_on'])
    const sortedPrimaryClassLesson = _.orderBy(primaryClass.lesson, ['scheduled_on'])
    //const nextKindergardenLesson = _.find(sortedKindergardenClassLesson, o => {
    //  return moment(o.scheduled_on) > moment()
    //})
    const nextPrimaryLesson = _.find(sortedPrimaryClassLesson, o => {
      return moment(o.scheduled_on) > moment()
    })

    this.setState({
      primaryClass,
      //kindergardenClass,
      pastClass,
      selectedClass,
      nextLessonId: {
        //kindergarden: nextKindergardenLesson?._id,
        primary: nextPrimaryLesson?._id
      }
    })
  }

  selectProgram = selectedProgram => () => {
    const { primaryClass, kindergardenClass } = this.state
    let selectedClass = null
    if (selectedProgram === 'primary') {
      selectedClass = primaryClass
    } else if (selectedProgram === 'kindergarden') {
      selectedClass = kindergardenClass
    }
    this.setState({ selectedProgram, selectedClass })
  }

  scrollToElement = location => () => {
    const elmnt = document.getElementById(location)
    window.scrollTo({ top: elmnt.offsetTop, behavior: 'smooth' })
  }

  toggleExpandFullClassDesc = () => {
    this.setState({ expandFullClassDesc: !this.state.expandFullClassDesc })
  }

  toggleGetInTouchDialog = () => {
    this.setState({ isGetInTouchDialogOpen: !this.state.isGetInTouchDialogOpen })
  }

  toggleCTADialog = () => {
    this.setState({ isCTADialogOpen: !this.state.isCTADialogOpen })
  }

  toggleProceedToCheckoutDialog = () => {
    this.setState({ isProceedToCheckoutDialogOpen: !this.state.isProceedToCheckoutDialogOpen })
  }

  clickPurchase = () => {
    let { token } = this.props
    let { selectedClass } = this.state

    if (!selectedClass) return

    let lessonsIncluded = _.filter(selectedClass.lesson, o => {
      return moment(o.scheduled_on) > moment()
    })
    lessonsIncluded = _.orderBy(lessonsIncluded, ['scheduled_on'])
    this.setState({ lessonsIncluded })

    if (lessonsIncluded.length > 0) {
      let packageItem = {
        class_id: selectedClass._id,
        count: lessonsIncluded.length,
        options: {
          start_date: lessonsIncluded[0].scheduled_on,
          end_date: lessonsIncluded[lessonsIncluded.length - 1].scheduled_on
        },
        rate: selectedClass.rate,
        title: selectedClass.name
      }

      if (token) {
        this.props.getPackageQuote(
          {
            class_id: selectedClass._id,
            start_date: lessonsIncluded[0].scheduled_on,
            end_date: lessonsIncluded[lessonsIncluded.length - 1].scheduled_on
          },
          packageItem
        )
      } else {
        let quote = calculateQuote(
          lessonsIncluded,
          selectedClass.rate,
          lessonsIncluded[0].scheduled_on,
          lessonsIncluded[lessonsIncluded.length - 1].scheduled_on
        )
        this.props.calcPackageQuote({ data: quote }, packageItem)
      }
    }
  }

  updateCartStatus = () => {
    let addedToCart = []
    for (let i = 0; i < this.props.shoppingCart.item.length; i++) {
      addedToCart.push(this.props.shoppingCart.item[i].item_id)
    }

    this.setState({ addedToCart })
  }

  addToCart = lessonPackage => {
    let { selectedClass } = this.state

    let formattedItem = formatCartItem(selectedClass, 'Class', lessonPackage.packageItem.options)
    if (!!this.props.token) {
      this.props.addToCart(formattedItem)
    } else {
      this.props.addToLocalCart(formattedItem)
    }
    this.toggleProceedToCheckoutDialog()
  }

  goToCart = () => {
    this.toggleProceedToCheckoutDialog()
    let redirectUrl = this.props.token ? `${APP}${SHOPPING_CART}` : SHOPPING_CART
    this.props.history.push(redirectUrl)
  }

  toggleVideoDialog = () => {
    this.setState({ isVideoDialogOpen: !this.state.isVideoDialogOpen })
  }

  render() {
    const {
      selectedProgram,
      selectedClass,
      pastClass,
      nextLessonId,
      isGetInTouchDialogOpen,
      isCTADialogOpen,
      isProceedToCheckoutDialogOpen,
      purchased,
      addedToCart,
      lessonsIncluded,
      isVideoDialogOpen
    } = this.state

    if (!selectedClass) {
      return (
        <div className="manage-margin text-center my-5">
          <CircularProgress size={50} />
        </div>
      )
    }
    const TAB_STYLE = {
      tab: {
        border: '1px solid #dee2e6',
        borderRadius: '10px',
        boxShadow:
          '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
      },
      activeTab: {
        borderBottom: '5px solid #8e51f0',
        borderTop: '1px solid #8e51f0',
        borderRight: '1px solid #8e51f0',
        borderLeft: '1px solid #8e51f0',
        borderRadius: '10px',
        boxShadow:
          '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
      }
    }

    return (
      <div className="landing">
        <Helmet>
          <title>Book Club | Tenopy</title>
          <meta
            name="description"
            content="The Ox-Ten Book Club brings award-winning authors and engaging teachers to discuss one book a week, live online. By bringing stories to life, the sessions interest the child in reading, gain useful knowledge and sharpen language skills."
          />
          <meta
            name="keywords"
            content="book club, award-winning authors, engaging teachers, stories, live online, reading interest, language skills, learn language, vocabulary, writing, knowledge, Ox-Ten"
          />
        </Helmet>
        {/* <section className={`trial__banner-bg--main-vertical ${isMobile ? 'px-0' : ''}`}>
          {!isMobile && <div className="trial__banner-bg--side-vertical" />}
          <div className="container h-100">
            <div className="row align-items-center h-100 mb-3 mb-md-0">
              <div className="col-md-5 col-6">
                <span className="tag amber-tag text-capitalize font-weight-semibold " style={{ color: '#29303b' }}>
                  New
                </span>
                <p style={{ fontSize: '20px' }}>
                  Ox-Ten Book Club is going offline! Tenopy and Agora CoLearning would like to invite your child to
                  spend this Youth Day holiday with us for an immersive story-reading journey. Your kid may now interact
                  directly in the engaging quizzes, a live discussion, and more fun activities.
                </p>
                <p style={{ fontSize: '20px' }}>
                  Join us on <strong>Monday, July 4th</strong> (Youth Day school holiday) from{' '}
                  <strong>10am to 12pm.</strong>
                </p>

                <p style={{ fontSize: '20px' }}>
                  Seats are limited. Reserve yours now at
                  <a href="https://bit.ly/agora-book-club"> https://bit.ly/agora-book-club</a>
                </p>
              </div>
            </div>
          </div>
        </section> */}

        {moment() < moment('2022-09-04T00:00:00Z') && (
          <section className="trial__banner-bg--main-vertical pt-4 mb-10">
            <div className="trial__banner-bg--side-vertical d-md-block d-none" />
            <div className="container h-100">
              <div className="row align-items-center h-100 mb-3 mb-md-0">
                <div className="col-md-5 col-12">
                  <span className="tag amber-tag text-capitalize font-weight-semibold " style={{ color: '#29303b' }}>
                    New
                  </span>
                  <div className="text-justify text-md-left">
                    <p style={{ fontSize: '17px' }}>
                    Wishing to inspire your child to pick up the habit of reading? Look no further! Join us in our weekly Ox-Ten book club where we invite authors and teachers to take your child on an immersive story-reading journey. We would explore themes and literary techniques in a fun and engaging manner intriguing your child to find out more!
                    </p>
                    <p style={{ fontSize: '17px' }}>
                      Join us on <strong>Saturday, 3 September</strong>
                      <strong>, 10am to 12pm.</strong>
                    </p>
                    <p style={{ fontSize: '16px' }}>
                     Seats are limited so reserve yours now at 
                      <a href="https://bit.ly/3PU2LQo"> https://bit.ly/3PU2LQo</a>
                    </p>
                    <div
                      className="d-flex align-items-center justify-content-center d-md-none d-block mb-10"
                      style={{
                        backgroundImage:
                          "url('https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/amazing_ash_3_sep.jpeg')",
                        backgroundSize: 'contain',
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                        minHeight: '300px',
                        width: '100%',
                        height: '100%',
                        marginTop: '-35px'
                      }}></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {moment() < moment('2022-09-04T00:00:00Z') && (
          <>
            <section className="py-4 d-md-none d-block">
              <div className="container py-4"></div>
            </section>
            <section className="py-4 d-md-none d-block">
              <div className="container py-4"></div>
            </section>
          </>
        )}

        <section className="trial__banner-bg--main pt-4">
          <div className="trial__banner-bg--side d-md-block d-none" />
          <div className="container h-100">
            <div className="row align-items-center h-100 mb-3 mb-md-0">
              <div className="col-md-6 col-12">
                <span className="tag amber-tag text-capitalize font-weight-semibold mt-6" style={{ color: '#29303b' }}>
                  New
                </span>
                <h1 className="title--rubik">Welcome to OX-TEN Book Club!</h1>
                <p>
                  Nothing beats avid reading in mastering a language. Our book club brings award-winning authors and
                  engaging teachers to discuss one book a week, live online.
                </p>
                <p>By bringing stories to life, the weekly sessions:</p>
                <ul>
                  <li>Interest the child to finish reading the discussed book</li>
                  <li>Impart knowledge and wisdom</li>
                  <li>Sharpen the child’s language skills in vocabulary and writing</li>
                </ul>
                <div
                  className="d-flex align-items-center justify-content-center d-md-none d-block"
                  style={{
                    backgroundImage:
                      "url('https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/student/main_background.jpg')",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    minHeight: '200px',
                    marginBottom: '1rem'
                  }}></div>
                <p>
                  The books include globally renowned works in both fiction and non-fiction, as well as local works on
                  highly relatable topics close to home.
                </p>
                <div className="d-flex">
                  <Button
                    size={isMobile ? 'medium' : 'large'}
                    variant="contained"
                    color="primary"
                    className="mr-2 mt-3"
                    onClick={this.scrollToElement('book-club')}>
                    Get Started
                  </Button>
                  <Button
                    size={isMobile ? 'medium' : 'large'}
                    variant="outlined"
                    color="primary"
                    className="mr-2 mt-3"
                    onClick={this.toggleVideoDialog}>
                    Preview
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="book-club"
          style={{
            backgroundImage:
              'radial-gradient( circle farthest-corner at 10% 20%,  rgba(215,223,252,1) 0%, rgba(255,255,255,1) 0%, rgba(215,223,252,1) 84% )'
          }}>
          <div className="container">
            <div className="container">
              {false && (
                <>
                  <div className="section__header mb-5">
                    <div
                      className="title title--rubik mb-2"
                      style={{
                        fontSize: '2.3rem',
                        fontWeight: '400',
                        fontFamily: "'Rubik', sans-serif",
                        lineHeight: 1.2
                      }}>
                      Select a level
                    </div>
                    <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #0cc652' }} />
                  </div>
                  <div className="row justify-content-center mb-5">
                    <div className="col-12 col-md-4 mb-2">
                      <div
                        className="d-flex flex-row h-100 align-items-center clickable p-2 bg-white"
                        style={selectedProgram === 'primary' ? TAB_STYLE.activeTab : TAB_STYLE.tab}
                        onClick={this.selectProgram('primary')}>
                        <img
                          src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/misc/oral-workshop-icon.png"
                          width="70px"
                          height="70px"
                          alt="oral workshop"
                        />
                        <div className="ml-2">
                          <h3 className="title--rubik mb-0">Primary Level</h3>
                          <div className="text-muted text-small">(Age 8 - 12)</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 mb-2">
                      <div
                        className="d-flex flex-row h-100 align-items-center clickable p-2 bg-white"
                        style={selectedProgram === 'kindergarden' ? TAB_STYLE.activeTab : TAB_STYLE.tab}
                        onClick={this.selectProgram('kindergarden')}>
                        <img
                          src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/misc/oral-clinic-icon.png"
                          width="70px"
                          height="70px"
                          alt="oral clinic"
                        />
                        <div className="ml-2">
                          <h3 className="title--rubik mb-0">Junior Level</h3>
                          <div className="text-muted text-small">(Age 5 - 7)</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {selectedClass && (
                <div className="row">
                  <div className="col-12">
                    <div
                      className="bg-white py-2 px-4"
                      style={{
                        borderRadius: '10px',
                        borderBottom: '10px solid #8e51f0',
                        borderTop: '1px solid #8e51f0',
                        borderRight: '1px solid #8e51f0',
                        borderLeft: '1px solid #8e51f0'
                      }}>
                      <div className="row align-items-center">
                        <div className="col-md-auto col-12">
                          {/* alex: to change back when book club starts - name of class */}
                          {/* <h1 className="title--rubik mb-1">{selectedClass.name}</h1> */}
                          <h1 className="title--rubik mb-1">Book Club</h1>
                        </div>
                        <div className="col-md col-12 text-right">
                          <div className="my-3">
                            {/* alex: to change back - price of class */}
                            {/* <div className="class__price d-flex align-items-end justify-content-end">
                              <h1 className="text-primary mb-0">$ {selectedClass.rate}</h1>
                            </div>
                            <small>per sessions</small> */}
                          </div>
                        </div>
                      </div>
                      <Divider />
                      <div className="mt-2">
                        <div className="text-muted mb-2">
                          <small>About Class</small>
                        </div>
                        {/* alex: to change back - date of class */}
                        {/* <div className="d-flex align-items-center  mb-2">
                          <i className="material-icons text-muted mr-2">event</i>
                          {moment(selectedClass.start_date).format('Do MMM')}
                          &nbsp;-&nbsp;
                          {moment(selectedClass.end_date).format('Do MMM YYYY')}
                        </div>
                        <div className="d-flex align-items-center  mb-2">
                          <i className="material-icons text-muted mr-2">access_time</i>
                          Every {selectedClass.schedule.date_of_week},&nbsp;
                          {moment(selectedClass.schedule.start_time).format('h:mm a')}
                          &nbsp;to&nbsp;
                          {moment(selectedClass.schedule.end_time).format('h:mm a')}
                        </div> */}
                        <div className="d-flex align-items-md-center align-items-tstart  mb-3">
                          <i className="material-icons text-muted mr-2">people</i>
                          {`Suitable for ${selectedClass.level.join(', ').replace(LEVEL_REGEX, ' ')}`}
                        </div>
                        <p className="text-justify" dangerouslySetInnerHTML={{ __html: selectedClass.desc }} />
                      </div>
                      <div className="text-center mt-3 px-md-5 px-0">
                        {/* alex: to change back - purchase class button*/}
                        {/* {purchased.includes(selectedClass._id) ? (
                          <Button fullWidth variant="contained" size="large" className="mb-2" disabled>
                            Class Purchased
                          </Button>
                        ) : (
                          <>
                            {addedToCart.includes(selectedClass._id) ? (
                              <Button fullWidth variant="contained" color="primary" onClick={this.goToCart}>
                                Checkout
                              </Button>
                            ) : (
                              <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={this.clickPurchase}>
                                Purchase Remaining Sessions
                              </Button>
                            )}
                          </>
                        )} */}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="text-center mt-4">
                      {/* <h2 className="title--rubik">2022 Jan - Mar Sessions (past)</h2> */}
                      <h2 className="title--rubik">2022 Sessions</h2>
                    </div>
                    {_.orderBy(selectedClass.lesson, 'meta.index').map((lesson, index) => {
                      const pastSession = moment() > moment(lesson.scheduled_on)
                      let expanded = false
                      let tbc = false
                      if (lesson._id === nextLessonId[selectedProgram]) {
                        expanded = true
                      }
                      if (lesson.name.includes('TBC')) {
                        tbc = true
                      }

                      return (
                        <Accordion key={index} className="w-100" defaultExpanded={expanded}>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                              <div className="h3 title--rubik mt-md-0 mt-1 mb-0">
                                {tbc ? (
                                  <span className="text-muted font-italic">to be announced</span>
                                ) : (
                                  <>
                                    {lesson.meta.custom_title ? (
                                      <div dangerouslySetInnerHTML={{ __html: lesson.meta.custom_title }} />
                                    ) : (
                                      <>{lesson.name}</>
                                    )}
                                  </>
                                )}
                              </div>
                              {/* <div className={`tag ${pastSession ? 'light-tag' : 'primary-tag'}`}>
                                {moment(lesson.scheduled_on).format('Do MMM YYYY')}
                              </div> */}
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            {tbc ? (
                              <span>To be announced.</span>
                            ) : (
                              <div dangerouslySetInnerHTML={{ __html: lesson.desc }} />
                            )}
                          </AccordionDetails>
                        </Accordion>
                      )
                    })}
                  </div>
                  <div className="col-12">
                    <div className="text-center mt-4">
                      {/* <h2 className="title--rubik">2021 Oct - Dec Sessions (past)</h2> */}
                      <h2 className="title--rubik">2021 Sessions</h2>
                    </div>
                    {_.orderBy(pastClass.lesson, 'meta.index').map((lesson, index) => (
                      <Accordion key={index} className="w-100">
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          {lesson.meta.custom_title ? (
                            <div dangerouslySetInnerHTML={{ __html: lesson.meta.custom_title }} />
                          ) : (
                            <>{lesson.name}</>
                          )}
                        </AccordionSummary>
                        <AccordionDetails>
                          <div dangerouslySetInnerHTML={{ __html: lesson.desc }} />
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        <section className="bg-white" id="read-more">
          <div className="container">
            <div className="section__header mb-3">
              <div
                className="title title--rubik mb-2"
                style={{
                  fontSize: '2.3rem',
                  fontWeight: '400',
                  fontFamily: "'Rubik', sans-serif",
                  lineHeight: 1.2
                }}>
                About the Book Club
              </div>
              <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #0cc652' }} />
            </div>
            <div className="row justify-content-center">
              <div className="col-12 text-large">
                <p>
                  In each live online session, the speaker will take book club members through the most exciting
                  highlights of the book, discuss how the stories relate to our own lives, and highlight good use of
                  words and phrases in the book for appreciation of the language.
                </p>
                <p>
                  The book club members will engage with the speaker using on-screen quizzes, selective invitation to
                  speak, and free flow participation in chat.
                </p>
                <p>
                  The Ox-Ten Book Club is brought to you by Tenopy, the leading online education company in Singapore,
                  and Ox-Kids, an education consultancy based in Singapore.
                </p>
                <div className="text-center">
                  <video
                    className="w-100"
                    controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                    controlsList="nodownload">
                    <source
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/video-assets/book-club-preview.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-light-gray">
          <div className="container">
            <div className="section__header mb-5">
              <div
                className="title mb-2"
                style={{ fontSize: '2.3rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif" }}>
                What they said
              </div>
              <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #8089ff' }}></div>
            </div>
            <div className="row">
              {TESTIMONIALS.map((testi, index) => (
                <div className="col-md-4 col-12" key={index}>
                  <div className="testimonial-item">
                    <div className="quote-bubble">{testi.content}</div>
                    <p className="testimonial-user">
                      <span>{testi.name}</span>
                      <small>- {testi.level}</small>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="bg-white">
          <div className="container">
            <div className="row align-items-center">
              <div className="col">
                <h1 className="text-primary font-weight-normal mb-0">Ready to explore the world of books?</h1>
                <span style={{ fontSize: '18px' }}>Join us today!</span>
              </div>
              <div className="col-auto my-3 my-md-0">
                <Button
                  variant="contained"
                  color="primary"
                  className="mr-2"
                  onClick={this.scrollToElement('book-club')}>
                  Get Started
                </Button>
                <Button variant="outlined" color="primary" onClick={this.toggleGetInTouchDialog}>
                  Get in Touch
                </Button>
              </div>
            </div>
          </div>
        </section>
        <GetInTouchDialog
          isOpen={isGetInTouchDialogOpen}
          close={this.toggleGetInTouchDialog}
          intent="Conversion Page"
        />
        <OralClinicCTADialog isOpen={isCTADialogOpen} close={this.toggleCTADialog} />
        <ProceedToCheckoutDialog
          isOpen={isProceedToCheckoutDialogOpen}
          toggleDialog={this.toggleProceedToCheckoutDialog}
          proceedToCheckout={this.goToCart}
          classTitle={selectedClass?.name}
          lessonsIncluded={lessonsIncluded}
        />
        <VideoPlayerDialog
          videoURL="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/video-assets/book-club-preview.mp4"
          isOpen={isVideoDialogOpen}
          close={this.toggleVideoDialog}
        />
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BookClubProgrammes))
