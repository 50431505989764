import React from 'react'
import { connect } from 'react-redux'
import { Button, CircularProgress, Paper } from '@material-ui/core'

import agent from 'agent'
import { GET_CLASS_REPORTS, GET_REPORT_PDF } from 'constants/actionTypes'
import PreviewReportDialog from 'components/online-class/dialog-preview-report'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  selectedClass: state.classes.selectedClass,
  loadingClassReports: state.report.loadingClassReports,
  classReports: state.report.classReports,
  loadingPDF: state.report.loadingPDF
})

const mapDispatchToProps = dispatch => ({
  getClassReport: classId => dispatch({ type: GET_CLASS_REPORTS, payload: agent.Report.getByClass(classId), classId }),
  getReportPDF: (reportId, fileName) =>
    dispatch({ type: GET_REPORT_PDF, payload: agent.Report.getPDF(reportId), fileName })
})

class TabReport extends React.Component {
  state = {
    isPreviewDialogOpen: false,
    selectedReport: null
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.activeView && this.props.activeView) {
      const { selectedClass } = this.props
      this.props.getClassReport(selectedClass._id)
    }
  }

  togglePreviewDialog = reportData => () => {
    this.setState({
      isPreviewDialogOpen: !this.state.isPreviewDialogOpen,
      selectedReport: reportData
    })
  }

  clickDownloadPDF = reportData => () => {
    const { currentUser } = this.props
    this.props.getReportPDF(reportData._id, `${reportData.title} - ${currentUser.name}.pdf`)
  }

  render() {
    const { currentUser, loadingClassReports, classReports, loadingPDF } = this.props
    const { isPreviewDialogOpen, selectedReport } = this.state

    return (
      <div className="animated slideInUpTiny animation-duration-3">
        {loadingClassReports && (
          <div className="text-center my-5">
            <CircularProgress size={25} color="primary" />
          </div>
        )}

        {classReports.data.map(report => (
          <Paper className="p-3" elevation={2} key={report._id}>
            <div className="d-flex align-items-center justify-content-between">
              <h3 className="title--rubik mb-0">{report.title}</h3>
              <div className="manage-margin">
                <Button variant="contained" color="primary" onClick={this.togglePreviewDialog(report)}>
                  View
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={this.clickDownloadPDF(report)}
                  disabled={loadingPDF}>
                  Download PDF {loadingPDF && <CircularProgress color="primary" className="ml-2" size={15} />}
                </Button>
              </div>
            </div>
          </Paper>
        ))}

        <PreviewReportDialog
          isOpen={isPreviewDialogOpen}
          close={this.togglePreviewDialog()}
          studentData={currentUser}
          reportData={selectedReport}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabReport)
