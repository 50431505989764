import FileSaver from 'file-saver'
import _ from 'lodash'
import * as Type from 'constants/actionTypes'

const initialState = {
  loadingClassReports: false,
  classReports: { data: [], classId: '', homework: {} },
  loadingReports: false,
  reportList: [],
  creating: false,
  updating: false,
  loadingStudentHomeworkReport: false,
  loadingPDF: false,
  releasingReports: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.GET_CLASS_REPORTS:
      return {
        ...state,
        loadingClassReports: true,
        classReports: { data: [], classId: '', homework: {} }
      }

    case Type.GET_CLASS_REPORTS_SUCCESS:
      return {
        ...state,
        loadingClassReports: false,
        classReports: {
          data: action.payload.data,
          classId: action.classId,
          homework: {} // NOTE: set this to empty obj when class report refreshed bcz nt handling any pagination now
        }
      }

    case Type.GET_CLASS_REPORTS_FAILED:
      return {
        ...state,
        loadingClassReports: false
      }

    case Type.GET_REPORT_LIST:
      return {
        ...state,
        loadingReports: true,
        reportList: []
      }

    case Type.GET_REPORT_LIST_SUCCESS:
      return {
        ...state,
        loadingReports: false,
        reportList: action.payload.data
      }

    case Type.GET_REPORT_LIST_FAILED:
      return {
        ...state,
        loadingReports: false
      }

    case Type.GET_REPORT_PDF:
      return {
        ...state,
        loadingPDF: true
      }

    case Type.GET_REPORT_PDF_SUCCESS: {
      FileSaver.saveAs(action.payload, action.fileName)
      return {
        ...state,
        loadingPDF: false
      }
    }

    case Type.GET_REPORT_PDF_FAILED:
      return {
        ...state,
        loadingPDF: false
      }

    case Type.GET_STUDENT_HOMEWORK_REPORT:
      return {
        ...state,
        loadingStudentHomeworkReport: true
      }

    case Type.GET_STUDENT_HOMEWORK_REPORT_SUCCESS: {
      const studentId = action.payload.student
      let updatedHomeworkReport = state.classReports.homework
      updatedHomeworkReport[studentId] = action.payload.data
      return {
        ...state,
        loadingStudentHomeworkReport: false,
        classReports: {
          ...state.classReports,
          homework: updatedHomeworkReport
        }
      }
    }

    case Type.GET_STUDENT_HOMEWORK_REPORT_FAILED:
      return {
        ...state,
        loadingStudentHomeworkReport: false
      }

    case Type.CREATE_REPORT:
      return {
        ...state,
        creating: true
      }

    case Type.CREATE_REPORT_SUCCESS: {
      let updatedClassReports = state.classReports
      updatedClassReports.data.push(action.payload.data)

      return {
        ...state,
        creating: false,
        classReports: updatedClassReports
      }
    }

    case Type.CREATE_REPORT_FAILED:
      return {
        ...state,
        creating: false
      }

    case Type.UPDATE_REPORT:
      return {
        ...state,
        updating: true
      }

    case Type.UPDATE_REPORT_SUCCESS: {
      let updatedClassReports = state.classReports
      const updatedReportId = action.payload.data._id

      const existingReportIndex = _.findIndex(state.classReports.data, o => {
        return o._id === updatedReportId
      })
      if (existingReportIndex > -1) {
        updatedClassReports.data[existingReportIndex] = action.payload.data
      }

      return {
        ...state,
        updating: false,
        classReports: updatedClassReports
      }
    }

    case Type.UPDATE_REPORT_FAILED:
      return {
        ...state,
        updating: false
      }

    case Type.RELEASE_COMPLETED_REPORTS:
      return {
        ...state,
        releasingReports: true
      }

    case Type.RELEASE_COMPLETED_REPORTS_SUCCESS:
    case Type.RELEASE_COMPLETED_REPORTS_FAILED:
      return {
        ...state,
        releasingReports: false
      }

    case Type.LOGOUT:
      return initialState

    default:
      return state
  }
}
