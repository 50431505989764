import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Avatar from '@material-ui/core/Avatar'
import { getInitials } from '../../../utils/misc'
import moment from 'moment'
import _ from 'lodash'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  userClassWithDetail: state.common.userClassWithDetail
})

const mapDispatchToProps = dispatch => ({})

// for tutor or TA usage

const ClassItem = props => {
  let { _id, name, subject, tutor, student, is_video_product, video_student } = props.classData
  let { className, isTutor, isNewClass, isNewTrialClass, isNewFreeClass, currentUser } = props
  let classDetails = {}
  if (!!props.userClassWithDetail) {
    classDetails = props.userClassWithDetail.hasOwnProperty(_id) ? props.userClassWithDetail[_id] : {}
  }
  let userLessons = classDetails.hasOwnProperty('lesson') ? classDetails.lesson : null

  if (!tutor) {
    tutor = {
      name: 'Class Tutor'
    }
  }

  let lessons = _.filter(props.classData.lesson, c => {
    return c.is_active
  })
  let now = moment()
  let mostRecent = null
  let closest = null
  let purchased = null

  _.forEach(lessons, ll => {
    if (!mostRecent) {
      mostRecent = ll
    } else if (moment(ll.scheduled_on) <= now && moment(ll.scheduled_on) > moment(mostRecent.scheduled_on)) {
      mostRecent = ll
    }

    if (!closest && moment(ll.scheduled_on) >= now) {
      closest = ll
    } else if (!!closest && moment(ll.scheduled_on) < moment(closest.scheduled_on) && moment(ll.scheduled_on) >= now) {
      closest = ll
    }
  })

  if (!!closest) {
    if (!!userLessons) {
      purchased = userLessons.includes(closest._id)
    }
  }

  let showStatus = true
  if (currentUser) {
    if (is_video_product) {
      let isVideoStudent = video_student.includes(currentUser._id)
      let isNormalStudent = student.includes(currentUser._id)
      if (isVideoStudent && !isNormalStudent) {
        showStatus = false
      }
    }
  }

  return (
    <div className={classNames('class__item', className, subject)} onClick={props.viewClass}>
      {isNewFreeClass && (
        <div className="ribbon ribbon-top-right">
          <span>Free</span>
        </div>
      )}
      {isNewTrialClass && (
        <div className="ribbon ribbon-top-right">
          <span>Trial</span>
        </div>
      )}
      {isNewClass && (
        <div className="ribbon ribbon-top-right">
          <span>New</span>
        </div>
      )}
      <div className={`class__content ${showStatus ? '' : 'pb-2'}`}>
        <div className={classNames('subject-tag', subject)}>{subject}</div>
        <div className={!!closest ? 'class__title mb-0' : 'class__title'}>{name}</div>
        {!!closest && (
          <div className="text-muted px-3 mb-3">
            <small>{moment(closest.scheduled_on).format('dddd, h:mma')}</small>
          </div>
        )}
        {isTutor ? (
          <div className="d-flex align-items-center flex-wrap mt-auto px-3">
            <Avatar className="bg-blue">
              <h3 className="m-0 text-white">{student.length}</h3>
            </Avatar>
            <div className="d-inline-block ml-2 font-weight-semibold">students</div>
          </div>
        ) : (
          <div className="row px-3 mt-auto">
            {tutor.map((item, index) => {
              return (
                <div key={index} className="col-auto text-center">
                  {item.profile_pic ? (
                    <Avatar className="size-60 mx-auto" alt={item.name} src={item.profile_pic} />
                  ) : (
                    <Avatar className="size-60 mx-auto bg-blue">
                      <h3 className="m-0 text-white">{getInitials(item.name)}</h3>
                    </Avatar>
                  )}
                  <div className="user-detail">
                    <p className="user-name text-capitalize">{item.name}</p>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
      {showStatus && (
        <React.Fragment>
          {!!mostRecent && now >= moment(mostRecent.scheduled_on) && now <= moment(mostRecent.ended_on) ? (
            <div className={'mt-2 px-3 py-2 text-white bg-warning'}>
              <strong>Currently in session</strong>
            </div>
          ) : !!closest ? (
            purchased === false ? (
              <div className={'mt-2 px-3 py-2 text-danger font-weight-semibold'}>Renewal Required</div>
            ) : (
              <div
                className={
                  moment(closest.scheduled_on).diff(now, 'days', true) < 2
                    ? 'mt-2 px-3 py-2 text-white bg-primary'
                    : 'mt-2 px-3 py-2'
                }>
                Next lesson in <strong>{now.to(closest.scheduled_on, true)}</strong>
              </div>
            )
          ) : (
            <div className={'mt-2 px-3 py-2 bg-danger text-white'}>
              <strong>Class Ended</strong>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

ClassItem.propTypes = {
  classData: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassItem)
