import React from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import agent from '../../../agent'
import { FORGOT_PASSWORD } from '../../../routes/routes'
import { LOGIN } from '../../../constants/actionTypes'

import * as Sentry from '@sentry/browser'
import {
  Button,
  CircularProgress,
  IconButton,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  TextField
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Visibility, VisibilityOff } from '@material-ui/icons'

const mapStateToProps = state => ({
  token: state.common.token,
  inProgress: state.auth.inProgress,
  errors: state.auth.errors
})

const mapDispatchToProps = dispatch => ({
  login: (loginObj, next) => dispatch({ type: LOGIN, payload: agent.Auth.login(loginObj), next, disableRedirect: true })
})

class Login extends React.Component {
  state = {
    email: '',
    password: '',
    errorMsg: '',
    next: '',
    isPasswordVisible: ''
  }

  componentDidUpdate(prevProps) {
    if (prevProps.inProgress && !this.props.inProgress) {
      let { errors } = this.props
      if (!!errors && errors.errors && errors.errors.length > 0) {
        if (errors.errors[0].message === 'Invalid login credential') {
          this.setState({
            errorMsg: 'You have entered the wrong email or password'
          })
        } else {
          //NOTE: This line shall handle all kind of general login issue, include "Account locked".
          //Add in more specific errorMsg handling if necessary
          this.setState({
            errorMsg: 'There seems to be an issue logging in. Contact us for help.'
          })
          Sentry.captureEvent({
            message: 'LOGIN_FAILED',
            level: 'error',
            extra: errors
          })
        }
      }
    }
  }

  handleChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState({ [name]: value })
  }

  clickLogin = () => {
    this.setState({ errorMsg: '' })
    let loginObj = {
      email: this.state.email,
      password: this.state.password
    }
    this.props.login(loginObj, this.state.next)
  }

  togglePasswordVisibility = () => {
    this.setState({
      isPasswordVisible: !this.state.isPasswordVisible
    })
  }

  render() {
    const { inProgress, buttonText } = this.props
    const { email, password, errorMsg, isPasswordVisible } = this.state

    return (
      <div>
        {errorMsg && (
          <Alert severity="error" className="mb-3">
            {errorMsg}
          </Alert>
        )}
        <div className="app-login-form">
          <TextField
            label="Email"
            name="email"
            fullWidth
            onChange={this.handleChange}
            value={email}
            margin="normal"
            className="mt-1 my-sm-3"
          />

          <FormControl className="mt-1 my-sm-3" fullWidth>
            <InputLabel>Password</InputLabel>
            <Input
              type={isPasswordVisible ? 'text' : 'password'}
              value={password}
              name="password"
              onChange={event => this.setState({ password: event.target.value })}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="Toggle password visibility" onClick={this.togglePasswordVisibility}>
                    {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            onClick={this.clickLogin}
            disabled={inProgress}
            fullWidth={true}
            className="my-2">
            {buttonText} {inProgress && <CircularProgress size={24} className="ml-2" />}
          </Button>

          <div className="d-flex align-items-center justify-content-between">
            <Link to={FORGOT_PASSWORD} id="forgot-password" title="Reset Password">
              Forgot your password?
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

Login.propTypes = {
  buttonText: PropTypes.string
}

Login.defaultProps = {
  buttonText: 'Continue to checkout'
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
