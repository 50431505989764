import React from 'react'
import { connect } from 'react-redux'
import { Clear, DeleteOutline } from '@material-ui/icons'
import _ from 'lodash'
import PropTypes from 'prop-types'

import agent from 'agent'
import { REMOVE_CART_ITEM, REMOVE_CART_ITEM_NOAUTH, EMPTY_CART, EMPTY_CART_NOAUTH } from 'constants/actionTypes'

const mapStateToProps = state => ({
  token: state.common.token,
  shoppingCart: state.shoppingCart.shoppingCart
})

const mapDispatchToProps = dispatch => ({
  removeFromCart: item_id => dispatch({ type: REMOVE_CART_ITEM, payload: agent.ShoppingCart.removeItem(item_id) }),
  removeFromLocalCart: class_id => dispatch({ type: REMOVE_CART_ITEM_NOAUTH, payload: class_id }),
  emptyCart: () => dispatch({ type: EMPTY_CART, payload: agent.ShoppingCart.clearCart() }),
  emptyLocalCart: () => dispatch({ type: EMPTY_CART_NOAUTH })
})

class RemoveItemsButton extends React.Component {
  removeItem = () => {
    const { token, classId, shoppingCart } = this.props
    if (token) {
      let cartItem = _.find(shoppingCart.item, o => {
        return o.item_id === classId
      })
      this.props.removeFromCart(cartItem._id)
    } else {
      this.props.removeFromLocalCart(classId)
    }
  }

  removeAllItems = () => {
    const { token } = this.props
    if (token) {
      this.props.emptyCart()
    } else {
      this.props.emptyLocalCart()
    }
  }

  render() {
    const { action } = this.props

    if (action === 'clear') {
      return (
        <div
          onClick={this.removeAllItems}
          className="d-flex align-items-center justify-content-end text-muted clickable hover--danger">
          Clear Cart <DeleteOutline className="ml-1" style={{ fontSize: '22px' }} />
        </div>
      )
    }

    return (
      <Clear
        className="clickable hover--danger"
        style={{ fontSize: '20px', marginBottom: '3px' }}
        onClick={this.removeItem}
      />
    )
  }
}

RemoveItemsButton.propTypes = {
  classId: PropTypes.string,
  action: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveItemsButton)
