import React from 'react'
import { Helmet } from 'react-helmet'

class AboutUs extends React.Component {
  render() {
    return (
      <div className="landing">
        <Helmet>
          <title>Why Tenopy? | Tenopy</title>
          <meta
            name="description"
            content="We are a team of full-time educators, software engineers, business and operations people innovating to help students learn in the best ways."
          />
          <meta
            name="keywords"
            content="Online tuition,Primary school tuition,Online tuition singapore,Online learning,Online education,primary school Education,Online tutor,Home tutor,home tuition,sa1,sa2,ca1,ca2,psle,online study,online learning,convenient education,quality education,fun education,interactive education,about us,about tenopy"
          />
        </Helmet>

        <section
          data-scrim-top="9"
          className="bg-img"
          style={{
            backgroundImage:
              'url(https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/team.jpg)'
          }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 text-center">
                <h1 className="fs-60 fw-600 text-white">Why Tenopy</h1>
                <p className="mb-6 fs-20 text-white">
                  “We took the best of traditional classroom tutoring and made it better with technology.” <br />
                  Founding CEO Chong Kian
                </p>
              </div>
            </div>
          </div>
          <div className="oval-divider--bottom oval-divider--1"></div>
        </section>
        <section className="pt-5">
          <div className="container">
            <div className="section__header mb-5">
              <div
                className="title mb-2"
                style={{ fontSize: '2.2rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif", lineHeight: 1.2 }}>
                The Tenopy Parent
              </div>
              <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #0cc652' }}></div>
            </div>

            <div className="row justify-content-center">
              <div className="col-10 text-justify">
                <p>
                  With Tenopy’s live online tutoring, parents no longer have to choose between tiring commutes to
                  classes and settling for what’s best in the neighbourhood. We make top tutoring programmes accessible
                  from the comfort of home, conducted by top educators in the field, while keeping it affordable.
                  Parents can also be kept fully abreast of their child’s learning by sitting in during lessons.
                </p>
                <p>
                  <span className="font-weight-semibold">Top Quality Teaching:</span> With former NIE Assistant Dean Dr
                  Tan Liang See working with our dynamic team of teachers, parents can be assured of the quality of our
                  teaching. All curriculum and teaching materials are crafted in-house with the aim of fostering
                  critical thinking skills. These skills, in turn, help students navigate new topics and challenges with
                  greater ease and independence.
                </p>
                <p>
                  <span className="font-weight-semibold">Convenience:</span> Using just a laptop and internet
                  connection, students can attend scheduled classes in online classrooms while at home, visiting the
                  grandparents or even while travelling overseas. Parents can enjoy the freedom of not having to
                  schedule their lives around tuition classes, without compromising the quality of teaching.
                </p>
                <p>
                  <span className="font-weight-semibold">Flexible Parental Involvement:</span> From regular qualitative
                  feedback, lesson replays, homework reviews, to sitting next to your child at lesson time, we make our
                  classroom experiences truly transparent to parents. Parents choose the level of involvement they want.
                </p>
              </div>
            </div>

            <div className="section__header m-5">
              <div
                className="title mb-2"
                style={{ fontSize: '2.2rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif", lineHeight: 1.2 }}>
                The Tenopy Child
              </div>
              <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #0cc652' }}></div>
            </div>

            <div className="row justify-content-center">
              <div className="col-10 text-justify">
                <p>
                  As educators, we appreciate that every child has their own unique learning needs and aptitudes.
                  Combining technology and great teaching, students receive personalized instruction suited to their
                  learning pace, while having a “front row” view in a familiar environment at every class.
                </p>
                <p>
                  <span className="font-weight-semibold">Personalized Instruction:</span> At Tenopy, we make classes
                  personal. By monitoring a child's response to our interactive teaching content on screen, we conduct
                  our classes with differentiated instruction. Weaker students are taken through more basic thinking
                  steps while stronger students are stimulated with more advanced content in the same class.
                </p>
                <p>
                  <span className="font-weight-semibold">Comfort:</span> After a long day at school, the last thing a
                  child needs is yet another lesson in a classroom setting. By being able to attend classes in the
                  comfort of home, students come refreshed and ready to learn.
                </p>
                <p>
                  <span className="font-weight-semibold">Strong Engagement:</span> With “front row seats” at every
                  class, students are not only able to engage with teachers through live audio or text chat, they can
                  also interact with their classmates during class discussions. Through Tenopy’s online platform,
                  teaching becomes more creative and interactive, keeping students interested and focused.
                </p>
                <p>
                  <span className="font-weight-semibold">Ease of Learning:</span> Proprietary learning materials are
                  delivered ahead of time so students can better prepare for lessons. They can also easily submit
                  homework online to be personally graded by our tutors with differentiated instructions and feedback
                  each week.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default AboutUs
