import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { DropdownSection } from './components'

import { NavLink } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { ALL_CLASSES, BOOK_CLUB, WW_3000 } from 'routes/routes'

const DevelopersDropdownEl = styled.div`
  width: 100%;
`

const CatalogueDropdown = props => {
  let { classCategories } = props
  //let promoSection = classCategories?.promo

  return (
    <DevelopersDropdownEl onMouseLeave={props.onMouseLeave}>
      <DropdownSection data-first-dropdown-section>
        <div className="d-flex" tabIndex="-1">
          <div>
            {classCategories ? (
              <div className="d-flex" tabIndex="-1">
                {Object.keys(classCategories.classes).map((categoryLabel, key) => (
                  <div className="navbar-classes-menu-column" key={key} style={{ borderRight: '1px solid #eaeaea' }}>
                    <div className="navbar-subtitle" style={{ borderBottom: '1px solid #eaeaea' }}>
                      {categoryLabel}
                    </div>
                    {Object.keys(classCategories.classes[categoryLabel]).map((classLabel, key) => (
                      <NavLink
                        to={ALL_CLASSES}
                        key={key}
                        className="navbar-link"
                        onClick={() => {
                          props.setOnlineClassFilter({
                            level: classCategories.classes[categoryLabel][classLabel],
                            status: 'Ongoing',
                            subject: 'All',
                            type: '2023'
                          })
                          props.exitDropdown()
                        }}>
                        {classLabel}
                      </NavLink>
                    ))}
                  </div>
                ))}
                <div className="navbar-classes-menu-column" style={{ borderRight: '1px solid #eaeaea' }}>
                  <div className="navbar-subtitle" style={{ borderBottom: '1px solid #eaeaea' }}>
                    Featuring
                  </div>
                  {/* <NavLink
                    to={BOOK_CLUB}
                    className="navbar-link"
                    onClick={() => {
                      props.exitDropdown()
                    }}>
                    Book Club
                    {moment() < moment('2022-09-04T00:00:00Z') && (
                    <span className="tag amber-tag text-capitalize font-weight-semibold " style={{ color: '#29303b', marginLeft: '2px' }}>
                       New
                    </span>
                    )}
                  </NavLink> */}
                  {/* {moment() < moment('2023-01-01T00:00:00Z') && ( */}
                  <NavLink
                    to={WW_3000}
                    className="navbar-link"
                    onClick={() => {
                      props.exitDropdown()
                    }}>
                    Wordly Wise i3000
                  </NavLink>
                  {/* )} */}
                </div>
              </div>
            ) : null}
            <div className="navbar-explore-all-container">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  props.setOnlineClassFilter({
                    level: 'All',
                    status: 'Ongoing',
                    subject: 'All',
                    type: '2023'
                  })
                  props.goToPage(ALL_CLASSES)()
                  props.exitDropdown()
                }}>
                Explore All Classes
              </Button>
            </div>
          </div>
          {/*promoSection?.title && (
            <div
              className="navbar-classes-menu-column navbar-classes-menu-ad align-items-center"
              style={{ backgroundImage: promoSection.background_css }}>
              <span dangerouslySetInnerHTML={{ __html: promoSection.title }} />
              {promoSection.button_text && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    props.goToPage(promoSection.button_action)()
                    props.exitDropdown()
                  }}>
                  {promoSection.button_text}
                </Button>
              )}
              {promoSection.decoration_img && (
                <img src={promoSection.decoration_img} className="img-fluid" alt="promo" />
              )}
            </div>
          )*/}
          <div
            className="navbar-classes-menu-column navbar-classes-menu-ad align-items-center justify-content-between"
            style={{
              backgroundImage:
                'linear-gradient( 109.8deg, rgba(62,5,116,1) -5.2%, rgba(41,14,151,1) -5.2%, rgba(216,68,148,1) 103.3% )'
            }}>
            <div className="my-2">Tenopy 2023 Classes!</div>
            <img
              src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/nav-online-learning.png"
              className="img-fluid"
              alt="workshop"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                props.setOnlineClassFilter({
                  level: 'All',
                  status: 'Ongoing',
                  subject: 'All',
                  type: '2023'
                })
                props.goToPage('/online-class?t=2023')()
                props.exitDropdown()
              }}>
              View
            </Button>
          </div>
        </div>
      </DropdownSection>
    </DevelopersDropdownEl>
  )
}

export default CatalogueDropdown
