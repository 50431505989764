import React from 'react'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import Lightbox from 'react-images'
import { Avatar, Paper, Grid, Container, Box } from '@material-ui/core'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineDot from '@mui/lab/TimelineDot'
// import FastfoodIcon from '@mui/icons-material/Fastfood';
// import LaptopMacIcon from '@mui/icons-material/LaptopMac';
// import HotelIcon from '@mui/icons-material/Hotel';
// import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography'

import CTADivider from 'components/misc/cta-divider'
import MediaFeaturedCarousel from 'components/misc/media-featured-carousel'
import VideoPlayerDialog from 'components/dialog/video-player'
// import Timeline from './timeline'

const TeamGallery = [
  { src: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/team-gallery-1.jpg' },
  { src: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/team-gallery-2.jpg' },
  { src: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/team-gallery-3.jpg' },
  { src: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/team-gallery-4.jpg' }
]

class AboutUs extends React.Component {
  state = {
    isLightBoxOpen: false,
    currentImageIndex: 0,
    isVideoDialogOpen: false
  }

  openLightbox = index => {
    this.setState({
      currentImageIndex: index,
      isLightBoxOpen: true
    })
  }

  closeLightbox = () => {
    this.setState({ isLightBoxOpen: false })
  }

  goToPrevious = () => {
    this.setState({ currentImageIndex: this.state.currentImageIndex - 1 })
  }

  goToNext = () => {
    this.setState({ currentImageIndex: this.state.currentImageIndex + 1 })
  }

  toggleVideoDialog = () => {
    this.setState({
      isVideoDialogOpen: !this.state.isVideoDialogOpen
    })
  }

  render() {
    let { currentImageIndex, isLightBoxOpen, isVideoDialogOpen } = this.state

    return (
      <div className="landing">
        <Helmet>
          <title>About Us | Tenopy</title>
          <meta
            name="description"
            content="A combination of quality education and good technology is what makes Tenopy Singapore's leader in innovating live online teaching. The Tenopy team have a common passion to make learning engaging and empowering for all Singapore students."
          />
          <meta
            name="keywords"
            content="Online tuition, Online tuition Singapore, Singapore online tuition, Tenopy, Tenopy, tuition, Tenopy online tuition, Tenopy Singapore, live online tuition"
          />
        </Helmet>

        <section
          data-scrim-top="9"
          className="bg-img"
          style={{
            padding: `${isMobile ? '4rem 0 5.5rem 0' : '6rem 0'}`,
            backgroundImage:
              'radial-gradient( circle farthest-corner at 10% 20%,  rgba(254,113,210,1) 0%, rgba(184,96,255,1) 90% )'
          }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 text-center">
                <h1 className="text-white title--rubik">About Tenopy</h1>
                <p className="text-white text-large">We believe every child is unique</p>
              </div>
            </div>
          </div>
          <div className="oval-divider--bottom oval-divider--1"></div>
        </section>

        <section className="pt-0 pt-md-5">
          <div className="container">
            <div className="row">
              {/* <div className="col-md-7 col-12">
                <div
                  style={{ boxShadow: '0 18px 38px rgba(0,0,0,0.2)', padding: '10px', position: 'relative' }}
                  onClick={this.toggleVideoDialog}>
                  <img
                    src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/video-assets/cover/tenopy-cares.jpg"
                    alt="video cover"
                    className="img-fluid"
                  />
                  <div
                    className="sonar-wrapper w-100"
                    style={{ position: 'absolute', top: 'calc(50% - 75px', right: '20px' }}>
                    <i className="material-icons sonar-play--sm">play_circle_filled</i>
                    <div className="sonar-emitter--sm">
                      <div className="sonar-wave"></div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-md-12 col-12 mt-md-0 mt-5">
                <p className="text-justify">
                  Tenopy is Singapore’s leader in innovating live online teaching in tutoring classes. We have done this
                  for 3 years with thousands of students, before online education became a trend in 2020.
                </p>
                <p className="text-justify">
                  In our process of helping students achieve results, we want to groom our children to be thinkers,
                  creators and leaders of tomorrow, in their own fields.
                </p>
                <p className="text-justify">
                  This mission shapes our teaching approach. We take young minds on a journey in our classes to:
                </p>
                <div className="d-flex flex-row mb-3">
                  <div className="mr-2">
                    <img
                      src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/check.png"
                      alt=""
                      width="20"
                      height="20"
                    />
                  </div>
                  <div>to attempt with courage</div>
                </div>
                <div className="d-flex flex-row mb-3">
                  <div className="mr-2">
                    <img
                      src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/check.png"
                      alt=""
                      width="20"
                      height="20"
                    />
                  </div>
                  <div>to question with curiosity and</div>
                </div>
                <div className="d-flex flex-row mb-3">
                  <div className="mr-2">
                    <img
                      src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/check.png"
                      alt=""
                      width="20"
                      height="20"
                    />
                  </div>
                  <div>to understand through self-discovery</div>
                </div>
                <p>We enable young minds, and help them achieve.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-0 pt-md-5">
          <div className="container">
            <div className="row d-lg-block d-none">
              <Box sx={{ textAlign: 'center', mb: 3 }}>
                <Typography sx={{ fontSize: '48px', fontFamily: 'Rubik', color: '#1a1f6b' }}>
                  {' '}
                  TENOPY TIMELINE
                </Typography>
              </Box>
              <hr style={{ marginBottom: '55px', backgroundColor: '#1a1f6b' }} />
              <Timeline position="alternate">
                <TimelineItem>
                  <TimelineOppositeContent
                    sx={{ mt: -3, fontFamily: 'Rubik' }}
                    align="right"
                    variant="h4"
                    color="#1a1f6b">
                    <b>2017</b>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    {/* <TimelineConnector /> */}
                    <TimelineDot sx={{ backgroundColor: '#d4ae5e' }}>
                      <img
                        src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/white-circle.png"
                        alt=""
                        width="30"
                        height="30"
                      />
                    </TimelineDot>
                    <TimelineConnector sx={{ mt: -1.4, mb: 5, border: '2px solid #d4ae5e' }} />
                  </TimelineSeparator>

                  <TimelineContent
                    sx={{
                      // ml: '-15px',
                      mt: '-40px',
                      py: '20px'
                    }}>
                    <Box sx={{ pr: { md: 5, lg: 15 } }}>
                      <Typography
                        variant="h6"
                        component="span"
                        sx={{ fontWeight: 'bold', color: '#1a1f6b', fontFamily: 'Rubik' }}>
                        First to innovate online learning
                      </Typography>
                      <hr className="rdottedhr" />
                      <Box sx={{ fontFamily: 'Rubik' }}>
                        <ul className="timelineul">
                          <li>Creation of learning management system for student's ease of use</li>
                          <li>Easily accessible homework portal to facilitate submission, marking & comments.</li>
                        </ul>
                      </Box>
                    </Box>
                  </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot sx={{ backgroundColor: '#cd8753', mt: -5 }}>
                      <img
                        src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/white-circle.png"
                        alt=""
                        width="30"
                        height="30"
                      />
                    </TimelineDot>
                    <TimelineConnector sx={{ mt: -1.4, mb: 4, border: '2px solid #cd8753' }} />
                  </TimelineSeparator>
                  <TimelineContent
                    sx={{
                      mt: '-100px',
                      py: '20px',
                      px: 2
                    }}>
                    <Box sx={{ pl: { md: 5, lg: 15 } }}>
                      <TimelineOppositeContent
                        align="right"
                        variant="h6"
                        sx={{ fontWeight: 'bold', color: '#1a1f6b', fontFamily: 'Rubik' }}>
                        Differentiated Instruction
                      </TimelineOppositeContent>
                      <hr className="ldottedhr" />
                      <TimelineOppositeContent align="right" sx={{ fontFamily: 'Rubik', color: '#000' }}>
                        Improved and innovated our curriculum with experts in the field to ensure differentiated
                        instructions so that no child is left behind
                      </TimelineOppositeContent>
                    </Box>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    {/* <TimelineConnector /> */}
                    <TimelineDot sx={{ backgroundColor: '#dc7466', mt: -5 }}>
                      <img
                        src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/white-circle.png"
                        alt=""
                        width="30"
                        height="30"
                      />
                    </TimelineDot>
                    <TimelineConnector sx={{ mt: -1.4, mb: 8, border: '2px solid #dc7466' }} />
                  </TimelineSeparator>
                  <TimelineContent
                    sx={{
                      // ml: '-15px',
                      mt: '-90px',
                      py: '20px',
                      px: 2
                    }}>
                    {' '}
                    <Box sx={{ pr: { md: 5, lg: 15 } }}>
                      <Typography
                        variant="h6"
                        component="span"
                        sx={{ fontWeight: 'bold', color: '#1a1f6b', fontFamily: 'Rubik' }}>
                        Online Teaching pedagogy
                      </Typography>
                      <hr className="rdottedhr" />
                      <Box sx={{ fontFamily: 'Rubik' }}>
                        <ul className="timelineul">
                          <li>
                            Created our proprietary online teaching methodology to ensure engagement and differentiation{' '}
                          </li>
                          <li>All teachers go through rigorous training to ensure quality </li>
                        </ul>
                      </Box>{' '}
                    </Box>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    {/* <TimelineConnector sx={{ bgcolor: 'secondary.main' }} /> */}
                    <TimelineDot sx={{ backgroundColor: '#a4564e', mt: -8 }}>
                      <img
                        src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/white-circle.png"
                        alt=""
                        width="30"
                        height="30"
                      />
                    </TimelineDot>
                    <TimelineConnector sx={{ mt: -1.4, mb: 3, border: '2px solid #a4564e' }} />
                  </TimelineSeparator>
                  <TimelineContent
                    sx={{
                      // ml: '-15px',
                      mt: '-155px',
                      py: '20px',
                      px: 2
                    }}>
                    {' '}
                    <Box sx={{ pl: { md: 5, lg: 15 } }}>
                      <TimelineOppositeContent
                        align="right"
                        variant="h6"
                        sx={{ fontWeight: 'bold', color: '#1a1f6b', fontFamily: 'Rubik' }}>
                        Curriculum to encourage critical thinking
                      </TimelineOppositeContent>
                      <hr className="ldottedhr" />
                      <TimelineOppositeContent align="right" sx={{ fontFamily: 'Rubik', color: '#000' }}>
                        Launched enrichment programmes to constantly expand the child's knowledge and deepen their
                        critical thinking skills
                      </TimelineOppositeContent>
                    </Box>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    {/* <TimelineConnector /> */}
                    <TimelineDot sx={{ backgroundColor: '#c06780', mt: -5 }}>
                      <img
                        src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/white-circle.png"
                        alt=""
                        width="30"
                        height="30"
                      />
                    </TimelineDot>
                    <TimelineConnector sx={{ my: -1.4, mb: 2, border: '2px solid #c06780' }} />
                  </TimelineSeparator>
                  <TimelineContent
                    sx={{
                      // ml: '-15px',
                      mt: '-90px',
                      py: '20px',
                      px: 2
                    }}>
                    {' '}
                    <Box sx={{ pr: { md: 5, lg: 15 } }}>
                      <Typography
                        variant="h6"
                        component="span"
                        sx={{ fontWeight: 'bold', color: '#1a1f6b', fontFamily: 'Rubik' }}>
                        Expert sharing
                      </Typography>
                      <hr className="rdottedhr" style={{ marginLeft: '-15px' }} />
                      <Box sx={{ fontFamily: 'Rubik', color: '#000' }}>
                        Launched our free 'expert sharing' where experts in their field share their experiences and tips
                        and trick for parents and students to succeed
                      </Box>
                    </Box>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    {/* <TimelineConnector sx={{ bgcolor: 'secondary.main' }} /> */}
                    <TimelineDot sx={{ backgroundColor: '#963f51', mt: -3 }}>
                      <img
                        src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/white-circle.png"
                        alt=""
                        width="30"
                        height="30"
                      />
                    </TimelineDot>
                    <TimelineConnector sx={{ mt: -1.4, mb: 10, border: '2px solid #963f51' }} />
                  </TimelineSeparator>
                  <TimelineContent
                    sx={{
                      // ml: '-15px',
                      mt: '-115px',
                      py: '20px',
                      px: 2
                    }}>
                    {' '}
                    <Box sx={{ pl: { md: 5, lg: 15 } }}>
                      <TimelineOppositeContent
                        align="right"
                        variant="h6"
                        sx={{ fontWeight: 'bold', color: '#1a1f6b', fontFamily: 'Rubik' }}>
                        First to create and launch our proprietary online classroom
                      </TimelineOppositeContent>
                      <hr className="ldottedhr" />
                      <TimelineOppositeContent align="right" sx={{ fontFamily: 'Rubik', color: '#000' }}>
                        <ul className="timelineul">
                          <li>Integrated data tracking methodologies and features to ensure data driven learning</li>
                          <li> Developed features for instant feedback during activities</li>
                        </ul>
                      </TimelineOppositeContent>
                    </Box>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    {/* <TimelineConnector /> */}
                    <TimelineDot sx={{ backgroundColor: '#6a5a7d', mt: -10 }}>
                      <img
                        src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/white-circle.png"
                        alt=""
                        width="30"
                        height="30"
                      />
                    </TimelineDot>
                    <TimelineConnector sx={{ mt: -1.4, mb: 5, border: '2px solid #6a5a7d' }} />
                  </TimelineSeparator>
                  <TimelineContent
                    sx={{
                      // ml: '-15px',
                      mt: '-130px',
                      py: '20px',
                      px: 2
                    }}>
                    {' '}
                    <Box sx={{ pr: { md: 5, lg: 15 } }}>
                      <Typography
                        variant="h6"
                        component="span"
                        sx={{ fontWeight: 'bold', color: '#1a1f6b', fontFamily: 'Rubik' }}>
                        Data Driven Learning
                      </Typography>
                      <hr className="rdottedhr" />
                      <Box sx={{ fontFamily: 'Rubik', color: '#000' }}>
                        Perfected our data collection and data tracking model to continually improve our analysis of
                        students' needs
                      </Box>
                    </Box>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent
                    sx={{ mt: 1, fontFamily: 'Rubik' }}
                    align="right"
                    variant="h4"
                    color="#1a1f6b">
                    <b>2023</b>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot sx={{ backgroundColor: '#242266', mt: -5 }}>
                      <img
                        src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/white-circle.png"
                        alt=""
                        width="30"
                        height="30"
                      />
                    </TimelineDot>
                    <hr className="dashedhr" style={{ width: '25px', marginTop: '-2px' }} />
                    <hr className="dashedhr" style={{ width: '25px', marginTop: '-3px' }} />
                    <hr className="dashedhr" style={{ width: '25px', marginTop: '-3px' }} />
                    <hr className="dashedhr" style={{ width: '25px', marginTop: '-3px' }} />
                    <hr className="dashedhr" style={{ width: '25px', marginTop: '-3px' }} />
                    <hr className="dashedhr" style={{ width: '25px', marginTop: '-3px' }} />
                  </TimelineSeparator>

                  <TimelineContent
                    sx={{
                      // ml: '-15px',
                      mt: '-85px',
                      py: '5px',
                      px: 2
                    }}>
                    {' '}
                    <Box sx={{ pl: { md: 5, lg: 15 } }}>
                      <TimelineOppositeContent
                        align="right"
                        variant="h6"
                        sx={{ fontWeight: 'bold', color: '#1a1f6b', fontFamily: 'Rubik' }}>
                        Small, Targeted Classes
                      </TimelineOppositeContent>
                      <hr className="ldottedhr" />
                      <TimelineOppositeContent align="right" sx={{ fontFamily: 'Rubik', color: '#000' }}>
                        Classes are capped at a maximum of 5 with full on screen interaction, so that teachers can
                        provide students with individualised help.
                      </TimelineOppositeContent>
                    </Box>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </div>
          </div>
        </section>

        {/* MOBILE VERSIOn */}
        <section className="pt-0 pt-md-5">
          <div className="container">
            <div className="row d-block d-lg-none">
              <img
                src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/tenopy_timeline_final.jpg"
                alt=""
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </section>
        {/* <section>
          <div className="container">
            <div className="section__header mb-4 mb-md-4">
              <div
                className="title mb-2"
                style={{ fontSize: '2.3rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif", lineHeight: 1.2 }}>
                Founder
              </div>
              <div className="mx-auto" style={{ maxWidth: '70px', borderBottom: '3px solid #9b51e0' }} />
            </div>

            <div className="row justify-content-center">
              <div className="col-12">
                <div className="row">
                  {isMobile ? (
                    <div className="col-12">
                      <div className="d-flex flex-row justify-content-center mb-3">
                        <Avatar
                          className="size-120 avatar-shadow mr-3"
                          alt="Soh Chong Kian"
                          src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/about-us/founder-ck.jpg"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="col-auto align-self-end">
                      <img
                        src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/about-us/founder-ck-hb.png"
                        alt="Soh Chong Kian"
                        width="280"
                      />
                    </div>
                  )}

                  <div className="col align-self-end">
                    <p className="text-justify">
                      Stemming from childhood where he found a passion for Math and later on won a medal for Singapore
                      at the International Math Olympiad, Founder Chong Kian (CK) deeply appreciated how learning should
                      be about developing the mind to think, mastering the skills and applying them in new problems and
                      situations. The correct way of learning, not only can handle any form of school assessment and
                      exams well, but will build the foundation of skills and character to do well later in life.
                    </p>
                    <p className="text-justify mb-0">
                      After graduating from Stanford, CK served in the Singapore government and thereafter started an
                      entrepreneurship journey. CK founded Tenopy wanting to enable every child to learn the right way,
                      by enabling teachers with technology and data analytics to do what traditional classroom learning
                      cannot – create differentiated learning paths for each student.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="bg-light-gray">
          <div className="container">
            <div className="section__header mb-5">
              <div
                className="title mb-2"
                style={{ fontSize: '2.3rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif", lineHeight: 1.2 }}>
                Advisory Board
              </div>
              <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #f08d02' }}></div>
            </div>

            <div className="row justify-content-center">
              <div className="col-md-10 col-12">
                <Paper className="p-3 mb-3">
                  <div className="row justify-content-center">
                    <div className="col-md-auto col-12">
                      <Avatar
                        className="size-120 avatar-shadow ml-md-2 mx-auto mb-3"
                        src="https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/tutors/tan-liang-see.jpg"
                        alt="Dr Tan Liang See"
                      />
                    </div>
                    <div className="col">
                      <h2 className="mb-1">Dr Tan Liang See</h2>
                      <div className="d-flex">
                        <i className="material-icons text-muted mr-1">keyboard_arrow_right</i>
                        <p className="mb-0">
                          Former Assistant Dean at Office of Education Research, National Institute of Education
                        </p>
                      </div>
                      <div className="d-flex">
                        <i className="material-icons text-muted mr-1">keyboard_arrow_right</i>
                        <p>Head of GEP consortium, Hwa Chong institution</p>
                      </div>

                      <div className="label text-muted text-small">Description</div>
                      <p>
                        Locally and globally recognized thought leader in fostering creativity and developing talents in
                        young learners.
                      </p>
                      <p>
                        Dr Tan currently works with Tenopy to put in place a highly actionable teaching approach that
                        engages students and encourages critical thinking.
                      </p>
                    </div>
                  </div>
                </Paper>

                <Paper className="p-3 mb-3">
                  <div className="row justify-content-center">
                    <div className="col-md-auto col-12">
                      <Avatar
                        className="size-120 avatar-shadow ml-md-2 mx-auto mb-3"
                        src="https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/frontend/us/profile-loh-cheng-yi.png"
                        alt="Loh Cheng Yee"
                      />
                    </div>
                    <div className="col">
                      <h2 className="mb-1">Loh Cheng Yee</h2>
                      <div className="d-flex">
                        <i className="material-icons text-muted mr-1">keyboard_arrow_right</i>
                        <p className="mb-0">Trainer and Exam Setter for Math Olympiad</p>
                      </div>
                      <div className="d-flex">
                        <i className="material-icons text-muted mr-1">keyboard_arrow_right</i>
                        <p>Author of "Challenge your Brain: GEP and Math Olympiad Questions" and "Math Whizz" series</p>
                      </div>

                      <div className="label text-muted text-small">Description</div>
                      <p>
                        From veteran teacher in top schools like Hwa Chong Institution and Catholic High School, to Math
                        Olympiad trainer, exam setter and celebrated author of several Math assessment series.
                      </p>
                      <p>
                        Ms Loh leverages her wealth of knowledge and decades of experience in Tenopy’s Math curriculum
                        and teaching development.
                      </p>
                    </div>
                  </div>
                </Paper>

                <Paper className="p-3">
                  <div className="row justify-content-center">
                    <div className="col-md-auto col-12">
                      <Avatar
                        className="size-120 avatar-shadow ml-md-2 mx-auto mb-3"
                        src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/about/advisor-tom-collin.jpg"
                        alt="Tom Collin"
                      />
                    </div>
                    <div className="col">
                      <h2 className="mb-1">Tom Collin</h2>
                      <div className="d-flex">
                        <i className="material-icons text-muted mr-1">keyboard_arrow_right</i>
                        <p>
                          Chairman of the Board at ILA Vietnam (ILA is Vietnam's leading English language training
                          organisation. It teaches over 20,000 students per year).{' '}
                        </p>
                      </div>

                      <div className="label text-muted text-small">Description</div>
                      <p>
                        With an illustrious career globally in the management and operations of educational entities, Mr
                        Tom Collins has built and invested in numerous schools across Asia.
                      </p>
                      <p>
                        He currently consults with Tenopy to establish organizational effectiveness in running an
                        education company with high quality and scalability.
                      </p>
                    </div>
                  </div>
                </Paper>
              </div>
            </div>
          </div>
        </section> */}

        <section className="bg__dotted d-none">
          <div className="container">
            <div className="section__header mb-5">
              <div
                className="title mb-2"
                style={{ fontSize: '2.3rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif" }}>
                The Tenopy Team
              </div>
              <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #8089ff' }}></div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-10 col-12">
                <p className="text-justify">
                  A team of dedicated educators, curriculum developers, software engineers and data analysts, the Tenopy
                  team came together with a common passion to make learning an engaging and empowering process for
                  Singapore students. Combining quality education with good technology, they strive to transform the
                  tuition landscape in Singapore while keeping students at the heart of it all.
                </p>
              </div>
              <div className="col-md-10 col-12">
                <div className="row">
                  {TeamGallery.map((team, index) => (
                    <div className="col-md-auto col-12 text-center mb-2" key={index}>
                      <img
                        src={team.src}
                        alt="team gallery"
                        className="gallery-img"
                        onClick={() => {
                          this.openLightbox(index)
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        <CTADivider />

        <MediaFeaturedCarousel />

        <Lightbox
          images={TeamGallery}
          isOpen={isLightBoxOpen}
          onClickPrev={this.goToPrevious}
          onClickNext={this.goToNext}
          onClose={this.closeLightbox}
          currentImage={currentImageIndex}
        />

        <VideoPlayerDialog
          isOpen={isVideoDialogOpen}
          close={this.toggleVideoDialog}
          videoURL="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/video-assets/tenopy-cares.mp4"
        />
      </div>
    )
  }
}

export default AboutUs
