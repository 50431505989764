import React from 'react'
import { withRouter } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { Avatar, Button, Dialog, DialogContent, Input, Slide } from '@material-ui/core'
import Proptypes from 'prop-types'
import { FREE_SHARING_VIDEO } from 'routes/routes'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

class SharePastSession extends React.Component {
  copyShareURL = () => {
    var copyText = document.getElementById('share-url-input')
    copyText.select()
    copyText.setSelectionRange(0, 99999) // NOTE: For mobile devices
    document.execCommand('copy')
  }

  render() {
    let { eventData, isOpen } = this.props

    if (!eventData) {
      return null
    }

    let tutorData = eventData.host_by ?? {
      name: 'Tenopy tutor',
      profile_pic: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/tutors/placeholder.jpg'
    }

    let eventName = eventData.name
    if (eventData.meta?.series?.name) {
      eventName = `${eventData.meta?.series?.name} Ep ${eventData.meta?.series?.order}: ${eventData.name}`
    }

    const shareURL = `${process.env.REACT_APP_HOSTNAME}${FREE_SHARING_VIDEO.replace(
      ':eventId',
      eventData._id
    )}?utm_source=website&utm_medium=share&utm_campaign=free%20sharing`

    return (
      <Dialog open={isOpen} onClose={this.props.close} TransitionComponent={Transition} maxWidth="md">
        <DialogContent>
          <div className="row align-items-center" style={{ overflow: 'hidden' }}>
            <div className={`col-md-7 col-12 ${isMobile ? 'border-bottom' : 'border-right'}`}>
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="d-flex align-items-center">
                    <Avatar className="size-60 avatar-shadow mr-2" alt={tutorData.name} src={tutorData.profile_pic} />
                    <div>
                      <h3 className="mb-0">{eventName}</h3>
                      <span>by {tutorData.name}</span>
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="label text-small text-muted mb-2">Description</div>
                  <div dangerouslySetInnerHTML={{ __html: eventData.desc }} />
                </div>
              </div>
            </div>
            <div className="col-md-5 col-12 text-center px-4">
              <h2 className="title--rubik">Share it if useful!</h2>
              <p className="text-large">Share this free session with your friends by copying the following link.</p>
              <Input
                id="share-url-input"
                className="text-primary"
                defaultValue={shareURL}
                readOnly
                fullWidth
                multiline
              />
              <div className="my-3">
                <Button color="primary" variant="contained" onClick={this.copyShareURL}>
                  Copy Link
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

SharePastSession.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired,
  eventData: Proptypes.object
}

export default withRouter(SharePastSession)
