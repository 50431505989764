import React from 'react'
import { isMobileOnly, isTablet } from 'react-device-detect'
import { Carousel } from 'react-responsive-carousel'
import { Avatar } from '@material-ui/core'
import _ from 'lodash'

const FEATURED_TEACHER = [
  {
    name: 'Esther',
    subject: 'English',
    profile_img: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/teacher/esther-ng.jpg',
    shortText:
      '<p>Well-liked by students for her clear, detailed explanations while being engaging and fun, Teacher Esther has over 10 years of teaching in both <span class="text-primary clickable">read more</span></p>',
    fullText:
      '<p>Well-liked by students for her clear, detailed explanations while being engaging and fun, Teacher Esther has over 10 years of teaching in both Singapore and American schools, making her an expert in simplifying complex techniques and equipping your child with the skills they need to succeed. She believes in a language education that inculcates a love for language and reading. <span class="text-primary clickable">read less</span></p>'
  },
  // {
  //   name: 'Seok Chin',
  //   subject: 'Mathematics',
  //   profile_img: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/teacher/ng-seok-chin.jpg',
  //   shortText:
  //     '<p>Teacher Seok Chin is adored by her students for her ability to clarify their doubts and communicate effective methods that translate into results. Her <span class="text-primary clickable">read more</span></p>',
  //   fullText:
  //     '<p>Teacher Seok Chin is adored by her students for her ability to clarify their doubts and communicate effective methods that translate into results. Her experience in teaching for 10 years and developing curriculum places her in a unique position to prepare and support students. Teacher Seok Chin believes that a good teacher is someone who can teach students to love learning. <span class="text-primary clickable">read less</span></p>'
  // },
  {
    name: 'Grace',
    subject: 'Science',
    profile_img: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/teacher/grace.jpg',
    shortText:
      '<p>Teacher Grace is praised for her kind and encouraging manner in the classroom. An NIE-trained educator with a Bachelor of Science from Imperial College <span class="text-primary clickable">read more</span></p>',
    fullText:
      '<p>Teacher Grace is praised for her kind and encouraging manner in the classroom. An NIE-trained educator with a Bachelor of Science from Imperial College London, Grace is well-equipped with the skills to transform students’ abilities. She believes children all have a desire to learn and excel, and a good foundation will help them enjoy lifelong learning. <span class="text-primary clickable">read less</span></p>'
  },
  // {
  //   name: 'Celesta',
  //   subject: 'English',
  //   profile_img: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/teacher/celesta-chee.jpg',
  //   shortText:
  //     '<p>Understanding and entertaining are two adjectives that Teacher Celesta’s students use to describe her. Tenopy’s Head of English Department found her <span class="text-primary clickable">read more</span></p>',
  //   fullText:
  //     '<p>Understanding and entertaining are two adjectives that Teacher Celesta’s students use to describe her. Tenopy’s Head of English Department found her passion for teaching through volunteer teaching programmes. She believes that the approach to learning should be fuelled by curiosity and guided with the means to seek out the answer. <span class="text-primary clickable">read less</span></p>'
  // },
  {
    name: 'Kristen',
    subject: 'Science',
    profile_img: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/teacher/kristen-ang.jpg',
    shortText:
      '<p>Teacher Kristen builds strong rapport with her classes and motivates her students to achieve breakthroughs that were never thought possible. Teacher <span class="text-primary clickable">read more</span></p>',
    fullText:
      '<p>Teacher Kristen builds strong rapport with her classes and motivates her students to achieve breakthroughs that were never thought possible. Teacher Kristen graduated from National University of Singapore with a Bachelor of Biological Sciences. She believes that evoking her students\' passion for the subject she teaches, making the students take charge of their own learning. <span class="text-primary clickable">read less</span></p>'
  },
  {
    name: 'Eddie',
    subject: 'English',
    profile_img: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/teacher/edwin-goh.jpg',
    shortText: `<p>Eddie Goh attained his Bachelor's in Communication, majoring in media studies and minoring in Journalism, Creative Writing and International Studies <span class="text-primary clickable">read more</span></p>`,
    fullText: `<p>Eddie Goh attained his Bachelor's in Communication, majoring in media studies and minoring in Journalism, Creative Writing and International Studies at Monash University. He then qualified for NIE, where he attained his Post graduate diploma in education and served as an English teacher with the MOE. Teacher Eddie taught English Language, English Literature and Drama for 11 years at a secondary school in the heartlands. Having interacted with students of varying learning calibres, he soon became an advocate of Differentiated Learning, believing wholeheartedly that education should never be approached using a one-size-fits-all mentality. <span class="text-primary clickable">read less</span></p>`
  }
  // {
  //   name: 'Tatum',
  //   subject: 'English',
  //   profile_img: 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/teacher/tatum-rosete.jpg',
  //   shortText:
  //     '<p>Our writing expert, Teacher Tatum is loved by her students, with many seeking her guidance throughout their primary years. Having taught English for 6 <span class="text-primary clickable">read more</span></p>',
  //   fullText:
  //     '<p>Our writing expert, Teacher Tatum is loved by her students, with many seeking her guidance throughout their primary years. Having taught English for 6 years, Teacher Tatum is great at crafting curriculum that reflects topics that appear in school and national examinations. She believes that writing is a skill for a life and honing this skill sets a child up for life. <span class="text-primary clickable">read less</span></p>'
  // }
]

class FeaturedTeacher extends React.Component {
  state = {
    expandFullTextIndex: []
  }

  scrollNext = () => {
    let elmnt = document.getElementById('featured-teacher-list-slider')
    if (elmnt) {
      elmnt.scrollBy(300, 0)
    }
  }

  scrollBack = () => {
    let elmnt = document.getElementById('featured-teacher-list-slider')
    if (elmnt) {
      elmnt.scrollBy(-300, 0)
    }
  }

  expandFullText = index => () => {
    let { expandFullTextIndex } = this.state
    if (expandFullTextIndex.includes(index)) {
      _.remove(expandFullTextIndex, function (n) {
        return n === index
      })
    } else {
      expandFullTextIndex.push(index)
    }

    this.setState({ expandFullTextIndex })
  }

  render() {
    const { expandFullTextIndex } = this.state

    if (isMobileOnly) {
      return (
        <Carousel
          showThumbs={false}
          showIndicators={false}
          showArrows={false}
          showStatus={false}
          centerMode={true}
          centerSlidePercentage={90}>
          {FEATURED_TEACHER.map((teacher, index) => (
            <div className={`${index === 0 ? 'pl-3' : 'pl-2'}`} style={{ position: 'relative' }} key={index}>
              <div style={{ position: 'absolute', left: 'calc(50% - 60px)', top: '-60px' }}>
                <Avatar src={teacher.profile_img} className="size-120 avatar-shadow" style={{ zIndex: 1 }} />
              </div>
              <div
                className="bg-white mr-3"
                style={{ border: '2px solid #dee2e6', borderRadius: '15px', marginTop: '65px', paddingTop: '65px' }}>
                {teacher.shortText && !expandFullTextIndex.includes(index) ? (
                  <div
                    onClick={this.expandFullText(index)}
                    className="text-center"
                    style={{
                      fontFamily: "'Nunito Sans', sans-serif",
                      fontSize: '18px',
                      color: '#333',
                      padding: '0 10px'
                    }}
                    dangerouslySetInnerHTML={{ __html: teacher.shortText }}
                  />
                ) : (
                  <div
                    onClick={this.expandFullText(teacher.shortText ? index : '')}
                    className="text-center"
                    style={{
                      fontFamily: "'Nunito Sans', sans-serif",
                      fontSize: '18px',
                      color: '#333',
                      padding: '0 10px'
                    }}
                    dangerouslySetInnerHTML={{ __html: teacher.fullText }}
                  />
                )}
                <div
                  style={{
                    padding: '10px 15px',
                    borderBottomLeftRadius: '15px',
                    borderBottomRightRadius: '15px',
                    backgroundColor: '#54657b'
                  }}>
                  <p className="h3 mb-1 text-white" style={{ fontFamily: "'Rubik', sans-serif" }}>
                    {teacher.name}
                  </p>
                  <span className="text-white" style={{ fontFamily: "'Nunito Sans', sans-serif" }}>
                    {teacher.subject}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      )
    }

    return (
      <div className="position-relative">
        <div className="carousel-container">
          <div className="carousel-slides" id="featured-teacher-list-slider">
            {FEATURED_TEACHER.map((teacher, index) => (
              <div className="col-lg-4 col-6 mb-4" style={{ position: 'relative' }} key={index}>
                <div style={{ position: 'absolute', left: 'calc(50% - 60px)' }}>
                  <Avatar
                    className="size-120 avatar-shadow"
                    src={teacher.profile_img}
                    alt={teacher.name}
                    style={{ zIndex: 1 }}
                  />
                </div>
                <div
                  className="mx-3 bg-white"
                  style={{
                    border: '2px solid #dee2e6',
                    borderRadius: '15px',
                    marginTop: '65px',
                    paddingTop: '65px'
                  }}>
                  {teacher.shortText && !expandFullTextIndex.includes(index) ? (
                    <div
                      onClick={this.expandFullText(index)}
                      className="text-center"
                      style={{
                        fontFamily: "'Nunito Sans', sans-serif",
                        fontSize: '18px',
                        color: '#333',
                        padding: '0 10px'
                      }}
                      dangerouslySetInnerHTML={{ __html: teacher.shortText }}
                    />
                  ) : (
                    <div
                      onClick={this.expandFullText(teacher.shortText ? index : '')}
                      className="text-center"
                      style={{
                        fontFamily: "'Nunito Sans', sans-serif",
                        fontSize: '18px',
                        color: '#333',
                        padding: '0 10px'
                      }}
                      dangerouslySetInnerHTML={{ __html: teacher.fullText }}
                    />
                  )}
                  <div
                    style={{
                      padding: '10px 15px',
                      borderBottomLeftRadius: '15px',
                      borderBottomRightRadius: '15px',
                      backgroundColor: '#54657b'
                    }}>
                    <p className="h3 mb-1 text-white" style={{ fontFamily: "'Rubik', sans-serif" }}>
                      Teacher {teacher.name}
                    </p>
                    <span className="text-white" style={{ fontFamily: "'Nunito Sans', sans-serif" }}>
                      {teacher.subject}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={`carousel-nav ${isTablet ? 'next--tablet' : 'next'}`}>
          <img
            className="carousel-nav-icon"
            src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/carousel-next.png"
            alt="next"
            onClick={this.scrollNext}
          />
        </div>
        <div className={`carousel-nav ${isTablet ? 'prev--tablet' : 'prev'}`}>
          <img
            className="carousel-nav-icon"
            src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/carousel-back.png"
            alt="prev"
            onClick={this.scrollBack}
          />
        </div>
      </div>
    )
  }
}

export default FeaturedTeacher
