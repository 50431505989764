import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormLabel,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slide,
  TextField
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { Editor } from 'react-draft-wysiwyg'
import _ from 'lodash'
import Proptypes from 'prop-types'
import agent from 'agent'
import { CREATE_LESSON_ACTIVITY, GET_LESSON_BY_ID, UPDATE_LESSON_ACTIVITY } from 'constants/actionTypes'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const mapStateToProps = state => ({
  token: state.common.token,
  creating: state.activity.creating,
  updating: state.activity.updating
})

const mapDispatchToProps = dispatch => ({
  createActivity: reqPayload =>
    dispatch({ type: CREATE_LESSON_ACTIVITY, payload: agent.Activity.create('open_ended', reqPayload) }),
  updateActivity: (type, id, reqPayload) =>
    dispatch({ type: UPDATE_LESSON_ACTIVITY, payload: agent.Activity.update(type, id, reqPayload), activityId: id }),
  getLesson: lessonId => dispatch({ type: GET_LESSON_BY_ID, payload: agent.Lessons.getById2(lessonId) })
})

class AddOpenEndedDialog extends React.Component {
  state = {
    order: 1,
    question: EditorState.createEmpty(),
    hint: '',
    mode: 'word',
    answer: ['', '', '', ''],
    answerCapSensitive: false,
    answerMaxLength: 25,
    allowMultipleAnswer: false,
    alertMsg: ''
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const { activityData, projectedOrder } = this.props

      if (activityData) {
        // NOTE: decide later whether to include edit function
        const blocksFromHTML = convertFromHTML(activityData.content.question)
        const content = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
        this.setState({
          order: activityData.order,
          question: EditorState.createWithContent(content),
          hint: '',
          mode: activityData.content.mode,
          answer: activityData.content.answer,
          answerCapSensitive: activityData.content.answer_cap_sensitive,
          answerMaxLength: activityData.content.answer_max_length,
          allowMultipleAnswer: false,
          alertMsg: ''
        })
      } else {
        this.setState({
          order: projectedOrder ?? 1,
          question: EditorState.createEmpty(),
          hint: '',
          mode: 'word',
          answer: ['', '', '', ''],
          answerCapSensitive: false,
          answerMaxLength: 25,
          allowMultipleAnswer: false,
          alertMsg: ''
        })
      }
    }

    if ((prevProps.creating && !this.props.creating) || (prevProps.updating && !this.props.updating)) {
      this.props.getLesson(this.props.lessonId)
      this.props.close()
    }
  }

  onEditorStateChange = element => editorState => {
    this.setState({ [element]: editorState })
  }

  uploadImageCallBack = file => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open('POST', `${process.env.REACT_APP_API_ROOT}/v2/misc/image-upload`)
      xhr.setRequestHeader('Authorization', `Bearer ${this.props.token}`)
      const data = new FormData()
      data.append('attachment', file)
      xhr.send(data)
      xhr.addEventListener('load', () => {
        const response = JSON.parse(xhr.responseText)
        resolve({ data: { link: response.url } })
      })
      xhr.addEventListener('error', () => {
        const error = JSON.parse(xhr.responseText)
        reject(error)
      })
    })
  }

  handleChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState({ [name]: value })
  }

  handleCheckboxChange = (e, checked) => {
    const name = e.target.name
    this.setState({ [name]: checked })
  }

  handleAnswerChange = index => event => {
    const { answer } = this.state
    let newOption = answer.slice(0)
    newOption[index] = event.target.value
    this.setState({ answer: newOption })
  }

  addMoreAnswer = () => {
    const expandedRawOption = this.state.answer
    expandedRawOption.push('')
    this.setState({ answer: expandedRawOption })
  }

  handleSave = () => {
    const { activityData, lessonId } = this.props
    const { order, question, hint, mode, answer, answerCapSensitive, answerMaxLength, allowMultipleAnswer } = this.state

    if (!question.getCurrentContent().hasText()) {
      this.setState({ alertMsg: 'Activity cannot have empty question body.' })
      return
    }

    if (_.compact(answer).length < 1) {
      this.setState({ alertMsg: 'Open Ended activity must have at least 1 answer.' })
      return
    }

    this.setState({ alertMsg: '' })

    if (activityData) {
      const reqPayload = {
        question: stateToHTML(question.getCurrentContent()),
        hint: hint.trim(),
        mode,
        answer: _.compact(answer),
        allow_multiple_answer: allowMultipleAnswer,
        answer_cap_sensitive: answerCapSensitive,
        answer_max_length: answerMaxLength
      }
      this.props.updateActivity(activityData.content._type, activityData.content._id, reqPayload)
    } else {
      const reqPayload = {
        lesson: {
          id: lessonId,
          order
        },
        activity: {
          question: stateToHTML(question.getCurrentContent()),
          hint: hint.trim(),
          mode,
          answer: _.compact(answer),
          allow_multiple_answer: allowMultipleAnswer,
          answer_cap_sensitive: answerCapSensitive,
          answer_max_length: answerMaxLength
        }
      }
      this.props.createActivity(reqPayload)
    }
  }

  updateActivity = () => {}

  render() {
    const { isOpen, activityData, creating, updating } = this.props
    const { order, question, mode, answer, answerCapSensitive, answerMaxLength, alertMsg } = this.state

    const inProgress = creating || updating

    return (
      <Dialog open={isOpen} maxWidth="md" fullWidth TransitionComponent={Transition}>
        <DialogTitle>
          <span className="h3 tittle--rubik">{activityData ? 'Edit' : 'Create'} Activity</span>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {activityData && (
            <Alert severity="info">
              NOTE: Editing this activity will result in changes to all lessons that use the same activity.
            </Alert>
          )}
          {alertMsg && <Alert severity="error">{alertMsg}</Alert>}

          <div className="mt-2 mb-3 d-flex align-items-center">
            {!activityData && (
              <TextField
                type="text"
                label="Order"
                name="order"
                variant="outlined"
                onChange={this.handleChange}
                value={order}
                className="mr-4"
              />
            )}
            <FormControl component="fieldset">
              <RadioGroup name="mode" value={mode} onChange={this.handleChange} row>
                <FormControlLabel
                  name="mode"
                  value="word"
                  control={<Radio color="primary" />}
                  label="Word"
                  labelPlacement="end"
                />
                <FormControlLabel
                  name="mode"
                  value="sentence"
                  control={<Radio color="primary" />}
                  label="Sentence"
                  labelPlacement="end"
                  disabled
                />
              </RadioGroup>
            </FormControl>
          </div>

          <div className="mb-3">
            <FormLabel component="legend">Question Body</FormLabel>
            <Editor
              editorState={question}
              onEditorStateChange={this.onEditorStateChange('question')}
              toolbar={{
                options: [
                  'inline',
                  'list',
                  'textAlign',
                  'colorPicker',
                  'link',
                  'embedded',
                  'emoji',
                  'image',
                  'history'
                ],
                image: {
                  uploadCallback: this.uploadImageCallBack,
                  previewImage: true,
                  alt: { present: true, mandatory: true }
                }
              }}
            />
          </div>

          {/*<TextField
            type="text"
            label="Hint"
            name="hint"
            variant="outlined"
            onChange={this.handleChange}
            fullWidth
            value={hint}
            className="mb-3"
          />*/}

          <div className="d-flex align-items-center mb-3">
            <TextField
              type="text"
              label="Answer Max Length"
              name="answerMaxLength"
              variant="outlined"
              onChange={this.handleChange}
              value={answerMaxLength}
              className="mr-4"
            />

            {/*<FormControlLabel
              control={
                <Checkbox
                  checked={answerCapSensitive}
                  onChange={this.handleCheckboxChange}
                  name="answerCapSensitive"
                  color="primary"
                />
              }
              label="Cap Sensitive"
            />*/}

            {/*<FormControlLabel
              control={
                <Checkbox
                  checked={allowMultipleAnswer}
                  onChange={this.handleCheckboxChange}
                  name="allowMultipleAnswer"
                  color="primary"
                />
              }
              label="Allow multiple answer entry"
            />*/}
          </div>

          <div className="mb-3">
            <FormLabel component="legend">Answer</FormLabel>
            {answer.map((item, index) => (
              <TextField
                key={index}
                label={`Answer ${index + 1} ${index > 0 ? '(optional)' : ''}`}
                value={item}
                onChange={this.handleAnswerChange(index)}
                variant="outlined"
                fullWidth={true}
                margin="normal"
              />
            ))}
            <Button onClick={this.addMoreAnswer} color="primary">
              Add more
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="default" onClick={this.props.close} disabled={inProgress}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={this.handleSave} disabled={inProgress}>
            Save & Upload {inProgress && <CircularProgress className="ml-2" size={15} color="primary" />}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

AddOpenEndedDialog.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired,
  lessonId: Proptypes.string.isRequired,
  activityData: Proptypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(AddOpenEndedDialog)
