import React from 'react'
import { Button } from '@material-ui/core'
import { ALL_CLASSES } from 'routes/routes'

function LandingPhotoDescription(props) {
  const { button, position, imgSrc, imgAlt, title, description, goToClassCatalogue, buttonTitle } = props

  return position === 'left' ? (
    <section>
      {/* <section style={{ backgroundColor: '#f6f6fc' }}> */}
      <div className="container">
        <div className="row align-items-center mb-5">
          <div className="col-12 col-md-5">
            <img src={imgSrc} alt={imgAlt} width="90%" className="mb-3 shadow" />
          </div>
          <div className="col-12 col-md-7 mb-1">
            <div
              className="mb-3"
              style={{
                fontSize: '2.3rem',
                fontFamily: "'Rubik', sans-serif",
                lineHeight: 1.2,
                color: '#000000',
                textAlign: 'left'
              }}>
              {title}
            </div>
            {/* <div className="text-large mb-3">We leave no child behind in class.</div> */}

            <div className="text-large mb-3">{description}</div>

            {button === 'yes' && (
              <Button variant="contained" size="large" className="btn--purple">
                <a style={{ color: 'white' }} href="mailto:ask@tenopy.com?subject=Enquiry on your learning technology">
                  {buttonTitle}
                </a>
              </Button>
            )}
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div className="container">
      <div className="row align-items-center mb-md-5 mb-0 ml-md-0 ml-2">
        <div className="col-12 col-md-5 d-md-none d-block">
          <img src={imgSrc} alt={imgAlt} width="90%" className="mb-3 shadow" />
        </div>

        <div className="col-12 col-md-7 mb-4">
          <div
            className="mb-3"
            style={{
              fontSize: '2.3rem',
              fontFamily: "'Rubik', sans-serif",
              lineHeight: 1.2,
              color: '#000000',
              textAlign: 'left'
            }}>
            {title}
          </div>

          <div className="text-large mb-3">{description}</div>
          {button === 'yes' && (
            <Button variant="contained" size="large" className="btn--purple mb-3" onClick={() => goToClassCatalogue()}>
              Get Started
            </Button>
          )}
        </div>

        <div className="col-12 col-md-5 d-md-block d-none">
          <img src={imgSrc} alt={imgAlt} width="90%" className="mb-3 shadow" />
        </div>
      </div>
    </div>
  )
}

export default LandingPhotoDescription
