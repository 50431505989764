import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import { Accordion, AccordionSummary, AccordionDetails, Button } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { ALL_CLASSES, TRIAL } from 'routes/routes'
import TutorProfileDialog from 'components/dialog/tutor-profile'
import CTADivider from 'components/misc/cta-divider'
import VideoPlayerDialog from 'components/dialog/video-player'

function TenopyEnglish() {
  const [activeTutor, setActiveTutor] = useState('')
  const [tutorProfileDialog, setTutorProfileDialog] = useState(false)
  const [videoDialog, setVideoDialog] = useState({ isOpen: false, videoUrl: '' })

  let openPage = destination => () => {
    window.open(destination, '_blank')
  }

  return (
    <div className="landing">
      <Helmet>
        <title>Tenopy English Programmes | Tenopy</title>
        <meta
          name="description"
          content="Improve your child’s critical and analytical thinking skills with Tenopy English, run by our dedicated English department teachers. Our English programmes are catered to Primary and Secondary students with different ability levels."
        />
        <meta
          name="keywords"
          content="Tenopy English, Online tuition, Online tuition Singapore, Online english tuition, online english tuition singapore, Online english tutor, online learning, online tutor, English programmes, Primary school tuition, secondary school tuition, criticial thinking, analytical thinking, Different ability level, improvements, tailored learning"
        />
      </Helmet>

      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-12 order-md-1 order-2">
              <div className="subtitle-left title--rubik">Introducing</div>
              <div className="title--rubik h1">Tenopy English Programmes</div>
              <div className="text-large">
                <p>
                  At Tenopy, we believe that top quality education begins with conversations, rather than rote learning
                  where your child is simply told what to do. This is why we structure our classes and proprietary
                  curriculum to guide your child to think critically and learn actively.
                </p>
                <p>
                  Differentiated instruction forms the core of our curriculum, which is meticulously designed to cater
                  to every child’s learning style and individual pace. In particular, we also encourage every child at
                  Tenopy to ask more questions because this is the first step in exploring different perspectives and
                  different possibilities. Thinking critically and learning actively are not only crucial to acing
                  examinations, but also allow your child to emerge from the crowd as a sharp and analytical thinker.
                  Our specialist teachers facilitate discussions and create a nurturing environment for fruitful
                  learning experiences.
                </p>
                <p>At Tenopy, we leave no questions unanswered and no child will ever be left behind. </p>

                <div className="my-2">
                  <Button variant="outlined" className="mr-2 mb-2" color="primary" onClick={openPage(TRIAL)}>
                    Get A Free Trial
                  </Button>
                  <Button
                    variant="contained"
                    className="mb-2"
                    color="primary"
                    onClick={openPage(`${ALL_CLASSES}?subject=English`)}>
                    Class Catalogue (English)
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 order-md-2 order-1 mb-5 mb-md-0">
              <img
                src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/about-teacher-vector.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>

      {/* <section style={{ backgroundColor: '#f4fafe' }}>
        <div className="container">
          <div className="section__header mb-5">
            <div
              className="title mb-2"
              style={{ fontSize: '2.3rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif", lineHeight: 1.2 }}>
              Meet The Expert Teachers
            </div>
            <div className="mx-auto" style={{ maxWidth: '140px', borderBottom: '3px solid #4e63d7' }}></div>
          </div>

          <div className="row">
            <div className="col-md-4 col-12">
              <div
                className="team-item"
                onClick={() => {
                  setActiveTutor('esther-ng')
                  setTutorProfileDialog(true)
                }}>
                <div className="mb-30 position-relative d-flex align-items-center">
                  <span className="subject d-inline-block">Secondary</span>
                  <span className="img-holder d-inline-block">
                    <img
                      src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/teacher/esther-ng-hb.jpg"
                      alt="Team"
                    />
                  </span>
                </div>
                <div className="team-content">
                  <div className="h2 title--rubik mb-2">Esther Ng</div>
                  <p className="text-uppercase mb-0">Director of English</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div
                className="team-item"
                onClick={() => {
                  setActiveTutor('celesta-chee')
                  setTutorProfileDialog(true)
                }}>
                <div className="mb-30 position-relative d-flex align-items-center">
                  <span className="subject d-inline-block">Primary</span>
                  <span className="img-holder d-inline-block">
                    <img
                      src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/teacher/celesta-chee-hb.jpg"
                      alt="Team"
                    />
                  </span>
                </div>
                <div className="team-content">
                  <div className="h2 title--rubik mb-2">Celesta Chee</div>
                  <p className="text-uppercase mb-0">Head of Primary English</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div
                className="team-item"
                onClick={() => {
                  setActiveTutor('tatum-rosete')
                  setTutorProfileDialog(true)
                }}>
                <div className="mb-30 position-relative d-flex align-items-center">
                  <span className="subject d-inline-block">Primary</span>
                  <span className="img-holder d-inline-block">
                    <img
                      src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/teacher/tatum-rosete-hb.jpg"
                      alt="Team"
                    />
                  </span>
                </div>
                <div className="team-content">
                  <div className="h2 title--rubik mb-2">Tatum Rosete</div>
                  <p className="text-uppercase mb-0">Curriculum Specialist</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section>
        <div className="container">
          <div className="section__header mb-5">
            <div
              className="title mb-2"
              style={{ fontSize: '2.3rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif", lineHeight: 1.2 }}>
              Our Primary English Programmes
            </div>
            <div className="mx-auto" style={{ maxWidth: '160px', borderBottom: '3px solid #f08d02' }}></div>
          </div>

          <div className="row">
            <div className="col-md-4 col-12">
              <img
                src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/about-us/primary-student.png"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-md-8 col-12">
              <div>
                <p>
                  Tenopy’s Primary English Department specialises in training your child’s critical thinking skills. We
                  believe that in order to cultivate a love for the English language and for learning, a child needs to
                  see its relevance to their own lives. In order to do so, we make learning English practical and
                  logical. We source for and write passages that are in line with your child’s interest such that
                  learning grammar rules and increasing their vocabulary would no longer be a chore.
                </p>
                <p>
                  Every child at the Primary School level must master communication. This is a life-skill that will
                  serve them beyond scoring good grades. As such, we don’t believe in the one-size-fits all approach or
                  teaching tailored just to answer examination trends. Our approach grows good thinkers, not just good
                  exam-takers.
                </p>
                <p>
                  Inference and active reading are skills that are constantly tested in comprehension passages. Unlike
                  traditional methodologies of doing the questions repeatedly, we show children inference is a skill
                  that we apply in our day to day lives, and that these skills are not merely academic. We ensure that
                  story writing is not a mere regurgitation of beautiful descriptions. Instead, we train your child to
                  imagine the scenes and then build their stories in an interesting manner.
                </p>
                <p>
                  Additionally, we apply differentiated instruction both in our teaching and homework worksheets. This
                  means that no matter what your child’s ability level, he or she will receive tasks tailored to their
                  needs. This way, we can ensure that he or she sees improvements that are equal to the efforts. Whether
                  it is to constantly challenge them or to give them a helping hand to be able to apply the skills, we
                  believe in gradually growing your child into independent thinkers.
                </p>
              </div>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: '#f9f9f9' }}>
                  About Paper 1 Classes
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <p>
                      Many students mistake memorised phrases, even entire paragraphs from model compositions, for good
                      writing. The truth is, however, that schools are rewarding students for writing in a logical and
                      thoughtfully described way.
                    </p>
                    <p>
                      At Tenopy, our teachers help your child to master composition writing organically, teaching them
                      to select and adapt writing techniques and descriptions to suit each story. Supported by education
                      research, we combine teacher-led brainstorming, vocabulary building tasks, and close feedback on
                      all writing assignments to accelerate improvement. Our lively class discussions will help your
                      child develop his or her own creative voice and a lifelong enthusiasm for writing.
                    </p>
                    <p>In these classes, your child will be equipped to score better for Paper 1 by</p>
                    <ul>
                      <li>
                        Learning how to effectively employ “Show not Tell” and utilise their five senses to enliven
                        stories.
                      </li>
                      <li>
                        Learning to describe setting while smoothly linking it to the context and reasoning of the
                        narrative.
                      </li>
                      <li>
                        Learning how to describe not just the character’s actions during the climax, but also the
                        protagonist’s thoughts, feelings, and internal crisis.
                      </li>
                      <li>Learning how to pinpoint what makes a mature reflection and stand out from the crowd.</li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: '#f9f9f9' }}>
                  About Paper 2 Classes
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <p>
                      Many students approach English using their ‘gut instinct’ instead of making sure that they
                      understand the concept or skill tested. This is especially true for complex question types in
                      Synthesis and Transformation, Cloze, and Comprehension OE.
                    </p>
                    <p>
                      At Tenopy, our teachers use content designed specifically to teach tough question types,
                      especially inference questions, and strengthen your child’s grammar knowledge and reasoning
                      ability. We place great emphasis on clear and concise explanations that will make your child’s
                      everyday revision more effective!
                    </p>
                    <p>In these classes, they will </p>
                    <ul>
                      <li>Master active reading and develop critical thinking skills. </li>
                      <li>
                        Learn how to identify different question types and their requirements by analysing the
                        questions’ keywords.
                      </li>
                      <li>Learn the steps needed to answer different question types. </li>
                      <li>
                        Learn how to gather evidence to infer a feeling / trait / attitude and to read between the lines
                        to infer the answer.
                      </li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: '#f9f9f9' }}>
                  About High Ability Classes
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <p>
                      Is your child already very proficient in the English language and is excited to learn more? Our
                      English High Ability classes will introduce your child to a whole range of more challenging but
                      also exciting content.
                    </p>
                    <p>
                      At Tenopy, English High Ability classes extend Paper 2 content and approaches. This stretches your
                      child’s analytical skills and they will have the opportunity to venture into the study of literary
                      techniques, more sophisticated vocabulary and language use, and also symbolism and irony. Your
                      child will also extrapolate from these lessons and use their reading skills to critique, among
                      other things, world affairs and prejudices represented in mass media.
                    </p>
                    <p>In these classes, they will </p>
                    <ul>
                      <li>
                        Stretch their linguistic talent with advanced vocabulary, reading passages, and word puzzles.
                      </li>
                      <li>Learn literary techniques, and solve challenging comprehension questions.</li>
                      <li>Learn how to apply the skills acquired in their day to day lives. </li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="section__header mb-5">
            <div
              className="title mb-2"
              style={{ fontSize: '2.3rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif", lineHeight: 1.2 }}>
              Our Secondary English Programmes
            </div>
            <div className="mx-auto" style={{ maxWidth: '160px', borderBottom: '3px solid #0cc652' }}></div>
          </div>

          <div className="row">
            <div className="col-md-4 col-12">
              <img
                src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/about-us/secondary-student.png"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-md-8 col-12">
              <div>
                <p>
                  Tenopy’s Secondary English Department offers a holistic and comprehensive approach to the study of
                  English Language across the different streams. Whether your child comes from the O’ levels stream or
                  the IP stream (and potentially joining the IB programme), our proprietary curriculum is customised to
                  the nuances of both systems and our specialist teachers are able to help students benefit from both
                  systems, instead of only one.
                </p>
                <p>
                  At Tenopy, we dedicate entire programmes to Paper I (Critical Writing and Essay Skills) and Paper 2
                  (Critical Reading and Comprehension Skills), instead of having a general programme that covers English
                  Language at Secondary level. This allows your child to learn more effectively as they can explore and
                  ask questions at their own pace, and also allows our teachers to better guide them to achieving
                  excellence.
                </p>
                <p>
                  We craft our proprietary content on the specialist knowledge of the formats and expectations of your
                  child’s examinations, so as to generate fruitful discussions where your child can be introduced to
                  many different perspectives. Our homework is also carefully planned and meticulously marked with
                  extensive comments and suggestions. With Tenopy’s Secondary English programmes, your child will
                  achieve marked improvements and they will be able to face their examinations with confidence.
                </p>
                <p>
                  Beyond this, we also go one step even further in training them to be active and critical thinkers.
                  Critical and analytical thinking are crucial to success in any field in our fast-paced world. These
                  skills are also transferable across the other subjects your child will take at secondary and
                  pre-tertiary levels, especially in the humanities. Tenopy’s Secondary English programmes will not only
                  benefit your child’s academics beyond English Language, they will also become more interested and
                  informed global citizens with an edge in thinking skills.
                </p>
              </div>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: '#f9f9f9' }}>
                  About Paper 1 Classes
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <p>
                      In our Paper 1 classes, your child will be introduced to the different genres of writing and learn
                      essential writing skills and how to craft arguments to suit a variety of contexts and purposes,
                      including descriptive, argumentative and discursive essays, along with situation-based writing.
                      They will also be introduced to a wide range of essay questions and be guided in analysing issues
                      critically.
                    </p>
                    <p>
                      At Tenopy, we believe that good writing begins with clear, analytical thinking. This is why we
                      focus our discussions on contextualising issues, instead of memorising specific arguments. We also
                      guide your child to refine their writing such that they do not need to rely on conventional essay
                      writing formats (such as the Point, Elaboration, Example, Link) so as to better avoid their
                      pitfalls. A key component in our Paper 1 classes is also how we teach your child to question
                      commonly held assumptions, allowing for an exploration of different opinions.
                    </p>
                    <p>This way, we systematically train your child to become a highly versatile writer. </p>
                    <p>In these classes, your child will </p>
                    <ul>
                      <li>Hone and refine writing techniques that are transferable to other subjects</li>
                      <li>
                        Participate in discussions on current affairs and global issues that will help content knowledge
                      </li>
                      <li>Think more deeply about social, economic and political issues</li>
                      <li>Develop a strong interest in discussing issues that impact their lives</li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: '#f9f9f9' }}>
                  About Paper 2 Classes
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <p>
                      In our Paper 2 classes, your child will be introduced to the many different types of comprehension
                      questions that they will be faced with at Secondary level. While comprehension questions test your
                      child’s understanding of reading passages, there is a range of skills required to ace it. Not only
                      does your child have to read the passages carefully and critically, they will also have to
                      appreciate the nuances of the questions and craft answers that are tailored to them.
                    </p>
                    <p>
                      At Tenopy, we believe that an inspiring a love for language and language use would allow your
                      child to better understand what they are reading and, in fact, how they are reading. This
                      translates into sharper reading and more precise answers, that then translates into higher grades.
                      We also emphasise the relationship between context and content, where students constantly practice
                      reading against the prevailing background that is set in the text.
                    </p>
                    <p>This way, we train your child to become a critical and highly effective reader. </p>
                    <p>In these classes, your child will </p>
                    <ul>
                      <li>
                        Become familiar with the different text types, across visual narrative and non-narrative texts
                      </li>
                      <li>Appreciate the nuances of language use</li>
                      <li>Understand how context shapes content</li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </section>

      <CTADivider />

      <TutorProfileDialog
        isOpen={tutorProfileDialog}
        slug={activeTutor}
        close={() => {
          setTutorProfileDialog(false)
        }}
        showVideoIntro={setVideoDialog}
      />

      <VideoPlayerDialog
        videoURL={videoDialog.videoUrl}
        isOpen={videoDialog.isOpen}
        close={() => {
          setVideoDialog({ isOpen: false, videoUrl: '' })
          setTutorProfileDialog(true)
        }}
      />
    </div>
  )
}

export default TenopyEnglish
