import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import Proptypes from 'prop-types'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Slide,
  Slider,
  IconButton
} from '@material-ui/core'
import { Close, RecordVoiceOver, Clear } from '@material-ui/icons'
import _ from 'lodash'
import CKEditor from 'ckeditor4-react'
import agent from 'agent'
import { UPDATE_SUBMISSION } from 'constants/actionTypes'
import AudioRecorder from 'components/shared/Media/AudioRecorder'
import Waveform from 'components/shared/Media/Waveform'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  updatingSubmission: state.submission.updatingSubmission
})

const mapDispatchToProps = dispatch => ({
  updateSubmission: (data, submissionId) =>
    dispatch({ type: UPDATE_SUBMISSION, payload: agent.Submission.update('classic', submissionId, data), submissionId })
})

class MarkSubmissionDialog extends React.Component {
  state = {
    isOpen: false,
    files: [],
    comment: '',
    tagScore: [],
    showAudioRecorder: false,
    audioFiles: [],
    editMode: false
  }

  componentDidMount() {
    let { submissionData, currentUser } = this.props
    this.setState({
      comment: `<p>Dear ${submissionData.created_by.name},</p><p>I have returned your marked work. Please let me know if you are unable to access it. </p><p>Best regards, <br/>Teacher ${currentUser.name}.</p>`
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.updatingSubmission && !this.props.updatingSubmission) {
      this.setState({
        isOpen: false,
        files: [],
        comment: '',
        tagScore: []
      })
    }

    if (!prevState.isOpen && this.state.isOpen) {
      let { submissionData, currentUser } = this.props
      let editMode = submissionData.status === 'MARKED'

      this.initTag(editMode)
      if (submissionData && currentUser) {
        let comment = `<p>Dear ${submissionData.created_by.name},</p><p>I have returned your marked work. Please let me know if you are unable to access it. </p><p>Best regards, <br/>Teacher ${currentUser.name}.</p>`
        if (editMode) {
          comment = submissionData.comment
        }
        this.setState({ comment })
      }
    }
  }

  initTag = editMode => {
    let { submissionData } = this.props
    let tag = submissionData.homework.tag
    let previousTag = submissionData.tag
    let tagScore = []
    _.each(tag, t => {
      let score = 5
      if (editMode) {
        let previousT = _.find(previousTag, o => {
          return o.name === t
        })
        score = previousT.score
      }
      let obj = {
        name: t,
        score: score
      }
      tagScore.push(obj)
    })
    this.setState({ tagScore })
  }

  toggleDialog = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  addFile = event => {
    let { files } = this.state
    let input = event.target
    let selectedFile = files || []
    for (let i = 0; i < input.files.length; i++) {
      selectedFile.push(input.files[i])
    }
    this.setState({ files })
  }

  removeFile = index => {
    let { files } = this.state
    files.splice(index, 1)
    this.setState({ files: files })
  }

  toggleAudioRecording = () => {
    this.setState(prevState => ({
      showAudioRecorder: !prevState.showAudioRecorder
    }))
  }

  uploadRecording = blob => {
    let audioFiles = [...this.state.audioFiles]
    audioFiles.push(blob)
    this.setState({ audioFiles, showAudioRecorder: false })
  }

  deleteRecording = index => () => {
    let audioFiles = [...this.state.audioFiles]
    audioFiles.splice(index, 1)
    this.setState({ audioFiles })
  }

  onEditorChange = evt => {
    this.setState({
      comment: evt.editor.getData()
    })
  }

  handleSliderChanged = (event, value) => {
    let { tagScore } = this.state
    let tagIndex = _.findIndex(tagScore, o => {
      return o.name === event.target.id
    })

    if (tagIndex !== -1) {
      tagScore[tagIndex].score = value
    }

    this.setState({ tagScore })
  }

  update = () => {
    let { files, comment, tagScore, audioFiles } = this.state
    let { submissionData } = this.props

    let patchSubmissionData = new FormData()
    files.forEach(file => patchSubmissionData.append('marked_attachment', file))
    audioFiles.forEach((file, idx) => {
      const fileName = `audio_${idx + 1}.wav`
      patchSubmissionData.append('marked_attachment', file, fileName)
    })
    patchSubmissionData.append('tag', JSON.stringify(tagScore))
    patchSubmissionData.append('comment', comment)
    patchSubmissionData.append('status', 'MARKED')
    this.props.updateSubmission(patchSubmissionData, submissionData._id)
  }

  render() {
    let { buttonFullWidth, buttonText, submissionData, updatingSubmission } = this.props
    let { isOpen, files, comment, tagScore, showAudioRecorder, audioFiles } = this.state

    let editMarking = submissionData.status === 'MARKED'

    return (
      <Fragment>
        <Button
          variant="contained"
          color="primary"
          onClick={this.toggleDialog}
          fullWidth={buttonFullWidth}
          disableRipple
          disableFocusRipple
          disableTouchRipple>
          {buttonText || 'Mark'}
        </Button>

        <Dialog open={isOpen} onClose={this.toggleDialog} maxWidth="md" fullWidth TransitionComponent={Transition}>
          <DialogTitle>
            {editMarking ? 'Edit Marking' : 'Mark Submission'} ({submissionData.created_by.name})
          </DialogTitle>
          <DialogContent>
            <div className="form-group">
              <div className="row">
                <div className="col-auto">
                  <label htmlFor="uploads">
                    <Button color="primary" variant="contained" component="span" disableRipple disableFocusRipple>
                      Add Files
                    </Button>
                  </label>
                  <input
                    id="uploads"
                    type="file"
                    className="d-none"
                    onChange={this.addFile}
                    accept={'.jpg,.jpeg,.gif,.png,.bmp, .pdf, .doc, .docx'}
                    multiple
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <div className="text-muted">
                    <span className="font-weight-semibold">Note:</span>
                    {editMarking
                      ? 'This will replace previous returned homework file'
                      : 'Upload and return student homework'}
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group mb-0 d-flex flex-row">
              {files.map((file, index) => {
                if (file.type.split('/')[0] === 'image') {
                  return (
                    <div
                      key={index}
                      className="card shadow mb-3 px-0 py-2 mr-1"
                      style={{ backgroundColor: '#f5f5f5', width: '25%' }}>
                      <img src={URL.createObjectURL(file)} width="100%" alt="marked homework file" />
                      <Divider />
                      <div className="col-auto d-flex align-items-center justify-content-between text-muted pt-2">
                        <i className="material-icons">image</i>
                        {file.name}
                        <i
                          className="material-icons clickable"
                          onClick={() => {
                            this.removeFile(index)
                          }}>
                          clear
                        </i>
                      </div>
                    </div>
                  )
                } else if (file.type === 'application/pdf') {
                  return (
                    <div
                      key={index}
                      className="card shadow mb-3 px-0 py-2 mr-1"
                      style={{ backgroundColor: '#f5f5f5', width: '25%' }}>
                      <embed src={URL.createObjectURL(file)} width="100%" height="314px"></embed>
                      <Divider />
                      <div className="col-auto d-flex align-items-center justify-content-between text-muted pt-2">
                        <i className="material-icons">picture_as_pdf</i>
                        {file.name}
                        <i
                          className="material-icons clickable"
                          onClick={() => {
                            this.removeFile(index)
                          }}>
                          clear
                        </i>
                      </div>
                    </div>
                  )
                }

                return (
                  <div key={index} className="card shadow mb-3 p-2 h-100" style={{ backgroundColor: '#f5f5f5' }}>
                    <div className="row">
                      <div className="col-auto d-flex align-items-center text-muted">
                        {(file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                          file.type === 'application/msword') && <i className="material-icons">description</i>}
                      </div>
                      <div className="col d-flex align-items-center">{file.name}</div>
                      <div className="col-auto d-flex align-items-center text-muted">
                        <i
                          className="material-icons clickable"
                          onClick={() => {
                            this.removeFile(index)
                          }}>
                          clear
                        </i>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>

            <div className="text-large text-muted title--rubik mb-2">Tag score:</div>
            <div className="row mb-3">
              {tagScore.map((t, index) => (
                <Fragment key={index}>
                  <div className="col-2">{t.name}:</div>
                  <div className="col-4">
                    <Slider
                      defaultValue={5}
                      value={t.score}
                      valueLabelDisplay="auto"
                      name={t.name}
                      id={t.name}
                      marks
                      step={1}
                      min={0}
                      max={10}
                      onChange={this.handleSliderChanged}
                    />
                  </div>
                </Fragment>
              ))}
            </div>

            <div className="text-large text-muted title--rubik mb-2">Additional Comment:</div>
            <CKEditor
              data={comment}
              onChange={this.onEditorChange}
              config={{
                toolbarGroups: [
                  { name: 'basicstyles', groups: ['basicstyles'] },
                  { name: 'paragraph', groups: ['list', 'align', 'bidi'] }
                ]
              }}
            />
            <Button
              onClick={this.toggleAudioRecording}
              style={{ marginTop: '0.5rem', marginBottom: '0.5rem', width: '100%' }}>
              <div className="d-flex align-items-center">
                <RecordVoiceOver className="text-info mr-2" />
                Record Voice Message
              </div>
            </Button>
            <Dialog open={showAudioRecorder} fullWidth>
              <DialogTitle
                disableTypography
                className={'d-flex align-items-center py-0'}
                style={{ margin: '1rem 0 0.5rem 0', width: '100%' }}>
                <h3 className="mb-0">Record Voice Message</h3>
                <IconButton size={'small'} className={'ml-auto'} aria-label="Close" onClick={this.toggleAudioRecording}>
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent style={{ paddingBottom: '1rem' }}>
                <AudioRecorder onSave={this.uploadRecording} />
              </DialogContent>
            </Dialog>
            {audioFiles.length > 0 &&
              audioFiles.map((blob, index) => {
                return (
                  <div key={index} className="border p-2 mb-2">
                    <div className="mb-2">
                      <div className="row">
                        <div className="col">Audio Recording {index + 1}</div>
                        <div className="col-auto d-flex align-items-center">
                          <Clear onClick={this.deleteRecording(index)} className="hover--danger pointer" />
                        </div>
                      </div>
                    </div>
                    <Waveform src={blob} isBlob={true} />
                  </div>
                )
              })}
          </DialogContent>
          <DialogActions>
            <Button variant="text" color="default" onClick={this.toggleDialog}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={this.update} disabled={updatingSubmission}>
              Submit {updatingSubmission && <CircularProgress className="ml-2" size={15} color="primary" />}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

MarkSubmissionDialog.propTypes = {
  buttonText: Proptypes.string,
  buttonFullWidth: Proptypes.bool,
  submissionData: Proptypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(MarkSubmissionDialog)
