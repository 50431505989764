import * as Type from 'constants/actionTypes'

const initialState = {
  creating: false,
  updating: false,
  loadingLessonActivityAnswers: false,
  loadingLessonActivity: false,
  lessonActivity: {
    _id: '',
    activity: []
  },
  lessonActivityAnswers: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.CREATE_LESSON_ACTIVITY:
      return {
        ...state,
        creating: true
      }

    case Type.CREATE_LESSON_ACTIVITY_SUCCESS:
    case Type.CREATE_LESSON_ACTIVITY_FAILED:
      return {
        ...state,
        creating: false
      }

    case Type.UPDATE_LESSON_ACTIVITY:
      return {
        ...state,
        updating: true
      }

    case Type.UPDATE_LESSON_ACTIVITY_SUCCESS:
    case Type.UPDATE_LESSON_ACTIVITY_FAILED:
      return {
        ...state,
        updating: false
      }

    case Type.GET_LESSON_ACTIVITY:
      return {
        loadingLessonActivity: true,
        lessonActivity: { activity: [], id: '' }
      }

    case Type.GET_LESSON_ACTIVITY_SUCCESS:
      return {
        ...state,
        loadingLessonActivity: false,
        lessonActivity: {
          _id: action.payload.data._id,
          activity: action.payload.data.activity ?? []
        }
      }

    case Type.GET_LESSON_ACTIVITY_FAILED:
      return {
        ...state,
        loadingLessonActivity: false
      }

    case Type.GET_LESSON_ACTIVITY_ANSWER:
      return {
        loadingLessonActivityAnswers: true,
        lessonActivityAnswers: null
      }

    case Type.GET_LESSON_ACTIVITY_ANSWER_SUCCESS:
      return {
        ...state,
        loadingLessonActivityAnswers: false,
        lessonActivityAnswers: action.payload.data
      }

    case Type.GET_LESSON_ACTIVITY_ANSWER_FAILED:
      return {
        ...state,
        loadingLessonActivityAnswers: false
      }

    default:
      return state
  }
}
