import React from 'react'
import { Link, NavLink, withRouter } from 'react-router-dom'
import { Badge, Button, Menu } from '@material-ui/core'
import { ShoppingCart, AccountCircle, Menu as MenuIcon } from '@material-ui/icons'
import moment from 'moment'
import TenopyBrand from 'assets/tenopy-brand.svg'
import {
  ROOT,
  LOGIN,
  ABOUT_US,
  ALL_CLASSES,
  SIGNUP,
  CLASS_DETAILS,
  APP,
  SHOPPING_CART,
  BOOK_CLUB,
  FREE_SHARING,
  TRIAL,
  WW_3000
} from 'routes/routes'

function NavMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const openMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  return (
    <div className={props.className}>
      {props.renderButton ? (
        props.renderButton(openMenu)
      ) : (
        <div className={`navbar-toplink ${props.isActive ? 'is_active' : ''}`} onClick={openMenu}>
          {props.title}
        </div>
      )}
      <Menu
        className="navbar-dropdown"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: -27,
          horizontal: 'left'
        }}
        style={{ transform: 'translate(0 20px)' }}
        disableScrollLock={true}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}>
        {props.renderChildren(closeMenu)}
      </Menu>
    </div>
  )
}

class MobileNavbar extends React.Component {
  redirectToLoginRegisterPage = intent => {
    let redirectURL = intent === 'Login' ? LOGIN : SIGNUP
    let currentLocation = this.props.location.pathname
    let currentQuery = this.props.location.search
    if (currentLocation.startsWith(ALL_CLASSES) || currentLocation.startsWith(CLASS_DETAILS)) {
      redirectURL = `${redirectURL}?next=${APP}${currentLocation}`
    } else if (currentQuery) {
      redirectURL = `${redirectURL}${currentQuery}`
    }
    this.props.history.push(redirectURL)
  }

  render() {
    const { shoppingCart, token, classCategories, pathname } = this.props
    const promoSection = classCategories && classCategories.promo ? classCategories.promo : {}

    return (
      <header>
        <nav className="navbar fixed-top navbar-expand-md">
          <NavMenu
            className="navbar-mobile-navmenu"
            renderButton={openMenu => (
              <button type="button" className="navbar-toggler" onClick={openMenu}>
                <MenuIcon />
              </button>
            )}
            renderChildren={closeMenu => (
              <div>
                <div className="d-flex flex-row" tabIndex="-1">
                  <div className="w-100">
                    {/* {classCategories ? (
                      <div className="d-flex" tabIndex="-1">
                        {Object.keys(classCategories.classes).map((categoryLabel, key) => (
                          <div className="navbar-classes-menu-column-mobile" key={key}>
                            <div className="navbar-subtitle">{categoryLabel}</div>
                            {Object.keys(classCategories.classes[categoryLabel]).map((classLabel, key) => (
                              <NavLink
                                to={ALL_CLASSES}
                                key={key}
                                className="navbar-link-mobile"
                                onClick={() => {
                                  closeMenu()
                                  this.props.setOnlineClassFilter({
                                    level: classCategories.classes[categoryLabel][classLabel],
                                    status: 'Ongoing',
                                    subject: 'All',
                                    type: '2023'
                                  })
                                }}>
                                {classLabel}
                              </NavLink>
                            ))}
                          </div>
                        ))}
                      </div>
                    ) : null} */}
                    {/* <div className="navbar-explore-all-container-mobile">
                      <span
                        className="navbar-menu-toplink-mobile "
                        onClick={() => {
                          closeMenu()
                          this.props.setOnlineClassFilter({
                            level: 'All',
                            status: 'Ongoing',
                            subject: 'All',
                            type: '2024'
                          })
                          this.props.history.push(ALL_CLASSES)
                        }}
                        style={{ textAlign: 'center' }}>
                        Explore All Classes
                      </span>
                    </div> */}
                  </div>
                </div>
                {/* <NavLink to={TRIAL} className="navbar-menu-toplink-mobile" onClick={closeMenu}>
                  Trial Class
                </NavLink> */}
                {/* <NavLink to={BOOK_CLUB} className="navbar-menu-toplink-mobile" onClick={closeMenu}>
                  Book Club
                  {moment() < moment('2022-09-04T00:00:00Z') && (
                  <span className="link-tag-secondary align-self-start">NEW</span>
                  )}
                </NavLink> */}
                {moment() < moment('2022-05-26T00:00:00Z') && (
                  <NavLink to={WW_3000} className="navbar-menu-toplink-mobile" onClick={closeMenu}>
                    Wordly Wise i3000
                  </NavLink>
                )}
                <NavLink
                  to={ALL_CLASSES}
                  className="navbar-menu-toplink-mobile mb-0"
                  onClick={closeMenu}
                  style={{ height: '45px' }}>
                  <div className="d-flex h-100">
                    <span className="align-self-center">Explore All Classes</span>
                    <span className="link-tag-secondary align-self-start">{moment().year()}</span>
                  </div>
                </NavLink>
                <NavLink
                  to={FREE_SHARING}
                  className="navbar-menu-toplink-mobile mb-0"
                  onClick={closeMenu}
                  style={{ height: '45px' }}>
                  <div className="d-flex h-100">
                    <span className="align-self-center">Sharing By Experts</span>
                    <span className="link-tag align-self-start">Free</span>
                  </div>
                </NavLink>
                <NavLink to={ABOUT_US} className="navbar-menu-toplink-mobile" onClick={closeMenu}>
                  About Us
                </NavLink>
                {/* <div
                  className="navbar-classes-menu-column navbar-classes-menu-ad-mobile"
                  style={{ backgroundImage: promoSection.background_css }}>
                  <span>Tenopy 2023 Classes!</span>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 20, width: '50%' }}
                    onClick={() => {
                      closeMenu()
                      this.props.setOnlineClassFilter({
                        level: 'All',
                        status: 'Ongoing',
                        subject: 'All',
                        type: '2023'
                      })
                      this.props.history.push(ALL_CLASSES)
                    }}>
                    View
                  </Button>
                </div> */}
              </div>
            )}
          />

          <Link to={!!token ? APP : ROOT} className="navbar-brand">
            <img src={TenopyBrand} height="30" alt="tenopy logo" />
          </Link>

          <div className="ml-auto">
            <div className="d-flex align-items-center">
              {!!token ? (
                <NavLink to={APP} className={'px-3'}>
                  <AccountCircle />
                </NavLink>
              ) : pathname.startsWith('/login') || pathname.startsWith('/signup') ? (
                <>
                  {pathname.startsWith('/login') && (
                    <div
                      className="navbar-toplink"
                      onClick={() => {
                        this.redirectToLoginRegisterPage('Signup')
                      }}>
                      Sign Up
                    </div>
                  )}
                  {pathname.startsWith('/signup') && (
                    <div
                      className="navbar-toplink"
                      onClick={() => {
                        this.redirectToLoginRegisterPage('Login')
                      }}>
                      Log In
                    </div>
                  )}
                </>
              ) : (
                <div
                  className="navbar-toplink"
                  onClick={() => {
                    this.redirectToLoginRegisterPage('Login')
                  }}>
                  Log In
                </div>
              )}
              <div className="border-left px-3 pointer">
                <NavLink to={SHOPPING_CART}>
                  <Badge badgeContent={shoppingCart.item.length} color="primary">
                    <ShoppingCart className={`${pathname.startsWith('/cart') ? 'text-primary' : 'text-grey'}`} />
                  </Badge>
                </NavLink>
              </div>
            </div>
          </div>
        </nav>
      </header>
    )
  }
}

export default withRouter(MobileNavbar)
