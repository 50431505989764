import React, { Component } from 'react'
import { Link, NavLink, withRouter } from 'react-router-dom'
import { Flipper } from 'react-flip-toolkit'
import { Badge } from '@material-ui/core'
import { ShoppingCart, AccountCircle } from '@material-ui/icons'
import moment from 'moment'

import TenopyBrand from 'assets/tenopy-brand.svg'
import { USER_NAVIGATION_ACCESS } from 'constants/userTypes'
import {
  APP,
  ROOT,
  SHOPPING_CART,
  TRIAL,
  LOGIN,
  SIGNUP,
  ALL_CLASSES,
  CLASS_DETAILS,
  FAQ,
  ACCOUNT_ACTIVATION,
  FREE_SHARING,
  ABOUT_US
} from 'routes/routes'
import Navbar from './navbar'
import NavbarItem from './navbar/navbar-item'
import DropdownContainer from './dropdown-container'
import CatalogueDropdown from './dropdown-contents/catalogue'
import AboutDropdown from './dropdown-contents/about'

const navbarConfig = [
  { title: '', dropdown: null },
  { title: 'Class Catalogue', dropdown: CatalogueDropdown },
  { title: 'About', dropdown: AboutDropdown }
]

class AnimatedNavbar extends Component {
  state = {
    activeIndices: []
  }

  resetDropdownState = i => {
    this.setState({
      activeIndices: typeof i === 'number' ? [i] : [],
      animatingOut: false
    })
    delete this.animatingOutTimeout
  }

  onMouseEnter = i => {
    if (this.animatingOutTimeout) {
      clearTimeout(this.animatingOutTimeout)
      this.resetDropdownState(i)
      return
    }
    if (this.state.activeIndices[this.state.activeIndices.length - 1] === i) return

    this.setState(prevState => ({
      activeIndices: prevState.activeIndices.concat(i),
      animatingOut: false
    }))
  }

  onMouseLeave = () => {
    this.setState({
      animatingOut: true
    })
    this.animatingOutTimeout = setTimeout(this.resetDropdownState, this.props.duration)
  }

  goToPage = dest => () => {
    this.props.history.push(dest)
  }

  redirectToLoginRegisterPage = intent => {
    let redirectURL = intent === 'Login' ? LOGIN : SIGNUP
    let currentLocation = this.props.location.pathname
    let currentQuery = this.props.location.search
    if (currentLocation.startsWith(ALL_CLASSES) || currentLocation.startsWith(CLASS_DETAILS)) {
      redirectURL = `${redirectURL}?next=${APP}${currentLocation}`
    } else if (currentQuery) {
      if (currentLocation !== ACCOUNT_ACTIVATION) {
        redirectURL = `${redirectURL}${currentQuery}`
      }
    }
    this.props.history.push(redirectURL)
  }

  render() {
    const { duration, token, currentUser, pathname, shoppingCart, classCategories } = this.props
    let CurrentDropdown
    let PrevDropdown
    let direction

    const currentIndex = this.state.activeIndices[this.state.activeIndices.length - 1]
    const prevIndex =
      this.state.activeIndices.length > 1 && this.state.activeIndices[this.state.activeIndices.length - 2]

    if (typeof currentIndex === 'number') {
      if (navbarConfig[currentIndex].dropdown) {
        CurrentDropdown = navbarConfig[currentIndex].dropdown
      }
    }
    if (typeof prevIndex === 'number') {
      PrevDropdown = navbarConfig[prevIndex].dropdown
      direction = currentIndex > prevIndex ? 'right' : 'left'
    }

    const userType = currentUser ? currentUser._type : ''
    let userAccess = userType ? USER_NAVIGATION_ACCESS[userType] : []

    return (
      <header>
        <nav className="navbar fixed-top navbar-expand-md">
          <div className="d-flex align-items-center">
            <Link to={ROOT} className="navbar-brand">
              <img src={TenopyBrand} height="30" alt="tenopy logo" />
            </Link>

            <Flipper flipKey={currentIndex} spring={duration === 300 ? 'noWobble' : { stiffness: 10, damping: 10 }}>
              <Navbar onMouseLeave={this.onMouseLeave}>
                <NavbarItem
                  title="Class Catalogue"
                  badge={moment().year()}
                  index={0}
                  active={pathname.startsWith('/online-class')}
                  onMouseEnter={this.onMouseEnter}
                  linkOnly={true}
                  goToPage={this.goToPage(ALL_CLASSES)}
                />

                {/* <NavbarItem
                  title="Class Catalogue"
                  index={1}
                  badge={moment() < moment('2022-09-04T00:00:00Z') && 'New'}
                  active={pathname.startsWith('/online-class')}
                  expanded={currentIndex === 1}
                  onMouseEnter={this.onMouseEnter}
                  noLeftPadding={true}>
                  {currentIndex === 1 && (
                    <DropdownContainer
                      direction={direction}
                      animatingOut={this.state.animatingOut}
                      duration={duration}
                      additionalPixel={24}>
                      <CurrentDropdown
                        setOnlineClassFilter={this.props.setOnlineClassFilter}
                        goToPage={this.goToPage}
                        exitDropdown={this.onMouseLeave}
                        classCategories={classCategories}
                        onMouseLeave={this.onMouseLeave}
                      />
                      {PrevDropdown && <PrevDropdown />}
                    </DropdownContainer>
                  )}
                </NavbarItem> */}

                {/* <NavbarItem
                  title="Trial Class"
                  index={0}
                  active={pathname.startsWith('/trial')}
                  onMouseEnter={this.onMouseEnter}
                  linkOnly={true}
                  goToPage={this.goToPage(TRIAL)}
                /> */}

                <NavbarItem
                  title="Sharing By Experts"
                  badge="free"
                  index={0}
                  active={pathname.startsWith('/free-sharing')}
                  onMouseEnter={this.onMouseEnter}
                  linkOnly={true}
                  goToPage={this.goToPage(FREE_SHARING)}
                />

                <NavbarItem
                  title="About"
                  index={0}
                  active={pathname.startsWith('/about')}
                  onMouseEnter={this.onMouseEnter}
                  linkOnly={true}
                  goToPage={this.goToPage(ABOUT_US)}
                />
                {/* 
                <NavbarItem
                  title="About"
                  index={2}
                  active={pathname.startsWith('/about') || pathname.startsWith('/tenopy')}
                  expanded={currentIndex === 2}
                  onMouseEnter={this.onMouseEnter}
                  noLeftPadding={false}>
                  {currentIndex === 2 && (
                    <DropdownContainer
                      direction={direction}
                      animatingOut={this.state.animatingOut}
                      duration={duration}
                      additionalPixel={24}>
                      <CurrentDropdown exitDropdown={this.onMouseLeave} onMouseLeave={this.onMouseLeave} />
                      {PrevDropdown && <PrevDropdown />}
                    </DropdownContainer>
                  )}
                </NavbarItem> */}

                {/* <NavbarItem
                  title="FAQ"
                  index={0}
                  active={pathname.startsWith('/faq')}
                  onMouseEnter={this.onMouseEnter}
                  linkOnly={true}
                  goToPage={this.goToPage(FAQ)}
                /> */}
              </Navbar>
            </Flipper>
          </div>

          <div className="d-flex align-items-center">
            {!!token ? (
              <NavLink to={APP} className={'px-3'}>
                <AccountCircle />
              </NavLink>
            ) : (
              <>
                <div
                  className={`navbar-toplink px-3 mr-0 ${pathname.startsWith('/signup') ? 'is_active' : ''}`}
                  onClick={() => {
                    this.redirectToLoginRegisterPage('Signup')
                  }}>
                  Sign Up
                </div>
                <div
                  className={`navbar-toplink border-left pl-4 pr-3 ${pathname.startsWith('/login') ? 'is_active' : ''}`}
                  onClick={() => {
                    this.redirectToLoginRegisterPage('Login')
                  }}>
                  Log In
                </div>
              </>
            )}
            {(!token || userAccess.includes('cart')) && (
              <div className="border-left px-3 pointer">
                <NavLink to={SHOPPING_CART}>
                  <Badge badgeContent={shoppingCart.item.length} color="primary">
                    <ShoppingCart className={`${pathname.startsWith('/cart') ? 'text-primary' : 'text-grey'}`} />
                  </Badge>
                </NavLink>
              </div>
            )}
          </div>
        </nav>
      </header>
    )
  }
}

export default withRouter(AnimatedNavbar)
