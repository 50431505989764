import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Pluralize from 'react-pluralize'

import { Button, Card, CircularProgress, Paper } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Event as EventIcon, CloudDownload as CloudDownloadIcon, PersonOutline as TutorIcon } from '@material-ui/icons'
import moment from 'moment'
import _ from 'lodash'

import agent from 'agent'
import {
  GET_PENDING_SUBMISSION_HOMEWORK,
  GET_UPCOMING_LESSONS,
  GET_FILTERED_SUBMISSION_LIST,
  GET_REPORT_LIST,
  GET_REPORT_PDF
} from 'constants/actionTypes'
import { CLASS_TYPE_RECORDING, subjectColors } from 'constants/generalTypes'
import { MY_PAID_CLASSES } from 'routes/studentRoutes'
import JoinLessonButton from 'components/online-class/button-join-lesson'
import PreviewReportDialog from 'components/online-class/dialog-preview-report'
import LessonMaterialSummary from './widget-resource-summary'
import LessonDetailDialog from './dialog-lesson-detail'
import SubmitHomeworkDialog from './dialog-submit-homework'
import TrialBanner from 'components/misc/trial-banner'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  loadingUpcomingLessons: state.lessons.loadingUpcomingLessons,
  upcomingLessons: state.lessons.upcomingLessons,
  loadingPendingSubmission: state.homework.loadingPendingSubmission,
  pendingSubmissionList: state.homework.pendingSubmissionList,
  loadingUndoneSubmission: state.submission.loadingFilteredList,
  undoneSubmissionList: state.submission.filteredList,
  loadingReports: state.report.loadingReports,
  reportList: state.report.reportList,
  loadingReportPDF: state.report.loadingPDF
})

const mapDispatchToProps = dispatch => ({
  getUpcomingLessons: () =>
    dispatch({ type: GET_UPCOMING_LESSONS, payload: agent.Lessons.getList('upcoming', { max: 20, offset: 0 }) }),
  getPendingSubmissionHomework: () =>
    dispatch({ type: GET_PENDING_SUBMISSION_HOMEWORK, payload: agent.Homework.getPendingSubmission() }),
  getUndoneSubmissionList: filter =>
    dispatch({ type: GET_FILTERED_SUBMISSION_LIST, payload: agent.Submission.getList(filter), filter }),
  getNewReleasedReports: reqParams => dispatch({ type: GET_REPORT_LIST, payload: agent.Report.getList(reqParams) }),
  getReportPDF: (reportId, fileName) =>
    dispatch({ type: GET_REPORT_PDF, payload: agent.Report.getPDF(reportId), fileName })
})

class Dashboard extends React.Component {
  state = {
    quickStat: {
      upcomingCount: 0,
      upcomingText: 'upcoming lessons',
      pendingSubmissionHomeworkCount: 0,
      markedHomeworkCount: 0
    },
    upcomingTitle: 'Upcoming Lessons',
    upcomingLessons: [],
    selectedLessonId: '',
    isLessonDetailDialogOpen: false,
    selectedHomework: '',
    isSubmitHomeworkDialogOpen: false,
    isPreviewDialogOpen: false,
    selectedReport: null
  }

  componentDidMount() {
    this.props.getUpcomingLessons()
    this.props.getPendingSubmissionHomework()
    this.props.getUndoneSubmissionList({
      status: 'MARKED',
      viewed: false,
      updated: {
        $gte: moment().subtract(1, 'month').toISOString()
      },
      max: 30,
      offset: 0
    })
    this.props.getNewReleasedReports(`opt=new_release`)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loadingUpcomingLessons && !this.props.loadingUpcomingLessons) {
      const { upcomingLessons } = this.props
      const { quickStat } = this.state

      if (upcomingLessons.length > 0) {
        const now = moment()
        const nextWeek = moment().add(1, 'week')
        const nextLessonDate = moment(upcomingLessons[0].scheduled_on)

        const isNextLessonThisWeek = nextLessonDate.isSame(now, 'isoWeek')
        const isNextLessonNextWeek = nextLessonDate.isSame(nextWeek, 'isoWeek')
        let displayUpcomingLessons = []
        let upcomingTitle = 'upcoming Lessons',
          upcomingText = 'upcoming lessons'
        if (isNextLessonThisWeek) {
          upcomingTitle = 'This Week Lessons'
          upcomingText = 'upcoming lessons this week'
          displayUpcomingLessons = _.filter(upcomingLessons, o => {
            return moment(o.scheduled_on).isSame(now, 'isoWeek') && o.class.class_type !== CLASS_TYPE_RECORDING
          })
        } else if (isNextLessonNextWeek) {
          upcomingTitle = 'Next Week Lessons'
          upcomingText = 'upcoming lessons next week'
          displayUpcomingLessons = _.filter(upcomingLessons, o => {
            return moment(o.scheduled_on).isSame(nextWeek, 'isoWeek') && o.class.class_type !== CLASS_TYPE_RECORDING
          })
        } else {
          upcomingTitle = 'Upcoming Lessons'
          upcomingText = 'upcoming lessons'
          displayUpcomingLessons = _.filter(upcomingLessons, o => {
            return (
              moment(o.scheduled_on).isSame(nextLessonDate, 'isoWeek') && o.class.class_type !== CLASS_TYPE_RECORDING
            )
          })
        }

        this.setState({
          upcomingTitle,
          upcomingLessons: displayUpcomingLessons,
          quickStat: {
            ...quickStat,
            upcomingText: upcomingText,
            upcomingCount: displayUpcomingLessons.length
          }
        })
      }
    }
  }

  toggleLessonDetailsDialog = lessonId => () => {
    const { upcomingLessons } = this.props
    const selectedLesson = _.find(upcomingLessons, o => {
      return o._id === lessonId
    })

    this.setState({
      selectedLesson,
      isLessonDetailDialogOpen: !this.state.isLessonDetailDialogOpen
    })
  }

  toggleSubmitHomeworkDialog = homeworkData => () => {
    this.setState({
      selectedHomework: homeworkData,
      isSubmitHomeworkDialogOpen: !this.state.isSubmitHomeworkDialogOpen
    })
  }

  submissionViewed = submissionId => () => {
    agent.Submission.update('classic', submissionId, { viewed: true, status: 'MARKED' })
  }

  goToSection = sectionId => () => {
    let sectionDOM = document.getElementById(sectionId)
    if (sectionDOM) {
      window.scrollTo({ top: sectionDOM.offsetTop - 70, behavior: 'smooth' })
    }
  }

  togglePreviewDialog = reportData => () => {
    this.setState({
      isPreviewDialogOpen: !this.state.isPreviewDialogOpen,
      selectedReport: reportData
    })
  }

  clickDownloadPDF = reportData => () => {
    const { currentUser } = this.props
    this.props.getReportPDF(reportData._id, `${reportData.title} - ${currentUser.name}.pdf`)
  }

  render() {
    const {
      loadingUpcomingLessons,
      loadingPendingSubmission,
      pendingSubmissionList,
      loadingUndoneSubmission,
      undoneSubmissionList,
      reportList,
      loadingReportPDF,
      currentUser
    } = this.props
    const {
      quickStat,
      upcomingTitle,
      upcomingLessons,
      isLessonDetailDialogOpen,
      selectedLesson,
      isSubmitHomeworkDialogOpen,
      selectedHomework,
      isPreviewDialogOpen,
      selectedReport
    } = this.state

    return (
      <>
        <div className="dashboard animated slideInUpTiny animation-duration-3">
          <div className="app-wrapper">
            <TrialBanner />
            <div className="row">
              <div className="col-md-4 col-12">
                <Paper
                  elevation={2}
                  className="mb-2 mb-md-0 p-3 pointer"
                  onClick={this.goToSection('upcoming-lessons')}
                  style={{ backgroundImage: 'linear-gradient(90deg,#3f51b5 0,#1fb6fc)' }}>
                  <h1 className="title--rubik text-white mb-1">{quickStat.upcomingCount}</h1>
                  <div className="text-large text-white">
                    {quickStat.upcomingCount > 1
                      ? quickStat.upcomingText
                      : quickStat.upcomingText.replace('lessons', 'lesson')}
                  </div>
                </Paper>
              </div>
              <div className="col-md-4 col-12">
                <Paper
                  elevation={2}
                  className="mb-2 mb-md-0 p-3 pointer"
                  onClick={this.goToSection('pending-submission')}
                  style={{ backgroundImage: 'linear-gradient(90deg,#aa3c6d 0,#ff9800)' }}>
                  <h1 className="title--rubik text-white mb-1">{pendingSubmissionList.length}</h1>
                  <div className="text-large text-white">
                    <Pluralize singular="homework" count={pendingSubmissionList.length} showCount={false} />
                    &nbsp;need submission
                  </div>
                </Paper>
              </div>
              <div className="col-md-4 col-12">
                <Paper
                  elevation={2}
                  className="mb-2 mb-md-0 p-3 pointer"
                  onClick={this.goToSection('undone-submission')}
                  style={{ backgroundImage: 'linear-gradient(90deg,#2b2d5d 0,#39bf35)' }}>
                  <h1 className="title--rubik text-white mb-1">{undoneSubmissionList.data.length}</h1>
                  <div className="text-large text-white">
                    marked&nbsp;
                    <Pluralize singular="homework" count={undoneSubmissionList.data.length} showCount={false} />
                    &nbsp;returned
                  </div>
                </Paper>
              </div>
            </div>
          </div>

          {reportList.length > 0 && (
            <>
              <Paper elevation={2} className="app-wrapper" id="upcoming-lessons">
                <div className="row align-items-center">
                  <div className="col">
                    <h2 className="mb-0">Learning Progress Report</h2>
                  </div>
                </div>
              </Paper>

              <div className="app-wrapper">
                {reportList.map(reportData => {
                  const subject = reportData.class.subject
                  const subjectColor = !!subjectColors[subject] ? subjectColors[subject] : subjectColors.other

                  return (
                    <Card className="mb-3 link-card" elevation={3} key={reportData._id}>
                      <div className="text-muted px-3" style={{ borderTop: `3px solid ${subjectColor}` }} />
                      <div className="d-flex justify-content-between align-items-center px-3 py-3">
                        <div>
                          <div className="text-muted">{reportData.class.name}</div>
                          <h2 className="title--rubik mb-0">{reportData.title}</h2>
                        </div>
                        <div className="manage-margin">
                          <Button variant="contained" color="primary" onClick={this.togglePreviewDialog(reportData)}>
                            View
                          </Button>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={this.clickDownloadPDF(reportData)}
                            disabled={loadingReportPDF}>
                            Download PDF
                            {loadingReportPDF && <CircularProgress color="primary" className="ml-2" size={15} />}
                          </Button>
                        </div>
                      </div>
                    </Card>
                  )
                })}
              </div>
            </>
          )}

          <Paper elevation={2} className="app-wrapper" id="upcoming-lessons">
            <div className="row align-items-center">
              <div className="col">
                <h2 className="mb-0">{upcomingTitle}</h2>
              </div>
            </div>
          </Paper>

          <div className="app-wrapper">
            {loadingUpcomingLessons && (
              <div className="text-center">
                <CircularProgress size={30} />
              </div>
            )}

            {!loadingUpcomingLessons && upcomingLessons.length === 0 && (
              <div className="col-12 my-3">
                <Alert severity="info">No pending submission homework</Alert>
              </div>
            )}

            {upcomingLessons.map((lesson, index) => {
              const subject = lesson.class.subject
              const subjectColor = !!subjectColors[subject] ? subjectColors[subject] : subjectColors.other
              const tutorNames = _.map(lesson.tutor, 'name')

              let lessonTimeDescription = ''
              if (moment() > moment(lesson.scheduled_on)) {
                lessonTimeDescription = 'Currently in session'
              } else {
                lessonTimeDescription = `In ${moment().to(lesson.scheduled_on, true)}`
              }

              return (
                <Card className="mb-3 link-card" elevation={3} key={index}>
                  <div className="text-muted px-3 pt-2" style={{ borderTop: `3px solid ${subjectColor}` }}>
                    {lesson.class.name}
                  </div>

                  <div className="px-3 py-2">
                    <h2 className="title--rubik">
                      {lesson.meta?.index && <span>Lesson {lesson.meta.index}: </span>}
                      {lesson.name}
                    </h2>
                    <div className="row">
                      <div className="col-md-7 col-12">
                        <div className="d-flex align-items-center mb-1">
                          <TutorIcon className="text-muted mr-2" />
                          Teacher {tutorNames.join(', Teacher ')}
                        </div>
                        <div className="d-flex align-items-center mb-1">
                          <EventIcon className="text-muted mr-2" />
                          {moment(lesson.scheduled_on).format('lll')}
                          &nbsp;-&nbsp;
                          {moment(lesson.ended_on).format('h:mm A')}
                          <div
                            className="ml-2 text-small"
                            style={{
                              border: '1px solid #2196F3',
                              borderRadius: '5px',
                              padding: '2px 7px',
                              color: '#2196F3'
                            }}>
                            {lessonTimeDescription}
                          </div>
                        </div>
                        <JoinLessonButton lessonData={lesson} />
                      </div>
                      <div className="col-md-5 col-12">
                        <div className="row justify-content-end">
                          <div className="col-md-10 col-12 mb-2">
                            <LessonMaterialSummary
                              lessonId={lesson._id}
                              viewLessonDetails={this.toggleLessonDetailsDialog}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              )
            })}

            <LessonDetailDialog
              isOpen={isLessonDetailDialogOpen}
              close={this.toggleLessonDetailsDialog()}
              lessonData={selectedLesson}
            />
          </div>

          <Paper elevation={2} className="app-wrapper" id="pending-submission">
            <div className="row align-items-center">
              <div className="col">
                <h2 className="mb-0">Pending Homework Submission</h2>
              </div>
            </div>
          </Paper>

          <div className="app-wrapper">
            {loadingPendingSubmission && (
              <div className="text-center">
                <CircularProgress size={30} />
              </div>
            )}

            <div className="row">
              {!loadingPendingSubmission && pendingSubmissionList.length === 0 && (
                <div className="col-12 my-3">
                  <Alert severity="info">No pending submission homework</Alert>
                </div>
              )}

              {pendingSubmissionList.map((homework, index) => {
                const subject = homework.lesson.class.subject
                const subjectColor = !!subjectColors[subject] ? subjectColors[subject] : subjectColors.other
                const overdue = moment() > moment(homework.due_on)

                let lessonTitle = homework.lesson.name
                if (homework.lesson.meta?.index) {
                  lessonTitle = `Lesson ${homework.lesson.meta.index}: ${homework.lesson.name}`
                }

                return (
                  <div className="col-md-4 col-12 link-card mb-4" key={index}>
                    <Paper elevation={3}>
                      <div style={{ borderTop: `3px solid ${subjectColor}` }} />
                      <div className="p-2">
                        <div className="title-rubik text-muted">Class:</div>
                        <div className="text-large px-2 mb-2">{homework.lesson.class.name}</div>
                        <div className="title-rubik text-muted ">Lesson:</div>
                        <div className="text-large px-2 mb-2">{lessonTitle}</div>
                        <div className="title-rubik text-muted ">Due on:</div>
                        <div className="d-flex align-items-center text-large font-weight-semibold px-2 mb-2">
                          <span>{moment(homework.due_on).format('lll')}</span>
                          {overdue && (
                            <span className="tag danger-tag ml-2" style={{ padding: '0.1rem' }}>
                              Overdue
                            </span>
                          )}
                        </div>
                        <div className="title-rubik text-muted mb-1">Resources:</div>
                        <div className="mb-3">
                          {homework.attachment.map((file, index) => (
                            <div className="card px-3 py-2 mb-2" key={index}>
                              <a href={file.url} download>
                                <div className="row align-items-center ">
                                  <div className="col">
                                    <span className="link">{file.name}</span>
                                  </div>
                                  <div className="col-auto">
                                    <CloudDownloadIcon />
                                  </div>
                                </div>
                              </a>
                            </div>
                          ))}
                        </div>

                        {homework.submitted_files?.length > 0 && (
                          <>
                            <div className="title-rubik text-muted mb-1">Submitted:</div>
                            <div className="mb-3">
                              {homework.submitted_files.map((file, index) => (
                                <div className="card px-3 py-2 mb-2" key={index}>
                                  <a href={file.url} download>
                                    <div className="row align-items-center ">
                                      <div className="col">
                                        <span className="link">{file.name}</span>
                                      </div>
                                      <div className="col-auto">
                                        <CloudDownloadIcon />
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              ))}
                            </div>
                          </>
                        )}

                        <Button
                          className="my-2"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={homework?.submitted}
                          disableRipple
                          disableFocusRipple
                          onClick={this.toggleSubmitHomeworkDialog(homework)}>
                          {homework?.submitted ? 'Submitted Successfully' : 'Submit Homework'}
                        </Button>
                      </div>
                    </Paper>
                  </div>
                )
              })}
            </div>

            <SubmitHomeworkDialog
              isOpen={isSubmitHomeworkDialogOpen}
              close={this.toggleSubmitHomeworkDialog()}
              homeworkData={selectedHomework}
            />
          </div>

          <Paper elevation={2} className="app-wrapper" id="undone-submission">
            <div className="row align-items-center">
              <div className="col">
                <h2 className="mb-0">New marked homework from the teacher</h2>
              </div>
            </div>
          </Paper>

          <div className="app-wrapper">
            {loadingUndoneSubmission && (
              <div className="text-center">
                <CircularProgress size={30} />
              </div>
            )}

            <div className="row">
              {!loadingUndoneSubmission && undoneSubmissionList.data.length === 0 && (
                <div className="col-12 my-2">
                  <Alert severity="info">
                    No new marked homework returned. Please click "
                    <span
                      className="link"
                      onClick={() => {
                        this.props.history.push(MY_PAID_CLASSES)
                      }}>
                      My Classes
                    </span>
                    " to see past marked homework.
                  </Alert>
                </div>
              )}

              {undoneSubmissionList.data.map((submission, index) => {
                const subject = submission.homework.lesson.class.subject
                const subjectColor = !!subjectColors[subject] ? subjectColors[subject] : subjectColors.other
                const markedHomework = []
                const audioComments = []
                if (submission.marked_attachment.length > 0) {
                  submission.marked_attachment.forEach(file => {
                    if (file.mimetype === 'audio/wav') {
                      audioComments.push(file)
                    } else {
                      markedHomework.push(file)
                    }
                  })
                }

                let lessonTitle = submission.homework.lesson.name
                if (submission.homework.lesson.meta?.index) {
                  lessonTitle = `Lesson ${submission.homework.lesson.meta.index}: ${submission.homework.lesson.name}`
                }

                return (
                  <div className="col-md-6 col-12 mb-4" key={index}>
                    <Paper elevation={4} className="link-card">
                      <div style={{ borderTop: `3px solid ${subjectColor}` }} />
                      <div className="p-2">
                        <div className="title-rubik text-muted mb-1">Class:</div>
                        <div className="text-large px-2 mb-2">{submission.homework.lesson.class.name}</div>
                        <div className="title-rubik text-muted mb-1">Lesson:</div>
                        <div className="text-large px-2 mb-2">{lessonTitle}</div>
                        <div className="title-rubik text-muted my-1">Submitted:</div>
                        {submission.attachment.map((file, index) => (
                          <div className="card px-3 py-2 mb-2" key={index}>
                            <a href={file.url} download>
                              <div className="row align-items-center ">
                                <div className="col">
                                  <span className="link">{file.name}</span>
                                </div>
                                <div className="col-auto">
                                  <CloudDownloadIcon />
                                </div>
                              </div>
                            </a>
                          </div>
                        ))}

                        {markedHomework.length > 0 && (
                          <>
                            <div className="title-rubik text-muted my-1">Returned:</div>
                            {markedHomework.map((file, index) => (
                              <div
                                className="card px-3 py-2 mb-2"
                                key={index}
                                onClick={this.submissionViewed(submission._id)}>
                                <a href={file.url} download>
                                  <div className="row align-items-center ">
                                    <div className="col">
                                      <span className="link">{file.name}</span>
                                    </div>
                                    <div className="col-auto">
                                      <CloudDownloadIcon />
                                    </div>
                                  </div>
                                </a>
                              </div>
                            ))}
                          </>
                        )}

                        {audioComments.length > 0 && (
                          <>
                            <div className="title-rubik text-muted my-1">Audio Comments:</div>
                            {audioComments.map((file, index) => (
                              <audio
                                style={{ width: '100%', height: '35px' }}
                                className="px-2"
                                controls
                                src={file.url}
                                key={file.name}>
                                D
                              </audio>
                            ))}
                          </>
                        )}

                        <div className="title-rubik text-muted my-1">Comments:</div>
                        <div className="text-large px-2 mb-2">
                          {submission.comment ? (
                            <div dangerouslySetInnerHTML={{ __html: submission.comment }} />
                          ) : (
                            <span>N/A</span>
                          )}
                        </div>
                      </div>
                    </Paper>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <PreviewReportDialog
          isOpen={isPreviewDialogOpen}
          close={this.togglePreviewDialog()}
          studentData={currentUser}
          reportData={selectedReport}
        />
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard))
