import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { TRIAL } from 'routes/routes'

const ClassExperience = props => {
  return (
    <section className="bg-white">
      <div className="container">
        <div className="row align-items-center mb-5">
          <div className="col-12 col-md-5">
            <img
              src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/home-classroom-experience.jpg"
              alt="Classroom experience"
              width="85%"
              className="mb-3 shadow"
            />
          </div>
          <div className="col-12 col-md-7">
            <div
              className="mb-3"
              style={{
                fontSize: '2.3rem',
                fontFamily: "'Rubik', sans-serif",
                lineHeight: 1.2,
                color: '#000000'
              }}>
              We leave no child behind in class
            </div>
            <div className="text-large mb-3">
              We understand parents' concern when it comes to live online learning for your child and address each one
              with our proprietary tutoring platform and top teachers.
            </div>
            <Button
              variant="contained"
              size="large"
              className="btn--purple"
              onClick={() => {
                props.history.push(TRIAL)
              }}>
              Experience the Tenopy Classroom with a FREE trial
            </Button>
          </div>
        </div>

        <div className="row justify-content-center align-items-center mb-4">
          <div className="col-12 col-md-6 mb-5 text-center">
            <img
              src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/home-enhanced-quiz.gif"
              alt="combat zoom fatigue"
              width="85%"
            />
          </div>
          <div className="col-12 col-md-6">
            <div className="h1" style={{ color: '#000000' }}>
              1. Keep Students Interested
            </div>
            <div className="text-large text-justify">
              We encourage active, hands-on learning through well-designed on-screen activities that help students to
              think, apply and internalise. Our proprietary technology also supports two-way learning so classes are
              never boring.
            </div>
          </div>
        </div>

        <div className="row justify-content-center align-items-center mb-4">
          <div className="col-12 col-md-6 mb-5 text-center">
            <img
              src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/home-live-typing.gif"
              alt="combat zoom fatigue"
              width="85%"
            />
          </div>
          <div className="col-12 col-md-6">
            <div className="h1" style={{ color: '#000000' }}>
              2. Ensure Learning Takes Place
            </div>
            <div className="text-large text-justify">
              Our teachers can identify learning gaps in real-time using a "live" students' dashboard. This exclusive
              tracking function allows teachers to see answers as they are typed out and step in to give targeted
              guidance on the spot.
            </div>
          </div>
        </div>

        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-6 mb-5 text-center">
            <img
              src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/home-unfocused-mode.gif"
              alt="combat zoom fatigue"
              width="85%"
            />
          </div>
          <div className="col-12 col-md-6">
            <div className="h1" style={{ color: '#000000' }}>
              3. Staying on Task
            </div>
            <div className="text-large text-justify">
              Our technology alerts teachers when students switch tabs away from the lesson page - No more web surfing
              during class!
              <br />
              <br />
              Virtual cookies are also awarded to motivate students after an already exhausting day at school.
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default withRouter(ClassExperience)
