import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { USER_PROFILE, USER_PASSWORD_EDIT, MY_CLASS_LIST, MY_CLASS_DETAILS, APP, FAQ } from './routes'

import Classes from '../components/tutor/Classes'

import SubmissionList from '../components/tutor/Submission/List'

import FAQPage from 'pages/public/faq'
import UserProfilePage from 'pages/app/profile/tutor'
import EditPasswordPage from 'pages/app/profile/edit-password'
import ClassDetailPage from 'pages/app/my-class/detail/tutor'
import LessonPage from 'pages/app/my-lesson/tutor'

/*Route Definitions*/
export const LESSON_PAGE = '/app/class/:classId/lesson/:lessonId'
export const SUBMISSION_LIST = '/app/class/:classId/submission/:homeworkId'

/*Routes*/
export const TutorRoutes = () => (
  <Switch>
    <Route path={USER_PASSWORD_EDIT} component={EditPasswordPage} />
    <Route path={USER_PROFILE} component={UserProfilePage} />
    <Route path={SUBMISSION_LIST} component={SubmissionList} />
    <Route path={LESSON_PAGE} component={LessonPage} />
    <Route exact path={MY_CLASS_LIST} component={Classes} />
    <Route path={MY_CLASS_DETAILS} component={ClassDetailPage} />
    <Route path={`${APP}${FAQ}`} component={FAQPage} />
    <Redirect from="*" to={MY_CLASS_LIST} />
  </Switch>
)
