import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField
} from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'

import agent from 'agent'
import countrycodes from 'assets/country_code.json'
import educationLevels from 'assets/education_level.json'
import { UPDATE_USER } from 'constants/actionTypes'
import { USER_PROFILE } from 'routes/routes'
import { validateTextEntry, validatePassword } from 'utils/formValidation'
import { NumberOnly } from 'utils/format'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  updateUserInProgress: state.common.updateUserInProgress
})

const mapDispatchToProps = dispatch => ({
  updateUser: user => dispatch({ type: UPDATE_USER, payload: agent.Auth.updateUser(user), user })
})

class Edit extends React.Component {
  state = {
    email: '',
    name: '',
    dateOfBirth: '',
    gender: '',
    phoneCountryCode: 65,
    phone: '',
    level: '',
    othersLevel: '',
    showOthersLevel: false,
    school: '',
    firstLanguage: '',
    parentSalute: '',
    parentName: '',
    parentEmail: '',
    parentPhoneCountryCode: 65,
    parentPhone: '',
    contactWhatsapp: false,
    contactWechat: false,
    contactWechatId: '',
    contactOthers: false,
    contactOthersChannel: '',
    useStudentPhone: false,
    useStudentEmail: false,
    formError: {}
  }

  componentDidMount() {
    const { currentUser } = this.props
    const { parent } = currentUser

    let displayLevel = '',
      showOthersLevel = false,
      othersLevel = ''
    if (currentUser.level) {
      let levelObj = _.find(educationLevels, function (o) {
        return o.code === currentUser.level
      })
      if (levelObj) {
        displayLevel = levelObj.code
      } else {
        showOthersLevel = true
        othersLevel = currentUser.level
        displayLevel = 'others'
      }
    }

    let parentPhoneCountryCode = 65
    if (parent?.phone_country_code) {
      parentPhoneCountryCode = parent.phone_country_code
    } else {
      parentPhoneCountryCode = currentUser.phone_country_code
    }

    this.setState({
      email: currentUser.email,
      name: currentUser.name ?? '',
      dateOfBirth: currentUser.date_of_birth
        ? moment(currentUser.date_of_birth).startOf('day').format('YYYY-MM-DD')
        : '',
      gender: currentUser.gender ?? '',
      phoneCountryCode: currentUser.phone_country_code,
      phone: currentUser.phone ?? '',
      level: displayLevel,
      othersLevel,
      showOthersLevel,
      school: currentUser.school ?? '',
      firstLanguage: currentUser.first_language ?? '',
      parentSalute: parent?.salute ?? 'Mr',
      parentName: parent?.name ?? '',
      parentEmail: parent?.email ?? '',
      parentPhoneCountryCode,
      parentPhone: parent?.phone ?? '',
      contactWhatsapp: parent?.contact_preference?.whatsapp ? true : false,
      contactWechat: parent?.contact_preference?.wechat ? true : false,
      contactWechatId: parent?.contact_preference?.wechat_id,
      contactOthers: parent?.contact_preference?.others ? true : false,
      contactOthersChannel: parent?.contact_preference?.others
    })
  }

  validateFormElement = (type, customMsg) => {
    let errorMsg

    if (type === 'password') {
      errorMsg = validatePassword(this.state.password)
    } else {
      if (customMsg) {
        errorMsg = validateTextEntry(this.state[type], type, customMsg)
      } else {
        errorMsg = validateTextEntry(this.state[type], type)
      }
    }

    let tempFormError = this.state.formError
    if (errorMsg) {
      tempFormError[type] = errorMsg
    } else {
      delete tempFormError[type]
    }
    this.setState({ formError: tempFormError })
  }

  onFocusFormElement = e => {
    let type = e.target.name
    let tempFormError = this.state.formError
    delete tempFormError[type]
    this.setState({ formError: tempFormError })
  }

  onChangeFormElement = e => {
    let type = e.target.name
    let value = e.target.value
    this.setState({ [type]: value })

    if (type === 'level') {
      let tempFormError = this.state.formError
      delete tempFormError[type]
      this.setState({ formError: tempFormError })
      if (value === 'others') {
        this.setState({ showOthersLevel: true })
      } else {
        this.setState({ showOthersLevel: false })
      }
    }
  }

  submitChanges = () => {
    this.validateFormElement('name')
    this.validateFormElement('phone')
    if (this.state.level === 'others') {
      this.validateFormElement('othersLevel', 'your level')
    } else {
      this.validateFormElement('level')
    }
    this.validateFormElement('school')
    if (_.isEmpty(this.state.formError)) {
      const {
        name,
        dateOfBirth,
        gender,
        phoneCountryCode,
        phone,
        level,
        othersLevel,
        school,
        firstLanguage,
        parentSalute,
        parentName,
        parentEmail,
        parentPhoneCountryCode,
        parentPhone,
        contactWhatsapp,
        contactWechat,
        contactWechatId,
        contactOthersChannel
      } = this.state

      let details = {
        name,
        phone,
        gender,
        date_of_birth: moment(dateOfBirth).startOf('day').toISOString(),
        phone_country_code: phoneCountryCode,
        level: level === 'others' ? othersLevel : level,
        school,
        first_language: firstLanguage,
        parent: {
          salute: parentSalute,
          name: parentName,
          email: parentEmail,
          phone_country_code: parentPhoneCountryCode,
          phone: parentPhone,
          contact_preference: {
            whatsapp: contactWhatsapp,
            wechat: contactWechat,
            wechat_id: contactWechatId ?? '',
            other: contactOthersChannel ?? ''
          }
        }
      }
      this.props.updateUser(details)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.phoneCountryCode !== this.state.phoneCountryCode || prevState.phone !== this.state.phone) {
      if (this.state.useStudentPhone) {
        this.setState({
          parentPhoneCountryCode: this.state.phoneCountryCode,
          parentPhone: this.state.phone
        })
      }
    }

    if (prevProps.updateUserInProgress && !this.props.updateUserInProgress) {
      this.props.history.push(USER_PROFILE)
    }
  }

  render() {
    let { updateUserInProgress } = this.props
    let {
      email,
      name,
      dateOfBirth,
      gender,
      phoneCountryCode,
      phone,
      level,
      othersLevel,
      showOthersLevel,
      school,
      firstLanguage,
      formError,
      parentEmail,
      useStudentEmail,
      parentSalute,
      parentName,
      parentPhoneCountryCode,
      parentPhone,
      useStudentPhone,
      contactWhatsapp,
      contactWechat,
      contactWechatId,
      contactOthers,
      contactOthersChannel
    } = this.state

    return (
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <Paper elevation={2} className="app-wrapper">
          <h2 className="mb-0">Update Details</h2>
        </Paper>
        <div className="app-wrapper">
          <div className="container">
            <Card>
              <CardContent>
                <h2 className="mb-0">Student</h2>
                <small className="text-muted">
                  <i className="fa fa-exclamation-circle mr-2" />
                  This is important for your tutors to recognise and address you during lessons.
                </small>
                <div className="row align-items-center mt-3">
                  <div className="col-md-3 col-5 font-weight-medium">Email</div>
                  <div className="col-md-9 col-7">
                    <Input className="w-100 mb-3" type="email" name="email" value={email} disabled />
                  </div>

                  <div className="col-md-3 col-5 font-weight-medium">Name</div>
                  <div className="col-md-9 col-7">
                    <TextField
                      className="w-100 mb-3"
                      type="text"
                      name="name"
                      onChange={this.onChangeFormElement}
                      value={name}
                      error={!!formError.name}
                      helperText={formError.name}
                      onFocus={this.onFocusFormElement}
                    />
                  </div>

                  <div className="col-md-3 col-5 font-weight-medium mb-2">Gender</div>
                  <div className="col-md-9 col-7 mb-2">
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="gender"
                        name="gender"
                        value={gender}
                        onChange={this.onChangeFormElement}>
                        <FormControlLabel value="f" control={<Radio color="primary" />} label="Female" />
                        <FormControlLabel value="m" control={<Radio color="primary" />} label="Male" />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  <div className="col-md-3 col-5 font-weight-medium mb-2">Date of birth</div>
                  <div className="col-md-9 col-7 mb-2">
                    <TextField
                      type="date"
                      className="mb-3"
                      name="dateOfBirth"
                      onChange={this.onChangeFormElement}
                      value={dateOfBirth}
                      error={!!formError.dateOfBirth}
                      helperText={formError.dateOfBirth}
                      onFocus={this.onFocusFormElement}
                    />
                  </div>

                  <div className="col-md-3 col-5 font-weight-medium">Phone number</div>
                  <div className="col-md-9 col-7">
                    <div className="row">
                      <div className="col-auto">
                        <Select
                          value={Number(phoneCountryCode)}
                          onChange={this.onChangeFormElement}
                          className="mt-0"
                          name="phoneCountryCode">
                          {countrycodes.map(cc => {
                            return (
                              <MenuItem key={cc.code} value={cc.code}>
                                <span className={`flag ${cc.flag} mr-1`} /> +{cc.code}{' '}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </div>
                      <div className="col">
                        <TextField
                          className="w-100 mb-3"
                          type="tel"
                          name="phone"
                          onChange={this.onChangeFormElement}
                          value={phone}
                          error={!!formError.phone}
                          helperText={formError.phone}
                          onFocus={this.onFocusFormElement}
                          InputProps={{ inputComponent: NumberOnly }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-5 font-weight-medium">Level / Grade</div>
                  <div className="col-md-5 col-7">
                    <FormControl className="w-100 mb-2" error={!!this.state.formError.level}>
                      <Select value={level} onChange={this.onChangeFormElement} name="level">
                        {educationLevels.map(level => {
                          return (
                            <MenuItem key={level.code} value={level.code}>
                              {level.name}
                            </MenuItem>
                          )
                        })}
                      </Select>
                      {formError.level ? (
                        <FormHelperText>{formError.level}</FormHelperText>
                      ) : (
                        <FormHelperText>Grade/ Level in School (as of January 2021)</FormHelperText>
                      )}
                    </FormControl>
                  </div>
                  <div className="col-md-4 col-12">
                    {showOthersLevel && (
                      <TextField
                        type="text"
                        placeholder="Please specify your level"
                        name="othersLevel"
                        onChange={this.onChangeFormElement}
                        error={!!formError.othersLevel}
                        helperText={formError.othersLevel}
                        onFocus={this.onFocusFormElement}
                        fullWidth
                        defaultValue={othersLevel}
                        margin="normal"
                        className="mt-0 mb-2"
                      />
                    )}
                  </div>

                  <div className="col-md-3 col-5 font-weight-medium">School</div>
                  <div className="col-md-9 col-7">
                    <TextField
                      className="w-100 mb-3"
                      type="text"
                      name="school"
                      onChange={this.onChangeFormElement}
                      value={school}
                      error={!!formError.school}
                      helperText={formError.school}
                      onFocus={this.onFocusFormElement}
                    />
                  </div>

                  <div className="col-md-3 col-5 font-weight-medium">First Language</div>
                  <div className="col-md-9 col-7">
                    <TextField
                      className="w-100 mb-3"
                      type="text"
                      name="firstLanguage"
                      onChange={this.onChangeFormElement}
                      placeholder="e.g. English, Mandarin"
                      value={firstLanguage}
                      error={!!formError.firstLanguage}
                      helperText={formError.firstLanguage}
                      onFocus={this.onFocusFormElement}
                    />
                  </div>
                </div>
                <hr />
                <h2 className="mt-4 mb-0">Parent</h2>
                <small className="text-muted">
                  <i className="fa fa-exclamation-circle mr-2" />
                  Please fill the parent’s details to receive regular updates on the student’s performance.
                </small>

                <div className="row align-items-center mt-3">
                  <div className="col-3 font-weight-medium">Name</div>
                  <div className="col-9">
                    <div className="row">
                      <div className="col-auto">
                        <Select
                          value={parentSalute}
                          onChange={this.onChangeFormElement}
                          className="mt-0"
                          name="parentSalute">
                          <MenuItem value="Mr">Mr</MenuItem>
                          <MenuItem value="Mrs">Mrs</MenuItem>
                          <MenuItem value="Ms">Ms</MenuItem>
                        </Select>
                      </div>
                      <div className="col">
                        <TextField
                          className="w-100 mb-3"
                          type="text"
                          name="parentName"
                          onChange={this.onChangeFormElement}
                          value={parentName}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-3 font-weight-medium">Email</div>
                  <div className="col-9">
                    <div className="row">
                      <div className="col-md col-12">
                        <TextField
                          className="w-100 mb-3"
                          type="email"
                          name="parentEmail"
                          value={parentEmail}
                          onChange={this.onChangeFormElement}
                        />
                      </div>
                      <div className="col-md-auto col-12">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={useStudentEmail}
                              onChange={(event, checked) => {
                                this.setState({
                                  useStudentEmail: checked,
                                  parentEmail: checked ? email : ''
                                })
                              }}
                            />
                          }
                          label="Use student&#39;s email"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-3 font-weight-medium">Phone number</div>
                  <div className="col-9">
                    <div className="row">
                      <div className="col-auto">
                        <Select
                          value={Number(parentPhoneCountryCode)}
                          onChange={this.onChangeFormElement}
                          className="mt-0"
                          name="parentPhoneCountryCode">
                          {countrycodes.map(cc => {
                            return (
                              <MenuItem key={cc.code} value={cc.code}>
                                <span className={`flag ${cc.flag} mr-1`} /> +{cc.code}{' '}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </div>
                      <div className="col">
                        <TextField
                          className="w-100 mb-3"
                          type="tel"
                          name="parentPhone"
                          onChange={this.onChangeFormElement}
                          value={parentPhone}
                          InputProps={{ inputComponent: NumberOnly }}
                        />
                      </div>

                      <div className="col-auto">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={useStudentPhone}
                              onChange={(event, checked) => {
                                this.setState({
                                  useStudentPhone: checked,
                                  parentPhoneCountryCode: checked ? phoneCountryCode : 65,
                                  parentPhone: checked ? phone : ''
                                })
                              }}
                            />
                          }
                          label="Use student&#39;s phone"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 font-weight-medium mt-2">
                    Please select your preferred mode of contact for updates:
                  </div>
                  {/* NOTE: Keep checkbox style implemetation for contact prefrence bcz it done in checkbox 1st then requested to change to radio style. */}
                  <div className="col-12 ml-3">
                    <div className="row">
                      <div className="col-12">
                        <FormControlLabel
                          control={
                            <Radio
                              color="primary"
                              checked={contactWhatsapp}
                              onChange={() => {
                                this.setState({
                                  contactWhatsapp: true,
                                  contactWechat: false,
                                  contactOthers: false
                                })
                              }}
                            />
                          }
                          label="Whatsapp"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-auto">
                        <FormControlLabel
                          control={
                            <Radio
                              color="primary"
                              checked={contactWechat}
                              onChange={() => {
                                this.setState({
                                  contactWhatsapp: false,
                                  contactWechat: true,
                                  contactOthers: false
                                })
                              }}
                            />
                          }
                          label="Wechat"
                        />
                      </div>
                      <div className="col-md-3 col-6">
                        <TextField
                          type="text"
                          placeholder="Wechat ID"
                          name="contactWechatId"
                          onChange={this.onChangeFormElement}
                          error={!!formError.contactWechatId}
                          helperText={formError.contactWechatId}
                          onFocus={this.onFocusFormElement}
                          fullWidth
                          defaultValue={contactWechatId}
                          margin="normal"
                          className="mt-0 mb-2"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-auto">
                        <FormControlLabel
                          control={
                            <Radio
                              color="primary"
                              checked={contactOthers}
                              onChange={() => {
                                this.setState({
                                  contactWhatsapp: false,
                                  contactWechat: false,
                                  contactOthers: true
                                })
                              }}
                            />
                          }
                          label="Other"
                        />
                      </div>
                      <div className="col-md-3 col-6">
                        <TextField
                          type="text"
                          placeholder="e.g. phone call, SMS, e-mail"
                          name="contactOthersChannel"
                          onChange={this.onChangeFormElement}
                          error={!!formError.contactOthersChannel}
                          helperText={formError.contactOthersChannel}
                          onFocus={this.onFocusFormElement}
                          fullWidth
                          defaultValue={contactOthersChannel}
                          className="mt-0 mb-2"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-row flex-wrap justify-content-end align-items-center mt-3 mr-3">
                  <Link to={USER_PROFILE}>Cancel</Link>
                  <Button
                    variant="contained"
                    color="primary"
                    className="ml-3"
                    onClick={this.submitChanges}
                    disabled={updateUserInProgress}>
                    Save Changes
                  </Button>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Edit))
