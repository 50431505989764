import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Button, CircularProgress, Dialog, DialogContent, Slide } from '@material-ui/core'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const LessonStartingDialog = props => {
  const { isOpen, loading, lessonUrl } = props

  return (
    <Dialog open={isOpen} onClose={props.close} TransitionComponent={Transition} maxWidth="md">
      <DialogContent>
        <div className="row">
          <div className="col-3 align-self-end text-center">
            <img
              src="https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/frontend/boy_hi.svg"
              alt="live session starting"
              className="img-fluid"
              style={{ boxShadow: '0px 15px 10px -15px #777' }}
            />
          </div>
          <div className="col-9 d-flex align-items-center">
            {loading ? (
              <CircularProgress size={30} color="primary" className="my-5" />
            ) : (
              <div>
                <h2 className="title--rubik">Lesson starting...</h2>
                <p>
                  Your lesson shall launch automatically in a few seconds. Otherwise, please copy and paste the link
                  below into your browser window.
                </p>
                <code>
                  <a href={lessonUrl} target="_blank" rel="noopener noreferrer">
                    {lessonUrl}
                  </a>
                </code>

                <div className="my-3">
                  <CopyToClipboard text={lessonUrl}>
                    <Button color="primary" variant="contained">
                      Copy Lesson Link
                    </Button>
                  </CopyToClipboard>
                </div>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default LessonStartingDialog
