import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import agent from '../../../agent'
import _ from 'lodash'
import OnlineClassItem from 'pages/common/online-class/catalogue/item-card'
import { GET_RECOMMENDED_CLASSES } from '../../../constants/actionTypes'
import { ONLINE_CLASS_DETAILS } from '../../../routes/routes'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  onlineClassList: state.onlineClass.recommendedClasses,
  userClassWithDetail: state.common.userClassWithDetail,
  displayedClasses: state.classes.classes
})

const mapDispatchToProps = dispatch => ({
  getClassList: filter => dispatch({ type: GET_RECOMMENDED_CLASSES, payload: agent.OnlineClass.getList(filter) })
})

class Recommendation extends React.Component {
  componentDidMount() {
    this.getRelatedClasses()
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(prevProps.displayedClasses, this.props.displayedClasses) ||
      !_.isEqual(prevProps.userClassWithDetail, this.props.userClassWithDetail)
    ) {
      this.getRelatedClasses()
    }
  }

  getRelatedClasses = () => {
    const { userClassWithDetail, displayedClasses } = this.props

    if (!userClassWithDetail || !displayedClasses) {
      return
    }

    let userClasses = Object.keys(userClassWithDetail)
    let levels = []

    for (let i = 0; i < displayedClasses.length; i++) {
      let targetClass = displayedClasses[i]

      levels = _.union(levels, targetClass.level)
    }

    let levelQuery = levels

    if (levels.length > 1) {
      levelQuery = { $in: levels }
    }

    userClasses.push('NMYIMut4Rfm89Xi1knpdnA')
    userClasses.push('c4-eKKlXT3urKddAGJVXbg')

    this.props.getClassList({
      _id: { $nin: userClasses },
      level: levelQuery,
      end_date: { $gte: new Date().toISOString() }
    })
  }

  render() {
    const { onlineClassList, userClassWithDetail } = this.props

    if (!!onlineClassList) {
      const shuffled = onlineClassList.sort(() => 0.5 - Math.random())
      let selected = shuffled.slice(0, 5)

      if (selected.length === 0) {
        return null
      }

      return (
        <div className="mt-3">
          <h2 className="font-weight-semibold">
            {userClassWithDetail && Object.keys(userClassWithDetail).length > 0
              ? 'You may also like...'
              : 'Maybe you would like to try one of the following classes?'}
          </h2>
          <div className="grid__renderer grid__renderer--5">
            {selected.map(item => {
              return (
                <OnlineClassItem
                  key={item._id}
                  classData={item}
                  className={'grid__item shadow border-0 clickable'}
                  viewOnlineClass={() => {
                    this.props.history.push(ONLINE_CLASS_DETAILS.replace(':classId', item._id))
                  }}
                />
              )
            })}
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Recommendation))
