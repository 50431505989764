import agent from './agent'
import _ from 'lodash'
import * as Type from './constants/actionTypes'
import * as Sentry from '@sentry/browser'
import { STUDENT, TA, TUTOR } from 'constants/userTypes'

const promiseMiddleware = store => next => action => {
  if (isPromise(action.payload)) {
    const { payload, ...noPayload } = action // having payload extracts payload from the object so the spread operator only takes the remaining fields in the action object
    store.dispatch(noPayload)
    action.payload.then(
      res => {
        action.payload = res
        action.type += '_SUCCESS'
        store.dispatch(action)
      },
      error => {
        /* NOTE: logout user if any of these actions failed as these actions is for app init */
        if (action.type === Type.INIT_APP || action.type === Type.GET_USER_DATA) {
          store.dispatch({ type: Type.LOGOUT })
        } else {
          // TODO: standardize error handling, error message mapping to move to frontend
          // NOTE: need to ignore RESET_PASSWORD 401 because if user token is corrupted (wrong) then backend will return 401 error
          //       we dont want RESET_PASSWORD to be treat the same way, need properly handle backend error later
          if (error.status === 401 && action.type !== Type.RESET_PASSWORD) {
            //NOTE: logout user if invalid token
            store.dispatch({ type: Type.LOGOUT })
          } else {
            let errPayload = 'Something wrong'
            // NOTE: in prgress to change error response from server, new format will use the earlier format, later one is to handle old (inconsistent) format
            if (error.response?.body?.error) {
              errPayload = error.response.body.error
            } else if (error.response?.body?.message) {
              errPayload = error.response.body.message
            }

            action.type += '_FAILED'
            action.error = true
            action.payload = errPayload
            store.dispatch(action)
          }
        }

        //NOTE: only send sentry event for un-expected API FAILURE
        if (
          error.status !== 401 &&
          action.type !== Type.LOGIN_FAILED &&
          action.type !== Type.GET_TRIAL_AVAILABILITY_FAILED &&
          action.type !== Type.GET_PACKAGE_QUOTE_FAILED &&
          action.type !== Type.GET_CART_QUOTE_FAILED
        ) {
          Sentry.captureEvent({
            message: action.type,
            level: 'error',
            extra: {
              action,
              errStatus: error.status
            }
          })
        }
      }
    )
    return
  }
  next(action)
}

const localStorageMiddleware = store => next => action => {
  if (action.type === Type.INIT_APP_SUCCESS) {
    if (!action.error) {
      window.localStorage.setItem('jwt', action.payload.token)
      agent.setToken(action.payload.token)
    }
  } else if (action.type === Type.LOGIN_SUCCESS) {
    if (!action.error) {
      if ([STUDENT, TUTOR, TA].includes(action.payload?.data._type)) {
        window.localStorage.setItem('jwt', action.payload.token)
        agent.setToken(action.payload.token)
      } else {
        action.type = Type.LOGIN_FAILED
        action.error = true
        action.payload = {
          code: 'Error',
          errors: [{ type: 'invalid', message: 'Invalid user type' }]
        }
      }
    }
  } else if (action.type === Type.LOGOUT) {
    window.localStorage.setItem('jwt', '')
    agent.setToken(null)
  }
  next(action)
}

const cartMiddleware = store => next => action => {
  if (action.type === Type.GET_USER_DATA_SUCCESS) {
    let localCart
    //NOTE: ONLY need to process shopping cart when get user data having more than 2 payload
    if (action.payload.data > 2) {
      let fetchedCart = action.payload[2].data
      if (window.localStorage.getItem('tnp_cart')) {
        localCart = JSON.parse(window.localStorage.getItem('tnp_cart'))
      }
      if (!localCart) {
        window.localStorage.setItem('tnp_cart', JSON.stringify(fetchedCart))
      } else if (localCart && !localCart._id) {
        localCart['_id'] = fetchedCart._id
        window.localStorage.setItem('tnp_cart', JSON.stringify(localCart))
      }
    }
  } else if (action.type === Type.PATCH_CART_ITEM_SUCCESS) {
    //NOTE: Patch cart item now ONLY happen when user local cart item is more recent than fetched cart
    // Need to review this part logic WHEN patch cart item happen in app OR when we allow multiple items
    // from same class
    let localCart = JSON.parse(window.localStorage.getItem('tnp_cart'))
    let updatedItemIndex = _.findIndex(localCart.item, o => {
      return o._id === action.payload.data._id
    })
    if (updatedItemIndex === -1) {
      updatedItemIndex = _.findIndex(localCart.item, o => o.class_id === action.payload.data.class_id)
    }
    if (updatedItemIndex > -1) {
      localCart.item[updatedItemIndex] = action.payload.data
    }
    localCart.updated = action.payload.data.updated
    window.localStorage.setItem('tnp_cart', JSON.stringify(localCart))
  } else if (
    action.type === Type.ADD_CART_ITEM_SUCCESS ||
    action.type === Type.REMOVE_CART_ITEM_SUCCESS ||
    action.type === Type.EMPTY_CART_SUCCESS
  ) {
    window.localStorage.setItem('tnp_cart', JSON.stringify(action.payload.data))
  } else if (action.type === Type.CHECKOUT_CART_SUCCESS) {
    window.localStorage.removeItem('tnp_cart')
  }

  next(action)
}

function isPromise(v) {
  return v && typeof v.then === 'function'
}

export { promiseMiddleware, localStorageMiddleware, cartMiddleware }
