import * as Type from 'constants/actionTypes'

const defaultState = {
  loadingOnlineClassList: false,
  onlineClassList: null,
  onlineClassCount: 0,
  loadingOnlineClass: false,
  classData: null,
  loadingClasses: false,
  matchingClasses: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case Type.GET_ONLINE_CLASS_LIST:
      return {
        ...state,
        loadingOnlineClassList: true,
        loadOnlineClassListError: false
      }

    case Type.GET_ONLINE_CLASS_LIST_SUCCESS: {
      return {
        ...state,
        loadingOnlineClassList: false,
        onlineClassList: action.payload.data,
        onlineClassCount: action.payload.count,
        loadOnlineClassListError: false
      }
    }

    case Type.GET_ONLINE_CLASS_LIST_FAILED:
      return {
        ...state,
        loadingOnlineClassList: false,
        loadOnlineClassListError: true
      }

    case Type.GET_FEATURED_ONLINE_CLASS_LIST:
      return {
        ...state,
        loadingFeaturedClassList: true
      }

    case Type.GET_FEATURED_ONLINE_CLASS_LIST_SUCCESS:
      return {
        ...state,
        loadingFeaturedClassList: false,
        featuredClassList: action.payload.data
      }

    case Type.GET_ONLINE_CLASS_BY_ID:
      return {
        ...state,
        loadingOnlineClass: true,
        classData: null
      }

    case Type.GET_ONLINE_CLASS_BY_ID_SUCCESS:
      return {
        ...state,
        loadingOnlineClass: false,
        classData: action.payload.data[0]
      }

    case Type.GET_ONLINE_CLASS_BY_ID_FAILED:
      return {
        ...state,
        loadingOnlineClass: false,
        classData: null
      }

    case Type.SET_ONLINE_CLASS_FILTER:
      return {
        ...state,
        ...action.payload
      }

    case Type.GET_RECOMMENDED_CLASSES:
      return {
        ...state,
        recommendedClasses: null
      }

    case Type.GET_RECOMMENDED_CLASSES_SUCCESS:
      return {
        ...state,
        recommendedClasses: action.payload.data
      }

    case Type.GET_RECOMMENDED_CLASSES_FAILED:
      return {
        ...state,
        recommendedClasses: null
      }

    case Type.GET_MATCHING_CLASSES:
      return {
        ...state,
        loadingClasses: true,
        matchingClasses: null
      }

    case Type.GET_MATCHING_CLASSES_SUCCESS:
      return {
        ...state,
        loadingClasses: false,
        matchingClasses: action.payload.data
      }

    case Type.GET_MATCHING_CLASSES_FAILED:
      return {
        ...state,
        loadingClasses: false
      }

    case Type.LOGOUT:
      return {
        ...state,
        recommendedClasses: null
      }

    default:
      return state
  }
}
