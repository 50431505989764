import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import qs from 'qs'

import agent from 'agent'
import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'constants/settingsTypes'
import { STUDENT, TUTOR, TA } from 'constants/userTypes'
import { DETECT_USER, REDIRECT } from 'constants/actionTypes'
import { LOGIN, APP, ONLINE_CLASS_DETAILS, ONLINE_CLASS_LIST } from 'routes/routes'
import { StudentRoutes } from 'routes/studentRoutes'
import { TutorRoutes } from 'routes/tutorRoutes'
import Header from 'components/nav/app/header'
import Sidebar from 'components/nav/app/side'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  redirectTo: state.common.redirectTo,
  drawerType: state.settings.drawerType,
  appInitialized: state.common.appInitialized
})

const mapDispatchToProps = dispatch => ({
  onRedirect: url => dispatch({ type: REDIRECT, payload: url }),
  detectUser: (token, classId, classPath) =>
    dispatch({ type: DETECT_USER, payload: agent.Auth.detectUser(token, classId), classId, token, classPath })
})

class App extends React.Component {
  componentDidMount() {
    const token = window.localStorage.getItem('jwt')
    const parsed = qs.parse(this.props.location.search.slice(1))

    if (parsed.token && !token) {
      // TODO: review see whether still use this part of logic/flow
      const paths = this.props.location.pathname.split('/')
      const classIndex = _.indexOf(paths, 'class')

      if (classIndex !== -1 && classIndex !== paths.length - 1) {
        let classId = paths[classIndex + 1]
        this.props.detectUser(parsed.token, classId, LOGIN + `?next=${window.location.pathname}`)
      } else {
        this.props.onRedirect(LOGIN + `?next=${window.location.pathname}`)
      }
    } else if (!token) {
      let redirectURL = this.props.location.pathname
      if (redirectURL.startsWith(ONLINE_CLASS_LIST) || redirectURL.startsWith(ONLINE_CLASS_DETAILS)) {
        redirectURL = `${redirectURL.replace(APP, '')}${window.location.search && window.location.search}`
      } else {
        redirectURL = LOGIN + `?next=${window.location.pathname}${window.location.search && window.location.search}`
      }

      this.props.onRedirect(redirectURL)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      window.scrollTo(0, 0)
      ReactPixel.pageView()

      const page = this.props.location.pathname + this.props.location.search
      ReactGA.set({ page })
      ReactGA.pageview(page)
    }
  }

  render() {
    const { drawerType } = this.props
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'fixed-drawer'
      : drawerType.includes(COLLAPSED_DRAWER)
      ? 'collapsible-drawer'
      : 'mini-drawer'
    let userType = this.props.currentUser ? this.props.currentUser._type : ''

    return (
      <div className="app-main">
        <Helmet>
          <title>Tenopy</title>
          <meta
            name="description"
            content="At Tenopy, top online teachers lead hands-on classes live with our next-generation classroom technology. Achieve results for English, Math and Science at Primary and Secondary levels with our tuition classes. Enrich learning beyond mere grades with our featured learning programmes."
          />
          <meta
            name="keywords"
            content="Online tuition,Math tuition,English tuition,Secondary school tuition,Primary school tuition,Online tuition singapore,Online learning,Online education,Education platform,Online tutor,Home tutor,home tuition,science tuition,sa1,sa2,ca1,ca2,psle,revision,online revision,online study,online learning,psle revision"
          />
        </Helmet>

        <div className={`app-container ${drawerStyle}`}>
          <Sidebar />
          <div className="app-main-container">
            <div className="app-header">
              <Header />
            </div>

            <main className="app-main-content-wrapper">
              <div className="app-main-content" style={{ backgroundColor: '#e7eaf0' }}>
                {userType === STUDENT && <StudentRoutes />}
                {(userType === TUTOR || userType === TA) && <TutorRoutes />}
              </div>
              <footer className="app-footer">
                <div className="d-flex flex-row justify-content-between">
                  <div>
                    <span> Copyright TenOfTen &copy; {new Date().getFullYear()}</span>
                  </div>
                </div>
              </footer>
            </main>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
