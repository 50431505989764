import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Button } from '@material-ui/core'

import { ALL_CLASSES, TRIAL } from 'routes/routes'
import TutorProfileDialog from 'components/dialog/tutor-profile'
import StaticProfileDialog from 'components/dialog/static-profile'
import VideoPlayerDialog from 'components/dialog/video-player'
import CTADivider from 'components/misc/cta-divider'

function TenopyScience() {
  const [activeProfile, setActiveProfile] = useState('')
  const [activeProfileDialog, setActiveProfileDialog] = useState(false)

  const [activeTutor, setActiveTutor] = useState('')
  const [tutorProfileDialog, setTutorProfileDialog] = useState(false)
  const [videoDialog, setVideoDialog] = useState({ isOpen: false, videoUrl: '' })

  let openPage = destination => () => {
    window.open(destination, '_blank')
  }

  return (
    <div className="landing">
      <Helmet>
        <title>Tenopy Science Programmes | Tenopy</title>
        <meta
          name="description"
          content="We are a team of full-time educators, software engineers, business and operations people innovating to help students learn in the best ways."
        />
        <meta
          name="keywords"
          content="Online tuition,Primary school tuition,Online tuition singapore,Online learning,Online education,primary school Education,Online tutor,Home tutor,home tuition,sa1,sa2,ca1,ca2,psle,online study,online learning,convenient education,quality education,fun education,interactive education,about us,about tenopy"
        />
      </Helmet>

      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-12 order-md-1 order-2">
              <div className="subtitle-left title--rubik">Introducing</div>
              <div className="title--rubik h1">Tenopy Science Programmes</div>
              <div className="text-large">
                <p>
                  Studying and memorising textbook contents vigorously is not the way to score well for science. Here at
                  Tenopy, we believe that all children can excel, once equipped with the essential skills and
                  techniques.
                </p>
                <p>
                  Our Curriculum and Program Directors - Alda Lim and Cedric Chai, are armed with years of vast
                  education experience and achievements, they are the authors of the pioneer of Science Answering
                  Technique (SG 1st - Answering Technique (2009); widely adapted and used in schools and educational
                  institutions and Part of the NLB book repository) and the Principals of Science Heuristics Edu-Tech
                  who have incorporated the LiSC and T.A.P. Approach – Teach/ Apply/ Practice in our curriculum to teach
                  the essential process skills and answering techniques to our students.
                </p>
                <p>
                  Our experienced Science teachers bring science to life through their experience and teaching. With
                  Alda and Cedric, the teachers ensure our Science curriculum is designed to cater to every child’s
                  learning style and individual pace. Our program not only equips your child for examinations, it also
                  imbues in your child a proven Thinking/Analytical Approach – the LiSC approach.
                </p>
                <div className="my-2">
                  <Button variant="outlined" className="mr-2 mb-2" color="primary" onClick={openPage(TRIAL)}>
                    Get A Free Trial
                  </Button>
                  <Button
                    variant="contained"
                    className="mb-2"
                    color="primary"
                    onClick={openPage(`${ALL_CLASSES}?subject=Science`)}>
                    Class Catalogue (Science)
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 order-md-2 order-1 mb-5 mb-md-0">
              <img
                src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/about/tnp-science-banner.png"
                alt="tenopy science programmes"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>

      <section style={{ backgroundColor: '#f4fafe' }}>
        <div className="container">
          <div className="section__header mb-5">
            <div
              className="title mb-2"
              style={{ fontSize: '2.3rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif", lineHeight: 1.2 }}>
              About Our Directors
            </div>
            <div className="mx-auto" style={{ maxWidth: '140px', borderBottom: '3px solid #4e63d7' }}></div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-4 col-12">
              <div
                className="team-item"
                onClick={() => {
                  setActiveProfile('cedric')
                  setActiveProfileDialog(true)
                }}>
                <div className="mb-30 position-relative d-flex align-items-center">
                  <span className="subject d-inline-block">Science</span>
                  <span className="img-holder d-inline-block">
                    <img
                      src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/about/tnp-science-cedric.jpg"
                      alt="Team"
                    />
                  </span>
                </div>
                <div className="team-content">
                  <div className="h2 title--rubik mb-2">Cedric Chai</div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div
                className="team-item"
                onClick={() => {
                  setActiveProfile('alda')
                  setActiveProfileDialog(true)
                }}>
                <div className="mb-30 position-relative d-flex align-items-center">
                  <span className="subject d-inline-block">Science</span>
                  <span className="img-holder d-inline-block">
                    <img
                      src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/about/tnp-science-alda.jpg"
                      alt="Team"
                    />
                  </span>
                </div>
                <div className="team-content">
                  <div className="h2 title--rubik mb-2">Alda Lim</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section style={{ backgroundColor: '#f4fafe' }}>
        <div className="container">
          <div className="section__header mb-5">
            <div
              className="title mb-2"
              style={{ fontSize: '2.3rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif", lineHeight: 1.2 }}>
              Meet Our Teachers
            </div>
            <div className="mx-auto" style={{ maxWidth: '140px', borderBottom: '3px solid #0cc652' }}></div>
          </div>

          <div className="row ">
            <div className="col-md-4 col-12">
              <div
                className="team-item"
                onClick={() => {
                  setActiveTutor('yeo-mei-ling')
                  setTutorProfileDialog(true)
                }}>
                <div className="mb-30 position-relative d-flex align-items-center">
                  <span className="subject d-inline-block">Primary</span>
                  <span className="img-holder d-inline-block">
                    <img
                      src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/teacher/yeo-mei-ling.jpg"
                      alt="Team"
                    />
                  </span>
                </div>
                <div className="team-content">
                  <div className="h2 title--rubik mb-2">Yeo Mei Ling</div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-12">
              <div
                className="team-item"
                onClick={() => {
                  setActiveTutor('grace-koh')
                  setTutorProfileDialog(true)
                }}>
                <div className="mb-30 position-relative d-flex align-items-center">
                  <span className="subject d-inline-block">Primary</span>
                  <span className="img-holder d-inline-block">
                    <img
                      src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/teacher/grace-koh.jpg"
                      alt="Team"
                    />
                  </span>
                </div>
                <div className="team-content">
                  <div className="h2 title--rubik mb-2">Grace Koh</div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-12">
              <div
                className="team-item"
                onClick={() => {
                  setActiveTutor('karen-bay')
                  setTutorProfileDialog(true)
                }}>
                <div className="mb-30 position-relative d-flex align-items-center">
                  <span className="subject d-inline-block">Primary</span>
                  <span className="img-holder d-inline-block">
                    <img
                      src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/teacher/karen-bay.jpg"
                      alt="Team"
                    />
                  </span>
                </div>
                <div className="team-content">
                  <div className="h2 title--rubik mb-2">Karen Bay</div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-12">
              <div
                className="team-item"
                onClick={() => {
                  setActiveTutor('kristen-ang')
                  setTutorProfileDialog(true)
                }}>
                <div className="mb-30 position-relative d-flex align-items-center">
                  <span className="subject d-inline-block">Primary</span>
                  <span className="img-holder d-inline-block">
                    <img
                      src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/teacher/kristen-ang.jpg"
                      alt="Team"
                    />
                  </span>
                </div>
                <div className="team-content">
                  <div className="h2 title--rubik mb-2">Kristen Ang</div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-12">
              <div
                className="team-item"
                onClick={() => {
                  setActiveTutor('lynn-cheah')
                  setTutorProfileDialog(true)
                }}>
                <div className="mb-30 position-relative d-flex align-items-center">
                  <span className="subject d-inline-block">Secondary</span>
                  <span className="img-holder d-inline-block">
                    <img
                      src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/teacher/lyn-cheah.jpg"
                      alt="Team"
                    />
                  </span>
                </div>
                <div className="team-content">
                  <div className="h2 title--rubik mb-2">Lynn Cheah</div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-12">
              <div
                className="team-item"
                onClick={() => {
                  setActiveTutor('maggie-chan')
                  setTutorProfileDialog(true)
                }}>
                <div className="mb-30 position-relative d-flex align-items-center">
                  <span className="subject d-inline-block">Secondary</span>
                  <span className="img-holder d-inline-block">
                    <img
                      src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/teacher/maggie-chan.jpg"
                      alt="Team"
                    />
                  </span>
                </div>
                <div className="team-content">
                  <div className="h2 title--rubik mb-2">Maggie Chan</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section>
        <div className="container">
          <div className="section__header mb-5">
            <div
              className="title mb-2"
              style={{ fontSize: '2.3rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif", lineHeight: 1.2 }}>
              Our Teaching Approach
            </div>
            <div className="mx-auto" style={{ maxWidth: '160px', borderBottom: '3px solid #f08d02' }}></div>
          </div>

          <div className="row">
            <div className="col-md-4 col-12 d-md-block d-none">
              <img
                src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/about/tnp-science-lisc.png"
                alt="lisc approach"
                className="img-fluid"
              />
            </div>
            <div className="col-md-8 col-12">
              <h2 className="mt-3 text-center">Linking Science Concepts (LiSC) Approach</h2>
              <img
                src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/about/tnp-science-lisc.png"
                alt="lisc approach"
                className="img-fluid d-md-none d-block"
              />
              <p>
                The <span className="font-weight-semibold">LiSC</span> Approach hones your child’s process skills by
                using a Systematic approach towards solving examination-like questions on processes and skills. Help
                students in the identification of key requirements in each process and skill, thereby equipping students
                with the set of practical and easy to apply consolidated processes and skills to successfully tackle
                examination questions which involve experimental set-ups and/or are investigative in nature.
              </p>
              <p>
                The <span className="font-weight-semibold">LiSC</span> Approach teaches answering techniques to help
                your child express answers in a Scientific manner and understand the meaning of active application of
                concepts to new situations, bridging the gap they encountered in articulating their thoughts and thus
                attain improvement in their results from the minimisation of marks loss due to inaccurate, non-precise
                and incomplete answers.
              </p>
            </div>
          </div>
          <div className="row justify-content-center mt-5">
            <div className="col-12 text-center">
              <h2>Teach, Apply and Practice (T.A.P) Approach</h2>
            </div>
            <div className="col-md-10 col-12">
              <p>
                The assessment of Science is no longer about pure recall testing of scientific facts or principles but
                application! Tenopy Science programmes use the T.A.P approach to help your child ace their examinations.
              </p>
            </div>
            <div className="col-md-4 col-12 text-center">
              <img
                src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/about/tnp-science-tap-t.png"
                alt="teach"
                className="img-fluid"
              />
              <p>
                <span className="font-weight-semibold">T - Teach</span> and break down complex concepts into
                fundamentals and clarify misconceptions
              </p>
            </div>
            <div className="col-md-4 col-12 text-center">
              <img
                src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/about/tnp-science-tap-a.png"
                alt="apply"
                className="img-fluid"
              />
              <p>
                <span className="font-weight-semibold">A - Apply</span> LiSC approach (answering/process skills) so that
                they know how to apply specific templates to examination-like questions
              </p>
            </div>
            <div className="col-md-4 col-12 text-center">
              <img
                src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/about/tnp-science-tap-p.png"
                alt="learn"
                className="img-fluid"
              />
              <p>
                <span className="font-weight-semibold">P - Practice</span> online and offline application of learning{' '}
              </p>
            </div>
          </div>
        </div>
      </section>

      <CTADivider />

      <StaticProfileDialog
        isOpen={activeProfileDialog}
        slug={activeProfile}
        close={() => {
          setActiveProfileDialog(false)
        }}
      />

      <TutorProfileDialog
        isOpen={tutorProfileDialog}
        slug={activeTutor}
        close={() => {
          setTutorProfileDialog(false)
        }}
        showVideoIntro={setVideoDialog}
      />

      <VideoPlayerDialog
        videoURL={videoDialog.videoUrl}
        isOpen={videoDialog.isOpen}
        close={() => {
          setVideoDialog({ isOpen: false, videoUrl: '' })
          setTutorProfileDialog(true)
        }}
      />
    </div>
  )
}

export default TenopyScience
