import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Slider from 'react-slick'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Button,
  CircularProgress,
  Divider
} from '@material-ui/core'
import { ExpandMore, PlayArrow, Videocam } from '@material-ui/icons'
import _ from 'lodash'
import moment from 'moment'
import agent from 'agent'
import {
  CONTACT_US,
  GET_MATCHING_CLASSES,
  CALC_PACKAGE_QUOTE,
  GET_PACKAGE_QUOTE,
  ADD_CART_ITEM,
  ADD_CART_ITEM_NOAUTH
} from 'constants/actionTypes'
import { APP, SHOPPING_CART } from 'routes/routes'
import { formatCartItem, calculateQuote } from 'utils/cart'
import GetInTouchDialog from 'components/dialog/get-in-touch'
import ProceedToCheckoutDialog from 'components/online-class/dialog-proceed-to-checkout'
import VideoPlayerDialog from 'components/dialog/video-player'
import WW3000WordList from 'components/misc/info-ww3000-word-list'
import OxkidsCollaborated from 'components/online-class/misc-partner-oxkids'

const mapStateToProps = state => ({
  token: state.common.token,
  matchingClasses: state.onlineClass.matchingClasses,
  contactSuccess: state.misc.contacted,
  userClassWithDetail: state.common.userClassWithDetail,
  lessonPackages: state.shoppingCart.lessonPackages,
  shoppingCart: state.shoppingCart.shoppingCart
})

const mapDispatchToProps = dispatch => ({
  getEligible: query => dispatch({ type: GET_MATCHING_CLASSES, payload: agent.OnlineClass.getList(query, true) }),
  contactUs: reqPayload => dispatch({ type: CONTACT_US, payload: agent.Misc.contactUs(reqPayload) }),
  calcPackageQuote: (quote, packageItem) => dispatch({ type: CALC_PACKAGE_QUOTE, payload: quote, packageItem }),
  getPackageQuote: (payload, packageItem) =>
    dispatch({ type: GET_PACKAGE_QUOTE, payload: agent.OnlineClass.getQuote(payload), packageItem: packageItem }),
  addToCart: item => dispatch({ type: ADD_CART_ITEM, payload: agent.ShoppingCart.addItem(item), cartItem: item }),
  addToLocalCart: item => dispatch({ type: ADD_CART_ITEM_NOAUTH, payload: item })
})

const WW3000_IDS = [
  'JhIyOGEbQPS7S7cWP_bluA',
  'Y3W8G8ZdTWCepy7lGYp3iA',
  '9YlNKmy3T46i7naOznlGDw',
  'LOHD0xGtSWOeSftZeFa_Mw'
]
const TESTIMONIALS = [
  'https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/ww-3000-testi-1.png',
  'https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/ww-3000-testi-2.png',
  'https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/ww-3000-testi-3.png'
]

function SliderPrevArrow(props) {
  const { style, onClick } = props
  return (
    <img
      className="carousel-nav-icon"
      style={{ ...style, position: 'absolute', left: '-30px', top: '35%' }}
      src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/carousel-back.png"
      alt="next"
      onClick={onClick}
    />
  )
}

function SliderNextArrow(props) {
  const { style, onClick } = props
  return (
    <img
      className="carousel-nav-icon"
      style={{ ...style, position: 'absolute', right: '-30px', top: '35%' }}
      src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/carousel-next.png"
      alt="next"
      onClick={onClick}
    />
  )
}

class WW3000 extends React.Component {
  state = {
    selectedClass: null,
    isGetInTouchDialogOpen: false,
    lessonsIncluded: [],
    isProceedToCheckoutDialogOpen: false,
    addedToCart: [],
    isVideoDialogOpen: false
  }

  componentDidMount() {
    this.props.getEligible({
      _id: {
        $in: WW3000_IDS
      }
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.matchingClasses !== this.props.matchingClasses && this.props.matchingClasses) {
      if (this.props.matchingClasses.length > 0) {
        this.selectClass(this.props.matchingClasses[0]._id)()
        this.updateCartStatus()
      }
    }

    if (prevProps.lessonPackages !== this.props.lessonPackages) {
      const { lessonPackages } = this.props
      const { selectedClass } = this.state

      if (selectedClass) {
        let lessonPackage = lessonPackages[selectedClass._id]
        if (lessonPackage) {
          this.addToCart(lessonPackage.item[0])
        }
      }
    }

    if (prevProps.shoppingCart !== this.props.shoppingCart) {
      this.updateCartStatus()
    }
  }

  selectClass = classId => () => {
    const { matchingClasses } = this.props

    const selectedClass = _.find(matchingClasses, o => {
      return o._id === classId
    })

    let lessonsIncluded = selectedClass.lesson
    if (!selectedClass.force_full_purchase) {
      const now = moment()
      lessonsIncluded = _.filter(selectedClass.lesson, function (o) {
        return moment(o.scheduled_on) > now
      })
    }
    const sortedLessonsIncluded = _.orderBy(lessonsIncluded, ['scheduled_on'])

    this.setState({
      selectedClass,
      lessonsIncluded: sortedLessonsIncluded
    })
  }

  scrollToElement = location => () => {
    const elmnt = document.getElementById(location)
    window.scrollTo({ top: elmnt.offsetTop, behavior: 'smooth' })
  }

  toggleExpandFullClassDesc = () => {
    this.setState({ expandFullClassDesc: !this.state.expandFullClassDesc })
  }

  toggleGetInTouchDialog = () => {
    this.setState({ isGetInTouchDialogOpen: !this.state.isGetInTouchDialogOpen })
  }

  toggleProceedToCheckoutDialog = () => {
    this.setState({ isProceedToCheckoutDialogOpen: !this.state.isProceedToCheckoutDialogOpen })
  }

  clickPurchase = () => {
    const { token } = this.props
    const { selectedClass, lessonsIncluded } = this.state

    if (!selectedClass) return

    if (lessonsIncluded.length > 0) {
      let packageItem = {
        class_id: selectedClass._id,
        count: lessonsIncluded.length,
        options: {
          start_date: lessonsIncluded[0].scheduled_on,
          end_date: lessonsIncluded[lessonsIncluded.length - 1].scheduled_on
        },
        rate: selectedClass.rate,
        title: selectedClass.name
      }

      if (token) {
        this.props.getPackageQuote(
          {
            class_id: selectedClass._id,
            start_date: lessonsIncluded[0].scheduled_on,
            end_date: lessonsIncluded[lessonsIncluded.length - 1].scheduled_on
          },
          packageItem
        )
      } else {
        let quote = calculateQuote(
          lessonsIncluded,
          selectedClass.rate,
          lessonsIncluded[0].scheduled_on,
          lessonsIncluded[lessonsIncluded.length - 1].scheduled_on
        )
        this.props.calcPackageQuote({ data: quote }, packageItem)
      }
    }
  }

  updateCartStatus = () => {
    let addedToCart = []
    for (let i = 0; i < this.props.shoppingCart.item.length; i++) {
      addedToCart.push(this.props.shoppingCart.item[i].item_id)
    }

    this.setState({ addedToCart })
  }

  addToCart = lessonPackage => {
    let { selectedClass } = this.state

    let formattedItem = formatCartItem(selectedClass, 'Class', lessonPackage.packageItem.options)
    if (!!this.props.token) {
      this.props.addToCart(formattedItem)
    } else {
      this.props.addToLocalCart(formattedItem)
    }
    this.toggleProceedToCheckoutDialog()
  }

  goToCart = () => {
    this.toggleProceedToCheckoutDialog()
    let redirectUrl = this.props.token ? `${APP}${SHOPPING_CART}` : SHOPPING_CART
    this.props.history.push(redirectUrl)
  }

  toggleVideoDialog = () => {
    this.setState({ isVideoDialogOpen: !this.state.isVideoDialogOpen })
  }

  render() {
    const { matchingClasses, userClassWithDetail } = this.props
    const {
      selectedClass,
      lessonsIncluded,
      isGetInTouchDialogOpen,
      isProceedToCheckoutDialogOpen,
      addedToCart,
      isVideoDialogOpen
    } = this.state

    if (!selectedClass) {
      return (
        <div className="manage-margin text-center my-5">
          <CircularProgress size={50} />
        </div>
      )
    }

    return (
      <div className="landing">
        <Helmet>
          <title>Primary school English Vocabulary Holiday Tuition | Tenopy Wordly Wise </title>
          <meta
            name="description"
            content="Expand your child's English vocabulary and improve their writing and reading comprehension by signing up for our Wordly Wise i3000 English Vocabulary Immersive Programme. Learn more."
          />
          <meta
            name="keywords"
            content="Vocabulary, vocabulary workshop, vocabulary workshop Singapore, holiday tuition, holiday tuition singapore, English vocabulary workshop, English vocabulary, vocabulary english, Tenopy English, improve vocabulary, online vocabulary workshop"
          />
        </Helmet>

        <section
          style={{
            background:
              'linear-gradient(90deg, rgba(255,254,255,1) 0%, rgba(246,235,251,1) 50%, rgba(240,222,248,1) 100%)',
            paddingBottom: 0
          }}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-5 order-2 order-md-1 align-self-end">
                <img
                  src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/ww-3000-banner.png"
                  alt="Wordly Wise 3000"
                  className="img-fluid"
                />
              </div>
              <div className="col-12 order-1 order-md-2 col-md-7 align-self-center">
                <h2 className="title--rubik" style={{ fontWeight: 300, textDecoration: 'underline' }}>
                  A World-class English Vocabulary Online Programme
                </h2>
                <p>
                  Tenopy's Wordly Wise 3000 live online class combines great teaching with a world-renowned curriculum
                  to help students at primary and secondary levels master a broader range of English vocabulary in the
                  most engaging way.
                </p>
                <div
                  className="d-flex align-items-center mb-4"
                  style={{
                    cursor: 'pointer',
                    color: '#9E47A8'
                  }}
                  onClick={this.scrollToElement('ww-3000')}>
                  <span className="tittle--rubik">Find out more</span>
                  <PlayArrow style={{ color: '#9E47A8' }} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section style={{ backgroundColor: '#C591CB' }}>
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div
                  className="title title--rubik mb-2"
                  style={{
                    fontSize: '2.3rem',
                    fontWeight: '300',
                    fontFamily: "'Rubik', sans-serif",
                    lineHeight: 1.2
                  }}>
                  What is Wordly Wise 3000?
                </div>
                <div className="font-italic mb-3">
                  A world-class curriculum to help maximise your child become an eloquent speaker and writer
                </div>
                {false && (
                  <Button style={{ backgroundColor: '#F0DEF8', paddingLeft: '10px', paddingRight: '12px' }}>
                    <Videocam style={{ color: '#9E47A8', marginRight: '5px' }} />
                    <span style={{ color: '#9E47A8' }}>Sneak Peek</span>
                  </Button>
                )}
              </div>
              <div className="col-md-8">
                <p>
                  The Wordly Wise 3000 curriculum has been developed in the USA for over 20 years. Students can benefit
                  from:
                </p>
                <ul>
                  <li>
                    Engaging and direct academic vocabulary instruction that develops the critical link between
                    vocabulary and reading comprehension.
                  </li>
                  <li>
                    Careful selected words based on word importance, usefulness, and or difficulty. Knowing the words
                    can help students improve their reading comprehension exponentially and prepare them for
                    high-ability programmes and GEP tests.
                  </li>
                  <li>
                    Research based activities that provide multiple exposures to vocabulary words and deeply embed them
                    in students' long-term memory.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section id="ww-3000" className="bg-white">
          <div className="container">
            <div className="container">
              <div className="section__header mb-5">
                <div
                  className="title title--rubik mb-2"
                  style={{
                    fontSize: '2.3rem',
                    fontWeight: '300',
                    fontFamily: "'Rubik', sans-serif",
                    lineHeight: 1.2
                  }}>
                  Select a level
                </div>
                <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #C3BEC5' }} />
              </div>
              <div className="row mb-5">
                {matchingClasses.map((classData, index) => {
                  const style = {
                    tab: {
                      background: 'white',
                      border: '1px solid #dee2e6',
                      borderRadius: '10px',
                      boxShadow:
                        '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
                    },
                    activeTab: {
                      background: 'white',
                      borderBottom: '5px solid #8e51f0',
                      borderTop: '1px solid #8e51f0',
                      borderRight: '1px solid #8e51f0',
                      borderLeft: '1px solid #8e51f0',
                      borderRadius: '10px',
                      boxShadow:
                        '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
                    }
                  }
                  const activeClass = classData._id === selectedClass._id

                  return (
                    <div className="col-12 col-md-6 mb-2" key={index}>
                      <div
                        className="d-flex flex-row h-100 align-items-center clickable p-2"
                        style={activeClass ? style.activeTab : style.tab}
                        onClick={this.selectClass(classData._id)}>
                        <Avatar
                          className="size-60 avatar-shadow mx-auto my-2"
                          alt={classData.tutor[0].name}
                          src={classData.tutor[0].profile_pic}
                        />
                        <div className="ml-2">
                          <h3 className="mb-0">{classData.name}</h3>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              {selectedClass && (
                <div className="row">
                  <div className="col-12 col-md-5 mb-3">
                    <div
                      className="py-2 px-4"
                      style={{
                        borderRadius: '10px',
                        borderBottom: '10px solid #8e51f0',
                        borderTop: '1px solid #8e51f0',
                        borderRight: '1px solid #8e51f0',
                        borderLeft: '1px solid #8e51f0'
                      }}>
                      <div className="d-flex flex-row align-items-center">
                        <Avatar
                          className="size-60 avatar-shadow m-2"
                          alt={selectedClass.tutor[0].name}
                          src={selectedClass.tutor[0].profile_pic}
                        />
                        <div className="ml-2">
                          <h3 className="font-weight-semibold mb-1">{selectedClass.name}</h3>
                          <div className="d-flex flex-row">
                            <small className="mr-2">by</small>
                            {selectedClass.tutor[0].name}
                          </div>
                        </div>
                      </div>
                      <div className="text-right">
                        <div className="class__price">
                          <h1 className="text-primary mb-0">$ {selectedClass.rate}</h1>
                        </div>
                        <small>per lesson</small>
                      </div>
                      <Divider />
                      <div className="mt-2">
                        <small className="text-muted">About {selectedClass.tutor[0].name}</small>
                        <p className="text-justify" dangerouslySetInnerHTML={{ __html: selectedClass.tutor[0].desc }} />
                      </div>
                      <div className="text-center mt-3">
                        {userClassWithDetail && userClassWithDetail[selectedClass._id] ? (
                          <Button fullWidth color="primary" disabled>
                            Class Purchased
                          </Button>
                        ) : (
                          <>
                            {addedToCart.includes(selectedClass._id) ? (
                              <Button fullWidth variant="outlined" color="primary" onClick={this.goToCart}>
                                Checkout
                              </Button>
                            ) : (
                              <>
                                {selectedClass.is_sold_out ? (
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    className="mb-2 bg-danger text-white"
                                    disabled>
                                    Class Full
                                  </Button>
                                ) : (
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={this.clickPurchase}>
                                    Purchase Class
                                  </Button>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <h3>About Class:</h3>
                    <div className="text-justify mb-4" onClick={this.toggleExpandFullClassDesc}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: selectedClass.desc
                        }}
                      />
                      <OxkidsCollaborated classId={selectedClass._id} />
                      <WW3000WordList classId={selectedClass._id} />
                    </div>
                    <h3>Lesson Schedule:</h3>
                    <Accordion style={{ border: '1px solid #dee2e6', backgroundColor: '#f5f8fb' }}>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div>
                            {selectedClass.lesson.length} lessons <br />
                            Every {selectedClass.schedule.date_of_week} <br />
                            {moment(selectedClass.schedule.start_time).format('h:mm a')} to&nbsp;
                            {moment(selectedClass.schedule.end_time).format('h:mm a')}
                          </div>
                          <div>Expand to view full detail</div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="w-100">
                          {lessonsIncluded &&
                            lessonsIncluded.map((lesson, index) => (
                              <div
                                key={index}
                                className="mb-2"
                                style={{
                                  backgroundColor: '#f9f9f9',
                                  border: '1px solid #dedfe0',
                                  padding: '10px 15px'
                                }}>
                                <div className="mb-2">
                                  <i className="fa fa-check mr-2" />
                                  <span style={{ fontSize: '20px' }}>{lesson.name}</span>
                                </div>
                                <div className="d-flex align-items-center  px-3 mb-1 text-muted">
                                  <i className="material-icons mx-2">event</i>
                                  {moment(lesson.scheduled_on).format('dddd, MMMM Do YYYY')}
                                </div>
                                <div className="d-flex align-items-center  px-3 text-muted">
                                  <i className="material-icons mx-2">access_time</i>
                                  {moment(lesson.scheduled_on).format('h:mm a')} to{' '}
                                  {moment(lesson.ended_on).format('h:mm a')}
                                </div>
                              </div>
                            ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>

        <section className="bg-light-gray">
          <div className="container">
            <div className="section__header mb-5">
              <img
                src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/ww-3000-logo-red.png"
                alt="WW 3000 logo"
                style={{ maxWidth: '80%', width: '350px' }}
              />
            </div>

            <div className="row align-items-center">
              <div className="col-12 col-md-5 order-2 order-md-1">
                <img
                  src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/ww-3000-illustration.png"
                  alt="Wordly Wise 3000"
                  className="img-fluid"
                />
              </div>
              <div className="col-12 order-1 order-md-2 col-md-7">
                <p>
                  Tenopy's live online class provide student access to the Wordly Wise i3000 system to support
                  self-learning outside of class.
                </p>
              </div>
            </div>

            <br />
            <br />
            <br />

            <div className="section__header my-5">
              <div
                className="title mb-2"
                style={{ fontSize: '2.3rem', fontWeight: '300', fontFamily: "'Rubik', sans-serif" }}>
                Wordly Wise i3000 features
              </div>
              <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #C3BEC5' }}></div>
            </div>

            <div className="row justify-content-center">
              <div className="col-md-10 col-12">
                <Slider
                  dots={true}
                  infinite={true}
                  speed={300}
                  slidesToShow={1}
                  adaptiveHeight={true}
                  nextArrow={<SliderNextArrow />}
                  prevArrow={<SliderPrevArrow />}>
                  <div className="d-flex flex-column align-items-center text-center mb-4">
                    <div className="text-large text-underline font-weight-semibold mb-3">
                      Interactive Student Practice Activities
                    </div>
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/ww-3000-screenshot-1.jpg"
                      alt="WW3000 feature"
                      style={{ width: '80%' }}
                    />
                  </div>
                  <div className="d-flex flex-column align-items-center text-center mb-4">
                    <div className="text-large text-underline font-weight-semibold mb-3">
                      Real time feedback to track students' progress
                    </div>
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/ww-3000-screenshot-2.jpg"
                      alt="WW3000 feature"
                      style={{ width: '80%' }}
                    />
                  </div>
                  <div className="d-flex flex-column align-items-center text-center mb-4">
                    <div className="text-large text-underline font-weight-semibold mb-3">
                      Student can apply and share learning
                    </div>
                    <img
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/ww-3000-screenshot-3.jpg"
                      alt="WW3000 feature"
                      style={{ width: '80%' }}
                    />
                  </div>
                </Slider>
              </div>
            </div>

            <br />
            <br />
            <br />

            <div className="section__header my-5">
              <div
                className="title mb-2"
                style={{ fontSize: '2.3rem', fontWeight: '300', fontFamily: "'Rubik', sans-serif" }}>
                Student Experience
              </div>
              <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #C3BEC5' }}></div>
            </div>
            <div className="row">
              {TESTIMONIALS.map((imageUrl, index) => (
                <div className="mb-4 col-md-4 col-12 text-center" key={index}>
                  <img src={imageUrl} width="80%" alt="wordly wise testi" />
                </div>
              ))}

              <div className="col-12">
                <p className="text-justify">
                  The positive impact that teacher Eddie had on his students were evident as many students who completed
                  Tenopy's Wordly Wise 3000 G6 Live online classes wrote a thank you message to express their gratitude
                  to him. Here are just some of the overwhelming submissions we received from the students who benefited
                  greatly from our previous Wordly Wise 3000 G6 classes.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white">
          <div className="container">
            <div className="row align-items-center">
              <div className="col">
                <h1 className="text-primary font-weight-normal mb-0">Ready to have fun learning vocabulary?</h1>
                <span style={{ fontSize: '18px' }}>Join in the fun now.</span>
              </div>
              <div className="col-auto my-3 my-md-0">
                <Button variant="contained" color="primary" className="mr-2" onClick={this.scrollToElement('ww-3000')}>
                  Get Started
                </Button>
                <Button variant="outlined" color="primary" onClick={this.toggleGetInTouchDialog}>
                  Get in Touch
                </Button>
              </div>
            </div>
          </div>
        </section>

        <GetInTouchDialog
          isOpen={isGetInTouchDialogOpen}
          close={this.toggleGetInTouchDialog}
          intent="Conversion Page"
        />

        <ProceedToCheckoutDialog
          isOpen={isProceedToCheckoutDialogOpen}
          toggleDialog={this.toggleProceedToCheckoutDialog}
          proceedToCheckout={this.goToCart}
          classTitle={selectedClass?.name}
          lessonsIncluded={lessonsIncluded}
        />

        <VideoPlayerDialog
          videoURL="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/video-assets/book-club-preview.mp4"
          isOpen={isVideoDialogOpen}
          close={this.toggleVideoDialog}
        />
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WW3000))
