import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { isMobile, isTablet } from 'react-device-detect'
import VisibilitySensor from 'react-visibility-sensor'
import { Button, CircularProgress, Paper, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { KeyboardArrowDown } from '@material-ui/icons'
import agent from 'agent'
import { CONTACT_US } from 'constants/actionTypes'
import { FAQ, ALL_CLASSES, TRIAL, SIGNUP } from 'routes/routes'
import { checkWebpSupport } from 'utils/misc'
import ClassExperience from 'components/misc/info-class-experience'
import SuperiorMethodology from 'components/misc/info-superior-methodology'
import FeaturedClassesCarousel from 'components/misc/featured-class-carousel'
import TestimonialCarousel from 'components/misc/testimonial-carousel'
import FeaturedTeacherCarousel from 'components/misc/carousel-featured-teacher'
import VideoPlayerDialog from 'components/dialog/video-player'
import LandingPhotoDescription from 'components/misc/landing-photo-description'
import MediaFeaturedCarousel from 'components/misc/media-featured-carousel'
import CTADivider from 'components/misc/cta-divider'

const mapStateToProps = state => ({
  contactInProgress: state.misc.contactInProgress
})

const mapDispatchToProps = dispatch => ({
  contactUs: reqPayload => dispatch({ type: CONTACT_US, payload: agent.Misc.contactUs(reqPayload) })
})

class LandingSG extends React.Component {
  state = {
    isVideoDialogOpen: false,
    videoURL: '',
    webpSupport: true,
    name: '',
    phone: '',
    email: '',
    errorMsg: '',
    sent: false,
    showStatNumber: false
  }

  componentDidMount() {
    let component = this
    checkWebpSupport('lossy', function (feature, result) {
      component.setState({ webpSupport: result })
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.contactInProgress && !this.props.contactInProgress) {
      this.setState({ successMsg: 'Thanks for contacting us. We will reach you soon.' })
    }
  }

  onChangeFormElement = e => {
    const type = e.target.name
    const value = e.target.value
    this.setState({ [type]: value, errorMsg: '' })
  }

  contactTenopy = () => {
    const { name, email, phone } = this.state
    if (name && email && phone) {
      this.props.contactUs({
        message_type: 'contact',
        message: `${name} (${email}) (${phone ?? 'N/A'}) leave contact on Tenopy homepage`,
        intent: 'Home Page'
      })
      this.setState({ sent: true })
    } else {
      this.setState({
        errorMsg: 'Please provide your name, email and contact number.'
      })
    }
  }

  goToClassCatalogue = () => {
    this.props.history.push(ALL_CLASSES)
  }

  goToRegister = () => {
    this.props.history.push(SIGNUP)
  }

  goToFAQ = () => {
    this.props.history.push(FAQ)
  }

  goToTrialPage = () => {
    this.props.history.push(TRIAL)
  }

  goToNextSection = () => {
    let elmnt = document.getElementById('class-experience')
    window.scrollTo({ top: elmnt.offsetTop - 70, behavior: 'smooth' })
  }

  toggleVideoDialog = videoURL => () => {
    this.setState({
      isVideoDialogOpen: !this.state.isVideoDialogOpen,
      videoURL
    })
  }

  contactSectionVisibilityChange = isVisible => {
    const { showStatNumber } = this.state
    if (isVisible && !showStatNumber) {
      this.setState({ showStatNumber: true })
    }
  }

  render() {
    const { contactInProgress } = this.props
    const { isVideoDialogOpen, videoURL, webpSupport, name, phone, email, errorMsg, successMsg, sent, showStatNumber } =
      this.state

    return (
      <div>
        <Helmet>
          <title>LIVE Online Tutoring Singapore | Tenopy</title>
          <meta
            name="description"
            content="At Tenopy, top online teachers lead hands-on classes live with our next-generation classroom technology. Achieve results for English, Math and Science at Primary and Secondary levels with our tuition classes. Enrich learning beyond mere grades with our featured learning programmes."
          />
          <meta
            name="keywords"
            content="Online tuition,Math tuition,English tuition,Secondary school tuition,Primary school tuition,Online tuition singapore,Online learning,Online education,Education platform,Online tutor,Home tutor,home tuition,science tuition,sa1,sa2,ca1,ca2,psle,revision,online revision,online study,online learning,psle revision"
          />
        </Helmet>

        <section className={`lp-banner ${webpSupport ? 'webp' : 'no-webp'}`}>
          <div className="container">
            <div className="row align-items-center content">
              <div className="col-12 col-md-7 text-center text-md-left">
                <div className="header">Engaging Teaching. Targeted Learning.</div>
                <div className="desc">
                  We empower educators with technology & AI to focus on making learning effective.
                </div>
                <div className="my-3 d-none d-md-block">
                  {/* <Button
                    variant="outlined"
                    size="large"
                    className="btn-banner-cta--white mr-2 mb-3"
                    onClick={this.goToTrialPage}>
                    Get A Free Trial
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className="btn--purple mb-3"
                    onClick={this.goToClassCatalogue}>
                    Get Started
                  </Button>*/}
                  <Button
                    variant="contained"
                    size="large"
                    className="btn--purple mb-3"
                    onClick={() => {
                      window.open(
                        'https://formfacade.com/public/116456509124236683597/all/form/1FAIpQLSeW8WyPGrbwajelPS-ylx_KPY6DTZPFOw-uBIPdgI2Ad_lKHA',
                        '_blank'
                      )
                    }}>
                    Contact Us
                  </Button>
                  {/* <div
                    className="link text-white text-underline text-large"
                    onClick={() => {
                      window.open('https://wa.me/6588601548', '_blank')
                    }}>
                    Or reach us through WhatsApp
                  </div> */}
                </div>
              </div>

              <div className="col-12 col-md-5">
                <div
                  className="sonar-wrapper d-none"
                  onClick={this.toggleVideoDialog(
                    'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/video-assets/tenopy-testi-trial-captioned.mp4'
                  )}>
                  <i className="material-icons sonar-play">play_circle_filled</i>
                  <div className="sonar-emitter">
                    <div className="sonar-wave"></div>
                  </div>
                </div>
                <div className="d-block d-md-none text-center mt-3">
                  {/* <Button
                    variant="outlined"
                    size="large"
                    className="btn-banner-cta--white mr-2 mb-3"
                    onClick={this.goToTrialPage}>
                    Get A Free Trial
                  </Button> */}
                  <Button
                    variant="contained"
                    size="large"
                    className="btn--purple mb-3"
                    onClick={this.goToClassCatalogue}>
                    Get Started
                  </Button>
                  <div
                    className="link text-white text-underline text-large"
                    onClick={() => {
                      window.open(
                        'https://formfacade.com/public/116456509124236683597/all/form/1FAIpQLSeW8WyPGrbwajelPS-ylx_KPY6DTZPFOw-uBIPdgI2Ad_lKHA',
                        '_blank'
                      )
                    }}>
                    Or contact us
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="position-absolute text-center"
            style={{ top: 'calc(100vh - 40px)', left: 'calc(50% - 25px)' }}>
            <KeyboardArrowDown style={{ fontSize: 50 }} onClick={this.goToNextSection} />
          </div>
        </section>

        {/* <div id="class-experience">
          <ClassExperience />
        </div> */}

        <LandingPhotoDescription
          button="yes"
          position="left"
          imgSrc="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/Online+Classroom-min.webp"
          imgAlt="Online+Classroom"
          title="Learning Technology"
          buttonTitle="Discuss Tech With Us"
          description="Advanced tools for self-paced online learning and interactive live classes, complete with a full LMS and online classroom setup."
        />

        <LandingPhotoDescription
          position="right"
          imgSrc="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/Content+Screenshot.png"
          imgAlt="Content screenshot"
          title="Learning Content"
          description="Tailored educational materials for primary and secondary level students in English, Math, and Science."
        />

        <LandingPhotoDescription
          button="yes"
          position="left"
          imgSrc="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/Math+Mentor+GPT.png"
          imgAlt="math mentor"
          title="AI Customisation of Content"
          buttonTitle="Discuss Custom AI With Us"
          description="Innovative GPT technology for building custom English, Math, and Science content, enabling AI-driven automation for student, parent, and admin operations in educational organizations."
        />

        <LandingPhotoDescription
          goToClassCatalogue={() => this.props.history.push(ALL_CLASSES)}
          button="yes"
          position="right"
          imgSrc="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/Proactive+support+for+students+skilled.JPG"
          imgAlt="live classes"
          title="Live Online Classes:"
          description="Specially designed live classes for primary level English, focusing on engaging and effective learning experiences."
        />

        <section className="d-md-block" style={{ backgroundColor: '#ECD8EE' }}>
          <div className="container">
            <div className="row mb-4">
              <div className="col-md-4 col-12 text-center">
                <img
                  src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/home-targeted-class-icon.png"
                  alt="Targeted class"
                  className="feature-icon mb-2"
                />

                <div className="h1">
                  EduTech Track
                  <br /> Record
                </div>
                <div className="text-large">8 years of expertise in developing educational technology.</div>
              </div>
              <div className="col-md-4 col-12 text-center">
                <img
                  src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/home-proven-methodology-icon.png"
                  alt="Proven methodology"
                  className="feature-icon mb-2"
                />
                <div className="h1">
                  Quality Learning
                  <br />
                  Content
                </div>
                <div className="text-large">
                  Curriculum created with care by an experienced team led by ex-NIE assistant dean
                </div>
              </div>
              <div className="col-md-4 col-12 text-center">
                <img
                  src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/online-lesson-skilled.png"
                  alt="Enhanced classroom experience"
                  className="feature-icon mb-2"
                />
                <div className="h1">
                  Proven With
                  <br />
                  Students
                </div>
                <div className="text-large">
                  Successfully serving thousands of students through our online learning platform.
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section style={{ backgroundColor: '#f6f6fc' }}>
          <section style={{ backgroundColor: '#f6f6fc' }}>
          <div className="container">
            <div className="row align-items-center mb-5">
              <div className="col-12 col-md-5">
                <img
                  src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/Online+Classroom-min.webp"
                  alt="Online+Classroom"
                  width="90%"
                  className="mb-3 shadow"
                />
              </div>
              <div className="col-12 col-md-7 mb-4">
                <div
                  className="mb-3"
                  style={{
                    fontSize: '2.3rem',
                    fontFamily: "'Rubik', sans-serif",
                    lineHeight: 1.2,
                    color: '#000000',
                    textAlign: 'left'
                  }}>
                  Learning Technology
                </div>
                <div className="text-large mb-3">We leave no child behind in class.</div>

                <div className="text-large mb-3">
                  Advanced tools for self-paced online learning and interactive live classes, complete with a full LMS
                  and online classroom setup.
                </div>
                <Button variant="contained" size="large" className="btn--purple">
                  <a
                    style={{ color: 'white' }}
                    href="mailto:ask@tenopy.com?subject=Enquiry on your learning technology">
                    Discuss tech with us
                  </a>
                </Button>
              </div>
            </div>
          </div>
          </section>
          <div className="section__header mb-5">
            <div
              className="title mb-2"
              style={{
                fontSize: '2.3rem',
                fontWeight: '400',
                fontFamily: "'Rubik', sans-serif",
                lineHeight: 1.2,
                color: '#000000'
              }}>
              Featured Teachers
            </div>
            <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '2px solid #7B7B7B' }}></div>
          </div>
          <div className="container">
            <FeaturedTeacherCarousel />
          </div>
        </section> */}

        {/* <SuperiorMethodology /> */}

        {/* <section style={{ backgroundColor: '#f6f6fc' }}>
          <div className="container">
            <div className="row align-items-center justify-content-around mb-5">
              <div className="col-12 col-md-6 order-2 order-md-1">
                <div className="row">
                  <div className="col-6 col-md-4">
                    <div className="card text-center shadow p-2">
                      <div className="mb-2">
                        <img
                          src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/home-product-syllabus-based.png"
                          alt="syllabus based"
                          width="85%"
                        />
                      </div>
                      <div className="h3">
                        Syllabus
                        <br />
                        Based
                      </div>
                      <div className="hover-content">
                        <p className="mb-0 text-center">Helps students revise what has been covered in class.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-4">
                    <div className="card text-center shadow p-2">
                      <div className="mb-2">
                        <img
                          src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/home-product-teacher-guided.png"
                          alt="teacher guided"
                          width="85%"
                        />
                      </div>
                      <div className="h3">
                        Teacher
                        <br />
                        Guided
                      </div>
                      <div className="hover-content">
                        <p className="mb-0 text-center">
                          Students enjoy close guidance by teachers, and active interaction with teachers and peers.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-4">
                    <div className="card text-center shadow p-2">
                      <div className="mb-2">
                        <img
                          src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/chat.png"
                          alt="direct communication channel"
                          width="70%"
                        />
                      </div>
                      <div className="h3">
                        Direct
                        <br />
                        Communication Channel &nbsp;
                      </div>
                      <div className="hover-content">
                        <p className="mb-0 text-center">We leave no question unanswered.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-4">
                    <div className="card text-center shadow p-2">
                      <div className="mb-2">
                        <img
                          src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/home-product-psle-intensive.png"
                          alt="PSLE intensive"
                          width="85%"
                        />
                      </div>
                      <div className="h3">
                        PSLE
                        <br />
                        Intensive
                      </div>
                      <div className="hover-content">
                        <p className="mb-0 text-center">
                          Final sprint for students to reinforce key concepts, challenge thinking and clarify doubt.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-4">
                    <div className="card text-center shadow p-2">
                      <div className="mb-2">
                        <img
                          src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/small+group.png"
                          alt="Small Group"
                          width="85%"
                        />
                      </div>
                      <div className="h3">
                        Small
                        <br />
                        Group
                      </div>
                      <div className="hover-content">
                        <p className="mb-0 text-center">We leave no child behind.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-4">
                    <div className="card text-center shadow p-2">
                      <div className="mb-2">
                        <img
                          src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/home-product-featured-learning.png"
                          alt="featured learning"
                          width="85%"
                        />
                      </div>
                      <div className="h3">
                        Featured
                        <br />
                        Learning
                      </div>
                      <div className="hover-content">
                        <p className="mb-0 text-center">Enrich students' learning beyond academic classes.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <Button
                    variant="contained"
                    size="large"
                    className="btn--purple d-md-none d-block mx-auto"
                    onClick={() => {
                      this.props.history.push(ALL_CLASSES)
                    }}>
                    Find a class that is right for your child
                  </Button>
                </div>
              </div>
              <div className="col-12 col-md-5 order-1 order-md-2">
                <div
                  className="mb-3"
                  style={{ fontSize: '2.3rem', color: '#000000', fontFamily: "'Rubik', sans-serif", lineHeight: 1.2 }}>
                  Small, Targeted Classes
                </div>
                <div className="text-large mb-3">
                  We appreciate that every child has their own unique learning needs and aptitudes. Our programme
                  ensures there's a class that would suit your child's learning requirements. <br />
                  <br />
                  We ensure that our classes are capped at a maximum of 5 with full on screen interaction, so that
                  teachers can provide students with individualised help. <br />
                  <br />
                  Direct channels of communication with our teachers are also available whenever parents would like to
                  keep track of their child’s progress.
                </div>
                <Button
                  variant="contained"
                  size="large"
                  className="btn--purple d-md-block d-none"
                  onClick={() => {
                    this.props.history.push(ALL_CLASSES)
                  }}>
                  Find a class that is right for your child
                </Button>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="bg-white">
          <div className="section__header mb-5">
            <div
              className="title mb-2"
              style={{
                fontSize: '2.3rem',
                fontWeight: '400',
                fontFamily: "'Rubik', sans-serif",
                lineHeight: 1.2,
                color: '#000000'
              }}>
              Testimonials
            </div>
            <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '2px solid #7B7B7B' }}></div>
          </div>
          <div className={isMobile ? `${isTablet ? 'container-fluid' : 'container-fluid px-0'}` : 'container'}>
            <TestimonialCarousel />
          </div>
        </section> */}

        <section style={{ backgroundColor: '#f6f6fc' }}>
          <div className="container">
            <div className="section__header mb-5">
              <div
                className="title mb-2"
                style={{ fontSize: '2.3rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif", lineHeight: 1.2 }}>
                Featured Live Online Classes
              </div>
              <div className="subtitle font-weight-light title--rubik">
                We deliver selected classes to enrich students’ learning experience on top of mere grades.
              </div>
            </div>
            <FeaturedClassesCarousel />
            <div className="mt-4 mt-md-2 text-center">
              <Button
                variant="contained"
                size="large"
                className="btn--purple"
                onClick={() => {
                  this.props.history.push(ALL_CLASSES)
                }}>
                Check out the classes
              </Button>
            </div>
          </div>
        </section>

        <VisibilitySensor onChange={this.contactSectionVisibilityChange} partialVisibility={true}>
          {/* <section
            style={{
              backgroundImage:
                'linear-gradient(90deg, rgba(113,170,255,1) 0%, rgba(126,140,228,1) 31%, rgba(134,123,213,1) 50%, rgba(154,81,176,1) 100%)'
            }}> */}
          <div className="container">
            <div className="row justify-content-around align-items-center">
              {/* <div className="col-12 col-md-6">
                  <div className="section__header text-left" style={{ marginBottom: '3rem' }}>
                    <div
                      className="title mb-2"
                      style={{
                        fontSize: '2.3rem',
                        fontWeight: '400',
                        fontFamily: "'Rubik', sans-serif",
                        lineHeight: 1.2,
                        color: 'white'
                      }}>
                      Register for a FREE Trial Class
                    </div>
                    <div className="subtitle font-weight-light title--rubik" style={{ color: 'white' }}>
                      Unsure if your child will like our classes? Sign up for a trial class to see what it's like.&nbsp;
                      Parents are welcome too!
                    </div>
                  </div>
                  {false && (
                    <div className="row">
                      <div className="col-4">
                        <div
                          className="text-center text-large py-3"
                          style={{
                            backgroundColor: 'rgba(255, 255, 255, 0.15)',
                            color: '#FFFFFF',
                            border: '1px solid #FFFFFF',
                            borderRadius: '5px'
                          }}>
                          {showStatNumber && (
                            <p className="numbers">
                              <span className="numbers__window">
                                <span
                                  className="numbers__window__digit numbers__window__digit--1"
                                  data-fake="8642519073">
                                  9
                                </span>
                              </span>
                              <span className="numbers__window">
                                <span
                                  className="numbers__window__digit numbers__window__digit--2"
                                  data-fake="5207186394">
                                  3
                                </span>
                              </span>
                              %
                            </p>
                          )}
                          A* / As <br />
                          achieved
                        </div>
                      </div>
                      <div className="col-4">
                        <div
                          className="text-center text-large py-3"
                          style={{
                            backgroundColor: 'rgba(255, 255, 255, 0.15)',
                            color: '#FFFFFF',
                            border: '1px solid #FFFFFF',
                            borderRadius: '5px'
                          }}>
                          {showStatNumber && (
                            <p className="numbers">
                              <span className="numbers__window">
                                <span
                                  className="numbers__window__digit numbers__window__digit--1"
                                  data-fake="8642519073">
                                  6
                                </span>
                              </span>
                              <span className="numbers__window">
                                <span
                                  className="numbers__window__digit numbers__window__digit--2"
                                  data-fake="5207186394">
                                  4
                                </span>
                              </span>
                              <span className="numbers__window">
                                <span
                                  className="numbers__window__digit numbers__window__digit--3"
                                  data-fake="8642519073">
                                  3
                                </span>
                              </span>
                            </p>
                          )}
                          classes <br />
                          conducted
                        </div>
                      </div>
                      <div className="col-4">
                        <div
                          className="text-center text-large py-3"
                          style={{
                            backgroundColor: 'rgba(255, 255, 255, 0.15)',
                            color: '#FFFFFF',
                            border: '1px solid #FFFFFF',
                            borderRadius: '5px'
                          }}>
                          {showStatNumber && (
                            <p className="numbers">
                              <span className="numbers__window">
                                <span
                                  className="numbers__window__digit numbers__window__digit--1"
                                  data-fake="8642519073">
                                  9
                                </span>
                              </span>
                              <span className="numbers__window">
                                <span
                                  className="numbers__window__digit numbers__window__digit--2"
                                  data-fake="5207186394">
                                  8
                                </span>
                              </span>
                              %
                            </p>
                          )}
                          happy <br />
                          parents
                        </div>
                      </div>
                    </div>
                  )}
                </div> */}

              {/* <div className="col-12 col-md-5">
                                                  <Button variant="contained" color="primary" className="mr-2" onClick={this.scrollToTrialClassSection}>
                                                    View Trial Class
                                                  </Button>
                                                  <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    className="btn-white-bg"
                                                    onClick={this.toggleGetInTouchDialog}>
                                                    Get In Touch
                                                  </Button>
                                                </div> */}

              {/* <div className="my-3 d-none d-md-block">
                  <Button variant="contained" size="large" color="primary" className="mb-3" onClick={this.goToRegister}>
                    Register now
                  </Button>
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    className="btn-white-bg mb-3 ml-3"
                    onClick={this.goToTrialPage}>
                    Get A Free Trial
                  </Button>
                </div> */}

              {/* <div className="col-12 col-md-5">
                                                <Paper className="bg-white p-3">
                                                  {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
                                                  {successMsg && <Alert severity="success">{successMsg}</Alert>}
                                                  <div className="text-large">
                                                    Leave us your contact and our educational consultants will get in touch to arrange for a trial
                                                    class.
                                                  </div>
                                                  <TextField
                                                    type="text"
                                                    label="Name"
                                                    name="name"
                                                    onChange={this.onChangeFormElement}
                                                    fullWidth
                                                    value={name}
                                                    margin="normal"
                                                    variant="outlined"
                                                  />

                                                  <TextField
                                                    type="text"
                                                    label="Email"
                                                    name="email"
                                                    onChange={this.onChangeFormElement}
                                                    fullWidth
                                                    value={email}
                                                    margin="normal"
                                                    variant="outlined"
                                                  />

                                                  <TextField
                                                    type="text"
                                                    label="Phone Number"
                                                    name="phone"
                                                    onChange={this.onChangeFormElement}
                                                    fullWidth
                                                    value={phone}
                                                    margin="normal"
                                                    variant="outlined"
                                                  />

                                                  <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    onClick={this.contactTenopy}
                                                    disabled={sent}
                                                    fullWidth={true}
                                                    className="btn--purple my-2">
                                                    Send {contactInProgress && <CircularProgress size={24} className="ml-2" />}
                                                  </Button>
                                                </Paper>
                                              </div> */}
            </div>
          </div>
          {/* </section> */}
        </VisibilitySensor>

        {/* <section style={{ backgroundColor: '#f6f6fc' }}>
          <div className="container">
            <div className="section__header mb-5">
              <div
                className="title mb-2"
                style={{ fontSize: '2.3rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif", lineHeight: 1.2 }}>
                Our Partners
              </div>
              <div className="subtitle font-weight-light title--rubik">
                Working with prestigious partners helps us bring great teaching content to our classes and make lessons
                come alive.
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-lg-6 mb-3">
                <Paper className="text-center" elevation={5}>
                  <div className="row">
                    <div className="col-6 d-lg-block d-none">
                      <img
                        src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/home-school-speacialty-illustration.jpg"
                        alt="school specialty illustration"
                        height="100%"
                        style={{ objectFit: 'cover', maxWidth: '100%' }}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <img
                        src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/partner/school-specialty-logo.png"
                        alt="SAP"
                        height="85"
                        className="mt-3"
                      />
                      <div className="text-large p-3">
                        We partner with US-based global educational group School Specialty, and publisher of Wordly Wise
                        i3000, to combine their engaging, research-based content with superior live teaching.
                      </div>
                    </div>
                  </div>
                </Paper>
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <Paper className="text-center" elevation={5}>
                  <div className="row">
                    <div className="col-6 d-lg-block d-none">
                      <img
                        src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/home-sap-illustration.jpg"
                        alt="school specialty illustration"
                        height="100%"
                        style={{ objectFit: 'cover', maxWidth: '100%' }}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <img
                        src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/partner/sap-logo.png"
                        alt="SAP"
                        height="85"
                        className="mt-3"
                      />
                      <div className="text-large p-3">
                        We leverage on global group Singapore Asia Publisher's expertise in the MOE syllabus to enhance
                        learning by incorporating their creative, innovative content into our proprietary curriculum.
                      </div>
                    </div>
                  </div>
                </Paper>
              </div>
            </div>
          </div>
        </section> */}

        <MediaFeaturedCarousel />

        <CTADivider />
        <VideoPlayerDialog isOpen={isVideoDialogOpen} close={this.toggleVideoDialog()} videoURL={videoURL} />
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LandingSG))
