import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Accordion, AccordionSummary, AccordionActions, Button, Divider } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import _ from 'lodash'
import moment from 'moment'
import qs from 'qs'
import agent from 'agent'
import {
  GET_FEEDBACK,
  GET_LESSON_BY_ID,
  SET_LESSON_RECORDING_TO_WATCH,
  RECORD_USER_ACTION
} from 'constants/actionTypes'
import JoinLessonButton from 'components/online-class/button-join-lesson'
import DialogCreateFeedback from 'components/student/DialogCreateFeedback'

const mapStateToProps = state => ({
  token: state.common.token,
  selectedClass: state.classes.selectedClass,
  currentUser: state.common.currentUser,
  feedbackList: state.feedback.feedbackList,
  feedbackCount: state.feedback.feedbackCount,
  feedbackCreated: state.feedback.feedbackCreated,
  feedbackInProgress: state.feedback.inProgress
})

const mapDispatchToProps = dispatch => ({
  getLessonById: lessonId => dispatch({ type: GET_LESSON_BY_ID, payload: agent.Lessons.getById(lessonId) }),
  setLessonRecordingIdToWatch: lessonId => dispatch({ type: SET_LESSON_RECORDING_TO_WATCH, lessonId }),
  getFeedback: filter => dispatch({ type: GET_FEEDBACK, payload: agent.UserInput.getFeedback(filter) }),
  trackAction: reqPayload => dispatch({ type: RECORD_USER_ACTION, payload: agent.Misc.recordAction(reqPayload) })
})

const FeedbackButton = props => {
  const { lesson, toggleFeedback, feedbackList, inProgress } = props

  let exists =
    _.findIndex(feedbackList, f => {
      return f.feedback_target_id === lesson._id
    }) !== -1
  let isPast = moment() <= moment(lesson.ended_on)

  if (isPast || exists) {
    return null
  } else {
    return (
      <Button variant={'outlined'} color={'primary'} onClick={toggleFeedback} disabled={inProgress}>
        Rate Lesson
      </Button>
    )
  }
}

class TabLiveLesson extends React.Component {
  state = {
    activeMonth: this.props.activeMonth,
    activeMonthLessons: [],
    activeLessonId: '',
    activeTerm: this.props.activeTerm,
    feedbackOpen: false,
    feedbackLesson: null
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activeMonth !== this.props.activeMonth) {
      this.setState({ activeMonth: this.props.activeMonth })
    }

    if (prevProps.activeTerm !== this.props.activeTerm) {
      this.setState({ activeTerm: this.props.activeTerm })
    }

    if (prevState.activeMonth !== this.state.activeMonth || prevState.activeTerm !== this.state.activeTerm) {
      this.processLessonData()
    }

    if (prevState.activeMonthLessons !== this.state.activeMonthLessons) {
      this.getFeedback(this.state.activeMonthLessons)
    }

    if (!prevProps.feedbackCreated && this.props.feedbackCreated) {
      this.getFeedback(this.state.activeMonthLessons)
    }
  }

  //NOTE: Tab LiveLesson, Tab Resource is having same process lesson data, try to optimize it
  processLessonData = () => {
    let { selectedClass, firstLoad, showByTerm } = this.props
    let { activeMonth, activeTerm } = this.state
    let activeMonthLessons = []
    let sortedActiveMonthLessons = []
    let activeLessonId

    if (showByTerm) {
      activeMonthLessons = _.filter(selectedClass.lesson, o => {
        return parseInt(o.meta.term) === activeTerm
      })
      sortedActiveMonthLessons = _.sortBy(activeMonthLessons, ['scheduled_on'])
      let parsed = qs.parse(this.props.location.search.slice(1))

      if (firstLoad && parsed.tab && parsed.tab === 'live-lesson' && parsed.lid) {
        activeLessonId = parsed.lid
      } else {
        let activeLesson = _.find(sortedActiveMonthLessons, function (o) {
          return moment(o.ended_on).add(20, 'minutes') > moment()
        })
        activeLessonId = activeLesson ? activeLesson._id : ''
      }
    } else {
      if (activeMonth === 'All') {
        activeMonthLessons = selectedClass.lesson
      } else {
        activeMonthLessons = _.filter(selectedClass.lesson, function (o) {
          return moment(o.scheduled_on).isSame(moment(activeMonth), 'month')
        })
      }

      sortedActiveMonthLessons = _.sortBy(activeMonthLessons, ['scheduled_on'])
      let parsed = qs.parse(this.props.location.search.slice(1))

      if (firstLoad && parsed.tab && parsed.tab === 'live-lesson' && parsed.lid) {
        activeLessonId = parsed.lid
      } else {
        let activeLesson = _.find(sortedActiveMonthLessons, function (o) {
          if (activeMonth === 'All') {
            return moment(o.ended_on).add(20, 'minutes') > moment()
          } else {
            return moment().isSame(moment(activeMonth), 'month') && moment(o.ended_on).add(20, 'minutes') > moment()
          }
        })
        activeLessonId = activeLesson ? activeLesson._id : ''
      }
    }

    this.setState({
      activeMonthLessons: sortedActiveMonthLessons,
      activeLessonId
    })
  }

  watchRecording = lessonId => {
    this.props.setLessonRecordingIdToWatch(lessonId)
    this.props.switchToRecordingTab()
  }

  getFeedback = lessonArray => {
    const { currentUser } = this.props

    if (!!lessonArray && lessonArray.length > 0) {
      let filter = {
        created_by: currentUser._id
      }

      filter.feedback_target_id = {
        $in: lessonArray.map(l => {
          return l._id
        })
      }
      this.props.getFeedback(filter)
    }
  }

  toggleCreateFeedback = lesson => () => {
    this.setState({ feedbackLesson: lesson, feedbackOpen: true })
  }

  render() {
    const { currentUser, feedbackList } = this.props
    const { activeMonthLessons, activeLessonId, feedbackOpen, feedbackLesson } = this.state

    return (
      <div className="animated slideInUpTiny animation-duration-3">
        {activeMonthLessons.map(lesson => {
          if (!lesson.is_active) {
            return null
          }

          const isActiveLesson = activeLessonId === lesson._id
          let isUserLesson = false
          if (lesson.student.includes(currentUser._id) || lesson.trial_student.includes(currentUser._id)) {
            isUserLesson = true
          }
          let recordingStatus = ''
          let lessonEnded = false
          if (moment(lesson.ended_on).add(30, 'minutes') < moment()) {
            lessonEnded = true
            recordingStatus = 'Recording available soon...'
            if (lesson.playback_url && lesson.playback_url.length > 0) {
              lessonEnded = true
              recordingStatus = 'Recording available'
            }
          }

          return (
            <Accordion
              defaultExpanded={isActiveLesson && isUserLesson}
              key={lesson._id}
              disabled={!isUserLesson}
              style={{ zIndex: '0' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className="row align-items-center w-100">
                  <div className="col">
                    {lesson.meta && <div className="text-small text-muted">Lesson {lesson.meta.index}</div>}
                    <h3 className="title--rubik mb-0">{lesson.name}</h3>
                  </div>
                  {(!lessonEnded || isActiveLesson) && (
                    <div className="col-auto">
                      {lessonEnded && <div className="tag danger-tag">Ended</div>}
                      {isActiveLesson &&
                        (moment() > moment(lesson.scheduled_on) && moment() < moment(lesson.ended_on) ? (
                          <div className="tag success-tag">Ongoing</div>
                        ) : (
                          <div className="tag success-tag">Next</div>
                        ))}
                    </div>
                  )}
                  <div className="col-auto text-md-right">
                    {isUserLesson ? (
                      recordingStatus ? (
                        <span>{recordingStatus}</span>
                      ) : (
                        <div id={`${isActiveLesson ? 'nextLiveLesssonTime' : ''}`}>
                          {isActiveLesson && moment(lesson.scheduled_on).diff(moment(), 'minutes') < 30 ? (
                            <span className="blink text-danger">
                              {moment(lesson.scheduled_on).format('ddd D MMMM, h:mma')}
                            </span>
                          ) : (
                            moment(lesson.scheduled_on).format('ddd D MMMM, h:mma')
                          )}
                        </div>
                      )
                    ) : (
                      'Not purchased yet'
                    )}
                  </div>
                  <div className="col-auto">
                    <FeedbackButton
                      lesson={lesson}
                      toggleFeedback={this.toggleCreateFeedback(lesson)}
                      feedbackList={feedbackList}
                      inProgress={this.props.feedbackInProgress}
                    />
                  </div>
                </div>
              </AccordionSummary>
              <Divider />
              <AccordionActions>
                {recordingStatus === 'Recording available' && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.watchRecording(lesson._id)
                    }}>
                    Watch Recording
                  </Button>
                )}

                {isUserLesson && !lessonEnded && <JoinLessonButton lessonData={lesson} />}
              </AccordionActions>
            </Accordion>
          )
        })}

        {feedbackOpen && !!feedbackLesson && (
          <DialogCreateFeedback isOpen={feedbackOpen} lesson={feedbackLesson} toggle={this.toggleCreateFeedback()} />
        )}
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TabLiveLesson))
