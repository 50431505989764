import React from 'react'
import { NavLink } from 'react-router-dom'
import qs from 'qs'
import { IE_WARNING } from '../../routes/routes'
import Button from '@material-ui/core/Button'

class IEWarning extends React.Component {
  constructor(props) {
    super()

    let parsed = qs.parse(window.location.search.slice(1))

    this.state = {
      origin: parsed.origin && parsed.origin !== IE_WARNING ? parsed.origin : '/'
    }
  }

  render() {
    return (
      <div className={'d-flex w-100 align-items-center justify-content-center'}>
        <div className={'text-center'}>
          <div className="col-4 mx-auto mb-3">
            <img
              src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/logo/tenopy/logo-n-title.png"
              className={'img-fluid'}
              alt=""
            />
          </div>
          <h1>You may encounter issues with our platform if you use Internet Explorer.</h1>
          <h2>We recommend using either Chrome or Firefox for a smooth experience on Tenopy.</h2>
          <div>
            <Button
              variant={'contained'}
              size={'large'}
              className={'bg-amber text-white'}
              href={'https://www.google.com/chrome/'}
              target={'_blank'}>
              <i className="fa fa-chrome mr-2"></i>Google Chrome
            </Button>
            <Button
              variant={'contained'}
              size={'large'}
              className={'bg-blue text-white ml-3'}
              href={'https://www.mozilla.org/en-US/firefox/'}
              target={'_blank'}>
              <i className="fa fa-firefox mr-2"></i>Mozilla Firefox
            </Button>
          </div>
          <div className={'mt-3'}>
            <NavLink to={this.state.origin}>Return to previous page</NavLink>
          </div>
        </div>
      </div>
    )
  }
}

export default IEWarning
