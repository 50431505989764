import React from 'react'
import { Link } from 'react-router-dom'
import { DateRange, Schedule, PersonOutline } from '@material-ui/icons'
import classNames from 'classnames'
import _ from 'lodash'
import moment from 'moment'

import {
  CLASS_TYPE_WORKSHOP,
  CLASS_TYPE_REGULAR_CLASSES,
  TIMEZONE_ABBR,
  CLASS_TYPE_RECORDING
} from 'constants/generalTypes'

export const getInitials = name => {
  if (name) {
    let nameArr = name.split(' ')
    if (nameArr.length > 1) {
      return nameArr[0].substring(0, 1) + nameArr[1].substring(0, 1)
    } else {
      return nameArr[0].substring(0, 1)
    }
  } else {
    return ''
  }
}

// TODO: review
export const OnlineClassSummary = props => {
  let { classType, startDate, endDate, lessonStartTime, lessonEndTime, tutor, hideTeacherPrefix } = props
  let scheduleTime

  if (lessonStartTime && lessonEndTime) {
    scheduleTime = `${moment(lessonStartTime).format('h:mm a')} - ${moment(lessonEndTime).format('h:mm a')}`
  }

  if (classType === CLASS_TYPE_WORKSHOP || classType === CLASS_TYPE_RECORDING) {
    let lessonStartDate = moment(startDate).format('D MMM')
    let lessonEndDate = moment(endDate).format('D MMM YYYY')

    return (
      <div className="manage-margin">
        <div className="d-flex align-items-center">
          <PersonOutline className="text-muted mr-2" />
          :&nbsp;&nbsp;&nbsp;{!hideTeacherPrefix && <>Teacher</>} {tutor?.name}
        </div>
        <div className="d-flex align-items-center">
          <DateRange className="text-muted mr-2" />
          :&nbsp;&nbsp;&nbsp;{lessonStartDate} to {lessonEndDate}
        </div>
        <div className="d-flex align-items-center">
          <Schedule className="text-muted mr-2" />
          :&nbsp;&nbsp;&nbsp;{scheduleTime}
        </div>
      </div>
    )
  } else if (classType === CLASS_TYPE_REGULAR_CLASSES) {
    let day
    if (lessonStartTime) {
      day = moment(lessonStartTime).format('dddd')
    }

    return (
      <div className="manage-margin">
        <div className="d-flex align-items-center">
          <PersonOutline className="text-muted mr-2" />
          :&nbsp;&nbsp;&nbsp;{!hideTeacherPrefix && <>Teacher</>} {tutor?.name}
        </div>
        <div className="d-flex align-items-center">
          <DateRange className="text-muted mr-2" />
          :&nbsp;&nbsp;&nbsp;Every {day}
        </div>
        <div className="d-flex align-items-center">
          <Schedule className="text-muted mr-2" />
          :&nbsp;&nbsp;&nbsp;{scheduleTime}
        </div>
      </div>
    )
  }

  return null
}

export const addParameterToURL = (_url, _key, _value) => {
  let param = _key + '=' + escape(_value)

  let sep = '&'
  if (_url.indexOf('?') < 0) {
    sep = '?'
  } else {
    let lastChar = _url.slice(-1)
    if (lastChar === '&') sep = ''
    if (lastChar === '?') sep = ''
  }
  _url += sep + param

  return _url
}

// TODO: review
export const getClassData = (classType, lessons, rate, quote, classId) => {
  if (classType === CLASS_TYPE_WORKSHOP) {
    let sortedLessons = _.sortBy(lessons, function (l) {
      return new Date(l.scheduled_on)
    })

    let discount = 0
    let price = quote - discount
    let start = moment(sortedLessons[0].scheduled_on)
    let end = moment(sortedLessons[sortedLessons.length - 1].scheduled_on)

    return {
      price,
      original_price: quote,
      lessonCount: sortedLessons.length,
      start_month: start.format('MMMM'),
      end_month: end.format('MMMMM'),
      discount
    }
  } else {
    let start = moment().startOf('month')
    let end = moment().endOf('month')

    let currentLessons = _.filter(lessons, l => {
      let lessonTime = moment(l.scheduled_on)
      return lessonTime >= start && lessonTime <= end
    })

    return {
      price: quote / lessons.length,
      lessonCount: currentLessons.length,
      start_month: start.format('MMMM'),
      end_month: end.format('MMMMM'),
      discount: 0
    }
  }
}

export const toReadable = (delimitedString, delimiter) => {
  let exploded = delimitedString.split(delimiter)

  for (let i = 0; i < exploded.length; i++) {
    exploded[i] = exploded[i].charAt(0).toUpperCase() + exploded[i].slice(1)
  }

  return exploded.join(' ')
}

export const FormattedLink = props => {
  const { link, className, children } = props

  if (/^https?:\/\//.test(link)) {
    return (
      <a href={link} className={classNames(className)}>
        {children}
      </a>
    )
  } else {
    return (
      <Link to={link} className={classNames(className)}>
        {children}
      </Link>
    )
  }
}

export const getTimezoneAbbr = () => {
  let timezoneOffset = moment().format('ZZ')
  let timezoneAbbr = TIMEZONE_ABBR[timezoneOffset] || ''

  return timezoneAbbr
}

export const checkWebpSupport = (feature, callback) => {
  var kTestImages = {
    lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
    lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
    alpha:
      'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
    animation:
      'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA'
  }
  var img = new Image()
  img.onload = function () {
    var result = img.width > 0 && img.height > 0
    callback(feature, result)
  }
  img.onerror = function () {
    callback(feature, false)
  }
  img.src = 'data:image/webp;base64,' + kTestImages[feature]
}

export const getSubmissionStatusBadgeColorOrText = (status, returnColor) => {
  let statusColor = 'info'
  let statusText = ''

  if (status === 'SUBMITTED') {
    statusText = 'Submitted'
    statusColor = 'info'
  } else if (status === 'RESUBMISSION_REQUIRE') {
    statusText = 'Resubmission required'
    statusColor = 'warning'
  } else if (status === 'RESUBMITTED') {
    statusText = 'Submitted'
    statusColor = 'info'
  } else if (status === 'MARKING_IN_PROGRESS') {
    statusText = 'Marking In Progress'
    statusColor = 'info'
  } else if (status === 'MARKED') {
    statusText = 'Marking Complete'
    statusColor = 'success'
  }

  if (returnColor) {
    return statusColor
  } else {
    return statusText
  }
}
