import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import agent from '../../../../agent'
import {
  ADD_CART_ITEM,
  ADD_CART_ITEM_NOAUTH,
  CALC_PACKAGE_QUOTE,
  GET_PACKAGE_QUOTE,
  CLEAR_PACKAGE_QUOTE,
  CONTACT_US,
  GET_ONLINE_CLASS_LIST
} from '../../../../constants/actionTypes'
import { APP, SHOPPING_CART, MY_CLASS_DETAILS, ONLINE_CLASS_LIST } from '../../../../routes/routes'
import _ from 'lodash'
import moment from 'moment'
import { Avatar, Button, CircularProgress } from '@material-ui/core'
import { formatCartItem, calculateQuote } from 'utils/cart'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import Divider from '@material-ui/core/Divider'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { isMobile } from 'react-device-detect'
import { CLASS_TYPE_REGULAR_CLASSES, CLASS_TYPE_WORKSHOP } from '../../../../constants/generalTypes'
import VisibilitySensor from 'react-visibility-sensor'
import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet'
import { Badge } from 'reactstrap'
import { roundPrice } from '../../../../utils/merchandise'
import JoinTrialDialog from 'pages/common/online-class/trial/dialog-join-trial'
import DialogProceedToCheckout from 'components/online-class/dialog-proceed-to-checkout'

const mapStateToProps = state => ({
  token: state.common.token,
  onlineClassList: state.onlineClass.onlineClassList,
  contactSuccess: state.misc.contacted,
  lessonPackages: state.shoppingCart.lessonPackages,
  shoppingCart: state.shoppingCart.shoppingCart,
  userClassWithDetail: state.common.userClassWithDetail
})

const mapDispatchToProps = dispatch => ({
  getClassList: () => dispatch({ type: GET_ONLINE_CLASS_LIST, payload: agent.OnlineClass.getList() }),
  contactUs: reqPayload => dispatch({ type: CONTACT_US, payload: agent.Misc.contactUs(reqPayload) }),
  addToCart: item => dispatch({ type: ADD_CART_ITEM, payload: agent.ShoppingCart.addItem(item), cartItem: item }),
  addToLocalCart: item => dispatch({ type: ADD_CART_ITEM_NOAUTH, payload: item }),
  calcPackageQuote: (quote, packageItem) => dispatch({ type: CALC_PACKAGE_QUOTE, payload: quote, packageItem }),
  getPackageQuote: (payload, packageItem) =>
    dispatch({ type: GET_PACKAGE_QUOTE, payload: agent.OnlineClass.getQuote(payload), packageItem: packageItem }),
  clearPackageQuote: (class_id, component_id) =>
    dispatch({ type: CLEAR_PACKAGE_QUOTE, class_id: class_id, component: component_id })
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

class VariantB extends React.Component {
  state = {
    promoClass: null,
    promoClassId: '',
    promoLessonCount: 0,
    lessonsIncluded: [],
    contactInfo: '',
    contactInfoErr: '',
    contactRequestSent: false,
    lessonPackage: null,
    addedToCart: false,
    isTutorVideoOpen: false,
    useFixedRightCol: false,
    footerVisible: false,
    isProceedToCheckoutDialogOpen: false,
    bannerVisible: true,
    isJoinTrialDialogOpen: false
  }

  constructor(props) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    this.setState({
      promoClassId: this.props.match.params.classId,
      promoLessonCount: this.props.match.params.lessonCount || 4
    })

    if (!this.props.onlineClassList || this.props.onlineClassList.length === 0) {
      this.props.getClassList()
    } else {
      this.filterOnlineClass()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    this.props.clearPackageQuote(this.state.promoClassId)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.onlineClassList !== this.props.onlineClassList) {
      this.filterOnlineClass()
    }

    if (prevState.promoClassId !== this.state.promoClassId) {
      this.filterOnlineClass()
      this.checkCartStatus()
    }

    if (!prevProps.contactSuccess && this.props.contactSuccess) {
      this.setState({ contactRequestSent: true })
    }

    if (prevProps.shoppingCart !== this.props.shoppingCart) {
      this.checkCartStatus()
    }

    if (prevProps.lessonPackages !== this.props.lessonPackages && this.props.lessonPackages[this.state.promoClassId]) {
      let lessonPackage = this.props.lessonPackages[this.state.promoClassId].item[0]
      this.setState({ lessonPackage })
    }
  }

  filterOnlineClass = () => {
    let { promoClassId, promoLessonCount } = this.state
    let { token, onlineClassList, userClassWithDetail } = this.props

    if (!promoClassId || !onlineClassList) {
      return
    }

    let promoClass = _.find(onlineClassList, function (o) {
      return o._id === promoClassId
    })
    let sortedLessons = _.sortBy(promoClass.lesson, ['scheduled_on'])
    let lessonsIncluded = []
    let now = moment()

    if (promoClass.class_type === CLASS_TYPE_WORKSHOP) {
      promoLessonCount = sortedLessons.length
    }

    let userLessonIds = []
    if (token && userClassWithDetail && userClassWithDetail[promoClassId]) {
      userLessonIds = userClassWithDetail[promoClassId].lesson
    }

    for (let i = 0; i < sortedLessons.length; i++) {
      if (lessonsIncluded.length < promoLessonCount && !userLessonIds.includes(sortedLessons[i]._id)) {
        if (promoClass.class_type === CLASS_TYPE_REGULAR_CLASSES) {
          if (moment(sortedLessons[i].scheduled_on) > now) {
            lessonsIncluded.push(sortedLessons[i])
          }
        } else {
          lessonsIncluded.push(sortedLessons[i])
        }
      }
    }

    if (lessonsIncluded.length > 0) {
      let packageItem = {
        class_id: promoClass._id,
        count: lessonsIncluded.length,
        options: {
          start_date: lessonsIncluded[0].scheduled_on,
          end_date: lessonsIncluded[lessonsIncluded.length - 1].scheduled_on
        },
        rate: promoClass.rate,
        title: promoClass.name
      }

      if (token) {
        this.props.getPackageQuote(
          {
            class_id: promoClass._id,
            start_date: lessonsIncluded[0].scheduled_on,
            end_date: lessonsIncluded[lessonsIncluded.length - 1].scheduled_on
          },
          packageItem
        )
      } else {
        let quote = calculateQuote(
          lessonsIncluded,
          promoClass.rate,
          lessonsIncluded[0].scheduled_on,
          lessonsIncluded[lessonsIncluded.length - 1].scheduled_on,
          promoClass._id
        )
        this.props.calcPackageQuote({ data: quote }, packageItem)
      }
    }

    this.setState({ promoClass, lessonsIncluded, promoLessonCount })
  }

  onChangeContactInfo = event => {
    this.setState({
      contactInfo: event.target.value,
      contactInfoErr: ''
    })
  }

  contactTenopy = () => {
    let { contactInfo, promoClass } = this.state
    if (contactInfo) {
      this.props.contactUs({
        message: `${contactInfo} requested more info for class ${promoClass.name}`,
        message_type: 'contact'
      })
    } else {
      this.setState({
        contactInfoErr: 'Please provide your either email or phone number.'
      })
    }
  }

  addToCart = () => {
    let { promoClass, lessonPackage } = this.state

    let formattedItem = formatCartItem(promoClass, 'Class', lessonPackage.packageItem.options)
    if (!!this.props.token) {
      this.props.addToCart(formattedItem)
    } else {
      this.props.addToLocalCart(formattedItem)
    }
    this.toggleProceedToCheckoutDialog()
  }

  checkCartStatus = () => {
    if (!this.state.promoClass) return

    let addedToCart = _.find(this.props.shoppingCart.item, ci => {
      return ci.item_id === this.state.promoClass._id
    })
    this.setState({ addedToCart })
  }

  toggleTutorVideo = () => {
    if (!this.state.isTutorVideoOpen) {
      ReactGA.event({
        category: 'Conversion',
        action: 'PlayTutorIntro',
        label: 'B'
      })
    }
    this.setState({ isTutorVideoOpen: !this.state.isTutorVideoOpen })
  }

  goToCart = () => {
    this.toggleProceedToCheckoutDialog()
    let redirectUrl = this.props.token ? `${APP}${SHOPPING_CART}` : SHOPPING_CART
    this.props.history.push(redirectUrl)
  }

  goToClass = () => {
    this.props.history.push(MY_CLASS_DETAILS.replace(':classId', this.state.promoClassId))
  }

  handleScroll = e => {
    let { useFixedRightCol } = this.state
    let footer = document.getElementById('footer')

    if (footer && footer.getBoundingClientRect().top < window.innerHeight) {
      this.setState({ footerVisible: true })
    } else {
      this.setState({ footerVisible: false })
    }

    if (window.scrollY > 100 && !useFixedRightCol) {
      this.setState({ useFixedRightCol: true })
    }
    if (window.scrollY <= 100 && useFixedRightCol) {
      this.setState({ useFixedRightCol: false })
    }
  }

  toggleProceedToCheckoutDialog = () => {
    this.setState({ isProceedToCheckoutDialogOpen: !this.state.isProceedToCheckoutDialogOpen })
  }

  bannerVisibilityChange = isVisible => {
    this.setState({ bannerVisible: isVisible })
  }

  joinTrial = () => {
    let { promoClass } = this.state
    if (!this.state.isJoinTrialDialogOpen) {
      ReactGA.event({
        category: 'Conversion',
        action: `Init join trial ${promoClass.name}`,
        label: 'B'
      })
    }

    this.setState({
      isJoinTrialDialogOpen: true
    })
  }

  toggleJoinTrialDialog = () => {
    if (!this.state.isJoinTrialDialogOpen) {
      ReactGA.event({
        category: 'Conversion',
        action: this.props.token ? 'JoinTrial' : 'InitRegistration',
        label: 'B'
      })
    }
    this.setState({
      isJoinTrialDialogOpen: !this.state.isJoinTrialDialogOpen
    })
  }

  render() {
    let { token } = this.props
    let {
      promoClass,
      lessonsIncluded,
      lessonPackage,
      addedToCart,
      isJoinTrialDialogOpen,
      isTutorVideoOpen,
      useFixedRightCol,
      footerVisible,
      isProceedToCheckoutDialogOpen,
      bannerVisible
    } = this.state

    let rightColClass = ''
    if (footerVisible) {
      rightColClass = 'fixed--bottom'
    } else if (useFixedRightCol) {
      rightColClass = 'fixed--top'
    }

    if (!promoClass) {
      return (
        <div className="manage-margin text-center my-5">
          <CircularProgress size={50} />
        </div>
      )
    }

    let videoPoster = promoClass.tutor[0].profile_pic
    let videoPosterAvailable = false
    let videoAvailable = promoClass.tutor[0].intro && promoClass.tutor[0].intro.video_url
    if (promoClass.tutor[0].intro && promoClass.tutor[0].intro.video_poster) {
      videoPoster = promoClass.tutor[0].intro.video_poster
      videoPosterAvailable = true
    }

    return (
      <div className="landing">
        <Helmet>
          <title>{promoClass.name} | Tenopy</title>
          <meta name="description" content={`Sign up for ${promoClass.name} class with Tenopy`} />
          <meta
            name="keywords"
            content="Online tuition,Math tuition,English tuition,Secondary school tuition,Primary school tuition,Online tuition singapore,Online learning,Online education,Education platform,Online tutor,Home tutor,home tuition,science tuition,sa1,sa2,ca1,ca2,psle,revision,online revision,online study,online learning,psle revision"
          />
        </Helmet>

        {!bannerVisible && isMobile && (
          <div className="promo__CTA--sticky">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col">
                  <div className="d-flex flex-row align-items-center">
                    <Avatar
                      className="size-60 avatar-shadow mx-auto my-2 pointer"
                      alt={promoClass.tutor[0].name}
                      src={promoClass.tutor[0].profile_pic}
                    />
                    <div className="ml-2">
                      <h3 className="font-weight-semibold mb-1">{promoClass.name}</h3>
                      <div className="d-flex flex-row">
                        <small className="mr-2">by</small>
                        {promoClass.tutor[0].name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  {lessonsIncluded.length > 0 ? (
                    addedToCart ? (
                      <Button className="my-3" variant="outlined" color="primary" onClick={this.goToCart}>
                        Checkout
                      </Button>
                    ) : (
                      <Button className="my-3" variant="contained" color="primary" onClick={this.addToCart}>
                        Add to Cart
                      </Button>
                    )
                  ) : (
                    token && (
                      <Button className="my-3" variant="contained" color="primary" onClick={this.goToClass}>
                        View Class
                      </Button>
                    )
                  )}
                  {!addedToCart && (
                    <Button variant="text" color="primary" onClick={this.joinTrial}>
                      Join a Trial Lesson
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <VisibilitySensor onChange={this.bannerVisibilityChange} partialVisibility={true}>
          <div
            className={`jumbotron jumbotron-fluid cc-detail__banner ${
              !videoPosterAvailable ? 'cc-detail__no-video' : ''
            }`}>
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-12 px-3">
                  <div className="d-flex flex-wrap align-items-end mb-2">
                    <h1 className="text-white mb-0">{promoClass.name}</h1>
                    <div>
                      <span className="text-white ml-2">by</span>
                      <span className="text-white ml-2" style={{ fontSize: '21px' }}>
                        {promoClass.tutor[0].name}
                      </span>
                    </div>
                  </div>
                  <div className="mb-3">
                    <span className="tag amber-tag text-capitalize font-weight-semibold " style={{ color: '#29303b' }}>
                      Recommended
                    </span>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: promoClass.desc }} className="text-white text-justify" />
                </div>
                <div className="col-md-4 col-12">
                  <div className={`cc-detail__right-col ${rightColClass}`}>
                    <div className="cc-detail__right-col--content">
                      {rightColClass && !isMobile ? (
                        <div className="py-2 px-3">
                          <div className="d-flex flex-row align-items-center">
                            <Avatar
                              className="size-80 avatar-shadow mx-auto mb-2 pointer"
                              alt={promoClass.tutor[0].name}
                              src={promoClass.tutor[0].profile_pic}
                              onClick={this.toggleTutorVideo}
                            />
                            <div className="ml-2">
                              <h2 className="font-weight-semibold mb-1">{promoClass.name}</h2>
                              <div className="d-flex flex-row">
                                <small className="mr-2">by</small>
                                {promoClass.tutor[0].name}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="cc-detail__video--container" onClick={this.toggleTutorVideo}>
                          <img
                            src={videoPoster}
                            alt="video poster"
                            className="img-fluid"
                            style={{ minHeight: '120px' }}
                          />
                          {videoAvailable && (
                            <React.Fragment>
                              <div className="cc-detail__video--overlay"></div>
                              <i className="fa fa-play fa-3x cc-detail__video--icon"></i>
                            </React.Fragment>
                          )}
                        </div>
                      )}
                      {lessonPackage && (
                        <React.Fragment>
                          {lessonPackage.quote.original_price > lessonPackage.quote.quote && (
                            <Badge className="ml-3 mt-3 mb-0 text-white" color="warning">
                              {Math.round(
                                ((lessonPackage.quote.original_price - lessonPackage.quote.quote) /
                                  lessonPackage.quote.original_price) *
                                  100
                              )}
                              % OFF
                            </Badge>
                          )}
                          <div className="d-flex align-items-center my-2 px-3">
                            <div className="cc-detail__price">${lessonPackage.quote.quote}</div>
                            {lessonPackage.quote.original_price > lessonPackage.quote.quote && (
                              <del className={'text-danger ml-1'}>
                                <span className="text-muted">${roundPrice(lessonPackage.quote.original_price)}</span>
                              </del>
                            )}
                            <small className="text-muted ml-2">for {lessonsIncluded.length} lessons</small>
                          </div>
                        </React.Fragment>
                      )}
                      <div className="d-flex align-items-center cc-detail__desc px-3 mb-2">
                        <i className="material-icons mr-2">event</i>Every{' '}
                        {moment(promoClass.lesson[0].scheduled_on).format('dddd')}
                      </div>
                      <div className="d-flex align-items-center cc-detail__desc px-3">
                        <i className="material-icons mr-2">access_time</i>
                        {moment(promoClass.lesson[0].scheduled_on).format('h:mm a')} to{' '}
                        {moment(promoClass.lesson[0].ended_on).format('h:mm a')}
                      </div>
                      <div className="mx-3">
                        {lessonsIncluded.length > 0 ? (
                          addedToCart ? (
                            <Button
                              className="mt-3 mb-1"
                              variant="outlined"
                              color="primary"
                              fullWidth={true}
                              size="large"
                              onClick={this.goToCart}>
                              Checkout
                            </Button>
                          ) : (
                            <Button
                              className="mt-3 mb-1"
                              variant="contained"
                              color="primary"
                              fullWidth={true}
                              size="large"
                              onClick={this.addToCart}>
                              Add to Cart
                            </Button>
                          )
                        ) : (
                          token && (
                            <Button
                              className="mt-3 mb-1"
                              variant="contained"
                              color="primary"
                              fullWidth={true}
                              size="large"
                              onClick={this.goToClass}>
                              View Class
                            </Button>
                          )
                        )}
                        {!addedToCart && (
                          <Button variant="text" color="primary" onClick={this.joinTrial} fullWidth>
                            Join a Trial Lesson
                          </Button>
                        )}
                      </div>
                      <div className="align-items-center cc-detail__desc px-3 mb-2">
                        What will you get:
                        <ul className="text-muted">
                          <li>{lessonsIncluded.length} LIVE online tutoring lessons</li>
                          <li>Homework marked for each lesson with personalized feedback</li>
                          {/*<li>Post-lesson consultation</li>
                        <li>Tutor review of individual students' school assessments</li>*/}
                          <li>Weekly report for child's learning progress</li>
                        </ul>
                      </div>
                    </div>
                    {/*<div className="cc-detail__right-col--content mt-3 px-3 pb-3 pt-2">
                    {contactRequestSent ?
                    <div className="alert alert-success mb-0">We have received your request.</div>
                    :
                    <React.Fragment>
                      <TextField
                        label="Email / Phone number"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                        onChange={this.onChangeContactInfo}
                        value={contactInfo}
                        error={!!contactInfoErr}
                      />
                      <Button variant="outlined" color="primary" fullWidth={true} onClick={this.contactTenopy}>I need more info!</Button>
                    </React.Fragment>
                    }
                  </div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </VisibilitySensor>

        <div className="container">
          <div className="row mb-5">
            <div className="col-md-8 col-12 px-3">
              <h2>Lessons Included</h2>
              {lessonsIncluded.map((lesson, index) => (
                <div
                  key={index}
                  className="mb-2"
                  style={{ backgroundColor: '#f9f9f9', border: '1px solid #dedfe0', padding: '10px 15px' }}>
                  <div className="mb-2">
                    <i className="fa fa-check mr-2" />
                    <span style={{ fontSize: '20px' }}>{lesson.name}</span>
                  </div>
                  <div className="d-flex align-items-center cc-detail__desc px-3 mb-1 text-muted">
                    <i className="material-icons mx-2">event</i>
                    {moment(lesson.scheduled_on).format('dddd, MMMM Do YYYY')}
                  </div>
                  <div className="d-flex align-items-center cc-detail__desc px-3 text-muted">
                    <i className="material-icons mx-2">access_time</i>
                    {moment(lesson.scheduled_on).format('h:mm a')} to {moment(lesson.ended_on).format('h:mm a')}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-8 col-12 px-3">
              <h2>FAQs</h2>
              <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <span className="font-weight-semibold">What is Tenopy?</span>
                </ExpansionPanelSummary>
                <Divider />
                <ExpansionPanelDetails>
                  Tenopy is a live online tutoring platform for students to learn from top tutors, through live
                  interactive classes, from the comforts of home. Students can also watch the video playback of their
                  lessons. It’s flexible, hassle-free and convenient, as students can learn from the comfort of their
                  homes, anytime.
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <span className="font-weight-semibold">How do you interact with the tutor during lesson?</span>
                </ExpansionPanelSummary>
                <Divider />
                <ExpansionPanelDetails>
                  During a live online class, students can see the tutor teaching, participate in quizzes and ask
                  questions in real time via chat box. During a live online class, students can see the tutor teaching,
                  participate in quizzes and ask questions in real time via chat box. The tutor will also enable the
                  students’ microphone to allow them to speak during class when the student clicks “raise hand”.
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <span className="font-weight-semibold">Do you have your own curriculum?</span>
                </ExpansionPanelSummary>
                <Divider />
                <ExpansionPanelDetails>
                  Yes, our curriculum is developed based on Singapore’s syllabus. We are also partnering with several
                  established education providers to bring their content and expertise to our platform. Find out about
                  our class schedules and details <Link to={ONLINE_CLASS_LIST}>here</Link>.
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <span className="font-weight-semibold">What classes do you currently offer?</span>
                </ExpansionPanelSummary>
                <Divider />
                <ExpansionPanelDetails>
                  Tenopy currently offers English, Mathematics and Science subjects for Primary 3 to Primary 6. Keep a
                  lookout for our future announcement for more classes.
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <span className="font-weight-semibold">Do you give lesson notes and homework?</span>
                </ExpansionPanelSummary>
                <Divider />
                <ExpansionPanelDetails>
                  Yes, lesson notes and homework will be uploaded by our tutors at least 2 days prior to the scheduled
                  lesson. Students can also submit Tenopy homework to get tutors’ feedback on our platform.
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <span className="font-weight-semibold">How do I keep track of my child’s learning progress?</span>
                </ExpansionPanelSummary>
                <Divider />
                <ExpansionPanelDetails>
                  <div>
                    <p>
                      We will be giving a weekly Parent Report. This report is meant to provide you with information
                      regarding your child in several ways:
                    </p>
                    <ul>
                      <li>
                        Attendance: The report reflects whether your child has been attending lessons as expected for
                        the full duration of the lesson. You may ignore this if your child is watching the replay of
                        lessons due to his/her schedule.
                      </li>
                      <li>
                        Attention: The report reflects whether your child has been active and responsive during lessons
                        by answering the quiz questions.
                      </li>
                      <li>
                        Understanding: The report reflects areas of understanding that your child may need more guidance
                        or revision in. Please note, however, that some quiz questions are deliberately designed to be
                        especially tricky for students.
                      </li>
                    </ul>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <span className="font-weight-semibold">What do I need to attend Tenopy lesson?</span>
                </ExpansionPanelSummary>
                <Divider />
                <ExpansionPanelDetails>
                  <div>
                    <p>
                      All you need to join Tenopy lessons is your personal computer and earphones with a microphone.
                    </p>
                    <p>
                      You can also join lessons with your mobile phone, but you will be prompted to download and run a
                      companion app. Please follow the on-screen instructions to install this app. However, we suggest
                      having lessons on computer as students may absorb knowledge better with a bigger screen.
                    </p>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          </div>
        </div>

        <Dialog
          fullWidth={false}
          maxWidth="lg"
          open={isTutorVideoOpen}
          onClose={this.toggleTutorVideo}
          TransitionComponent={Transition}>
          <div>
            {videoAvailable && (
              <video
                autoPlay
                className="w-100"
                controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                controlsList={'nodownload'}>
                <source src={promoClass.tutor[0].intro.video_url} type="video/mp4" />
              </video>
            )}
          </div>
        </Dialog>

        <DialogProceedToCheckout
          isOpen={isProceedToCheckoutDialogOpen}
          toggleDialog={this.toggleProceedToCheckoutDialog}
          proceedToCheckout={this.goToCart}
          classTitle={promoClass.name || ''}
          lessonsIncluded={lessonsIncluded}
        />

        <JoinTrialDialog
          isOpen={isJoinTrialDialogOpen}
          toggleDialog={this.toggleJoinTrialDialog}
          classData={promoClass}
        />
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VariantB))
