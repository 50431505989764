import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { AppBar, Badge, IconButton, Toolbar } from '@material-ui/core'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'

import { TOGGLE_COLLAPSED_NAV } from 'constants/actionTypes'
import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'constants/settingsTypes'
import { USER_NAVIGATION_ACCESS } from 'constants/userTypes'
import { APP, ONLINE_CLASS_LIST, SHOPPING_CART } from 'routes/routes'
import { DASHBOARD } from 'routes/studentRoutes'

const mapStateToProps = state => ({
  drawerType: state.settings.drawerType,
  shoppingCart: state.shoppingCart.shoppingCart,
  currentUser: state.common.currentUser
})

const mapDispatchToProps = dispatch => ({
  toggleCollapsedNav: isNavCollapsed => dispatch({ type: TOGGLE_COLLAPSED_NAV, isNavCollapsed })
})

class Header extends React.Component {
  onToggleCollapsedNav = e => {
    const val = !this.props.navCollapsed
    this.props.toggleCollapsedNav(val)
  }

  render() {
    const { drawerType, shoppingCart, location, currentUser } = this.props
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'd-block d-xl-none'
      : drawerType.includes(COLLAPSED_DRAWER)
      ? 'd-block'
      : 'd-none'

    let userType = currentUser ? currentUser._type : ''
    let userAccess = userType ? USER_NAVIGATION_ACCESS[userType] : []
    let showShoppingCartIcon = true
    //TODO: Find a better way to do this, When we need to hide something based on current location/pathname
    if (location.pathname.includes('/app/checkout') || !userAccess.includes('cart')) {
      showShoppingCartIcon = false
    }

    return (
      <AppBar className="app-main-header">
        <Toolbar className="app-toolbar" disableGutters={false}>
          <IconButton className={`jr-menu-icon ${drawerStyle}`} aria-label="Menu" onClick={this.onToggleCollapsedNav}>
            <span className="menu-icon" />
          </IconButton>

          <Link
            className="app-logo"
            to={location.pathname === `${APP}${SHOPPING_CART}` ? ONLINE_CLASS_LIST : `${APP}${DASHBOARD}`}>
            <img
              src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/logo/tenopy-white.png"
              alt="Tenopy"
              title="Tenopy"
            />
          </Link>

          {showShoppingCartIcon && (
            <IconButton
              className="ml-auto"
              onClick={() => {
                this.props.history.push(`${APP}${SHOPPING_CART}`)
              }}>
              <Badge badgeContent={shoppingCart.item.length} color="secondary">
                <ShoppingCartIcon className="text-white" />
              </Badge>
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
