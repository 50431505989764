import React from 'react'
import { connect } from 'react-redux'
import { Paper } from '@material-ui/core'

import agent from 'agent'
import { GET_MY_ARCHIVED_CLASSES } from 'constants/actionTypes'
import Pagination from 'utils/Pagination'
import UpdateProfileCTA from 'components/misc/cta-update-profile'
import ClassListing from '../my-class-list'

const mapStateToProps = state => ({
  loadingArchivedClass: state.classes.loadingArchivedClass,
  archivedClasses: state.classes.archivedClass
})

const mapDispatchToProps = dispatch => ({
  getCompletedClasses: filter =>
    dispatch({ type: GET_MY_ARCHIVED_CLASSES, payload: agent.Classes.getMyClassList('completed', filter) })
})

const PER_PAGE = 12

class CompletedClasses extends React.Component {
  componentDidMount() {
    this.getCompletedClasses(0)
  }

  getCompletedClasses = page => {
    this.props.getCompletedClasses({
      max: PER_PAGE,
      offset: page * PER_PAGE
    })
  }

  onChangePage = nextPage => {
    this.getCompletedClasses(nextPage - 1)
  }

  render() {
    const { loadingArchivedClass, archivedClasses } = this.props

    return (
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <Paper elevation={2} className="app-wrapper">
          <div className="row align-items-center">
            <div className="col">
              <h2 className="mb-0">Archived Classes</h2>
            </div>
          </div>
        </Paper>

        <div className="app-wrapper">
          <UpdateProfileCTA />

          <ClassListing
            loading={loadingArchivedClass}
            classList={archivedClasses.data}
            classCount={archivedClasses.count}
          />

          <Pagination itemCount={archivedClasses.count} pageSize={PER_PAGE} onChangePage={this.onChangePage} />
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompletedClasses)
