import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { APP, FAQ, SIGNUP } from '../../routes/routes'
import Clipboard from 'react-clipboard.js'
import { Button } from '@material-ui/core'
import ReactGA from 'react-ga'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  token: state.common.token
})

const mapDispatchToProps = dispatch => ({})

class ReferralBanner extends React.Component {
  constructor() {
    super()

    this.state = {
      showInviteLinkCopied: false,
      referralTnCDialogOpen: false
    }
  }

  getInviteLink = () => {
    return `${window.location.origin}${SIGNUP}?referral=${this.props.currentUser.referral_code}`
  }

  toggleInviteLinkCopied = () => {
    ReactGA.event({
      category: 'Referral',
      action: 'Copy Referral Link',
      value: this.props.currentUser._id
    })
    this.setState({ showInviteLinkCopied: true })
  }

  render() {
    const { currentUser, token } = this.props
    const { showInviteLinkCopied } = this.state

    if (!token) {
      return null
    }

    let referral_code = currentUser.referral_code

    return (
      <div className="container">
        <div className="row py-md-0 py-3 px-md-0 px-2 mb-3 referral-banner">
          <div className="col-auto d-none d-md-block">
            <img
              src="https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/frontend/illustration-referral.png"
              alt="referral program"
              className="mt-4 mx-4"
              height="120px"
            />
          </div>
          <div className="col align-self-center">
            <div className="referral__header text-primary font-weight-semibold mb-1">
              Refer a Friend and BOTH will get $20 in credits
            </div>
            <p>which you can use towards your next purchase.</p>
            <p>
              Your friend must <strong>buy at least 1 class</strong> for you to receive your credits.
            </p>
            <Link to={`${APP}${FAQ}#8-1`} target="_blank" className="text-light-blue">
              <small>Terms and conditions apply</small>
            </Link>
          </div>
          <div className="col-md-auto col-12 align-self-center">
            <div className="d-flex flex-row flex-md-column align-items-center mt-md-0 mt-3 mr-md-4 mr-2">
              <p>
                Your invite code: <strong>{referral_code}</strong>
              </p>
              <Clipboard
                component="a"
                className={'ml-auto'}
                option-text={this.getInviteLink}
                onSuccess={this.toggleInviteLinkCopied}>
                <Button color="primary" variant="contained">
                  Copy Invite Link
                </Button>
              </Clipboard>
              {showInviteLinkCopied && <div className="text-primary text-md-center text-left">Link copied</div>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferralBanner)
