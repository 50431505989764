import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import VisibilitySensor from 'react-visibility-sensor'
import { ExpandMore } from '@material-ui/icons'
import PropTypes from 'prop-types'
import { Accordion, AccordionSummary, AccordionDetails, Button, Collapse } from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'
import ClassPackageSelector from 'components/online-class/select-class-package'
import OxkidsCollaborated from 'components/online-class/misc-partner-oxkids'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

const TESTIMONIALS = [
  {
    content:
      'I am very happy to take part in the Ox-Ten book club. Through this book club, I know what are some other recommended books to read and have already borrowed one book (The Giver) and started reading it! I like teacher Celesta’s session because she explained the abridged version of The Giver very clearly.',
    name: 'Tan Shao Jie',
    level: 'Primary Level'
  },
  {
    content:
      'Isabel enjoyed the sessions very much and I think it served its purpose to instil an appreciation for reading. I saw in her NLB app that she recently borrowed one of the book club titles, The Giver. I’m encouraged by the fact that she looked up the title and borrowed the book.',
    name: 'Isabel Hannah Randhawa',
    level: 'Primary Level'
  },
  {
    content: 'I loved how detailed the lesson was! It made me enjoy reading a lot more and it was interesting!',
    name: 'Yu Youning',
    level: 'Primary Level'
  }
]

const mapStateToProps = state => ({
  token: state.common.token
})

class ClassIntro extends React.Component {
  state = {
    addToCartVisible: true,
    nextLessonId: ''
  }

  componentDidMount() {
    this.checkNextLesson()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.onlineClassItem !== this.props.onlineClassItem) {
      this.checkNextLesson()
    }
  }

  checkNextLesson = () => {
    const { onlineClassItem } = this.props
    if (onlineClassItem?.lesson.length > 0) {
      const sortedLesson = _.orderBy(onlineClassItem.lesson, ['scheduled_on'])
      const nextLesson = _.find(sortedLesson, o => {
        return moment(o.scheduled_on) > moment()
      })
      this.setState({ nextLessonId: nextLesson._id })
    }
  }

  ctaVisibilityChange = isVisible => {
    this.setState({ addToCartVisible: isVisible })
  }

  render() {
    const { onlineClassItem } = this.props
    const { addToCartVisible, nextLessonId } = this.state

    let profilePic = onlineClassItem.tutor[0]?.profile_pic
    if (onlineClassItem._id === 'Ml7l3z5NQpCiftZJ21aPjw' || onlineClassItem._id === 'TeQyzd6aTuuhbRqdnmNCHA') {
      profilePic =
        'https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/book-club/placeholder-primary.jpg'
    }

    return (
      <>
        <VisibilitySensor onChange={this.ctaVisibilityChange} partialVisibility={true} minTopValue={200}>
          <div className="intro mb-3">
            <div className="row">
              <div className="col-md-3">
                <div className="intro__image tutor-intro">
                  <div className="row">
                    <div className="col-6 col-md-12">
                      <img src={profilePic} alt="cover" className="img-fluid rounded shadow" />
                    </div>
                    <div
                      className={`col-6 col-md-12 d-flex align-items-center ${
                        onlineClassItem.tutor.length > 1 ? 'pb-4' : ''
                      }`}>
                      <div className="mt-2 mx-auto text-center">
                        <h3 className="mb-0">{onlineClassItem.tutor[0]?.name}</h3>
                        <h3 className="text-muted">{onlineClassItem.subject}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="intro__text">
                  <div className="mb-2">
                    <span className="tag purple-tag--outline">BOOK CLUB</span>
                  </div>
                  <h2 className="intro__title">{onlineClassItem.name}</h2>

                  <div style={{ position: 'relative' }}>
                    <div className="intro__description" dangerouslySetInnerHTML={{ __html: onlineClassItem.desc }} />
                    <OxkidsCollaborated classId={onlineClassItem._id} />
                  </div>

                  {onlineClassItem.is_sold_out ? (
                    <Button disabled variant="outlined" className="bg-danger text-white" fullWidth>
                      Class is full
                    </Button>
                  ) : (
                    <div className="d-flex justify-content-end">
                      <ClassPackageSelector onlineClassItem={onlineClassItem} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Collapse in={!addToCartVisible} className="d-md-none fixed-bottom">
              {onlineClassItem.is_sold_out ? (
                <Button disabled variant="outlined" className="bg-danger text-white" fullWidth>
                  Class is full
                </Button>
              ) : (
                <div className="text-center p-1 bg-white border-top">
                  <ClassPackageSelector onlineClassItem={onlineClassItem} variant="button" />
                </div>
              )}
            </Collapse>
          </div>
        </VisibilitySensor>
        <hr />
        <div className="my-4">
          <div className="section__header mb-4">
            <div
              className="title title--rubik mb-2"
              style={{
                fontSize: '24px',
                fontWeight: '400',
                fontFamily: "'Rubik', sans-serif",
                lineHeight: 1.2
              }}>
              Session Details
            </div>
            <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #0cc652' }} />
          </div>

          {nextLessonId && (
            <>
              {_.orderBy(onlineClassItem.lesson, 'meta.index').map((lesson, index) => {
                const pastSession = moment() > moment(lesson.scheduled_on)
                let expanded = false
                let tbc = false
                if (lesson._id === nextLessonId) {
                  expanded = true
                }
                if (lesson.name.includes('TBC')) {
                  tbc = true
                }
                return (
                  <Accordion key={index} className="w-100" defaultExpanded={expanded}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                        <div className="h3 title--rubik mt-md-0 mt-1 mb-0">
                          {tbc ? (
                            <span className="text-muted font-italic">to be announced</span>
                          ) : (
                            <>
                              {lesson.meta.custom_title ? (
                                <div dangerouslySetInnerHTML={{ __html: lesson.meta.custom_title }} />
                              ) : (
                                <>{lesson.name}</>
                              )}
                            </>
                          )}
                        </div>
                        <div className={`tag ${pastSession ? 'light-tag' : 'primary-tag'}`}>
                          {moment(lesson.scheduled_on).format('Do MMM YYYY')}
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {tbc ? <span>To be announced.</span> : <div dangerouslySetInnerHTML={{ __html: lesson.desc }} />}
                    </AccordionDetails>
                  </Accordion>
                )
              })}
            </>
          )}
        </div>

        <section className="bg-white" id="read-more">
          <div className="container">
            <div className="section__header mb-3">
              <div
                className="title title--rubik mb-2"
                style={{
                  fontSize: '24px',
                  fontWeight: '400',
                  fontFamily: "'Rubik', sans-serif",
                  lineHeight: 1.2
                }}>
                About the Book Club
              </div>
              <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #0cc652' }} />
            </div>
            <div className="row justify-content-center">
              <div className="col-12">
                <p>
                  In each live online session, the speaker will take book club members through the most exciting
                  highlights of the book, discuss how the stories relate to our own lives, and highlight good use of
                  words and phrases in the book for appreciation of the language.
                </p>
                <p>
                  The book club members will engage with the speaker using on-screen quizzes, selective invitation to
                  speak, and free flow participation in chat.
                </p>
                <p>
                  The Ox-Ten Book Club is brought to you by Tenopy, the leading online education company in Singapore,
                  and Ox-Kids, an education consultancy based in Singapore.
                </p>
                <div className="text-center">
                  <video
                    className="w-100"
                    controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                    controlsList="nodownload">
                    <source
                      src="https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/video-assets/book-club-preview.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-light-gray">
          <div className="container">
            <div className="section__header mb-5">
              <div
                className="title mb-2"
                style={{ fontSize: '24px', fontWeight: '400', fontFamily: "'Rubik', sans-serif" }}>
                What they said
              </div>
              <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #0cc652' }}></div>
            </div>
            <div className="row">
              {TESTIMONIALS.map((testi, index) => (
                <div className="col-md-4 col-12" key={index}>
                  <div className="testimonial-item">
                    <div className="quote-bubble">{testi.content}</div>
                    <p className="testimonial-user">
                      <span>{testi.name}</span>
                      <small>- {testi.level}</small>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </>
    )
  }
}

ClassIntro.propTypes = {
  onlineClassItem: PropTypes.object.isRequired
}

export default withRouter(connect(mapStateToProps)(ClassIntro))
