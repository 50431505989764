import React from 'react'
import { connect } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'
import { Button, Dialog, Slide } from '@material-ui/core'
import Proptypes from 'prop-types'
import moment from 'moment'
import { Player, ControlBar, PlaybackRateMenuButton, ReplayControl, ForwardControl } from 'video-react'
import agent from 'agent'
import { TUTOR, TA } from 'constants/userTypes'
import { GET_PLAYBACK_VIDEO } from 'constants/actionTypes'
import HLSSource from 'components/misc/hls-source'

import '../../../node_modules/video-react/dist/video-react.css'

const mapStateToProps = state => ({
  ...state.videos,
  currentUser: state.common.currentUser
})

const mapDispatchToProps = dispatch => ({
  getVideo: lessonId =>
    dispatch({ type: GET_PLAYBACK_VIDEO, payload: agent.Lessons.getVideo(lessonId), lessonId: lessonId })
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

// TODO: move student recording watch to this

class PlayRecordingDialog extends React.Component {
  state = {
    selectedVideo: '',
    videoType: 'MP4',
    selectedChat: [],
    selectedIndex: null,
    currentChatIndex: 0,
    playingTime: 0
  }

  componentDidMount() {
    document.addEventListener('contextmenu', this.handleContextMenu)
  }

  componentWillUnmount() {
    document.removeEventListener('contextmenu', this.handleContextMenu)
  }

  handleContextMenu = event => {
    event.preventDefault()
  }

  handleStateChange = (state, prevState) => {
    let { playingTime } = this.state
    let timeDiff = state.currentTime - playingTime
    if (timeDiff > 3 || timeDiff < -2) {
      this.setState({ playingTime: state.currentTime })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { lessonId } = this.props

    if (!prevProps.isOpen && this.props.isOpen) {
      this.props.getVideo(lessonId)
    }

    if (prevProps.fetchingVideo && !this.props.fetchingVideo && !!this.props[lessonId]) {
      let videoData = this.props[lessonId]
      let videoUrls = videoData ? videoData.playback : null

      if (!!videoUrls && videoUrls.length > 0) {
        this.setState({ selectedIndex: 0 })
      }
    }

    if (prevState.playingTime !== this.state.playingTime && this.state.selectedChat) {
      // TODO: improve this
      if (prevState.playingTime < this.state.playingTime) {
        let { selectedChat, currentChatIndex } = this.state
        for (let i = currentChatIndex; i < selectedChat.length; i++) {
          if (this.state.playingTime < moment.duration(selectedChat[i].timeStamp).asSeconds()) {
            break
          } else {
            this.setState({ currentChatIndex: i + 1 })
          }
        }
      } else {
        let { selectedChat } = this.state
        let currentChatIndex = 0
        for (let i = currentChatIndex; i < selectedChat.length; i++) {
          if (this.state.playingTime < moment.duration(selectedChat[i].timeStamp).asSeconds()) {
            break
          } else {
            currentChatIndex = i
          }
        }
        this.setState({ currentChatIndex })
      }
    }

    if (prevState.currentChatIndex !== this.state.currentChatIndex) {
      let { scrollbars } = this
      scrollbars.scrollToBottom()
    }

    if (prevState.selectedIndex !== this.state.selectedIndex && this.state.selectedIndex !== null) {
      const { originalVideoUrl } = this.props
      const videoData = this.props[lessonId]
      const { selectedIndex } = this.state
      const filenameArr = originalVideoUrl[0].split('.')
      const videoType = filenameArr[filenameArr.length - 1]

      this.setState({
        selectedVideo: videoData.playback[selectedIndex],
        selectedChat: videoData.chat_history[selectedIndex] ?? [],
        currentChatIndex: 0,
        videoType
      })
    }

    if (prevState.selectVideo !== this.state.selectedVideo && this.state.selectedVideo) {
      let { currentUser } = this.props
      if ([TA, TUTOR].includes(currentUser._type)) {
        if (this.player) {
          this.player.subscribeToStateChange(this.handleStateChange.bind(this))
        }
      }
    }
  }

  selectVideo = index => () => {
    this.setState({ selectedIndex: index })
    if (this.player) {
      this.player.load()
    }
  }

  render() {
    const { isOpen, lessonId, originalVideoUrl, currentUser } = this.props
    const { selectedVideo, videoType, selectedChat, currentChatIndex } = this.state
    const lessonVideoData = this.props[lessonId]
    const videoUrls = lessonVideoData ? lessonVideoData.playback : null

    return (
      <Dialog fullWidth={true} maxWidth="lg" open={isOpen} onClose={this.props.close} TransitionComponent={Transition}>
        <div className="row no-gutters">
          <div className="col">
            {videoType === 'm3u8' && (
              <Player
                autoPlay
                playsInline
                ref={player => {
                  this.player = player
                }}>
                <HLSSource isVideoChild src={originalVideoUrl[0]} />
                <ControlBar>
                  <ReplayControl seconds={10} order={2.2} />
                  <ForwardControl seconds={10} order={3.2} />
                  <PlaybackRateMenuButton rates={[2, 1.5, 1.25, 1, 0.5]} />
                </ControlBar>
              </Player>
            )}

            {videoType.toUpperCase() === 'MP4' && selectedVideo && (
              <Player
                autoPlay
                playsInline
                ref={player => {
                  this.player = player
                }}>
                <source src={selectedVideo} type="video/mp4" />
                <ControlBar>
                  <ReplayControl seconds={10} order={2.2} />
                  <ForwardControl seconds={10} order={3.2} />
                  <PlaybackRateMenuButton rates={[2, 1.5, 1.25, 1, 0.5]} />
                </ControlBar>
              </Player>
            )}
          </div>
          {[TA, TUTOR].includes(currentUser._type) && (
            <div className="col-md-4">
              <div className="chat-container">
                <Scrollbars style={{ height: '100%' }} ref={elem => (this.scrollbars = elem)}>
                  <div className="chat-main-content">
                    {selectedChat.slice(0, currentChatIndex).map((chat, index) => {
                      if (chat.from) {
                        return (
                          <div className="chat-item" key={index}>
                            {chat.from.startsWith(currentUser.name) ? (
                              <div className="bubble reverse">
                                <div className="d-flex flex-row justify-content-between text-muted">
                                  <div className="">{chat.from}</div>
                                  <div className="time ">{chat.timeStamp}</div>
                                </div>
                                <div className="message">{chat.content}</div>
                              </div>
                            ) : (
                              <div className="bubble">
                                <div className="d-flex flex-row justify-content-between text-muted">
                                  <div className="">{chat.from}</div>
                                  <div className="time ">{chat.timeStamp}</div>
                                </div>
                                <div className="message">{chat.content}</div>
                              </div>
                            )}
                          </div>
                        )
                      }
                      return null
                    })}
                  </div>
                </Scrollbars>
              </div>
            </div>
          )}
        </div>
        {!!videoUrls && videoUrls.length > 1 && (
          <div className="video__selection mt-3">
            {videoUrls.map((url, index) => {
              return (
                <Button
                  key={url}
                  className={'mr-2 mb-2'}
                  variant={'contained'}
                  color={'primary'}
                  disabled={url === this.state.selectedVideo}
                  onClick={this.selectVideo(index)}>
                  Video {index + 1}
                </Button>
              )
            })}
          </div>
        )}
      </Dialog>
    )
  }
}

PlayRecordingDialog.propTypes = {
  lessonId: Proptypes.string.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayRecordingDialog)
