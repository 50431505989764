import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Paper } from '@material-ui/core'

import agent from 'agent'
import { GET_MY_TRIAL_CLASSES } from 'constants/actionTypes'
import Pagination from 'utils/Pagination'
import UpdateProfileCTA from 'components/misc/cta-update-profile'
import ClassListing from '../my-class-list'

const mapStateToProps = state => ({
  loadingTrialClass: state.classes.loadingTrialClass,
  trialClasses: state.classes.trialClass
})

const mapDispatchToProps = dispatch => ({
  getTrialClasses: filter =>
    dispatch({ type: GET_MY_TRIAL_CLASSES, payload: agent.Classes.getMyClassList('trial', filter) })
})

const PER_PAGE = 12

class TrialClasses extends React.Component {
  componentDidMount() {
    this.getMyTrialClasses(0)
  }

  getMyTrialClasses = page => {
    this.props.getTrialClasses({
      max: PER_PAGE,
      offset: page * PER_PAGE
    })
  }

  onChangePage = nextPage => {
    this.getMyTrialClasses(nextPage - 1)
  }

  render() {
    const { loadingTrialClass, trialClasses } = this.props

    return (
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <Paper elevation={2} className="app-wrapper">
          <div className="row align-items-center">
            <div className="col">
              <h2 className="mb-0">My Trial Classes</h2>
            </div>
          </div>
        </Paper>

        <div className="app-wrapper">
          <UpdateProfileCTA />

          <ClassListing
            type="trial"
            loading={loadingTrialClass}
            classList={trialClasses.data}
            classCount={trialClasses.count}
          />

          <Pagination
            className="mb-3 shadow"
            itemCount={trialClasses.count}
            pageSize={PER_PAGE}
            onChangePage={this.onChangePage}
          />
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TrialClasses))
