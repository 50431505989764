import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, CircularProgress, Divider, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import _ from 'lodash'
import moment from 'moment'

import agent from 'agent'
import { GET_LESSON_BY_ID, GET_HOMEWORK, GET_MY_SUBMISSION } from 'constants/actionTypes'
import { CLASS_TYPE_REGULAR_CLASSES } from 'constants/generalTypes'
import { getSubmissionStatusBadgeColorOrText } from 'utils/misc'
import SubmitHomeworkDialog from 'components/online-class/dialog-submit-homework'
import FilePreviewDialog from 'components/dialog/file-preview'

import { Badge } from 'reactstrap'

const mapStateToProps = state => ({
  selectedClass: state.classes.selectedClass,
  currentUser: state.common.currentUser,
  loadingHomeworkList: state.homework.loadingHomeworkList,
  homeworkList: state.homework.homeworkList,
  loadingMySubmission: state.submission.loadingMySubmission,
  mySubmissions: state.submission.mySubmissions
})

const mapDispatchToProps = dispatch => ({
  getLessonById: lessonId => dispatch({ type: GET_LESSON_BY_ID, payload: agent.Lessons.getLessonById(lessonId) }),
  getHomeworkList: filter => dispatch({ type: GET_HOMEWORK, payload: agent.Homework.getList(filter), filter }),
  getHomeworkSubmission: homeworkId =>
    dispatch({ type: GET_MY_SUBMISSION, payload: agent.Submission.getByHomework(homeworkId), homeworkId })
})

class TabHomework extends React.Component {
  state = {
    doneLoadingHomework: false,
    doneLoadingSubmission: false,
    submissionsToLoad: [],
    activeMonthLessons: [],
    activeTerm: this.props.activeTerm,
    expandedLesson: [],
    submitHomeworkDialogOpen: false,
    filePreviewDialogOpen: false,
    selectedResource: null,
    selectedHomework: null,
    selectedSubmission: null,
    defaultHomeworkFileName: ''
  }

  componentDidMount() {
    this.processLessonData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activeTerm !== this.props.activeTerm) {
      this.setState({
        activeTerm: this.props.activeTerm,
        submissionsToLoad: [],
        doneLoadingHomework: false,
        doneLoadingSubmission: false
      })
    }

    if (prevState.activeTerm !== this.state.activeTerm) {
      this.processLessonData()
    }

    if (prevProps.loadingHomeworkList && !this.props.loadingHomeworkList) {
      this.setState({ doneLoadingHomework: true })
      const { homeworkList, mySubmissions } = this.props
      let submissionsToLoad = []

      for (let i = 0; i < homeworkList.data.length; i++) {
        const homeworkId = homeworkList.data[i]._id
        if (homeworkId && !mySubmissions[homeworkId]) {
          submissionsToLoad.push(homeworkId)
          this.props.getHomeworkSubmission(homeworkId)
        }
      }

      if (submissionsToLoad.length > 0) {
        this.setState({ submissionsToLoad })
      } else {
        this.setState({ doneLoadingSubmission: true })
      }
    }

    if (prevProps.loadingMySubmission !== this.props.loadingMySubmission) {
      const { submissionsToLoad } = this.state
      const { mySubmissions } = this.props
      let doneLoadingSubmission = true
      for (let i = 0; i < submissionsToLoad.length; i++) {
        if (!mySubmissions[submissionsToLoad[i]]) {
          doneLoadingSubmission = false
        }
      }
      this.setState({ doneLoadingSubmission })
    }
  }

  processLessonData = () => {
    let { selectedClass } = this.props
    let { activeTerm } = this.state

    if (!selectedClass || selectedClass === 'not_found') return

    // NOTE: to prevent loading of homework before the videoOnlyAccess is checked on index.
    let isFullPaidStudent = selectedClass.student.includes(selectedClass._id)
    if (selectedClass.is_video_product && !isFullPaidStudent) return

    if (selectedClass.class_type === CLASS_TYPE_REGULAR_CLASSES && !activeTerm) return

    let activeMonthLessons = []
    let sortedActiveMonthLessons = []
    let expandedLesson = []
    if (selectedClass.class_type === CLASS_TYPE_REGULAR_CLASSES) {
      activeMonthLessons = _.filter(selectedClass.lesson, o => {
        return parseInt(o.meta.term) === activeTerm
      })
      sortedActiveMonthLessons = _.sortBy(activeMonthLessons, ['scheduled_on'])
    } else {
      sortedActiveMonthLessons = _.sortBy(selectedClass.lesson, ['scheduled_on'])
    }
    let nextLessonIndex = _.findIndex(sortedActiveMonthLessons, o => {
      return moment(o.scheduled_on) > moment()
    })

    if (nextLessonIndex > 0) {
      expandedLesson.push(sortedActiveMonthLessons[nextLessonIndex - 1]._id)
    }

    this.setState({
      activeMonthLessons: sortedActiveMonthLessons,
      expandedLesson
    })

    if (sortedActiveMonthLessons.length > 0) {
      let lessonIds = []
      for (let i = 0; i < sortedActiveMonthLessons.length; i++) {
        // condition to hide homework even if uploaded beforehand, takes 8 days later to show.
        if (moment() > moment(sortedActiveMonthLessons[i].ended_on).subtract(8, 'days')) {
          lessonIds.push(sortedActiveMonthLessons[i]._id)
        }
      }

      if (lessonIds.length > 0) {
        this.props.getHomeworkList({
          lesson: {
            $in: lessonIds
          }
        })
      } else {
        this.setState({ doneLoadingHomework: true, doneLoadingSubmission: true })
      }
    }
  }

  handleLessonExpand = lessonId => (event, expanded) => {
    let { expandedLesson } = this.state
    if (expanded) {
      expandedLesson.push(lessonId)
    } else {
      expandedLesson = _.remove(expandedLesson, function (o) {
        return o !== lessonId
      })
    }
    this.setState({ expandedLesson })
  }

  toggleFilePreviewDialog = selectedResource => () => {
    this.setState({
      selectedResource,
      filePreviewDialogOpen: !this.state.filePreviewDialogOpen
    })
  }

  toggleSubmitHomeworkDialogOpen = (selectedHomework, selectedSubmission) => () => {
    let defaultHomeworkFileName = ''

    if (selectedHomework) {
      const { currentUser } = this.props
      defaultHomeworkFileName = `${selectedHomework.lesson.class.name}__${selectedHomework.lesson.name}__${currentUser.name}`
    }

    this.setState({
      selectedHomework,
      selectedSubmission,
      defaultHomeworkFileName,
      submitHomeworkDialogOpen: !this.state.submitHomeworkDialogOpen
    })
  }

  refreshSubmission = homeworkId => () => {
    this.props.getHomeworkSubmission(homeworkId)
    this.setState({
      submitHomeworkDialogOpen: false
    })
  }

  render() {
    let { currentUser, homeworkList, mySubmissions } = this.props
    let {
      doneLoadingHomework,
      doneLoadingSubmission,
      activeMonthLessons,
      expandedLesson,
      filePreviewDialogOpen,
      selectedResource,
      submitHomeworkDialogOpen,
      selectedHomework,
      selectedSubmission,
      defaultHomeworkFileName
    } = this.state

    if (!doneLoadingHomework || !doneLoadingSubmission) {
      return (
        <div className="text-center my-5">
          <CircularProgress size={50} />
        </div>
      )
    }

    return (
      <div className="animated slideInUpTiny animation-duration-3">
        {activeMonthLessons.map(lesson => {
          if (!lesson.is_active) {
            return null
          }

          let haveAccess = lesson.student.includes(currentUser._id) || lesson.trial_student.includes(currentUser._id)
          let homeworkOverdue = false
          let homeworkClosed = false
          let homeworkReleased = true
          let homeworkDueDateDesc, homeworkStatus, homeworkStatusColor
          let submitButtonText = ''

          let homeworkData = _.find(homeworkList.data, o => {
            return o.lesson._id === lesson._id
          })
          let submissionData = ''

          if (homeworkData) {
            let homeworkDueDate = moment(homeworkData.due_on)
            let overdueDay = 0
            if (moment() > homeworkDueDate) {
              homeworkOverdue = true
              overdueDay = moment().diff(moment(homeworkData.due_on), 'days')
            } else {
              if (moment().isSame(homeworkDueDate, 'day')) {
                homeworkDueDateDesc = `<span class="blink text-warning">Homework due today</span>`
              } else {
                homeworkDueDateDesc = `<span>Homework due on ${homeworkDueDate.format('Do MMM, YYYY')}</span>`
              }
            }

            if (moment() < moment(homeworkData.released_on)) {
              homeworkReleased = false
            }

            if (homeworkData.close_on) {
              if (moment() > moment(homeworkData.close_on)) {
                homeworkClosed = true
              }
            } else {
              if (overdueDay > 7) {
                homeworkClosed = true
              }
            }

            submitButtonText = 'Submit Homework'
            if (mySubmissions[homeworkData._id]) {
              submissionData = mySubmissions[homeworkData._id]
              homeworkStatus = getSubmissionStatusBadgeColorOrText(submissionData.status)
              homeworkStatusColor = getSubmissionStatusBadgeColorOrText(submissionData.status, true)

              if (submissionData !== 'not-found') {
                let status = submissionData.status
                if (status === 'MARKING_IN_PROGRESS' || status === 'MARKED') {
                  submitButtonText = ''
                } else {
                  submitButtonText = 'Resubmit Homework'
                }
              }
            }
          }
          let expanded = expandedLesson.includes(lesson._id)

          return (
            <Accordion
              expanded={expanded && haveAccess}
              key={lesson._id}
              disabled={!haveAccess}
              onChange={this.handleLessonExpand(lesson._id)}
              style={{ zIndex: '0' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: `${expanded ? '#00b96c' : '#333333'}` }} />}
                style={{ color: `${expanded ? '#00b96c' : '#333333'}` }}>
                <div className="row align-items-center w-100">
                  <div className="col">
                    <div className="d-flex align-items-center">
                      <div>
                        {lesson.meta && <div className="text-small text-muted">Lesson {lesson.meta.index}</div>}
                        <h3 className="title--rubik mb-0" style={{ color: `${expanded ? '#00b96c' : '#333333'}` }}>
                          {lesson.name}
                        </h3>
                      </div>
                      {homeworkStatus && (
                        <Badge className="ml-3 mb-0 text-white" color={homeworkStatusColor}>
                          {homeworkStatus}
                        </Badge>
                      )}
                    </div>
                  </div>
                  {!homeworkStatus && (
                    <div className="col-md-auto">
                      {!haveAccess && <span className="text-faded">Not purchased yet</span>}
                      {homeworkOverdue && !homeworkClosed && (
                        <Badge className="mb-0 text-white" color="danger">
                          Overdue
                        </Badge>
                      )}
                      {homeworkClosed && (
                        <Badge className="mb-0 text-white" color="light">
                          Closed
                        </Badge>
                      )}
                      {homeworkDueDateDesc && (
                        <div id="homework-due-date" dangerouslySetInnerHTML={{ __html: homeworkDueDateDesc }} />
                      )}
                    </div>
                  )}
                </div>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                <div className="w-100">
                  <div className="row">
                    {homeworkData && homeworkData.attachment.length > 0 ? (
                      <div className="col-md-6 col-12">
                        <div className="row">
                          <div className="col-12 align-self-center">
                            <h3 className="text-capitalize mt-2 mb-0">
                              Due on:&nbsp;
                              <span className="mb-0 text-large font-weight-light">
                                {moment(homeworkData.due_on).format('Do MMM, YYYY')}
                              </span>
                            </h3>
                          </div>
                          <div className="col-12">
                            <h3 className="text-capitalize my-3">Homework File</h3>
                          </div>
                          <div className="col-12">
                            {homeworkData.attachment.map((item, index) => (
                              <div key={index} className="card px-3 py-2 mb-2" style={{ backgroundColor: '#f5f5f5' }}>
                                <div className="row align-items-center">
                                  <div className="col">
                                    <span className="link" onClick={this.toggleFilePreviewDialog(item)}>
                                      {item.name}
                                    </span>
                                  </div>
                                  <div className="col-auto">
                                    <a href={item.url} download>
                                      <i className="fa fa-download" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col-12 text-muted mt-3">Homework file not available.</div>
                    )}
                  </div>
                  {submissionData && submissionData !== 'not-found' && (
                    <div className="row">
                      <div className="col-12 d-flex flex-row align-items-center">
                        <h3 className="text-capitalize my-3">My Submission</h3>
                      </div>
                      <div className="col-10">
                        {submissionData.attachment.map((item, index) => (
                          <div key={index} className="card px-3 py-2 mb-2" style={{ backgroundColor: '#f5f5f5' }}>
                            <div className="row align-items-center">
                              <div className="col">
                                <span className="link" onClick={this.toggleFilePreviewDialog(item)}>
                                  {item.name}
                                </span>
                              </div>
                              <div className="col-auto">
                                <a href={item.url} download>
                                  <i className="fa fa-download" />
                                </a>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      {submissionData.marked_attachment.length > 0 && (
                        <React.Fragment>
                          <div className="col-12">
                            <h3 className="text-capitalize my-3">Returned Submission</h3>
                          </div>
                          <div className="col-10">
                            {submissionData.marked_attachment.map((item, index) => (
                              <div key={index} className="card px-3 py-2 mb-2" style={{ backgroundColor: '#f5f5f5' }}>
                                <div className="row align-items-center">
                                  <div className="col">
                                    <span className="link" onClick={this.toggleFilePreviewDialog(item)}>
                                      {item.name}
                                    </span>
                                  </div>
                                  <div className="col-auto">
                                    <a href={item.url} download>
                                      <i className="fa fa-download" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </React.Fragment>
                      )}
                      {submissionData.comment && (
                        <div className="col-12">
                          <p className="text-muted mb-0">Comment:</p>
                          <p className="text-large" dangerouslySetInnerHTML={{ __html: submissionData.comment }} />
                        </div>
                      )}
                    </div>
                  )}
                  {submitButtonText && (
                    <div className="text-right">
                      <Button
                        id="submit-homework-btn"
                        variant="contained"
                        color="primary"
                        disabled={!homeworkReleased}
                        onClick={this.toggleSubmitHomeworkDialogOpen(homeworkData, submissionData)}>
                        {submitButtonText}
                      </Button>
                      {homeworkOverdue && (!submissionData || submissionData === 'not-found') && !homeworkClosed && (
                        <div className="text-muted">
                          Homework submission after the due date will be marked and returned within one month of
                          submission date.
                        </div>
                      )}
                      {!homeworkReleased && (
                        <div className="text-muted">You can submit your homework only after the lesson has ended.</div>
                      )}
                      {homeworkClosed && (
                        <div className="text-muted">
                          Submission of homework is not allowed
                          {homeworkData.close_on ? (
                            <>&nbsp;after {moment(homeworkData.close_on).format('Do MMM, YYYY')}</>
                          ) : (
                            <>&nbsp;more than 7 days after the due date</>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          )
        })}

        <FilePreviewDialog
          isOpen={filePreviewDialogOpen}
          close={this.toggleFilePreviewDialog(null)}
          file={selectedResource}
        />

        <SubmitHomeworkDialog
          dialogOpen={submitHomeworkDialogOpen}
          toggleDialog={this.toggleSubmitHomeworkDialogOpen()}
          homeworkData={selectedHomework}
          submissionData={selectedSubmission}
          homeworkFileName={defaultHomeworkFileName}
          refreshSubmission={this.refreshSubmission(selectedHomework?._id)}
        />
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TabHomework))
