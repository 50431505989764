import React from 'react'
import agent from '../../../agent'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { GET_CLASS_LIST, SET_CLASS_PAGE, SET_SELECTED_CLASS } from '../../../constants/actionTypes'
import { MY_CLASS_DETAILS } from '../../../routes/routes'
import ClassItem from '../../shared/Classes/ClassItem'
import TextField from '@material-ui/core/TextField'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import Pagination from '../../../utils/Pagination'
import CircularProgress from '@material-ui/core/CircularProgress'
import { TA, TUTOR } from '../../../constants/userTypes'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  classes: state.classes.classes,
  classCount: state.classes.classCount,
  classPage: state.classes.classPage
})

const mapDispatchToProps = dispatch => ({
  getClassList: (classType, perPage, page, additional) =>
    dispatch({
      type: GET_CLASS_LIST,
      payload: agent.Classes.getClassList(classType, perPage, page, {
        sortBy: 'end_date',
        order: 'desc',
        ...additional
      })
    }),
  setClass: classObject => dispatch({ type: SET_SELECTED_CLASS, selectedClass: classObject }),
  setPage: page => dispatch({ type: SET_CLASS_PAGE, payload: page })
})

class Classes extends React.Component {
  constructor(props) {
    super()

    this.viewClass = selectedClass => {
      //this.props.setClass(selectedClass)
      let classDetailUrl = MY_CLASS_DETAILS.replace(':classId', selectedClass._id)
      this.props.history.push(classDetailUrl)
    }

    this.onChangePage = page => {
      this.props.setPage(page)
      this.setState({ currentPage: page })
    }

    this.changeSubject = e => {
      this.setState({ subject: e.target.value })
    }

    this.changeText = e => {
      this.setState({ name: e.target.value })
    }

    this.state = {
      perPage: 18,
      currentPage: props.classPage ? props.classPage : 1,
      name: '',
      subject: '',
      searchQuery: null
    }
  }

  componentDidMount() {
    this.props.getClassList('class', this.state.perPage, this.state.currentPage)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPage !== this.state.currentPage) {
      const { perPage, currentPage, searchQuery } = this.state

      if (searchQuery) {
        this.props.getClassList('class', perPage, currentPage, searchQuery)
      } else {
        this.props.getClassList('class', perPage, currentPage)
      }
    }
  }

  searchClasses = e => {
    e.preventDefault()
    const { name, subject, perPage } = this.state
    let query = {}
    if (name) {
      query.name = { $regex: `.*${name}.*`, $options: 'i' }
    }
    if (subject) {
      query.subject = subject
    }
    this.setState({ searchQuery: query })
    this.props.getClassList('class', perPage, 1, query)
  }

  resetSearch = () => {
    const { perPage, currentPage } = this.state
    this.setState({ name: '', subject: '', searchMode: null })
    this.props.getClassList('class', perPage, currentPage)
  }

  render() {
    const { classes, classCount, currentUser } = this.props
    const { currentPage, perPage, name, subject } = this.state

    return (
      <div className="app-wrapper">
        <div className="dashboard animated slideInUpTiny animation-duration-3">
          {currentUser._type === TA && (
            <div className="my-3">
              <form onSubmit={this.searchClasses}>
                <div className="row">
                  <div className="col-md">
                    <TextField label={'Class Name'} value={name} onChange={this.changeText} fullWidth />
                  </div>
                  <div className="col">
                    <FormControl className="w-100 mb-2">
                      <InputLabel htmlFor="subject-selector">Subject</InputLabel>
                      <Select value={subject} onChange={this.changeSubject} input={<Input id="subject-selector" />}>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={'English'}>English</MenuItem>
                        <MenuItem value={'Mathematics'}>Mathematics</MenuItem>
                        <MenuItem value={'Science'}>Science</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-auto d-flex align-items-center">
                    <Button type={'submit'} variant={'contained'} color={'primary'}>
                      Search
                    </Button>
                  </div>
                  <div className="col-auto d-flex align-items-center">
                    <Button variant={'contained'} color={'default'} type={'button'} onClick={this.resetSearch}>
                      Reset
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          )}
          {classes ? (
            classes.length > 0 ? (
              <div className="grid__renderer">
                {classes.map((classData, index) => {
                  return (
                    <ClassItem
                      key={index}
                      className={'grid__item'}
                      classData={classData}
                      viewClass={() => {
                        this.viewClass(classData)
                      }}
                      isTutor={currentUser._type === TUTOR || currentUser._type === TA}
                    />
                  )
                })}
              </div>
            ) : (
              <h2>You are not enrolled in any classes</h2>
            )
          ) : (
            <div className="my-5 text-center">
              <CircularProgress size={40} color={'primary'} />
            </div>
          )}
          {!!classCount && (
            <div className="mt-3">
              <Pagination
                itemCount={classCount}
                pageSize={perPage}
                initialPage={currentPage}
                onChangePage={this.onChangePage}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Classes))
