import React from 'react'
import { isMobile } from 'react-device-detect'
import Proptypes from 'prop-types'
import { Dialog, Slide } from '@material-ui/core'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const OralClinicCTADialog = props => {
  let ctaImageURL = isMobile
    ? 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/misc/oral-clinic-cta-mobile.jpg'
    : 'https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/misc/oral-clinic-cta-desktop.jpg'

  return (
    <Dialog open={props.isOpen} onClose={props.close} TransitionComponent={Transition} maxWidth="lg" fullWidth>
      <img src={ctaImageURL} alt="Oral Clinic CTA" width="100%" onClick={props.close} />
    </Dialog>
  )
}

OralClinicCTADialog.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired
}

export default OralClinicCTADialog
