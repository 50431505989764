import _ from 'lodash'
import * as Type from 'constants/actionTypes'

const initialState = {
  loadingClassList: false,
  refreshClassList: false, // TODO: remove
  classes: [],
  classCount: 0,
  classPage: 1,
  newClassId: [], // TODO: remove
  newTrialClassId: [], // TODO: remove
  loadingPaidClass: false, // TODO: refactor this part where I put 3 different loading to save time
  paidClass: { data: [], count: 0 },
  loadingTrialClass: false,
  trialClass: { data: [], count: 0 },
  loadingArchivedClass: false,
  archivedClass: { data: [], count: 0 },
  searching: false,
  searchList: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.GET_CLASS_LIST:
      return {
        ...state,
        loadingClassList: true,
        classes: null,
        refreshClassList: false
      }

    case Type.GET_CLASS_LIST_SUCCESS:
      return {
        ...state,
        loadingClassList: false,
        classes: action.payload.data,
        classCount: action.payload.count
      }

    case Type.GET_CLASS_LIST_FAILED:
      return {
        ...state,
        loadingClassList: false
      }

    case Type.GET_MY_PAID_CLASSES:
      return {
        ...state,
        loadingPaidClass: true
      }

    case Type.GET_MY_PAID_CLASSES_SUCCESS:
      return {
        ...state,
        loadingPaidClass: false,
        paidClass: {
          data: action.payload.data,
          count: action.payload.count
        }
      }

    case Type.GET_MY_PAID_CLASSES_FAILED:
      return {
        ...state,
        loadingPaidClass: false
      }

    case Type.GET_MY_TRIAL_CLASSES:
      return {
        ...state,
        loadingTrialClass: true
      }

    case Type.GET_MY_TRIAL_CLASSES_SUCCESS:
      return {
        ...state,
        loadingTrialClass: false,
        trialClass: {
          data: action.payload.data,
          count: action.payload.count
        }
      }

    case Type.GET_MY_TRIAL_CLASSES_FAILED:
      return {
        ...state,
        loadingTrialClass: false
      }

    case Type.GET_MY_ARCHIVED_CLASSES:
      return {
        ...state,
        loadingArchivedClass: true
      }

    case Type.GET_MY_ARCHIVED_CLASSES_SUCCESS:
      return {
        ...state,
        loadingArchivedClass: false,
        archivedClass: {
          data: action.payload.data,
          count: action.payload.count
        }
      }

    case Type.GET_MY_ARCHIVED_CLASSES_FAILED:
      return {
        ...state,
        loadingArchivedClass: false
      }

    case Type.GET_CLASS_BY_ID:
      return {
        ...state,
        inProgress: true
      }

    case Type.GET_CLASS_BY_ID_SUCCESS:
      return {
        ...state,
        inProgress: false,
        selectedClass: action.payload.data[0] ? action.payload.data[0] : 'not_found'
      }

    case Type.GET_CLASS_BY_ID_FAILED:
      return {
        ...state,
        inProgress: false,
        selectedClass: null
      }

    // TODO: refactor this action
    case Type.LOAD_CLASS:
      return {
        ...state,
        loadingClass: true
      }

    case Type.LOAD_CLASS_SUCCESS: {
      // NOTE: temp handle for old get class by id and new get class bv id returned object type
      let selectedClass = 'not_found'
      if (action.payload[2]) {
        if (_.isObject(action.payload[2])) {
          selectedClass = action.payload[2].data
        } else {
          selectedClass = action.payload[2].data[0]
        }
      }

      return {
        ...state,
        loadingClass: false,
        selectedClass
      }
    }

    case Type.LOAD_CLASS_FAILED:
      return {
        ...state,
        loadingClass: false,
        selectedClass: 'not_found'
      }

    case Type.SEARCH_CLASS:
      return {
        ...state,
        searching: true,
        searchList: []
      }

    case Type.SEARCH_CLASS_SUCCESS:
      return {
        ...state,
        searching: false,
        searchList: action.payload.data
      }

    case Type.SET_SELECTED_CLASS:
      return {
        ...state,
        selectedClass: action.selectedClass
      }

    case Type.SET_CLASS_PAGE:
      return {
        ...state,
        classPage: action.payload
      }

    case Type.JOIN_TRIAL_SERVICE_SUCCESS: {
      let classId = action.payload.data.order_param.class_id
      let newTrialClassId = state.newTrialClassId ? state.newTrialClassId : []
      newTrialClassId.push(classId)
      return {
        ...state,
        newTrialClassId,
        refreshClassList: true
      }
    }

    case Type.SET_LESSON_RECORDING_TO_WATCH:
      return {
        ...state,
        lessonRecordingId: action.lessonId
      }

    case Type.LOGOUT:
      return initialState

    default:
      return state
  }
}
