import * as Type from '../constants/actionTypes'
import _ from 'lodash'

// TODO: refactor the usage of edited, uploadSuccess, and deleted
const initialState = {
  uploading: false,
  uploadSuccess: false,
  editing: false,
  edited: false,
  deleting: false,
  deleted: false,
  loading: false,
  lessonResources: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    // NOTE: to deprecate
    case Type.GET_LESSON_RESOURCES:
      return {
        ...state,
        inProgress: true
      }

    case Type.GET_LESSON_RESOURCES_SUCCESS:
      return {
        ...state,
        inProgress: false,
        [action.lessonId]: {
          ...state[action.lessonId],
          [action.resourceType]: {
            count: action.payload.count,
            data: action.payload.data
          }
        }
      }

    case Type.GET_LESSON_RESOURCES_FAILED:
      return {
        ...state,
        inProgress: false,
        [action.lessonId]: {
          [action.resourceType]: null
        }
      }
    // END

    case Type.GET_LESSON_RESOURCES_2:
      return {
        ...state,
        loading: true
      }

    case Type.GET_LESSON_RESOURCES_2_SUCCESS:
      return {
        ...state,
        loading: false,
        lessonResources: {
          ...state.lessonResources,
          [action.lessonId]: action.payload.data
        }
      }

    case Type.GET_LESSON_RESOURCES_2_FAILED:
      return {
        ...state,
        loading: false
      }

    case Type.UPLOAD_RESOURCE:
      return {
        ...state,
        uploading: true,
        uploadSuccess: false
      }

    case Type.UPLOAD_RESOURCE_SUCCESS:
      return {
        ...state,
        uploading: false,
        uploadSuccess: true
      }

    case Type.UPLOAD_RESOURCE_FAILED:
      return {
        ...state,
        uploading: false
      }

    case Type.DELETE_LESSON_RESOURCE:
      return {
        ...state,
        inProgress: true,
        deleting: true,
        deleted: false
      }

    case Type.DELETE_LESSON_RESOURCE_SUCCESS:
      return {
        ...state,
        inProgress: false,
        deleting: false,
        deleted: true
      }

    case Type.DELETE_LESSON_RESOURCE_FAILED:
      return {
        ...state,
        inProgress: false,
        deleting: false,
        deleted: false
      }

    case Type.EDIT_RESOURCE:
      return {
        ...state,
        editing: true,
        inProgress: true,
        edited: false
      }

    case Type.EDIT_RESOURCE_SUCCESS:
      return {
        ...state,
        editing: false,
        inProgress: false,
        edited: true
      }

    case Type.EDIT_RESOURCE_FAILED:
      return {
        ...state,
        editing: false,
        inProgress: false,
        edited: false
      }

    case Type.COUNT_ALL_LESSON_RESOURCES: {
      let returnState = {
        ...state
      }

      if (!!action.assignTo) {
        let assignTo = state[`${action.assignTo}Count`] || []
        assignTo.push(action.lessonId)

        returnState[`${action.assignTo}Count`] = assignTo
      } else {
        let countingResources = state.countingResources || []
        countingResources.push(action.lessonId)

        returnState.countingResources = countingResources
      }

      return returnState
    }

    case Type.COUNT_ALL_LESSON_RESOURCES_SUCCESS: {
      let returnState = {
        ...state
      }

      if (!!action.assignTo) {
        let assignTo = state[`${action.assignTo}Count`] || []
        let updatedAssign = _.remove(assignTo, function (o) {
          return o !== action.lessonId
        })
        let assignedResources = !!state[`${action.assignTo}Assigned`] ? state[`${action.assignTo}Assigned`] : {}
        assignedResources[action.lessonId] = {
          total: action.payload.count,
          data: action.payload.data
        }

        returnState[`${action.assignTo}Assigned`] = assignedResources
        returnState[`${action.assignTo}Count`] = updatedAssign
      } else {
        let countingResources = state.countingResources || []
        let updatedStatus = _.remove(countingResources, function (o) {
          return o !== action.lessonId
        })
        let lessonResources = state.lessonResources ? state.lessonResources : {}
        lessonResources[action.lessonId] = {
          total: action.payload.count,
          data: action.payload.data
        }

        returnState = {
          ...returnState,
          countingResources: updatedStatus,
          lessonResources
        }
      }

      return returnState
    }

    case Type.COUNT_ALL_LESSON_RESOURCES_FAILED: {
      let returnState = {
        ...state
      }

      if (!!action.assignTo) {
        let assignTo = state[`${action.assignTo}Count`] || []
        let updatedAssign = _.remove(assignTo, function (o) {
          return o !== action.lessonId
        })
        let assignedResources = !!state[`${action.assignTo}Assigned`] ? state[`${action.assignTo}Assigned`] : {}
        assignedResources[action.lessonId] = {
          total: 0,
          data: []
        }

        returnState[`${action.assignTo}Assigned`] = assignedResources
        returnState[`${action.assignTo}Count`] = updatedAssign
      } else {
        let countingResources = state.countingResources || []
        let updatedStatus = _.remove(countingResources, function (o) {
          return o !== action.lessonId
        })
        let lessonResources = state.lessonResources ? state.lessonResources : {}
        lessonResources[action.lessonId] = {
          total: 0,
          data: []
        }

        returnState = {
          ...returnState,
          countingResources: updatedStatus,
          lessonResources
        }
      }

      return returnState
    }

    default:
      return state
  }
}
