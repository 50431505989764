import _ from 'lodash'
import moment from 'moment'

function createCart() {
  return {
    item: [],
    updated: moment().toISOString()
  }
}

export const setCart = existingCart => {
  let localCart = JSON.parse(window.localStorage.getItem('tnp_cart'))

  if (!localCart) {
    localCart = createCart()
  }

  if (localCart.hasOwnProperty('_id')) {
    if (existingCart._id === localCart._id) {
      //  merge cart items
    } else {
      localCart._id = existingCart._id
      localCart.item = existingCart.item
    }
  } else {
    // Local cart was initialized before logging in and should merge the local and cloud cart
    localCart._id = existingCart._id
  }

  return localCart
}

export const getCart = () => {
  let localCart = JSON.parse(window.localStorage.getItem('tnp_cart'))

  if (!localCart) {
    localCart = createCart()
  }

  return localCart
}

export const addToCart = item => {
  let localCart = JSON.parse(window.localStorage.getItem('tnp_cart'))

  if (!localCart) {
    localCart = createCart()
  }

  let cartItems = localCart.item

  let inCart = _.find(cartItems, ci => {
    return ci.item_id === item.item[0].item_id
  })
  //Possibly do things to the item if already exists

  if (!inCart) {
    cartItems.push(item.item[0])
  }

  localCart.item = cartItems
  localCart.updated = moment().toISOString()

  window.localStorage.setItem('tnp_cart', JSON.stringify(localCart))
  return localCart
}

export const patchCartItem = (index, newItem) => {
  let localCart = JSON.parse(window.localStorage.getItem('tnp_cart'))

  if (!localCart) {
    return
  }

  let cartItems = localCart.item
  cartItems[index] = newItem

  window.localStorage.setItem('tnp_cart', JSON.stringify(localCart))
}

export const removeFromCart = itemId => {
  let localCart = JSON.parse(window.localStorage.getItem('tnp_cart'))
  if (!localCart) {
    localCart = createCart()
    window.localStorage.setItem('tnp_cart', JSON.stringify(localCart))
    return localCart
  }

  let cartItems = localCart.item
  _.remove(cartItems, ci => {
    return ci.item_id === itemId
  })
  localCart.item = cartItems
  localCart.updated = moment().toISOString()
  window.localStorage.setItem('tnp_cart', JSON.stringify(localCart))
  return localCart
}

export const emptyCart = () => {
  let localCart = JSON.parse(window.localStorage.getItem('tnp_cart'))

  if (!localCart) {
    localCart = createCart()
    window.localStorage.setItem('tnp_cart', JSON.stringify(localCart))

    return localCart
  }

  localCart.item = []
  localCart.updated = moment().toISOString()

  window.localStorage.setItem('tnp_cart', JSON.stringify(localCart))
  return localCart
}

export const formatCartItem = (item, item_type, options) => {
  let cartItem = { item_type, item_id: item._id }

  if (item_type === 'Class') {
    cartItem = {
      ...cartItem,
      ...options
    }
  }

  return { item: [cartItem] }
}

export const calculateQuote = (lessonList, rate, start_date, end_date, class_id) => {
  let lessonsToQuote = _.filter(lessonList, l => {
    let lessonTime = moment(l.scheduled_on)
    return lessonTime >= moment(start_date) && lessonTime <= moment(end_date)
  })
  let sortedLessonsToQuote = _.orderBy(lessonsToQuote, 'scheduled_on', 'asc')
  let billabbleLessonCount = lessonsToQuote.length
  let original_price = rate * billabbleLessonCount
  let discount = 0

  const excludeFromBulkDiscount = [
    '891rmPMdRUy5shYkpyJ1Xw',
    '_ajPacZ7QRyb4A36sTonkA',
    'mLBnfPNoTaG536CME5FWsw',
    '2GhBe0xAQ5WL50mNJ4pQNQ',
    'spYwAECjTwmrjJBlxQlxFw',
    'PnGg_cRDSBWRJE15OrEC7A',
    'r1WqiOXoTSmZR0g00rZlOw',
    "xI5Yp1xjS5acCNYaTB-VxQ",
    "JhIyOGEbQPS7S7cWP_bluA",
    "goGeKggXRWm_OyPPr0p0lQ",
    "6rZKIpQ0TcizE0p_1IpuLQ",
    "9YlNKmy3T46i7naOznlGDw",
    "Y3W8G8ZdTWCepy7lGYp3iA",
    "LOHD0xGtSWOeSftZeFa_Mw"
  ]

  if (!excludeFromBulkDiscount.includes(class_id)) {
    if (billabbleLessonCount >= 30 && billabbleLessonCount <= 40) {
      discount = original_price * 0.05
    } else if (billabbleLessonCount >= 16 && billabbleLessonCount < 30) {
      discount = original_price * 0.03
    }
  }

  let quote = original_price - discount

  return {
    quote,
    original_price,
    discount: 'no_applicable_discount',
    billable_lesson: sortedLessonsToQuote,
    subscription_started_on: sortedLessonsToQuote[0].scheduled_on,
    subscription_ended_on: sortedLessonsToQuote[sortedLessonsToQuote.length - 1].scheduled_on
  }
}
