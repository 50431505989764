import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Avatar, Button, CircularProgress, Card, CardContent, Paper } from '@material-ui/core'
import { USER_PASSWORD_EDIT } from 'routes/routes'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser
})

class Profile extends React.Component {
  render() {
    const { currentUser } = this.props

    if (!currentUser) {
      return (
        <div className="text-center my-5">
          <CircularProgress size={30} color="primary" />
        </div>
      )
    }

    return (
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <Paper elevation={2} className="app-wrapper">
          <h2 className="mb-0">Profile</h2>
        </Paper>
        <div className="app-wrapper">
          <div className="container">
            <Card className="mb-3">
              <CardContent>
                <div className="row">
                  <div className="col-md-auto col-12 pl-md-5 pl-0">
                    <Avatar src={currentUser.profile_pic} alt={currentUser.name} className="size-180 avatar-shadow" />
                  </div>
                  <div className="col offset-md-1 offset-0">
                    <div className="row">
                      <div className="col-md-3 col-5 my-2 font-weight-medium">Email</div>
                      <div className="col-md-9 col-7 my-2">{currentUser.email}</div>

                      <div className="col-md-3 col-5 my-2 font-weight-medium">Name</div>
                      <div className="col-md-9 col-7 my-2">{currentUser.name}</div>

                      {currentUser.meta?.title && (
                        <>
                          <div className="col-md-3 col-5 my-2 font-weight-medium">Title</div>
                          <div className="col-md-9 col-7 my-2">{currentUser.meta.title}</div>
                        </>
                      )}

                      <div className="col-md-3 col-5 my-2 font-weight-medium">Description</div>
                      <div className="col-md-9 col-7 my-2">
                        <div dangerouslySetInnerHTML={{ __html: currentUser.desc }} />
                      </div>
                    </div>
                    <div className="text-right mt-3">
                      <Button
                        variant="contained"
                        color="primary"
                        className="text-white mr-2 mb-2"
                        component={Link}
                        to={USER_PASSWORD_EDIT}>
                        Change my password
                      </Button>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(Profile)
