import React from 'react'
import { withRouter } from 'react-router-dom'
import { Avatar, Button, CircularProgress, Divider } from '@material-ui/core'
import moment from 'moment'

import { APP, MY_CLASS_DETAILS, ONLINE_CLASS_LIST, TRIAL } from 'routes/routes'
import { MY_LESSON_DETAILS } from 'routes/studentRoutes'
import { getInitials } from 'utils/misc'
import { CLASS_TYPE_RECORDING } from 'constants/generalTypes'

// for student usage

const MyClassList = props => {
  const { type, loading, classList, classCount } = props

  if (loading) {
    return (
      <div className="my-5 text-center">
        <CircularProgress size={40} color="primary" />
      </div>
    )
  }

  if (classCount === 0) {
    return (
      <div className="my-5 text-center">
        <img
          src="https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/frontend/illustration-class-empty.png"
          alt="get your first class"
          width="250px"
          className="mb-4"
        />
        <h1 className="title--rubik">Experience your first LIVE online lesson with Tenopy!</h1>
        <div className="d-flex justify-content-center manage-margin my-4">
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              props.history.push(`${APP}${TRIAL}`)
            }}>
            Get A Free Trial
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              props.history.push(ONLINE_CLASS_LIST)
            }}>
            Get Started
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className="row">
      {classList.map(classData => {
        let status = '',
          statusStyle = ''
        const showTutorName = classData.tutor.length === 1
        if (classData.next_lesson) {
          const now = moment()
          const nextLessonScheduledOn = moment(classData.next_lesson.scheduled_on)
          const nextLessonEndedOn = moment(classData.next_lesson.ended_on)
          if (nextLessonScheduledOn > now) {
            status = `Next lesson in <span class="font-weight-semibold">${now.to(nextLessonScheduledOn, true)}</span>`
            if (nextLessonScheduledOn.diff(now, 'days') < 2) {
              statusStyle = 'bg-primary text-white'
            } else {
              statusStyle = 'bg-light-gray'
            }
          } else if (nextLessonScheduledOn < now) {
            if (now > moment(classData.end_date)) {
              status = 'Class Ended'
              statusStyle = 'bg-danger text-white'
            } else {
              status = 'Renewal Required'
              statusStyle = 'bg-light-gray text-danger'
            }
          } else if (now >= nextLessonScheduledOn && now < nextLessonEndedOn) {
            status = 'Currently in session'
            statusStyle = 'bg-warning text-white'
          }
        } else if (classData.class_type === CLASS_TYPE_RECORDING) {
          const now = moment()
          if (now > moment(classData.end_date)) {
            status = 'Class Ended'
            statusStyle = 'bg-danger text-white'
          } else {
            status = 'Recording Only'
            statusStyle = 'bg-white text-black'
          }
        } else {
          status = 'Class Ended'
          statusStyle = 'bg-danger text-white'
        }

        return (
          <div className="col-md-4 col-12 mb-3" key={classData._id}>
            <div
              className={`class__item ${classData.subject} h-100`}
              onClick={() => {
                if (type === 'trial') {
                  props.history.push(`${APP}${MY_LESSON_DETAILS.replace(':lessonId', classData.next_lesson._id)}`)
                } else {
                  props.history.push(MY_CLASS_DETAILS.replace(':classId', classData._id))
                }
              }}>
              <div className="class__content">
                <div className={`subject-tag ${classData.subject}`}>{classData.subject}</div>
                <div className="class__title mb-0">{classData.name}</div>
                <div className="text-muted px-3 mb-3">
                  <small>{moment(classData.schedule.start_time).format('dddd, h:mma')}</small>
                </div>

                <div className="d-flex flex-wrap px-3 mb-3">
                  {classData.tutor.map((item, index) => {
                    return (
                      <div key={index} className="d-flex align-items-center">
                        {item.profile_pic ? (
                          <Avatar className="size-60" alt={item.name} src={item.profile_pic} />
                        ) : (
                          <Avatar className="size-60 bg-blue">
                            <h3 className="m-0 text-white">{getInitials(item.name)}</h3>
                          </Avatar>
                        )}
                        {showTutorName && (
                          <div className="user-detail ml-2">
                            <p className="user-name text-capitalize">{item.name}</p>
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>

                {status && (
                  <div className="mt-auto">
                    <Divider />
                    <div className={`px-3 py-2 ${statusStyle}`}>
                      <span dangerouslySetInnerHTML={{ __html: status }} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default withRouter(MyClassList)
