import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, CircularProgress, Paper } from '@material-ui/core'
import moment from 'moment'

import agent from 'agent'
import { GET_HOMEWORK_BY_ID, GET_MY_SUBMISSION } from 'constants/actionTypes'
import { MY_CLASS_DETAILS } from 'routes/routes'
import { getSubmissionStatusBadgeColorOrText } from 'utils/misc'
import DialogSubmitHomework from 'components/online-class/dialog-submit-homework'
import FileWidget from 'components/misc/widget-file'

import { Badge } from 'reactstrap'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  loadingHomeworkById: state.homework.loadingHomeworkById,
  homework: state.homework.homework,
  loadingMySubmission: state.submission.loadingMySubmission,
  mySubmissions: state.submission.mySubmissions
})

const mapDispatchToProps = dispatch => ({
  getHomeworkById: homeworkId => dispatch({ type: GET_HOMEWORK_BY_ID, payload: agent.Homework.getById(homeworkId) }),
  getHomeworkSubmission: homeworkId =>
    dispatch({ type: GET_MY_SUBMISSION, payload: agent.Submission.getByHomework(homeworkId), homeworkId })
})

class Homework extends React.Component {
  state = {
    submitHomeworkDialogOpen: false,
    homeworkFileName: ''
  }

  componentDidMount() {
    let homeworkId = this.props.match.params.homeworkId
    this.props.getHomeworkById(homeworkId)
  }

  componentDidUpdate(prevProp) {
    if (prevProp.loadingHomeworkById && !this.props.loadingHomeworkById && this.props.homework) {
      let { homework, currentUser } = this.props
      this.props.getHomeworkSubmission(homework._id)

      let homeworkFileName = `${homework.lesson.class.name}__${homework.lesson.name}__${currentUser.name}`
      this.setState({ homeworkFileName })
    }
  }

  download = url => () => {
    const link = document.createElement('a')
    link.href = url
    link.click()
  }

  toggleSubmitHomeworkDialog = () => {
    this.setState({
      submitHomeworkDialogOpen: !this.state.submitHomeworkDialogOpen
    })
  }

  refreshSubmission = homeworkId => () => {
    this.props.getHomeworkSubmission(homeworkId)
    this.setState({
      submitHomeworkDialogOpen: false
    })
  }

  render() {
    let { loadingHomeworkById, homework, loadingMySubmission, mySubmissions } = this.props
    let { homeworkFileName, submitHomeworkDialogOpen } = this.state

    if (loadingHomeworkById || !homework) {
      return (
        <div className="manage-margin text-center my-5">
          <CircularProgress size={50} />
        </div>
      )
    }

    let homeworkOverdue = false
    let homeworkReleased = true
    let homeworkClosed = false
    let overdueDay = 0
    if (moment() > moment(homework.due_on)) {
      homeworkOverdue = true
      overdueDay = moment().diff(moment(homework.due_on), 'days')
    }
    if (moment() < moment(homework.released_on)) {
      homeworkReleased = false
    }

    if (homework.close_on) {
      if (moment() > moment(homework.close_on)) {
        homeworkClosed = true
      }
    } else {
      if (overdueDay > 7) {
        homeworkClosed = true
      }
    }

    let submissionData, homeworkStatus, homeworkStatusColor
    let submitButtonText = 'Submit Homework'
    if (mySubmissions[homework._id]) {
      submissionData = mySubmissions[homework._id]
      if (submissionData && submissionData !== 'not-found') {
        homeworkStatus = getSubmissionStatusBadgeColorOrText(submissionData.status)
        homeworkStatusColor = getSubmissionStatusBadgeColorOrText(submissionData.status, true)

        let status = submissionData.status
        if (status === 'MARKING_IN_PROGRESS' || status === 'MARKED') {
          submitButtonText = ''
        } else {
          submitButtonText = 'Resubmit Homework'
        }
      }
    }

    return (
      <div className="app-wrapper">
        <div className="dashboard animated slideInUpTiny animation-duration-3">
          <div
            className="back-link mb-2"
            onClick={() => {
              this.props.history.push(MY_CLASS_DETAILS.replace(':classId', homework.lesson.class._id))
            }}>
            <i className="material-icons mr-1" style={{ fontSize: '10px' }}>
              arrow_back_ios
            </i>
            Back to Class
          </div>
          <div className="row">
            <h2>Homework Details</h2>
            <div className="col-12 mb-3">
              <Paper className="p-3">
                <div className="title-rubik text-muted ">Due on:</div>
                <div className="text-large px-2 mb-2" style={{ color: '#9b51e0' }}>
                  {moment(homework.due_on).format('lll')}
                </div>

                <div className="title-rubik text-muted mb-1">Homework File:</div>
                <div className="ml-3">
                  {homework.attachment.map((file, index) => (
                    <FileWidget fileUrl={file.url} fileName={file.name} key={file.url}></FileWidget>
                  ))}
                </div>

                <div className="title-rubik text-muted mt-2">Class:</div>
                <div className="text-large px-2 mb-2" style={{ color: '#9b51e0' }}>
                  {homework.lesson.class.name}
                </div>

                <div className="title-rubik text-muted ">Lesson:</div>
                <div className="text-large px-2 mb-2" style={{ color: '#9b51e0' }}>
                  {homework.lesson.name}
                </div>
              </Paper>
            </div>

            {homework && (
              <Fragment>
                <h2>Submission</h2>
                <div className="col-12 mb-3">
                  <Paper className="p-3">
                    {loadingMySubmission.includes(homework._id) ? (
                      <div className="my-3">
                        <CircularProgress size={20} />
                      </div>
                    ) : submissionData && submissionData !== 'not-found' ? (
                      <div>
                        <div className="title-rubik text-muted mb-2">
                          Status:{' '}
                          <Badge className="ml-2 mb-0 text-white" color={homeworkStatusColor}>
                            {homeworkStatus}
                          </Badge>
                        </div>
                        <div className="title-rubik text-muted mb-1">Submitted:</div>
                        <div className="ml-3 mb-3">
                          {submissionData.attachment.map(file => (
                            <FileWidget fileUrl={file.url} fileName={file.name} key={file.url}></FileWidget>
                          ))}
                        </div>
                        {submissionData.marked_attachment.length > 0 && (
                          <Fragment>
                            <div className="title-rubik text-muted mb-1">Returned Submission:</div>
                            <div className="ml-3 mb-3">
                              {submissionData.marked_attachment.map(file => (
                                <FileWidget fileUrl={file.url} fileName={file.name} key={file.url}></FileWidget>
                              ))}
                            </div>
                          </Fragment>
                        )}
                        {submissionData.comment && (
                          <Fragment>
                            <div className="title-rubik text-muted mb-1">Comment:</div>
                            <p
                              className="text-large ml-2"
                              dangerouslySetInnerHTML={{ __html: submissionData.comment }}
                            />
                          </Fragment>
                        )}
                      </div>
                    ) : (
                      <span className="text-muted text-large">
                        {homeworkClosed && (
                          <div className="title-rubik text-muted mb-2">
                            Status:{' '}
                            <Badge className="mb-0 text-white" color="light">
                              Closed
                            </Badge>
                          </div>
                        )}
                        {homeworkReleased
                          ? 'No submission yet'
                          : 'You can submit your homework only after the lesson has ended.'}
                      </span>
                    )}

                    {submitButtonText && (
                      <div className="text-right">
                        <Button
                          id="submit-homework-btn"
                          variant="contained"
                          color="primary"
                          onClick={this.toggleSubmitHomeworkDialog}
                          disabled={!homeworkReleased || homeworkClosed}>
                          {submitButtonText}
                        </Button>
                        {homeworkOverdue && !submissionData && !homeworkClosed && (
                          <div className="text-muted">
                            Homework submission after the due date will be marked and returned within one month of
                            submission date.
                          </div>
                        )}
                        {!homeworkReleased && (
                          <div className="text-muted">
                            You can submit your homework only after the lesson has ended.
                          </div>
                        )}
                        {homeworkClosed && (
                          <div className="text-muted">
                            Submission of homework is not allowed
                            {homework.close_on ? (
                              <React.Fragment>
                                &nbsp;after {moment(homework.close_on).format('Do MMM, YYYY')}
                              </React.Fragment>
                            ) : (
                              <React.Fragment>&nbsp;more than 7 days after the due date</React.Fragment>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </Paper>
                </div>
              </Fragment>
            )}

            <DialogSubmitHomework
              dialogOpen={submitHomeworkDialogOpen}
              toggleDialog={this.toggleSubmitHomeworkDialog}
              homeworkData={homework}
              submissionData={mySubmissions[homework._id] ? mySubmissions[homework._id] : null}
              homeworkFileName={homeworkFileName}
              refreshSubmission={this.refreshSubmission(homework._id)}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Homework))
