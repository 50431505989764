import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, CircularProgress, Dialog, DialogTitle, List, ListItem, ListItemText, Paper } from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'
import qs from 'qs'

import agent from 'agent'
import { MY_CLASS_LIST } from 'routes/routes'
import { GET_CLASS_BY_ID, CLEAR_PACKAGE_QUOTE } from 'constants/actionTypes'
import { CLASS_TYPE_WORKSHOP, CLASS_TYPE_REGULAR_CLASSES, CLASS_TYPE_RECORDING } from 'constants/generalTypes'
import LessonGroupingNav from 'components/online-class/nav-lesson-grouping'
import PurchaseDialog from 'components/online-class/dialog-purchase-class'
import LiveLessonTab from './tab-live-lesson'
import ResourceTab from './tab-resource'
import HomeworkTab from './tab-homework'
import RecordingTab from './tab-recording'
import ReportTab from './tab-report'

const mapStateToProps = state => ({
  selectedClass: state.classes.selectedClass,
  currentUser: state.common.currentUser
})

const mapDispatchToProps = dispatch => ({
  getClassById: classId => dispatch({ type: GET_CLASS_BY_ID, payload: agent.Classes.getClassById(classId) }),
  clearPackageQuote: class_id => dispatch({ type: CLEAR_PACKAGE_QUOTE, class_id: class_id })
})

class MyClassDetails extends React.Component {
  state = {
    activeTab: 'live-lesson',
    activeMonth: null,
    prevMonth: null,
    nextMonth: null,
    firstLoad: true,
    switchingMonth: false,
    selectMonthDialogOpen: false,
    availableMonths: [],
    showTabTutorial: '',
    futurePurchasedLessonCount: 0,
    futureNotPurchasedLessonCount: 0,
    purchaseDialogOpen: false,
    showByTerm: false,
    activeTerm: null,
    availableTerm: [1, 2, 3, 4],
    videoOnlyAccess: false
  }

  componentDidMount() {
    let { selectedClass } = this.props
    let classId = this.props.match.params.classId

    if (!selectedClass || selectedClass._id !== classId) {
      this.props.getClassById(classId)
    } else {
      if (selectedClass && selectedClass.class_type === CLASS_TYPE_RECORDING) {
        this.setState({
          activeTab: 'recording'
        })
      }
      this.processClassData()
    }
    let parsed = qs.parse(this.props.location.search.slice(1))
    if (parsed.tab) {
      this.setState({
        activeTab: parsed.tab
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedClass === 'not_found') {
      this.props.history.push(MY_CLASS_LIST)
    }

    if (prevProps.selectedClass !== this.props.selectedClass) {
      if (this.props.selectedClass && this.props.selectedClass.class_type === CLASS_TYPE_RECORDING) {
        this.setState({
          activeTab: 'recording'
        })
      }
      this.processClassData()
    }

    if (prevState.activeMonth !== this.state.activeMonth && this.state.activeMonth !== 'All') {
      this.getPrevNextMonth()
    }
  }

  componentWillUnmount() {
    if (this.props.selectedClass?._id) {
      this.props.clearPackageQuote(this.props.selectedClass._id)
    }
  }

  processClassData = () => {
    let { selectedClass, currentUser } = this.props
    let futurePurchasedLessonCount = 0
    let futureNotPurchasedLessonCount = 0
    let availableTerm = [1, 2, 3, 4]

    if (!selectedClass || selectedClass === 'not_found') return

    if (!selectedClass.is_active) {
      return
    }

    if (selectedClass.class_type === CLASS_TYPE_REGULAR_CLASSES) {
      if (selectedClass.lesson[0].meta && selectedClass.lesson[0].meta.term) {
        let activeReleasedLesson = _.filter(selectedClass.lesson, function (o) {
          return o.is_active && o.is_released
        })
        availableTerm = _.uniq(_.map(activeReleasedLesson, 'meta.term'))
        availableTerm = availableTerm.map(Number)
        let nextLesson = _.find(activeReleasedLesson, o => {
          return moment(o.scheduled_on) > moment()
        })
        let activeTerm = nextLesson
          ? parseInt(nextLesson.meta.term)
          : parseInt(activeReleasedLesson[activeReleasedLesson.length - 1].meta.term)

        for (let i = 0; i < activeReleasedLesson.length; i++) {
          let { scheduled_on, student, trial_student, video_student } = activeReleasedLesson[i]

          if (moment(scheduled_on) > moment()) {
            let isUserLesson =
              student.includes(currentUser._id) ||
              trial_student.includes(currentUser._id) ||
              video_student.includes(currentUser._id)
            if (isUserLesson) {
              futurePurchasedLessonCount++
            } else {
              futureNotPurchasedLessonCount++
            }
          }
        }

        this.setState({
          showByTerm: true,
          activeTerm,
          availableTerm,
          futurePurchasedLessonCount,
          futureNotPurchasedLessonCount
        })
      } else {
        let activeMonth = moment().format('YYYY-MM-DD')
        let activeMonthLessons = []
        let availableMonths = []
        let parsed = qs.parse(this.props.location.search.slice(1))
        if (parsed.month) {
          activeMonth = parsed.month
        }

        let activeReleasedLesson = _.filter(selectedClass.lesson, function (o) {
          return o.is_active && o.is_released
        })

        if (moment(activeReleasedLesson[0].scheduled_on) > moment()) {
          activeMonth = moment(activeReleasedLesson[0].scheduled_on).format('YYYY-MM-DD')
        }

        for (let i = 0; i < activeReleasedLesson.length; i++) {
          let { scheduled_on, student, trial_student, video_student } = activeReleasedLesson[i]
          if (moment(scheduled_on).isSame(moment(activeMonth), 'month')) {
            activeMonthLessons.push(activeReleasedLesson[i])
          }

          if (moment(scheduled_on) > moment()) {
            let isUserLesson =
              student.includes(currentUser._id) ||
              trial_student.includes(currentUser._id) ||
              video_student.includes(currentUser._id)
            if (isUserLesson) {
              futurePurchasedLessonCount++
            } else {
              futureNotPurchasedLessonCount++
            }
          }
          availableMonths.push(moment(scheduled_on).startOf('month').format('YYYY-MM-DDTHH:mm:SS'))
        }
        availableMonths = _.uniq(availableMonths)

        if (activeMonthLessons.length === 0) {
          let sortedClassLessons = _.sortBy(activeReleasedLesson, ['scheduled_on'])
          activeMonth = sortedClassLessons[sortedClassLessons.length - 1].scheduled_on
        }
        this.setState({
          activeMonth,
          availableMonths,
          futurePurchasedLessonCount,
          futureNotPurchasedLessonCount
        })
      }
    } else if (selectedClass.class_type === CLASS_TYPE_WORKSHOP || selectedClass.class_type === CLASS_TYPE_RECORDING) {
      let userLessons = _.filter(selectedClass.lesson, o => {
        return (
          o.student.includes(currentUser._id) ||
          o.trial_student.includes(currentUser._id) ||
          o.video_student.includes(currentUser._id)
        )
      })
      let futureNotPurchasedLessonCount = selectedClass.lesson.length - userLessons.length
      if (moment(selectedClass.lesson[selectedClass.lesson.length - 1]) < moment()) {
        futureNotPurchasedLessonCount = 0
      }

      //NOTE: Need to review this part of logic for workshop when we allow video purchase
      this.setState({
        activeMonth: 'All',
        futureNotPurchasedLessonCount
      })
    }

    let isVideoStudent = selectedClass.video_student && selectedClass.video_student.includes(currentUser._id)
    let isNormalStudent = selectedClass.student && selectedClass.student.includes(currentUser._id)
    let isTrialStudent = selectedClass.trial_student && selectedClass.trial_student.includes(currentUser._id)
    let isLiveLessonDisabled = selectedClass.disable?.includes('live-lesson')

    if (isLiveLessonDisabled || (isVideoStudent && !isNormalStudent && !isTrialStudent)) {
      this.setState({
        videoOnlyAccess: true,
        activeTab: 'recording',
        futureNotPurchasedLessonCount: 0
      })
    }
  }

  getPrevNextMonth = () => {
    let { selectedClass } = this.props
    let { activeMonth } = this.state
    let prevMonth = moment(activeMonth).subtract(1, 'month').format('YYYY-MM-DDTHH:mm:SS')
    let nextMonth = moment(activeMonth).add(1, 'month').format('YYYY-MM-DDTHH:mm:SS')

    if (
      _.findIndex(selectedClass.lesson, function (o) {
        return moment(o.scheduled_on).isSame(prevMonth, 'month')
      }) === -1
    ) {
      prevMonth = ''
    }
    if (
      _.findIndex(selectedClass.lesson, function (o) {
        return moment(o.scheduled_on).isSame(nextMonth, 'month')
      }) === -1
    ) {
      nextMonth = ''
    }

    this.setState({ prevMonth, nextMonth, switchingMonth: false })
  }

  handleTabChange = selectedTab => {
    this.setState({
      activeTab: selectedTab,
      showTabTutorial: selectedTab,
      firstLoad: false
    })
  }

  changeActiveMonth = activeMonth => {
    if (this.state.showByTerm) {
      this.setState({ activeTerm: activeMonth, selectMonthDialogOpen: false, firstLoad: false })
    } else {
      this.setState({ activeMonth, switchingMonth: true, selectMonthDialogOpen: false, firstLoad: false })
    }
  }

  toggleSelectMonthDialog = () => {
    this.setState({ selectMonthDialogOpen: !this.state.selectMonthDialogOpen })
  }

  togglePurchaseDialog = () => {
    this.setState({ purchaseDialogOpen: !this.state.purchaseDialogOpen })
  }

  render() {
    let { selectedClass } = this.props
    let {
      activeTab,
      activeMonth,
      prevMonth,
      nextMonth,
      switchingMonth,
      availableMonths,
      selectMonthDialogOpen,
      futurePurchasedLessonCount,
      futureNotPurchasedLessonCount,
      purchaseDialogOpen,
      firstLoad,
      showByTerm,
      activeTerm,
      availableTerm,
      videoOnlyAccess
    } = this.state

    if (!selectedClass) {
      return (
        <div className="dashboard animated slideInUpTiny animation-duration-3">
          <div className="manage-margin text-center py-5">
            <CircularProgress size={50} />
          </div>
        </div>
      )
    }

    let showHomework = true
    if (videoOnlyAccess || selectedClass.is_free || selectedClass.disable?.includes('homework')) {
      showHomework = false
    }

    //NOTE: shall control how every tab behave, for example/espeically homework tab, only fire api and get all homework status when tab mounted, to optimize performance

    return (
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <React.Fragment>
          <Paper elevation={2} className={`app-wrapper `}>
            <div className="row align-items-center">
              <div className="col">
                <div
                  className="back-link mb-2"
                  onClick={() => {
                    this.props.history.goBack()
                  }}>
                  <i className="material-icons mr-1" style={{ fontSize: '10px' }}>
                    arrow_back_ios
                  </i>
                  Back to My Classes
                </div>
                <div className="row">
                  <div className="col">
                    <h1 className="title--rubik mb-0">{selectedClass.name}</h1>
                  </div>
                  <div className="col-md-auto col-12 mt-md-0 mt-3 text-md-left text-right">
                    {futureNotPurchasedLessonCount > 0 && (
                      <React.Fragment>
                        {selectedClass.class_type === CLASS_TYPE_REGULAR_CLASSES && futurePurchasedLessonCount < 2 && (
                          <span className="blink text-danger mr-2">{futurePurchasedLessonCount} lesson left</span>
                        )}
                        <Button variant="contained" color="primary" onClick={this.togglePurchaseDialog}>
                          {selectedClass.class_type === CLASS_TYPE_WORKSHOP ? (
                            <React.Fragment>Purchase Full Workshop</React.Fragment>
                          ) : (
                            <React.Fragment>Buy More Lessons</React.Fragment>
                          )}
                        </Button>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </React.Fragment>
        <div className="app-wrapper">
          {selectedClass.class_type === CLASS_TYPE_REGULAR_CLASSES && (
            <LessonGroupingNav
              showByTerm={showByTerm}
              activeTerm={activeTerm}
              activeMonth={activeMonth}
              nextMonth={nextMonth}
              prevMonth={prevMonth}
              availableTerm={availableTerm}
              switchingMonth={switchingMonth}
              changeActiveMonth={this.changeActiveMonth}
              toggleSelectMonthDialog={this.toggleSelectMonthDialog}
            />
          )}

          {selectedClass.class_type !== CLASS_TYPE_RECORDING && (
            <>
              <div className="class-details-tab">
                {!videoOnlyAccess && (
                  <>
                    <input
                      id="live-lesson"
                      type="radio"
                      name="tabs"
                      checked={activeTab === 'live-lesson'}
                      onChange={() => {
                        this.handleTabChange('live-lesson')
                      }}
                    />
                    <label htmlFor="live-lesson">Live Lessons</label>
                  </>
                )}

                <>
                  <input
                    id="resource"
                    type="radio"
                    name="tabs"
                    checked={activeTab === 'resource'}
                    onChange={() => {
                      this.handleTabChange('resource')
                    }}
                  />
                  <label htmlFor="resource" id="resource-tab">
                    Resources
                  </label>{' '}
                </>

                {showHomework && (
                  <>
                    <input
                      id="homework"
                      type="radio"
                      name="tabs"
                      checked={activeTab === 'homework'}
                      onChange={() => {
                        this.handleTabChange('homework')
                      }}
                    />
                    <label htmlFor="homework" id="homework-tab">
                      Homework
                    </label>
                  </>
                )}

                <input
                  id="recording"
                  type="radio"
                  name="tabs"
                  checked={activeTab === 'recording'}
                  onChange={() => {
                    this.handleTabChange('recording')
                  }}
                />
                <label htmlFor="recording">Recordings</label>

                {!selectedClass.disable?.includes('report') && (
                  <>
                    <input
                      id="report"
                      type="radio"
                      name="tabs"
                      checked={activeTab === 'report'}
                      onChange={() => {
                        this.handleTabChange('report')
                      }}
                    />
                    <label htmlFor="report">Reports</label>
                  </>
                )}

                {!videoOnlyAccess && (
                  <section id="live-lesson">
                    <LiveLessonTab
                      activeMonth={activeMonth}
                      switchToRecordingTab={() => {
                        this.handleTabChange('recording')
                      }}
                      firstLoad={firstLoad}
                      showByTerm={showByTerm}
                      activeTerm={activeTerm}
                    />
                  </section>
                )}

                <section id="resource">
                  <ResourceTab
                    activeMonth={activeMonth}
                    firstLoad={firstLoad}
                    showByTerm={showByTerm}
                    activeTerm={activeTerm}
                  />
                </section>

                {showHomework && (
                  <section id="homework">
                    {/* homework tab are not using firstLoad, showByTerm because initially homeworktab having 2 different component where 1 is old post type and 1 is proper homework handle */}
                    <HomeworkTab activeTerm={activeTerm} />
                  </section>
                )}

                <section id="recording">
                  <RecordingTab activeMonth={activeMonth} showByTerm={showByTerm} activeTerm={activeTerm} />
                </section>

                {!selectedClass.disable?.includes('report') && (
                  <section id="report">
                    <ReportTab activeView={activeTab === 'report'} />
                  </section>
                )}

                <Dialog onClose={this.toggleSelectMonthDialog} open={selectMonthDialogOpen}>
                  <DialogTitle>
                    <span style={{ fontSize: '18px' }}>Select a {showByTerm ? 'term' : 'month'}</span>
                  </DialogTitle>
                  <List>
                    {showByTerm
                      ? availableTerm.map((term, index) => {
                          let isSelected = activeTerm === term

                          return (
                            <ListItem
                              button
                              key={index}
                              selected={isSelected}
                              onClick={() => {
                                this.changeActiveMonth(term)
                              }}>
                              <ListItemText primary={`Term ${term}`} />
                            </ListItem>
                          )
                        })
                      : availableMonths.length > 0 &&
                        availableMonths.map((month, index) => {
                          let isSelected = false
                          if (moment(activeMonth).isSame(moment(month), 'month')) {
                            isSelected = true
                          }

                          return (
                            <ListItem
                              button
                              key={index}
                              selected={isSelected}
                              onClick={() => {
                                this.changeActiveMonth(month)
                              }}>
                              <ListItemText primary={moment(month).format('MMMM, YYYY')} />
                            </ListItem>
                          )
                        })}
                  </List>
                </Dialog>

                {futureNotPurchasedLessonCount > 0 && (
                  <PurchaseDialog
                    isOpen={purchaseDialogOpen}
                    close={this.togglePurchaseDialog}
                    classId={selectedClass?._id}
                  />
                )}
              </div>{' '}
            </>
          )}

          {selectedClass.class_type === CLASS_TYPE_RECORDING && (
            <>
              {' '}
              <div className="class-details-tab">
                <input
                  id="recording"
                  type="radio"
                  name="tabs"
                  checked={activeTab === 'recording'}
                  onChange={() => {
                    this.handleTabChange('recording')
                  }}
                />
                <label htmlFor="recording">Recordings</label>

                <section id="recording">
                  <RecordingTab activeMonth={activeMonth} showByTerm={showByTerm} activeTerm={activeTerm} />
                </section>
              </div>
            </>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyClassDetails))
