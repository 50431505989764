import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

import agent from 'agent'
import { SET_ONLINE_CLASS_FILTER, GET_CUSTOM_CLASS_CATEGORIES } from 'constants/actionTypes'
import MobileNavMenu from './mobile'
import DesktopNavMenu from './desktop/animated-navbar'

const mapStateToProps = state => ({
  shoppingCart: state.shoppingCart.shoppingCart,
  token: state.common.token,
  currentUser: state.common.currentUser,
  classCategories: state.misc.classCategories,
  pathname: state.router.location.pathname
})

const mapDispatchToProps = dispatch => ({
  setOnlineClassFilter: filters => dispatch({ type: SET_ONLINE_CLASS_FILTER, payload: filters }),
  getClassCategories: () =>
    dispatch({
      type: GET_CUSTOM_CLASS_CATEGORIES,
      payload: agent.External.getJSON(
        `https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/json/menu-classes-sg.json`
      )
    })
})

class Navbar extends React.Component {
  componentDidMount() {
    this.props.getClassCategories()
  }

  render() {
    const { shoppingCart, token, currentUser, classCategories, pathname } = this.props

    if (isMobile) {
      return (
        <MobileNavMenu
          token={token}
          shoppingCart={shoppingCart}
          classCategories={classCategories}
          pathname={pathname}
          setOnlineClassFilter={this.props.setOnlineClassFilter}
        />
      )
    }

    return (
      <DesktopNavMenu
        duration={300}
        token={token}
        currentUser={currentUser}
        pathname={pathname}
        shoppingCart={shoppingCart}
        classCategories={classCategories}
        setOnlineClassFilter={this.props.setOnlineClassFilter}
      />
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar))
