import React from 'react'
import ReactDOM from 'react-dom'
import { Provider, connect } from 'react-redux'
import { push } from 'react-router-redux'
import agent from './agent'
import { store, history } from './store'
import moment from 'moment'
import _ from 'lodash'

import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'react-router-redux'

import * as Sentry from '@sentry/browser'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga'

import App from './components/App'
import Landing from './components/Landing'
import { APP, ROOT, IE_WARNING } from './routes/routes'
import {
  INIT_APP,
  GET_USER_DATA,
  STORE_UTM_DATA,
  GET_CART,
  GET_CART_NOAUTH,
  ADD_CART_ITEM,
  PATCH_CART_ITEM
} from './constants/actionTypes'
import { patchCartItem } from 'utils/cart'
import { createTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'

import './styles/bootstrap.css'
import './styles/app.css'
import './styles/custom.css'
import 'font-awesome/css/font-awesome.min.css'
import './styles/vendors/material-design-iconic-font/css/material-design-iconic-font.css'
import IEWarning from './components/public/IEWarning'
import { CLASS_TYPE_REGULAR_CLASSES } from './constants/generalTypes'

const mapStateToProps = state => ({
  redirectTo: state.common.redirectTo,
  appInitialized: state.common.appInitialized,
  loadingUserData: state.common.loadingUserData,
  currentUser: state.common.currentUser,
  loadingCart: state.shoppingCart.loadingCart,
  shoppingCart: state.shoppingCart.shoppingCart
})

const mapDispatchToProps = dispatch => ({
  initApp: () => dispatch({ type: INIT_APP, payload: agent.Auth.refreshToken() }),
  getUserData: payload => dispatch({ type: GET_USER_DATA, payload }),
  storeUTMData: () => dispatch({ type: STORE_UTM_DATA }),
  getCart: () => dispatch({ type: GET_CART, payload: agent.ShoppingCart.getCart() }),
  getLocalCart: () => dispatch({ type: GET_CART_NOAUTH }),
  addToCart: item => dispatch({ type: ADD_CART_ITEM, payload: agent.ShoppingCart.addItem(item), cartItem: item }),
  patchCartItem: (itemId, itemDetail) =>
    dispatch({ type: PATCH_CART_ITEM, payload: agent.ShoppingCart.updateItem(itemId, itemDetail), itemId })
})

let tenopyTheme = {
  palette: {
    primary: {
      light: '#4ecb95',
      main: '#00b96c',
      dark: '#009153',
      contrastText: '#fff'
    },
    secondary: {
      main: '#ffd300',
      contrastText: '#fff'
    },
    error: {
      main: '#f55859',
      contrastText: '#fff'
    }
  },
  status: {
    danger: 'orange'
  },
  typography: {
    useNextVariants: true,
    fontFamily: "'Libre Franklin', sans-serif",
    fontSize: 15,
    button: {
      fontFamily: "'Rubik', sans-serif",
      fontWeight: 400,
      textTransform: 'none'
    }
  }
}

class Index extends React.Component {
  constructor() {
    super()

    let ua = window.navigator.userAgent
    let msie = ua.indexOf('MSIE ')
    let trident = ua.indexOf('Trident/')
    if (msie > 0 || trident > 0) {
      store.dispatch(push(IE_WARNING + '?origin=' + window.location.pathname))
    }
  }

  componentDidMount() {
    this.props.storeUTMData()

    const token = window.localStorage.getItem('jwt')
    if (token) {
      agent.setToken(token)
      this.props.initApp()
    } else {
      // CHECK if Load cart for not logged in user
      this.props.getLocalCart()
    }

    ReactPixel.init(process.env.REACT_APP_PIXEL_ID, {}, { autoConfig: true, debug: false })
    ReactPixel.pageView()
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)
    ReactGA.pageview(window.location.pathname)
    if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
      Sentry.init({
        dsn: 'https://3f3c704dc461437db21096ba65a8eecc@sentry.io/1256274',
        release: '2-1-0',
        environment: process.env.REACT_APP_ENVIRONMENT
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.redirectTo !== this.props.redirectTo && this.props.redirectTo) {
      store.dispatch(push(this.props.redirectTo))
    }

    if (!prevProps.appInitialized && this.props.appInitialized) {
      this.props.getUserData(
        Promise.all([
          agent.Auth.current(),
          agent.Order.getList({ max: 50, offset: 0, type: 'PAYMENT,TRIAL', status: 'PROCESSED' }),
          agent.ShoppingCart.getCart()
        ])
      )
    }

    if (prevProps.loadingUserData && !this.props.loadingUserData && this.props.shoppingCart !== null) {
      const { shoppingCart, currentUser } = this.props
      let localCart = JSON.parse(localStorage.getItem('tnp_cart'))

      //if (!!localCart && moment(localCart.updated) > moment(shoppingCart.updated)) {
      if (!!localCart) {
        let isUserLocalCart = true
        if (localCart.created_by && localCart.created_by._id !== currentUser._id) {
          isUserLocalCart = false
        }
        //NOTE: Only perform local cart to fetch cart checking and update if it's cart belong to same user or when user id is not present
        if (isUserLocalCart) {
          let cartItems = [...localCart.item]
          let newItemsToAdd = []

          for (let i = 0; i < cartItems.length; i++) {
            let localCartItem = cartItems[i]
            let repeatedClassIndex = _.findIndex(shoppingCart.item, item => {
              return item.item_id === localCartItem.item_id
            })
            if (repeatedClassIndex !== -1) {
              let repeatedCartItem = shoppingCart.item[repeatedClassIndex]
              if (repeatedCartItem.item.class_type && repeatedCartItem.item.class_type === CLASS_TYPE_REGULAR_CLASSES) {
                let updatedStartDate =
                  moment(localCartItem.start_date) > moment(repeatedCartItem.start_date)
                    ? localCartItem.start_date
                    : repeatedCartItem.start_date
                let updatedEndDate =
                  moment(localCartItem.end_date) > moment(repeatedCartItem.end_date)
                    ? localCartItem.end_date
                    : repeatedCartItem.end_date

                if (updatedStartDate !== repeatedCartItem.start_date || updatedEndDate !== repeatedCartItem.end_date) {
                  this.props.patchCartItem(repeatedCartItem._id, {
                    start_date: updatedStartDate,
                    end_date: updatedEndDate
                  })
                } else {
                  if (
                    moment(localCartItem.start_date) !== moment(repeatedCartItem.start_date) ||
                    moment(localCartItem.end_date) !== moment(repeatedCartItem.end_date)
                  ) {
                    patchCartItem(i, repeatedCartItem)
                  }
                }
                // NOTE: Need more complicated logic to handle cart update when Video purchase logic confirmed. The initial idea is to padd video
                // can be as single item per lesson. So, need to check for overlapped start_date and end_date range and discard duplicate and not
                // relevant item OR backend can have another item_type, so video purchase is differentiate from lesson purchase
              }
            } else {
              newItemsToAdd.push({
                start_date: localCartItem.start_date,
                end_date: localCartItem.end_date,
                item_id: localCartItem.item_id,
                item_type: localCartItem.item_type
              })
            }
          }

          if (newItemsToAdd.length > 0) {
            this.props.addToCart({ item: newItemsToAdd })
          }
        } else {
          window.localStorage.removeItem('tnp_cart')
        }
      }
    }
  }

  render() {
    return (
      <ConnectedRouter history={history}>
        <MuiThemeProvider theme={createTheme(tenopyTheme)}>
          <Switch>
            <Route exact path={IE_WARNING} component={IEWarning} />
            <Route path={APP} component={App} />
            <Route path={ROOT} component={Landing} />
          </Switch>
        </MuiThemeProvider>
      </ConnectedRouter>
    )
  }
}

const IndexBase = connect(mapStateToProps, mapDispatchToProps)(Index)

ReactDOM.render(
  <Provider store={store}>
    <IndexBase />
  </Provider>,
  document.getElementById('app-site')
)
