import React from 'react'
import Slider from 'react-slick'

function MediaFeaturedCarousel() {
  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    centerMode: false,
    variableWidth: true
  }

  return (
    <section className="bg-white">
      <div className="container">
        <div className="section__header mb-5">
          <div
            className="title mb-2"
            style={{ fontSize: '2.3rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif", lineHeight: 1.2 }}>
            As Featured In
          </div>
          <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '3px solid #0cc652' }}></div>
        </div>

        <Slider {...settings}>
          <div className="mx-4 mb-3">
            <a
              href="https://e27.co/riding-the-tailwind-how-covid-19-accelerated-the-growth-of-edtech-startups-in-singapore-20200721/"
              target="_blank"
              rel="noopener noreferrer">
              <img
                src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/logo/logo-e27.png"
                alt="e27"
                style={{ height: '75px', width: 'auto' }}
              />
            </a>
          </div>
          <div className="mx-4 mb-3">
            <a
              href="https://www.channelnewsasia.com/news/singapore/tuition-centres-adjust-holiday-programmes-as-school-holidays-12668016"
              target="_blank"
              rel="noopener noreferrer">
              <img
                src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/logo/logo-cna.svg"
                alt="channel new asia"
                style={{ height: '75px', width: 'auto' }}
              />
            </a>
          </div>
          <div className="mx-4 mb-3">
            <a href="https://www.moneyfm893.sg/guest/chong-kian-tenopy/" target="_blank" rel="noopener noreferrer">
              <img
                src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/logo/logo_moneyfm.png"
                alt="money fm"
                style={{ height: '75px', width: 'auto' }}
              />
            </a>
          </div>
          <div className="mx-4 mb-3">
            <a
              href="https://www.techinasia.com/china-vc-zwc-ventures-southeast-asia"
              target="_blank"
              rel="noopener noreferrer">
              <img
                src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/logo/logo-tech-in-asia.png"
                alt="tech in asia"
                style={{ height: '75px', width: 'auto' }}
              />
            </a>
          </div>
          <div className="mx-4 mb-3">
            <a
              href="https://beltandroad.zaobao.com/beltandroad/news/story20200511-1052394?ref=newsticker"
              target="_blank"
              rel="noopener noreferrer">
              <img
                src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/logo/logo-zaobao.svg"
                alt="zaobao"
                style={{ height: '75px', width: 'auto' }}
              />
            </a>
          </div>
          <div className="mx-4 mb-3">
            <a href="https://www.taiwannews.com.tw/en/news/3945229" target="_blank" rel="noopener noreferrer">
              <img
                src="https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/logo/logo-taiwan-news.png"
                alt="taiwan english news"
                style={{ height: '75px', width: 'auto' }}
              />
            </a>
          </div>
        </Slider>
      </div>
    </section>
  )
}

export default MediaFeaturedCarousel
