import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField
} from '@material-ui/core'
import _ from 'lodash'
import qs from 'qs'

import agent from '../../agent'
import countrycodes from '../../assets/country_code.json'
import educationLevels from '../../assets/education_level.json'
import { LOGIN, REGISTER, REGISTER_UNLOAD } from '../../constants/actionTypes'
import { validatePassword, validateTextEntry, validatePhoneNumber } from '../../utils/formValidation'
import { NumberOnly } from '../../utils/format'

const mapStateToProps = state => ({
  ...state.auth
})

const mapDispatchToProps = dispatch => ({
  activateAccount: reqPayload => dispatch({ type: REGISTER, payload: agent.Auth.activateUser(reqPayload) }), // TODO: activate account shall dispatch a different action
  login: (loginObj, next) => dispatch({ type: LOGIN, payload: agent.Auth.login(loginObj), next }),
  onUnload: () => dispatch({ type: REGISTER_UNLOAD })
})

class QuickSignup extends React.Component {
  state = {
    name: '',
    email: '',
    countryCode: 65,
    phone: '',
    password: '',
    level: '',
    othersLevel: '',
    school: '',
    showOthersLevel: false,
    formError: {},
    classTitle: '',
    tutor: '',
    token: '',
    accountAlreadyVerified: false
  }

  componentDidMount() {
    // NOTE: this few lines is here because in early day there is a problem with the url send by backend. Still leave it here because no harm having.
    let search = window.location.href.split('&#x3D;').join('=')
    search = search.split('/activation?')
    if (search.length > 0) {
      search = search[1]
    }
    let parsed = qs.parse(search)
    this.setState({
      email: parsed.email,
      classTitle: parsed.class_name,
      tutor: parsed.tutor,
      token: parsed.token,
      nextLocation: parsed.next ? parsed.next : ''
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.loginUser) {
      this.props.login(
        {
          email: this.state.email,
          password: this.state.password
        },
        this.state.nextLocation
      )
    }

    if (prevProps.inProgress && !this.props.inProgress) {
      const { errors } = this.props
      if (errors?.errors === 'already_verified') {
        this.setState({ accountAlreadyVerified: true })
      }
    }
  }

  componentWillUnmount() {
    this.props.onUnload()
  }

  validateFormElement = (type, customMsg) => {
    let errorMsg
    if (type === 'password') {
      errorMsg = validatePassword(this.state[type])
    } else if (type === 'phone') {
      if (this.state.countryCode === 65) {
        errorMsg = validatePhoneNumber(this.state[type], this.state.countryCode)
      } else {
        errorMsg = validateTextEntry(this.state[type], type)
      }
    } else {
      if (customMsg) {
        errorMsg = validateTextEntry(this.state[type], type, customMsg)
      } else {
        errorMsg = validateTextEntry(this.state[type], type)
      }
    }

    let tempFormError = this.state.formError
    if (errorMsg) {
      tempFormError[type] = errorMsg
    } else {
      delete tempFormError[type]
    }
    this.setState({ formError: tempFormError })
  }

  onChangeFormElement = e => {
    let type = e.target.name
    let value = e.target.value
    this.setState({ [type]: value })

    if (type === 'level') {
      let tempFormError = this.state.formError
      delete tempFormError[type]
      this.setState({ formError: tempFormError })
      if (value === 'others') {
        this.setState({ showOthersLevel: true })
      } else {
        this.setState({ showOthersLevel: false })
      }
    }
  }

  onFocusFormElement = e => {
    let type = e.target.name
    let tempFormError = this.state.formError
    delete tempFormError[type]
    this.setState({ formError: tempFormError })
  }

  clickSignUp = () => {
    this.validateFormElement('name')
    this.validateFormElement('phone')
    this.validateFormElement('password')
    if (this.state.level === 'others') {
      this.validateFormElement('othersLevel', 'your level')
    } else {
      this.validateFormElement('level')
    }
    this.validateFormElement('school')

    if (_.isEmpty(this.state.formError)) {
      let level = this.state.level === 'others' ? this.state.othersLevel : this.state.level
      let reqPayload = {
        token: this.state.token,
        name: this.state.name,
        phone_country_code: this.state.countryCode,
        phone: this.state.phone,
        password: this.state.password,
        level,
        school: this.state.school,
        is_other_level: this.state.level === 'others' ? true : false
      }
      this.props.activateAccount(reqPayload)
    }
  }

  closeAccountAlreadyVerifiedDialog = () => {
    this.setState({ accountAlreadyVerified: false })
    this.props.history.push('/login')
  }

  render() {
    const {
      name,
      email,
      countryCode,
      phone,
      password,
      accountAlreadyVerified,
      level,
      othersLevel,
      showOthersLevel,
      school,
      formError,
      classTitle,
      tutor
    } = this.state
    const { inProgress } = this.props

    return (
      <div className="d-flex login-container justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>Account Activation | Tenopy</title>
          <meta
            name="description"
            content="At Tenopy, top online teachers lead hands-on classes live with our next-generation classroom technology. Achieve results for English, Math and Science at Primary and Secondary levels with our tuition classes. Enrich learning beyond mere grades with our featured learning programmes."
          />
          <meta
            name="keywords"
            content="Online tuition,Math tuition,English tuition,Secondary school tuition,Primary school tuition,Online tuition singapore,Online learning,Online education,Education platform,Online tutor,Home tutor,home tuition,science tuition,sa1,sa2,ca1,ca2,psle,revision,online revision,online study,online learning,psle revision"
          />
        </Helmet>
        <div className="container my-5">
          <div className="row justify-content-center">
            <div className="col-md-6 col-12">
              <div className="text-center mb-3">
                <h1 className="title--rubik">Welcome to Tenopy!</h1>
                <span className="text-large">You're joining this class:</span>
              </div>
              <Paper elevation={3} className="p-3">
                <h2 className="title--rubik mb-2">{classTitle}</h2>
                <div className="text-muted text-large">by Teacher {tutor}</div>
              </Paper>
            </div>
            <div className="col-md-5 offset-md-1 col-12">
              <div className="text-center">
                <h2 className="title--rubik mb-4">Complete registration to get started</h2>

                <div className="login-form">
                  <TextField
                    type="email"
                    helperText="This is the email address which will be used to contact you."
                    label="Email"
                    name="email"
                    fullWidth
                    value={email}
                    margin="normal"
                    className="mt-0 mb-3"
                    disabled
                  />

                  <TextField
                    type="text"
                    label="Student's Name"
                    name="name"
                    onChange={this.onChangeFormElement}
                    error={!!formError.name}
                    helperText={formError.name}
                    onFocus={this.onFocusFormElement}
                    fullWidth
                    defaultValue={name}
                    margin="normal"
                    className="mt-0 mb-2"
                  />

                  <div className="row align-items-end no-gutters mb-2">
                    <div className="col-auto">
                      <Select
                        value={countryCode}
                        onChange={this.onChangeFormElement}
                        className="mt-0"
                        name="countryCode">
                        {countrycodes.map(cc => {
                          return (
                            <MenuItem key={cc.code} value={cc.code}>
                              <span className={`flag ${cc.flag} mr-1`} /> +{cc.code}{' '}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </div>
                    <div className="col">
                      <TextField
                        type="text"
                        label="Phone Number"
                        name="phone"
                        onChange={this.onChangeFormElement}
                        error={!!formError.phone}
                        onFocus={this.onFocusFormElement}
                        InputProps={{ inputComponent: NumberOnly }}
                        fullWidth
                        value={phone}
                        margin="normal"
                        className="mt-0 mb-0"
                      />
                    </div>
                    <div className="col-12">
                      <FormHelperText error={!!formError.phone}>
                        {formError.phone ??
                          "We'll provide customer support over the phone in case you face any issues for your classes"}
                      </FormHelperText>
                    </div>
                  </div>

                  <TextField
                    type="password"
                    onChange={this.onChangeFormElement}
                    error={!!this.state.formError.password}
                    helperText={formError.password ?? 'minimum 6 characters'}
                    onFocus={this.onFocusFormElement}
                    id="required"
                    label="Set your own password"
                    name="password"
                    fullWidth
                    defaultValue={password}
                    margin="normal"
                    className="mt-0 mb-2"
                  />

                  <FormControl className="w-100 mb-2" error={!!formError.level}>
                    <InputLabel>Level/Grade</InputLabel>
                    <Select value={level} onChange={this.onChangeFormElement} name="level" className=" text-left">
                      {educationLevels.map(level => {
                        return (
                          <MenuItem key={level.code} value={level.code}>
                            {level.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                    {formError.level && <FormHelperText>{formError.level}</FormHelperText>}
                  </FormControl>

                  {showOthersLevel && (
                    <TextField
                      type="text"
                      label="Please specify your level"
                      name="othersLevel"
                      onChange={this.onChangeFormElement}
                      error={!!formError.othersLevel}
                      helperText={formError.othersLevel}
                      onFocus={this.onFocusFormElement}
                      fullWidth
                      defaultValue={othersLevel}
                      margin="normal"
                      className="mt-0 mb-4"
                    />
                  )}

                  <TextField
                    type="text"
                    label="School"
                    name="school"
                    onChange={this.onChangeFormElement}
                    error={!!formError.school}
                    helperText={formError.school}
                    onFocus={this.onFocusFormElement}
                    fullWidth
                    defaultValue={school}
                    margin="normal"
                    className="mt-0 mb-4"
                  />

                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={this.clickSignUp}
                    disabled={inProgress}>
                    Sign Up {inProgress && <CircularProgress size={24} className="ml-2" />}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Dialog open={accountAlreadyVerified} onClose={this.closeAccountAlreadyVerifiedDialog}>
          <DialogContent>
            <DialogContentText>You have done your account activation before. Please log in instead.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="text-capitalize"
              onClick={this.closeAccountAlreadyVerifiedDialog}
              color="primary"
              variant="contained">
              Log In
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuickSignup))
