import React from 'react'
import { connect } from 'react-redux'
import { Button, CircularProgress, Paper } from '@material-ui/core'
import { Description as DescriptionIcon } from '@material-ui/icons'
import _ from 'lodash'
import Proptypes from 'prop-types'

import agent from 'agent'
import { GET_LESSON_MATERIAL } from 'constants/actionTypes'

const mapStateToProps = state => ({
  loadingLessonMaterial: state.material.loadingLessonMaterial,
  lessonMaterial: state.material.lessonMaterial
})

const mapDispatchToProps = dispatch => ({
  getLessonMaterial: lessonId =>
    dispatch({
      type: GET_LESSON_MATERIAL,
      payload: agent.Lessons.getMaterial(lessonId),
      lessonId
    })
})

class ResourceSummaryWidget extends React.Component {
  componentDidMount() {
    this.props.getLessonMaterial(this.props.lessonId)
  }

  render() {
    const { lessonId, loadingLessonMaterial, lessonMaterial } = this.props

    if (loadingLessonMaterial[lessonId]) {
      return (
        <div className="text-center">
          <CircularProgress size={25} />
        </div>
      )
    }

    const materialList = lessonMaterial[lessonId] ?? []
    const materialTypes = _.uniq(_.map(materialList, 'type'))

    return (
      <Paper
        style={{
          backgroundColor: '#ffffff',
          border: 'solid 1px #6b37ea'
        }}>
        <div style={{ backgroundColor: '#6b37ea', padding: '0.8rem' }}>
          <h3 className="title--rubik text-white mb-0">Resources</h3>
        </div>

        <div style={{ padding: '0.8rem' }}>
          {materialList.length === 0 && <span>No resource uploaded yet</span>}
          {materialTypes.map((type, index) => {
            const typeCount = _.filter(materialList, o => {
              return o.type === type
            }).length
            return (
              <div className="d-flex align-items-center mb-1" key={index}>
                <DescriptionIcon className="mr-2" style={{ color: '#6b37ea' }} />
                {typeCount} {type} uploaded
              </div>
            )
          })}
          {materialList.length > 0 && (
            <Button
              variant="outlined"
              size="small"
              fullWidth
              className="mt-2"
              style={{
                color: '#6b37ea',
                borderColor: '#6b37ea'
              }}
              onClick={() => {
                this.props.viewLessonDetails(lessonId)()
              }}>
              View & Download
            </Button>
          )}
        </div>
      </Paper>
    )
  }
}

ResourceSummaryWidget.propTypes = {
  lessonId: Proptypes.string.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceSummaryWidget)
