import React from 'react'
import { connect } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import agent from '../../../agent'
import { GET_BLOG_POST } from '../../../constants/actionTypes'
import moment from 'moment'
import { Helmet } from 'react-helmet'

const mapStateToProps = state => ({
  loadingBlogPost: state.misc.loadingBlogPost,
  blogPost: state.misc.blogPost
})

const mapDispatchToProps = dispatch => ({
  getBlogPost: postTitle =>
    dispatch({
      type: GET_BLOG_POST,
      payload: agent.External.getJSON(
        `https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/json/${postTitle}.json`
      )
    })
})

class BlogPost extends React.Component {
  state = {
    postTitle: ''
  }

  componentDidMount() {
    this.props.getBlogPost(this.props.match.params.title)
  }

  render() {
    let { loadingBlogPost, blogPost } = this.props

    if (loadingBlogPost || !blogPost) {
      return (
        <div className="manage-margin text-center my-5">
          <CircularProgress size={50} />
        </div>
      )
    }

    return (
      <div className="landing bg-white">
        <Helmet>
          <title>{blogPost.title} | Tenopy</title>
          <meta
            name="description"
            content="At Tenopy, top online teachers lead hands-on classes live with our next-generation classroom technology. Achieve results for English, Math and Science at Primary and Secondary levels with our tuition classes. Enrich learning beyond mere grades with our featured learning programmes."
          />
          <meta
            name="keywords"
            content="Online tuition,Math tuition,English tuition,Secondary school tuition,Primary school tuition,Online tuition singapore,Online learning,Online education,Education platform,Online tutor,Home tutor,home tuition,science tuition,sa1,sa2,ca1,ca2,psle,revision,online revision,online study,online learning,psle revision"
          />
        </Helmet>

        <div className="border-bottom py-4" style={{ backgroundColor: '#efefef' }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10 col-12">
                <h1
                  className="mb-0"
                  style={{ fontSize: '1.8rem', fontWeight: '400', fontFamily: "'Rubik', sans-serif" }}>
                  {blogPost.title}
                </h1>
                {blogPost.author && (
                  <div className="mt-1">
                    by <span>{blogPost.author}</span> {moment(blogPost.publishedDate).fromNow()}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-md-10 col-12 text-justify">
              <div dangerouslySetInnerHTML={{ __html: blogPost.content }} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogPost)
