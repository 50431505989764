import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormLabel,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slide,
  TextField
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { Editor } from 'react-draft-wysiwyg'
import _ from 'lodash'
import Proptypes from 'prop-types'
import agent from 'agent'
import { CREATE_LESSON_ACTIVITY, GET_LESSON_BY_ID, UPDATE_LESSON_ACTIVITY } from 'constants/actionTypes'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const mapStateToProps = state => ({
  token: state.common.token,
  creating: state.activity.creating,
  updating: state.activity.updating
})

const mapDispatchToProps = dispatch => ({
  createActivity: reqPayload =>
    dispatch({ type: CREATE_LESSON_ACTIVITY, payload: agent.Activity.create('mcq', reqPayload) }),
  updateActivity: (type, id, reqPayload) =>
    dispatch({ type: UPDATE_LESSON_ACTIVITY, payload: agent.Activity.update(type, id, reqPayload), activityId: id }),
  getLesson: lessonId => dispatch({ type: GET_LESSON_BY_ID, payload: agent.Lessons.getById2(lessonId) })
})

class AddMCQDialog extends React.Component {
  state = {
    order: 1,
    question: EditorState.createEmpty(),
    hint: '',
    mcq_mode: 'single',
    raw_mcq_option: ['', '', '', ''],
    mcq_answer_index: [],
    alertMsg: ''
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const { activityData, projectedOrder } = this.props

      if (activityData) {
        // NOTE: decide later whether to include edit function
        const blocksFromHTML = convertFromHTML(activityData.content.question)
        const content = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
        const raw_mcq_option = _.map(activityData.content.option, 'content')
        this.setState({
          order: activityData.order,
          question: EditorState.createWithContent(content),
          hint: '',
          mcq_mode: activityData.content.mode,
          raw_mcq_option,
          mcq_answer_index: activityData.content.answer_index,
          alertMsg: ''
        })
      } else {
        this.setState({
          order: projectedOrder ?? 1,
          question: EditorState.createEmpty(),
          hint: '',
          mcq_mode: 'single',
          raw_mcq_option: ['', '', '', ''],
          mcq_answer_index: [],
          alertMsg: ''
        })
      }
    }

    if (prevProps.creating && !this.props.creating) {
      this.props.getLesson(this.props.lessonId)
      this.props.close()
    }

    if ((prevProps.updating && !this.props.updating) || (prevProps.updating && !this.props.updating)) {
      this.props.getLesson(this.props.lessonId)
      this.props.close()
    }
  }

  onEditorStateChange = element => editorState => {
    this.setState({ [element]: editorState })
  }

  uploadImageCallBack = file => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open('POST', `${process.env.REACT_APP_API_ROOT}/v2/misc/image-upload`)
      xhr.setRequestHeader('Authorization', `Bearer ${this.props.token}`)
      const data = new FormData()
      data.append('attachment', file)
      xhr.send(data)
      xhr.addEventListener('load', () => {
        const response = JSON.parse(xhr.responseText)
        resolve({ data: { link: response.url } })
      })
      xhr.addEventListener('error', () => {
        const error = JSON.parse(xhr.responseText)
        reject(error)
      })
    })
  }

  handleChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState({ [name]: value })
  }

  handleMCQAnswerOptionChange = index => event => {
    const { raw_mcq_option } = this.state
    let newOption = raw_mcq_option.slice(0)
    newOption[index] = event.target.value
    this.setState({ raw_mcq_option: newOption })
  }

  setMCQAnswer = index => () => {
    const { mcq_answer_index } = this.state
    let newAnswerIndex = mcq_answer_index
    if (mcq_answer_index.includes(index)) {
      newAnswerIndex = _.remove(mcq_answer_index, function (o) {
        return o !== index
      })
    } else {
      newAnswerIndex.push(index)
    }
    this.setState({ mcq_answer_index: newAnswerIndex })
  }

  addMCQAnswerOption = () => {
    const expandedRawOption = this.state.raw_mcq_option
    expandedRawOption.push('')
    this.setState({ raw_mcq_option: expandedRawOption })
  }

  handleSave = () => {
    const { lessonId, activityData } = this.props
    const { order, question, hint, mcq_mode, raw_mcq_option, mcq_answer_index } = this.state

    if (!question.getCurrentContent().hasText()) {
      this.setState({ alertMsg: 'Activity cannot have empty question body.' })
      return
    }

    if (_.compact(raw_mcq_option).length < 2) {
      this.setState({ alertMsg: 'MCQ activity must have at least 2 options.' })
      return
    }

    this.setState({ alertMsg: '' })

    let formattedOption = []
    for (let i = 0; i < raw_mcq_option.length; i++) {
      const opt = raw_mcq_option[i]
      if (opt) {
        formattedOption.push({
          label: i + 1,
          content: opt
        })
      }
    }
    // NOTE: some additional formatting due to editor problem
    let questionHtml = draftToHtml(convertToRaw(question.getCurrentContent()))
    questionHtml = questionHtml.replaceAll('text-align:none;', 'text-align:center;')
    questionHtml = questionHtml.replaceAll('<p></p>', '')

    if (activityData) {
      const reqPayload = {
        question: questionHtml,
        hint: hint.trim(),
        mode: mcq_mode,
        option: formattedOption,
        answer_index: mcq_answer_index
      }
      this.props.updateActivity(activityData.content._type, activityData.content._id, reqPayload)
    } else {
      const reqPayload = {
        lesson: {
          id: lessonId,
          order
        },
        activity: {
          question: questionHtml,
          hint: hint.trim(),
          mode: mcq_mode,
          option: formattedOption,
          answer_index: mcq_answer_index
        }
      }
      this.props.createActivity(reqPayload)
    }
  }

  updateActivity = () => {}

  render() {
    const { isOpen, activityData, creating, updating } = this.props
    const { order, question, mcq_mode, raw_mcq_option, mcq_answer_index, alertMsg } = this.state

    const inProgress = creating || updating

    return (
      <Dialog open={isOpen} maxWidth="md" fullWidth TransitionComponent={Transition}>
        <DialogTitle>
          <span className="h3 tittle--rubik">{activityData ? 'Edit' : 'Create'} Activity</span>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {activityData && (
            <Alert severity="info">
              NOTE: Editing this activity will result in changes to all lessons that use the same activity.
            </Alert>
          )}
          {alertMsg && <Alert severity="error">{alertMsg}</Alert>}

          <div className="mt-2 mb-3 d-flex align-items-center">
            {!activityData && (
              <TextField
                type="text"
                label="Order"
                name="order"
                variant="outlined"
                onChange={this.handleChange}
                value={order}
                className="mr-4"
              />
            )}

            <FormControl component="fieldset">
              <RadioGroup name="mcq_mode" value={mcq_mode} onChange={this.handleChange} row>
                <FormControlLabel
                  name="mcq_mode"
                  value="single"
                  control={<Radio color="primary" />}
                  label="Single choice"
                  labelPlacement="end"
                />
                <FormControlLabel
                  name="mcq_mode"
                  value="multiple"
                  control={<Radio color="primary" />}
                  label="Multiple choice"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </div>

          <div className="mb-3">
            <FormLabel component="legend">Question Body</FormLabel>
            <Editor
              editorState={question}
              onEditorStateChange={this.onEditorStateChange('question')}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              toolbar={{
                options: [
                  'inline',
                  'list',
                  'textAlign',
                  'colorPicker',
                  'link',
                  'embedded',
                  'emoji',
                  'image',
                  'history'
                ],
                image: {
                  uploadCallback: this.uploadImageCallBack,
                  previewImage: true,
                  alt: { present: true, mandatory: true }
                }
              }}
            />
          </div>

          {/*<div className="mb-3">{draftToHtml(convertToRaw(question.getCurrentContent()))}</div>*/}

          {/*<TextField
            type="text"
            label="Hint"
            name="hint"
            variant="outlined"
            onChange={this.handleChange}
            fullWidth
            value={hint}
            className="mb-3"
          />*/}

          <div className="mb-3">
            <FormLabel component="legend">Answer</FormLabel>
            {raw_mcq_option.map((item, index) => (
              <React.Fragment key={index}>
                <div className="d-flex flex-row">
                  <Checkbox
                    checked={mcq_answer_index.includes(index)}
                    onChange={this.setMCQAnswer(index)}
                    color="primary"
                  />
                  <TextField
                    label={`Answer ${index + 1} ${index > 1 ? '(optional)' : ''}`}
                    value={item}
                    onChange={this.handleMCQAnswerOptionChange(index)}
                    variant="outlined"
                    fullWidth={true}
                    margin="normal"
                  />
                </div>
              </React.Fragment>
            ))}
            <Button onClick={this.addMCQAnswerOption} color="primary">
              Add more
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="default" onClick={this.props.close} disabled={inProgress}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={this.handleSave} disabled={inProgress}>
            Save & Upload {inProgress && <CircularProgress className="ml-2" size={15} color="primary" />}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

AddMCQDialog.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired,
  lessonId: Proptypes.string.isRequired,
  activityData: Proptypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(AddMCQDialog)
